import React from "react"
import styled, { css } from "styled-components"
import { theme } from "../../theme"
import { height as headerHeight } from "../../components/header"

type Props = {
  type: 1 | 2 | 3
  children: React.ReactNode
}

export const Grid = (props: Props) => {
  switch (props.type) {
    case 1:
      return <Grid1>{props.children}</Grid1>
    case 2:
      return <Grid2>{props.children}</Grid2>
    case 3:
      return <Grid3>{props.children}</Grid3>
    default:
      return null
  }
}

const gridBasicsStyles = css`
  display: grid;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - ${headerHeight});
  padding: ${theme.offset1} 0 ${theme.offset3} 0;
`

/**
 * Grid 1 (login, welcome, password-recovery, password-reset)
 */

const Grid1 = styled("div")`
  ${gridBasicsStyles};
  grid-template-columns: max-content;
  grid-template-rows: max-content max-content;
  grid-row-gap: ${theme.offset1};
  justify-items: center;
  justify-content: center;
  align-content: center;
`

// Grid2 is for list, add and edit screens
const Grid2 = styled("div")`
  ${gridBasicsStyles};
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 20px minmax(50px, max-content) max-content max-content max-content;
  align-items: center;
  grid-template-areas:
    "breadcrumb breadcrumb breadcrumb"
    "heading  navigation  action"
    "content content content"
    "loading loading loading"
    "error error error";
`
// Grid3 is for user list, add and invite screens
const Grid3 = styled("div")`
  ${gridBasicsStyles};
  grid-template-columns: max-content 1fr 1fr 1fr;
  grid-template-rows: 20px minmax(50px, max-content) max-content max-content max-content;
  align-items: center;
  grid-template-areas:
    "breadcrumb breadcrumb breadcrumb breadcrumb"
    "heading search  navigation  action"
    "content content content content"
    "loading loading loading loading"
    "error error error error";

  @media all and (max-width: 1720px) {
    grid-template-rows: 20px minmax(50px, max-content) max-content max-content max-content max-content;
    grid-template-areas:
      "breadcrumb breadcrumb breadcrumb breadcrumb"
      "heading search action action"
      "navigation navigation navigation navigation"
      "content content content content"
      "loading loading loading loading"
      "error error error error";
  }
`
