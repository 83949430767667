import { MutationResult, MutationFunction } from "react-apollo"
import {
  LayoutCreate,
  LayoutCreateVariables,
  LayoutDelete,
  LayoutDeleteVariables,
  Layouts,
  LayoutsVariables,
} from "model/types"
import { QUERY_LAYOUTS } from "model/graphql/queries"
import { cloneDeep } from "lodash"
import { ApolloError, MutationUpdaterFn } from "apollo-client"

/**
 * Create Layout
 */

export type LayoutCreateMutation = MutationFunction<LayoutCreate, LayoutCreateVariables>
export type LayoutCreateMutationUpdater = MutationUpdaterFn<LayoutCreate>

export type UseCreateLayoutArgs = {
  onSuccessAction: () => void
  onErrorAction: (errorMessage: string) => void
  queryVariables: LayoutsVariables
}

export const useCreateLayout = (args: UseCreateLayoutArgs) => {
  const { onErrorAction, onSuccessAction, queryVariables } = args
  const createLayout = (layoutCreateMutation: LayoutCreateMutation, variables: LayoutCreateVariables) => async () => {
    try {
      await layoutCreateMutation({
        variables: variables,
        update: updateLayouts,
      })
      onSuccessAction()
    } catch (error) {
      console.error(error)

      const { message } = error as ApolloError
      onErrorAction(message)
    }
  }

  const updateLayouts: LayoutCreateMutationUpdater = (cache, payload) => {
    try {
      const { layouts } = payload.data.layoutCreate
      const data: Layouts = cloneDeep(cache.readQuery({ query: QUERY_LAYOUTS, variables: queryVariables }))
      data.layouts = layouts

      cache.writeQuery({
        query: QUERY_LAYOUTS,
        variables: queryVariables,
        data: data,
      })
    } catch (error) {
      console.error(error)
    }
  }

  return { createLayout }
}

/**
 * Delete Layout
 */

export type LayoutDeleteMutation = MutationFunction<LayoutDelete, LayoutDeleteVariables>
export type LayoutDeleteMutationResult = MutationResult<LayoutDelete>
export type LayoutDeleteMutationUpdater = MutationUpdaterFn<LayoutDelete>

export type UseDeleteLayoutArgs = {
  onSuccessAction?: () => void
  onErrorAction?: (errorMessage: string) => void
  queryVariables: LayoutsVariables
}

export const useDeleteLayout = (args: UseDeleteLayoutArgs) => {
  const { onSuccessAction, onErrorAction, queryVariables } = args

  const deleteLayout = (deleteLayoutMutation: LayoutDeleteMutation, variables: LayoutDeleteVariables) => async () => {
    try {
      await deleteLayoutMutation({
        variables: { id: variables.id },
        update: updateLayouts({
          layoutId: variables.id,
          queryVariables,
        }),
      })
      if (onSuccessAction) {
        onSuccessAction()
      }
    } catch (error) {
      console.error(error)
      if (onErrorAction) {
        const { message } = error as ApolloError
        onErrorAction(message)
      }
    }
  }

  type UpdateLayoutsArgs = {
    layoutId: string
    queryVariables: LayoutsVariables
  }

  const updateLayouts = (args: UpdateLayoutsArgs): LayoutDeleteMutationUpdater => (cache, payload) => {
    const { layoutId, queryVariables } = args

    if (!payload.data.layoutDelete.deleted) {
      return
    }

    try {
      const data: Layouts = cloneDeep(cache.readQuery({ query: QUERY_LAYOUTS, variables: queryVariables }))

      data.layouts.edges = [...data.layouts.edges.filter(({ node: layout }) => layout.id !== layoutId)]

      cache.writeQuery({
        query: QUERY_LAYOUTS,
        variables: queryVariables,
        data: data,
      })
    } catch (error) {
      console.error(error)
    }
  }

  return { deleteLayout }
}

/**
 *
 */

export const getFormattedWarning = (errorMessage: string): string => {
  if (errorMessage.includes("layout_name_already_exists")) return "Layout name must be unique"
  return ""
}
