import gql from "graphql-tag"
import { fragments } from "model/graphql/fragments"

export const QUERY_RULES = gql`
  query Rules($sortBy: String, $desc: Boolean) {
    rules(sortBy: $sortBy, desc: $desc) {
      edges {
        node {
          ...RuleData
          layout {
            ...LayoutData
          }
        }
      }
    }
  }
  ${fragments.rule}
  ${fragments.layout}
`
