const KEY_AUTH_TOKEN = "auth_token"
const KEY_REFRESH_TOKEN = "auth_refreshToken"

export const setToken = (token: string) => window.localStorage.setItem(KEY_AUTH_TOKEN, token)
export const removeToken = () => window.localStorage.removeItem(KEY_AUTH_TOKEN)
export const getToken = () => window.localStorage.getItem(KEY_AUTH_TOKEN)

export const setRefreshToken = (refreshToken: string) => window.localStorage.setItem(KEY_REFRESH_TOKEN, refreshToken)
export const removeRefreshToken = () => window.localStorage.removeItem(KEY_REFRESH_TOKEN)
export const getRefreshToken = () => window.localStorage.getItem(KEY_REFRESH_TOKEN)

export const hasToken = () => Boolean(window.localStorage.getItem(KEY_AUTH_TOKEN))

export const hasTokenExpirationError = (graphQLErrors: readonly any[]) => {
  if (graphQLErrors && graphQLErrors.length !== 0) {
    return graphQLErrors.some(
      (graphQLError: any) =>
        new RegExp("signature has expired", "i").test(graphQLError.message) ||
        new RegExp("you do not have permission to perform this action", "i").test(graphQLError.message)
    )
  }
  return false
}
