import React from "react"
import { Route, RouteProps } from "react-router"
import styled from "styled-components"
import { NavigationLink } from "../links"
import { theme } from "theme"

type Props = {
  path: RouteProps["path"]
  children: string
}

export const BreadcrumbLink = (props: Props) => {
  return (
    <Route
      path={props.path}
      render={({ match }) => {
        if (match.isExact) {
          return <Text>{props.children}</Text>
        }

        return (
          <NavigationLink color={"green"} weight={"semiBold"} size={"14px"} to={match.url}>
            {props.children}
          </NavigationLink>
        )
      }}
    />
  )
}

const Text = styled("span")`
  font-weight: ${theme.font.weight.semiBold};
  font-size: 14px;
  color: ${theme.color.textBlack};
`
