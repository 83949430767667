import React from "react"
import { theme } from "theme"
import {
  AutoSizer,
  Column,
  Index,
  Table as VirtualizedTable,
  TableCellDataGetterParams,
  TableCellProps,
  TableCellRenderer,
  TableHeaderProps,
} from "react-virtualized"
import "react-virtualized/styles.css"
import { NoContent } from "components/no-content"
import { NoContentWrapper } from "screens/screen"
import styled, { css } from "styled-components"
import { height as headerHeight } from "components/header"

/**
 * Method returns CSS style for Table Cell
 *
 * @param colsCount
 * @param currentColumnIndex
 */

const getCellCssStyle = (colsCount: number, currentColumnIndex: number): React.CSSProperties => {
  if (currentColumnIndex === 0) {
    return { paddingLeft: theme.offset3, paddingRight: theme.offset1, whiteSpace: "normal" }
  }
  if (colsCount - 1 === currentColumnIndex) {
    return { textAlign: "right", paddingRight: "40px" }
  }
  return { paddingRight: theme.offset1 }
}

type TableContent = "users" | "vendors" | "rules" | "quizzes" | "layouts"

type Props = {
  content: {
    head: React.ReactNode[]
    body: React.ReactNode[][]
  }
  noContentNotification: string
  contentType?: TableContent
  pageHeaderHasNavigation?: boolean
  children?: never
}

/**
 * Table Component
 *
 * @param props
 * @constructor
 */

export const Table = (props: Props) => {
  const { noContentNotification, contentType, pageHeaderHasNavigation } = props
  const { head, body } = props.content

  /**
   * Returns Cell Data for Table Body
   *
   * @param props
   */

  const cellDataGetter = (props: TableCellDataGetterParams) => {
    const { rowData, dataKey } = props
    return rowData[dataKey]
  }

  /**
   * Returns Row Data Set for Table Body
   *
   * @param props
   */

  const rowDataGetter = (props: Index) => {
    const { index } = props
    if (body.length > 0) {
      return { ...body[index] }
    }
  }

  /**
   * Render Table Cell
   *
   * @param props
   */

  const cellRenderer: TableCellRenderer = (props: TableCellProps) => {
    const { cellData, columnIndex } = props

    return <div style={getCellCssStyle(body[0].length, columnIndex)}>{cellData}</div>
  }

  /**
   * Render Header Cell
   *
   * @param props
   */

  const headerRenderer = (props: TableHeaderProps) => {
    const { dataKey } = props
    return <div style={getCellCssStyle(head.length, parseInt(dataKey))}>{head[parseInt(dataKey)]}</div>
  }

  const columnPropertyArray = getColumnWidthArray(contentType)

  /**
   * JSX
   */

  return (
    <AutoSizeWrapper hasNavigation={pageHeaderHasNavigation}>
      <AutoSizer>
        {({ width, height }) => (
          <VirtualizedTable
            rowStyle={(info: Index) => {
              return { background: `${info.index % 2 === 0 ? theme.color.white : theme.color.backgroundGray}` }
            }}
            disableHeader={false}
            headerHeight={80}
            height={height}
            rowHeight={80}
            overscanRowCount={10}
            noRowsRenderer={() => (
              <NoContentWrapper>
                <NoContent>{noContentNotification}</NoContent>
              </NoContentWrapper>
            )}
            rowGetter={rowDataGetter}
            rowCount={body.length}
            width={width}
          >
            {body.length > 0 &&
              body[0].map((col, index) => (
                <Column
                  key={`column-${index}`}
                  cellDataGetter={cellDataGetter}
                  headerRenderer={headerRenderer}
                  cellRenderer={cellRenderer}
                  dataKey={`${index}`}
                  width={columnPropertyArray[index].width}
                  minWidth={columnPropertyArray[index].minWidth}
                  flexGrow={columnPropertyArray[index].flexGrow}
                  style={
                    columnPropertyArray[index].whiteSpace
                      ? ({ whiteSpace: columnPropertyArray[index].whiteSpace } as React.CSSProperties)
                      : {}
                  }
                />
              ))}
          </VirtualizedTable>
        )}
      </AutoSizer>
    </AutoSizeWrapper>
  )
}

type AutoSizeWrapperProps = {
  hasNavigation: boolean
}
const AutoSizeWrapper = styled("div")`
  height: calc(100vh - ${headerHeight} - 130px - 40px);
  width: inherit;
  position: relative;
  ${(props: AutoSizeWrapperProps) =>
    props.hasNavigation
      ? css`
          @media all and (max-width: 1440px) {
            height: calc(100vh - ${headerHeight} - 202px - 40px);
          }
        `
      : ""}
`

/**
 * Computes column parameters array depends on table content
 *
 * @param content
 */

const getColumnWidthArray = (content: TableContent) => {
  switch (content) {
    case "users":
      return [
        { width: 100, minWidth: 100, flexGrow: 0 },
        { width: 200, minWidth: 200, flexGrow: 1 },
        { width: 200, minWidth: 200, flexGrow: 1 },
        { width: 200, minWidth: 200, flexGrow: 0 },
        { width: 100, minWidth: 100, flexGrow: 0 },
        { width: 150, minWidth: 150, flexGrow: 0 },
      ]
    case "quizzes":
      return [
        { width: 550, minWidth: 550, flexGrow: 1 },
        { width: 350, minWidth: 200, flexGrow: 1, whiteSpace: "normal" },
        { width: 350, minWidth: 150, flexGrow: 0 },
        { width: 200, minWidth: 150, flexGrow: 0 },
        { width: 150, minWidth: 150, flexGrow: 0 },
        { width: 150, minWidth: 150, flexGrow: 0 },
      ]

    default:
      return [{ width: 300, minWidth: 300, flexGrow: 1 }, { width: 200, minWidth: 200, flexGrow: 0 }]
  }
}
