import React from "react"
import { Link, LinkProps } from "react-router-dom"
import styled, { css } from "styled-components"
import { theme } from "theme"

type Props = {
  color: "green" | "red"
  weight: "semiBold" | "regular"
  size: "16px" | "14px" | "12px"
  isCapitalized?: boolean
  isDisabled?: boolean
  hasVisitedColor?: boolean
  children: React.ReactNode
} & LinkProps

export const NavigationLink = (props: Props) => {
  const { color, size, weight, isCapitalized, isDisabled, hasVisitedColor, to, children, ...rest } = props

  if (isDisabled) {
    return (
      <DisabledLink
        isCapitalized={isCapitalized}
        hasVisitedColor={hasVisitedColor}
        size={size}
        color={color}
        isDisabled={isDisabled}
        weight={weight}
      >
        {children}
      </DisabledLink>
    )
  }

  return (
    <StyledLink
      isCapitalized={isCapitalized}
      hasVisitedColor={hasVisitedColor}
      size={size}
      color={color}
      isDisabled={isDisabled}
      weight={weight}
      to={to}
      {...rest}
    >
      {children}
    </StyledLink>
  )
}

type LinkTemplateProps = {
  color: Props["color"]
  weight: Props["weight"]
  isDisabled: boolean
  size: Props["size"]
  hasVisitedColor: Props["hasVisitedColor"]
  isCapitalized: Props["isCapitalized"]
}

const linkTemplate = css`
  text-decoration: none;
  font-weight: ${(props: LinkTemplateProps) =>
    props.weight === "semiBold" ? theme.font.weight.semiBold : theme.font.weight.regular};
  color: ${(props: LinkTemplateProps) => {
    if (props.color === "green") {
      return props.isDisabled ? theme.color.primaryGreenLight : theme.color.primaryGreen
    }
    if (props.color === "red") {
      return props.isDisabled ? theme.color.warningRedLight : theme.color.warningRed
    }
  }};
  font-size: ${(props: LinkTemplateProps) => props.size};
  line-height: ${(props: LinkTemplateProps) => {
    if (props.size === "16px") {
      return "22px"
    }
    if (props.size === "14px") {
      return "19px"
    }
    if (props.size === "12px") {
      return "17px"
    }
  }};
  ${(props: LinkTemplateProps) => (props.isCapitalized ? "text-transform: uppercase" : "")};

  &:hover {
    text-decoration: ${(props: LinkTemplateProps) => (props.isDisabled ? "none" : "underline")};
    cursor: ${(props: LinkTemplateProps) => (props.isDisabled ? "not-allowed" : "pointer")};
    color: ${(props: LinkTemplateProps) => {
      if (props.color === "green") {
        return props.isDisabled ? theme.color.primaryGreenLight : theme.color.primaryGreenDark
      }
      if (props.color === "red") {
        return props.isDisabled ? theme.color.warningRedLight : theme.color.warningRedDark
      }
    }};
  }

  &:visited {
    ${(props: LinkTemplateProps) => (props.hasVisitedColor ? `color: ${theme.color.textBlack}` : "")};
  }
`

const StyledLink = styled(
  ({ color, weight, size, hasVisitedColor, isDisabled, isCapitalized, children, ...rest }: Props) => (
    <Link {...rest}>{children}</Link>
  )
)`
  ${linkTemplate};
`

const DisabledLink = styled("span")`
  ${linkTemplate};
`
