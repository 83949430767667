//Quizzes

import gql from "graphql-tag"
import { fragments } from "model/graphql/fragments"

export const QUERY_QUIZZES = gql`
  query Quizzes($sortBy: String, $desc: Boolean) {
    quizzes(sortBy: $sortBy, desc: $desc) {
      edges {
        node {
          ...QuizData
        }
      }
    }
    me {
      id
    }
  }
  ${fragments.quiz}
`
