import gql from "graphql-tag"
import { MutationResult, MutationFunction } from "react-apollo"
import { UnpublishQuiz, UnpublishQuizVariables } from "model/types"
import { fragments } from "model/graphql/fragments"

/**
 * Unpublish Quiz
 */

// Mutation

export const MUTATION_UNPUBLISH_QUIZ = gql`
  mutation UnpublishQuiz($id: ID!) {
    quizUnpublish(input: { id: $id }) {
      clientMutationId
      unpublished
      quiz {
        ...QuizData
      }
    }
  }
  ${fragments.quiz}
`

// Hook

export type UseUnpublishQuizArgs = {
  onStartAction: () => void
  onSuccessAction: () => void
  onErrorAction: () => void
}

export const useUnpublishQuiz = (args: UseUnpublishQuizArgs) => {
  const { onStartAction, onSuccessAction, onErrorAction } = args

  const unpublishQuiz = (
    unpublishQuizMutation: UnpublishQuizMutation,
    variables: UnpublishQuizVariables
  ) => async () => {
    try {
      onStartAction()
      await unpublishQuizMutation({ variables })
      onSuccessAction()
    } catch (error) {
      console.error(error)
      onErrorAction()
    }
  }

  return { unpublishQuiz }
}

/**
 * Unpublish Quiz Mutation Types
 */

export type UnpublishQuizMutation = MutationFunction<UnpublishQuiz, UnpublishQuizVariables>
export type UnpublishQuizMutationResult = MutationResult<UnpublishQuiz>
