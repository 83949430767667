import gql from "graphql-tag"
import { fragments } from "../fragments"

/**
 * Rule Create
 */

export const MUTATION_RULE_CREATE = gql`
  mutation RuleCreate($name: String, $utmSource: String, $layoutId: ID, $sortBy: String, $desc: Boolean) {
    ruleCreate(input: { name: $name, utmSource: $utmSource, layoutId: $layoutId, sortBy: $sortBy, desc: $desc }) {
      rule {
        ...RuleData
        layout {
          ...LayoutData
        }
      }
      rules {
        edges {
          node {
            ...RuleData
            layout {
              ...LayoutData
            }
          }
        }
      }
      clientMutationId
    }
  }
  ${fragments.rule}
  ${fragments.layout}
`

/**
 * Rule Change
 */

export const MUTATION_RULE_CHANGE = gql`
  mutation RuleChange($id: ID!, $name: String, $utmSource: String, $layoutId: ID) {
    ruleChange(input: { id: $id, name: $name, utmSource: $utmSource, layoutId: $layoutId }) {
      rule {
        ...RuleData
        layout {
          ...LayoutData
        }
      }
      clientMutationId
    }
  }
  ${fragments.rule}
  ${fragments.layout}
`

/**
 * Rule Delete
 */

export const MUTATION_RULE_DELETE = gql`
  mutation RuleDelete($id: ID!) {
    ruleDelete(input: { id: $id }) {
      deleted
      clientMutationId
    }
  }
`
