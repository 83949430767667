import TextareaAutosize from "react-textarea-autosize"
import styled, { css } from "styled-components"
import { theme } from "theme"

const template = css`
  border: none;
  border-radius: ${theme.rounding1};
  padding: 13px 14px;
  color: ${theme.color.textBlack};
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  resize: none;

  &:focus {
    outline: 0;
    border: none;
  }

  &::placeholder {
    font-family: ${theme.font.family.default};
    color: ${theme.color.textNonactive};
    line-height: 22px;
  }

  &:disabled {
    color: ${theme.color.textNonactive};
    cursor: not-allowed;

    &::placeholder {
      color: ${theme.color.borderGray};
    }
  }
`

export const AutoResizableTextarea = styled(TextareaAutosize)`
  ${template};
  font-family: ${theme.font.family.default};
`

export const AutoResizableCodeTextarea = styled(TextareaAutosize)`
  ${template};
  font-family: ${theme.font.family.code};
`
