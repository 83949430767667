import React from "react"
import { Portal } from "components/portal"
import { Modal, ModalIcon, ModalSection } from "components/modal"
import { Heading2 } from "components/typography"
import { IconDone } from "components/icons"
import { theme } from "theme"

type Props = {
  isModalOpen: boolean
  message: string
  children?: never
}

export const SuccessModal = (props: Props) => {
  const { isModalOpen, message = `The changes have been saved` } = props

  return (
    <Portal>
      <Modal isVisible={isModalOpen}>
        <ModalSection>
          <ModalIcon>
            <IconDone color={theme.color.primaryGreen} />
          </ModalIcon>
        </ModalSection>
        <ModalSection topOffset={"40px"}>
          <Heading2>{message}</Heading2>
        </ModalSection>
      </Modal>
    </Portal>
  )
}
