import gql from "graphql-tag"
import { fragments } from "model/graphql/fragments"

export const QUERY_RULE = gql`
  query Rule($id: ID!) {
    rule(id: $id) {
      ...RuleData
      layout {
        ...LayoutData
      }
    }
    layouts(sortBy: "name") {
      edges {
        node {
          ...LayoutData
        }
      }
    }
  }
  ${fragments.rule}
  ${fragments.layout}
`
