import { fragments } from "model/graphql/fragments"
import gql from "graphql-tag"

/**
 * Layout Create
 */

export const MUTATION_LAYOUT_CREATE = gql`
  mutation LayoutCreate($name: String, $sortBy: String, $desc: Boolean) {
    layoutCreate(input: { name: $name, sortBy: $sortBy, desc: $desc }) {
      layout {
        ...LayoutData
        variantSet {
          edges {
            node {
              ...VariantData
            }
          }
        }
      }
      layouts {
        edges {
          node {
            ...LayoutData
            variantSet {
              edges {
                node {
                  ...VariantData
                }
              }
            }
          }
        }
      }
      clientMutationId
    }
  }
  ${fragments.layout}
  ${fragments.variant}
`

/**
 * Layout Change
 */

export const MUTATION_LAYOUT_CHANGE = gql`
  mutation LayoutChange($id: ID!, $name: String) {
    layoutChange(input: { id: $id, name: $name }) {
      layout {
        ...LayoutData
      }
      clientMutationId
    }
  }
  ${fragments.layout}
`

/**
 * Layout Delete
 */

export const MUTATION_LAYOUT_DELETE = gql`
  mutation LayoutDelete($id: ID!) {
    layoutDelete(input: { id: $id }) {
      deleted
      clientMutationId
    }
  }
`
