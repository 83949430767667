import React from "react"
import Logo2x from "./static/Logo@2x.png"

type Props = {
  children?: never
}

export const ImageLogo = (props: Props) => {
  return <img src={Logo2x} alt="logo" />
}
