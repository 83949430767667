import React from "react"
import { IconProps } from "./types"
import { Svg } from "./styles"

type Props = IconProps

export const IconDown = (props: Props) => {
  return (
    <Svg color={props.color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 11.003">
      <g id="Down_Icon" transform="translate(-1439.5 -253.5)">
        <path
          d="M358.514,6422.867l10,10,10-10"
          transform="translate(1081.486 -6168.867)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </Svg>
  )
}
