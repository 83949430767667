import React, { useState } from "react"
import styled from "styled-components"
import { Box } from "components/box/"
import { theme } from "theme"
import { Label } from "components/label"
import { Textarea } from "components/textarea"
import { actionTypes, FormDispatch, FormState } from "./form-state"
import { Checkbox } from "components/checkbox/"
import { CodeEditor } from "components/code-editor"
import { RoundButton } from "components/round-button"
import { IconResize } from "components/icons/resize"
import { Switch } from "components/switch"
import { includes, without } from "lodash"
import { slotNames } from "utils"
import { Warning } from "components/warning"

type Props = {
  children?: never
  state: FormState
  dispatch: FormDispatch
}

type EditorValueType = "initialScript" | "wipeScript" | "refreshScript"

export const Form = (props: Props) => {
  const { state, dispatch } = props
  const { name, initialScript, wipeScript, isPersistent, slotCompatibility, refreshScript, nameWarning } = state
  const [isEditorOpened, setIsEditorOpened] = useState(false)
  const [codeToEdit, setCodeToEdit] = useState<EditorValueType>(null)
  const [editorHeading, setEditorHeading] = useState("")

  const getDefaultEditorValue = (editorValueType: EditorValueType): string => {
    if (editorValueType === "initialScript") {
      return initialScript
    }
    if (editorValueType === "wipeScript") {
      return wipeScript
    }
    if (editorValueType === "refreshScript") {
      return refreshScript
    }
    return ""
  }

  /**
   * Set Name
   * @param value
   */

  const setName = (value: string) => {
    dispatch({ type: actionTypes.setName, data: { name: value } })
    dispatch({ type: actionTypes.setNameWarning, data: { nameWarning: "" } })
  }

  /**
   * Set Initial Script
   * @param value
   */

  const setInitialScript = (value: string) =>
    dispatch({ type: actionTypes.setInitialScript, data: { initialScript: value } })

  /**
   * Set Refresh Script
   *
   * @param value
   */

  const setRefreshScript = (value: string) =>
    dispatch({ type: actionTypes.setRefreshScript, data: { refreshScript: value } })

  /**
   * Set Wipe Script
   * @param value
   */

  const setWipeScript = (value: string) => dispatch({ type: actionTypes.setWipeScript, data: { wipeScript: value } })

  /**
   * Set Persistence
   * @param value
   */

  const setIsPersistent = (value: boolean) =>
    dispatch({ type: actionTypes.setIsPersistent, data: { isPersistent: value } })

  /**
   * Set Initial or Wipe Script in Editor
   * @param editorValueType
   */

  const setEditorValue = (editorValueType: EditorValueType) => {
    if (editorValueType === "initialScript") {
      return setInitialScript
    }
    if (editorValueType === "wipeScript") {
      return setWipeScript
    }
    if (editorValueType === "refreshScript") {
      return setRefreshScript
    }
  }

  /**
   * Set Slot Compatibility
   * @param slotName
   */

  const setSlotCompatibility = ({ value: slotName }: { value?: string }) => {
    if (includes(slotCompatibility, slotName)) {
      const newSlotCompatibility = without(slotCompatibility, slotName)
      dispatch({ type: actionTypes.setSlotCompatibility, data: { slotCompatibility: newSlotCompatibility } })
    } else {
      const newSlotCompatibility = [...slotCompatibility, slotName]
      dispatch({ type: actionTypes.setSlotCompatibility, data: { slotCompatibility: newSlotCompatibility } })
    }
  }

  /**
   * JSX
   */

  return (
    <React.Fragment>
      <Container>
        <Box>
          <StyledForm>
            <FormRow gridArea={"unit-name"}>
              <Label>{`Unit Name`}</Label>
              <Textarea value={name} onChange={setName} hasWarning={Boolean(nameWarning)} />
              <Warning message={nameWarning} />
            </FormRow>

            <FormRow gridArea={"persistent-action"}>
              <Label>{`Persistent`}</Label>
              <SwitchWrapper>
                <Switch checked={isPersistent} onChange={setIsPersistent} />
              </SwitchWrapper>
            </FormRow>

            <FormRow gridArea={"initial-script"}>
              <Label>{`Initialization Routine`}</Label>
              <Textarea value={initialScript} hasStaticHeight={true} onChange={setInitialScript} fontType={"CODE"} />
              <ActionWrapper>
                <RoundButton
                  onClick={() => {
                    setCodeToEdit("initialScript")
                    setEditorHeading("Initialization Routine")
                    setIsEditorOpened(true)
                  }}
                >
                  <IconResize />
                </RoundButton>
              </ActionWrapper>
            </FormRow>

            <FormRow gridArea={"refresh-script"}>
              <Label>{`Refresh Routine`}</Label>
              <Textarea
                value={refreshScript}
                hasStaticHeight={true}
                onChange={setRefreshScript}
                fontType={"CODE"}
                isDisabled={!isPersistent}
              />
              <ActionWrapper>
                <RoundButton
                  onClick={() => {
                    setCodeToEdit("refreshScript")
                    setEditorHeading("Refresh Routine")
                    setIsEditorOpened(true)
                  }}
                  isDisabled={!isPersistent}
                >
                  <IconResize />
                </RoundButton>
              </ActionWrapper>
            </FormRow>

            <FormRow gridArea={"wipe-script"}>
              <Label>{`Wipe Routine`}</Label>
              <Textarea value={wipeScript} hasStaticHeight={true} onChange={setWipeScript} fontType={"CODE"} />
              <ActionWrapper>
                <RoundButton
                  onClick={() => {
                    setCodeToEdit("wipeScript")
                    setEditorHeading("Wipe Routine")
                    setIsEditorOpened(true)
                  }}
                >
                  <IconResize />
                </RoundButton>
              </ActionWrapper>
            </FormRow>

            <FormRow gridArea={"compatibility"}>
              <LabelCheckbox>{`Slot Compatibility`}</LabelCheckbox>
              <CheckboxArea>
                <Checkbox
                  isChecked={includes(slotCompatibility, slotNames.below_score)}
                  onChange={setSlotCompatibility}
                  value={slotNames.below_score}
                >
                  {slotNames.below_score}
                </Checkbox>

                <Checkbox
                  isChecked={includes(slotCompatibility, slotNames.below_response)}
                  onChange={setSlotCompatibility}
                  value={slotNames.below_response}
                >
                  {slotNames.below_response}
                </Checkbox>

                <Checkbox
                  isChecked={includes(slotCompatibility, slotNames.above_correct_answer)}
                  onChange={setSlotCompatibility}
                  value={slotNames.above_correct_answer}
                >
                  {slotNames.above_correct_answer}
                </Checkbox>

                <Checkbox
                  isChecked={includes(slotCompatibility, slotNames.above_answer_pic)}
                  onChange={setSlotCompatibility}
                  value={slotNames.above_answer_pic}
                >
                  {slotNames.above_answer_pic}
                </Checkbox>

                <Checkbox
                  isChecked={includes(slotCompatibility, slotNames.below_answer_pic)}
                  onChange={setSlotCompatibility}
                  value={slotNames.below_answer_pic}
                >
                  {slotNames.below_answer_pic}
                </Checkbox>

                <Checkbox
                  isChecked={includes(slotCompatibility, slotNames.paragraph_splitter)}
                  onChange={setSlotCompatibility}
                  value={slotNames.paragraph_splitter}
                >
                  {slotNames.paragraph_splitter}
                </Checkbox>

                <Checkbox
                  isChecked={includes(slotCompatibility, slotNames.below_correct_answer)}
                  onChange={setSlotCompatibility}
                  value={slotNames.below_correct_answer}
                >
                  {slotNames.below_correct_answer}
                </Checkbox>

                <Checkbox
                  isChecked={includes(slotCompatibility, slotNames.above_headline)}
                  onChange={setSlotCompatibility}
                  value={slotNames.above_headline}
                >
                  {slotNames.above_headline}
                </Checkbox>

                <Checkbox
                  isChecked={includes(slotCompatibility, slotNames.below_headline)}
                  onChange={setSlotCompatibility}
                  value={slotNames.below_headline}
                >
                  {slotNames.below_headline}
                </Checkbox>

                <Checkbox
                  isChecked={includes(slotCompatibility, slotNames.above_buttons)}
                  onChange={setSlotCompatibility}
                  value={slotNames.above_buttons}
                >
                  {slotNames.above_buttons}
                </Checkbox>

                <Checkbox
                  isChecked={includes(slotCompatibility, slotNames.below_buttons_1)}
                  onChange={setSlotCompatibility}
                  value={slotNames.below_buttons_1}
                >
                  {slotNames.below_buttons_1}
                </Checkbox>

                <Checkbox
                  isChecked={includes(slotCompatibility, slotNames.below_buttons_2)}
                  onChange={setSlotCompatibility}
                  value={slotNames.below_buttons_2}
                >
                  {slotNames.below_buttons_2}
                </Checkbox>

                <Checkbox
                  isChecked={includes(slotCompatibility, slotNames.below_buttons_3)}
                  onChange={setSlotCompatibility}
                  value={slotNames.below_buttons_3}
                >
                  {slotNames.below_buttons_3}
                </Checkbox>

                <Checkbox
                  isChecked={includes(slotCompatibility, slotNames.below_second_buttons_1)}
                  onChange={setSlotCompatibility}
                  value={slotNames.below_second_buttons_1}
                >
                  {slotNames.below_second_buttons_1}
                </Checkbox>

                <Checkbox
                  isChecked={includes(slotCompatibility, slotNames.below_second_buttons_2)}
                  onChange={setSlotCompatibility}
                  value={slotNames.below_second_buttons_2}
                >
                  {slotNames.below_second_buttons_2}
                </Checkbox>

                <Checkbox
                  isChecked={includes(slotCompatibility, slotNames.below_second_buttons_3)}
                  onChange={setSlotCompatibility}
                  value={slotNames.below_second_buttons_3}
                >
                  {slotNames.below_second_buttons_3}
                </Checkbox>

                <Checkbox
                  isChecked={includes(slotCompatibility, slotNames.page_level_1)}
                  onChange={setSlotCompatibility}
                  value={slotNames.page_level_1}
                >
                  {slotNames.page_level_1}
                </Checkbox>

                <Checkbox
                  isChecked={includes(slotCompatibility, slotNames.page_level_2)}
                  onChange={setSlotCompatibility}
                  value={slotNames.page_level_2}
                >
                  {slotNames.page_level_2}
                </Checkbox>

                <Checkbox
                  isChecked={includes(slotCompatibility, slotNames.page_level_3)}
                  onChange={setSlotCompatibility}
                  value={slotNames.page_level_3}
                >
                  {slotNames.page_level_3}
                </Checkbox>

                <Checkbox
                  isChecked={includes(slotCompatibility, slotNames.right_rail_upper)}
                  onChange={setSlotCompatibility}
                  value={slotNames.right_rail_upper}
                >
                  {slotNames.right_rail_upper}
                </Checkbox>

                <Checkbox
                  isChecked={includes(slotCompatibility, slotNames.right_rail_middle)}
                  onChange={setSlotCompatibility}
                  value={slotNames.right_rail_middle}
                >
                  {slotNames.right_rail_middle}
                </Checkbox>

                <Checkbox
                  isChecked={includes(slotCompatibility, slotNames.right_rail_lower)}
                  onChange={setSlotCompatibility}
                  value={slotNames.right_rail_lower}
                >
                  {slotNames.right_rail_lower}
                </Checkbox>
              </CheckboxArea>
            </FormRow>
          </StyledForm>
        </Box>
      </Container>
      <CodeEditor
        onChange={setEditorValue(codeToEdit)}
        editorHeading={editorHeading}
        defaultEditorState={getDefaultEditorValue(codeToEdit)}
        isEditorOpened={isEditorOpened}
        closeEditor={() => {
          setIsEditorOpened(false)
        }}
      />
    </React.Fragment>
  )
}

const Container = styled("div")`
  display: block;
  position: relative;
  width: 100%;
  max-width: 760px;
`

const StyledForm = styled("form")`
  display: grid;
  position: relative;
  padding: 40px;
  width: 760px;
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content max-content max-content;
  grid-row-gap: ${theme.offset3};
  grid-template-areas:
    "unit-name"
    "persistent-action"
    "initial-script"
    "refresh-script"
    "wipe-script"
    "compatibility";
`

type FormRowProps = {
  gridArea: string
}

const FormRow = styled("div")`
  display: grid;
  position: relative;
  grid-template-columns: 120px 1fr;
  grid-template-rows: max-content max-content;
  grid-template-areas:
    "label ."
    "warning warning";
  grid-column-gap: 10px;
  align-items: start;
  grid-area: ${(props: FormRowProps) => props.gridArea};
`

const CheckboxArea = styled("div")`
  display: grid;
  align-items: start;

  grid-template-columns: 1fr 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  grid-template-areas:
    "types types"
    "types types"
    "types types"
    "types types"
    "types types"
    "types types"
    "types types";
`

const LabelCheckbox = styled("label")`
  display: grid;
  align-items: start;
  grid-area: label;

  grid-template-columns: 130px 1fr 1fr;

  color: ${theme.color.textBlack};
  font-weight: ${theme.font.weight.semiBold};
  font-size: 16px;
  line-height: 22px;
`
const ActionWrapper = styled("div")`
  position: absolute;
  bottom: -20px;
  right: -20px;
`
const SwitchWrapper = styled("div")`
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 14px;
`
