import React, { useEffect } from "react"
import { IconEye, IconEyeClose } from "components/icons"
import styled from "styled-components"
import { theme } from "theme"
import { TextField } from "components/text-field"

type Props = {
  placeholder?: string
  value: string
  hasWarning?: boolean
  onChange: (value: string) => void
  onBlur?: () => void
  isDisabled?: boolean
  isFocused?: boolean
  type?: "text" | "password"
  children?: never
}

export const Input = (props: Props) => {
  const { type = "text" } = props

  const inputRef = React.useRef(null)
  const [isFocused, setIsFocused] = React.useState(false)
  const [isValueHidden, setIsValueHidden] = React.useState(type === "password")

  useEffect(() => {
    if (props.isFocused && Boolean(inputRef.current)) {
      inputRef.current.focus()
    }
  }, [props.isFocused])

  const toggleVisibility = () => {
    if (!props.isDisabled) {
      if (inputRef && inputRef.current) {
        inputRef.current.focus()
      }
      setIsValueHidden(!isValueHidden)
    }
  }

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true)
    if (event.target.value) event.target.select()
  }

  const handleBlur = () => {
    setIsFocused(false)
    if (props.onBlur) {
      props.onBlur()
    }
  }

  return (
    <TextField
      isDisabled={props.isDisabled}
      isFocused={isFocused}
      hasWarning={props.hasWarning}
      hasNoLeftBorder={false}
    >
      {type === "password" && (
        <ToggleButton
          onClick={toggleVisibility}
          isDisabled={props.isDisabled}
          isFocused={isFocused}
          hasValue={Boolean(props.value)}
        >
          {isValueHidden ? <IconEye /> : <IconEyeClose />}
        </ToggleButton>
      )}

      <StyledInput
        ref={inputRef}
        placeholder={props.placeholder}
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
        disabled={props.isDisabled}
        type={isValueHidden ? "password" : "text"}
        inputType={isValueHidden ? "password" : "text"}
        onFocus={handleFocus}
        onBlur={handleBlur}
        hasHiddenValue={isValueHidden}
      />
    </TextField>
  )
}

type ToggleButtonProps = {
  isDisabled: boolean
  isFocused: boolean
  hasValue: boolean
}

const ToggleButton = styled("div")`
  display: flex;
  position: absolute;
  right: 20px;
  top: 15px;
  width: 20px;
  height: 20px;
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
  cursor: ${(props: ToggleButtonProps) => (props.isDisabled ? "not-allowed" : "pointer")};

  &:focus {
    outline: none;
  }

  svg {
    stroke: ${(props: ToggleButtonProps) => {
      if (!props.hasValue || props.isDisabled) {
        return theme.color.borderGray
      }
      if (props.isFocused && props.hasValue) {
        return theme.color.textBlack
      }
      return theme.color.textNonactive
    }};
  }
`

type StyledInputProps = {
  hasHiddenValue: boolean
  inputType: Props["type"]
}

const StyledInput = styled("input")`
  display: flex;
  border: none;
  font-family: ${(props: StyledInputProps) =>
    props.hasHiddenValue ? "Verdana, sans-serif" : theme.font.family.default};
  font-weight: ${theme.font.weight.regular};
  font-size: 16px;
  background-color: transparent;
  line-height: 22px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 14px;
  padding-right: ${(props: StyledInputProps) => (props.inputType === "password" ? "60px" : "14px")};
  color: ${theme.color.textBlack};
  height: 48px;
  width: 100%;

  &::placeholder {
    font-family: ${theme.font.family.default};
    color: ${theme.color.textNonactive};
    line-height: 22px;
  }

  &:focus {
    outline: 0;
    border: none;
  }

  &:disabled {
    color: ${theme.color.textNonactive};
    cursor: not-allowed;

    &::placeholder {
      color: ${theme.color.borderGray};
    }
  }
`
