import React from "react"
import { IconProps } from "./types"
import { Svg } from "./styles"

type Props = IconProps

export const IconClose = (props: Props) => {
  return (
    <Svg color={props.color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.414 21.414">
      <g id="Close_Icon" transform="translate(-1219.276 -351.293)">
        <line
          x1="20"
          y2="20"
          transform="translate(1219.983 352)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          x1="20"
          y1="20"
          transform="translate(1219.983 352)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </Svg>
  )
}
