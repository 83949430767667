import { createGlobalStyle } from "styled-components"
import { theme } from "theme"

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  
  body {
    font-family: ${theme.font.family.default};
    font-size: 16px;
    font-weight: ${theme.font.weight.regular};
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: ${theme.color.backgroundGray};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .ReactVirtualized__Grid.ReactVirtualized__List {
    &:focus{
      outline: none;
    }
  }
  
  .ReactVirtualized__Table__row {
    height: 80px;
    overflow: visible !important;
     
    border-radius: ${theme.offset3};
  
    &:hover {
      svg {
        visibility: visible;
      }
     }
  }
 
  .ReactVirtualized__Grid.ReactVirtualized__Table__Grid {
    padding-top: ${theme.offset1};
    
    &:focus{
      outline: none;
    }
  }
 
  .ReactVirtualized__Table__rowColumn {
    overflow: visible !important;
  }
 
  .ReactVirtualized__Grid__innerScrollContainer{
    overflow: visible !important;
  }
 
`
