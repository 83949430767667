import { ApolloClient } from "apollo-client"
import { InMemoryCache } from "apollo-cache-inmemory"
import { onError } from "apollo-link-error"
import { ApolloLink, Observable } from "apollo-link"
import { createUploadLink } from "apollo-upload-client"
import { getToken, hasTokenExpirationError } from "utils"
import { refreshAuthToken } from "./authentication"
import { setContext } from "apollo-link-context"

/**
 * Print Errors To Console
 */

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      )
    if (networkError) console.error(`[Network error]: ${networkError}`)
  }
})

/**
 * Refresh Auth Token
 */

const refreshAuthTokenLink = onError(({ graphQLErrors, operation, forward }) => {
  if (hasTokenExpirationError(graphQLErrors)) {
    return new Observable(observer => {
      refreshAuthToken()
        .then(response => {
          if (response) {
            operation.setContext({
              headers: {
                authorization: `JWT ${response.newToken}`,
              },
            })
          }
        })
        .then(() => {
          forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          })
        })
        .catch(error => {
          observer.error(error)
        })
    })
  }
})

/**
 * Set Authorization Header
 */

const authLink = setContext((_, { headers }) => {
  const token = getToken()
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : "",
    },
  }
})

/**
 * Apollo Client
 */

export const client = new ApolloClient({
  link: ApolloLink.from([
    refreshAuthTokenLink,
    errorLink,
    authLink,
    createUploadLink({
      uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
      credentials: "include",
    }),
  ]),
  cache: new InMemoryCache(),
})
