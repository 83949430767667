import React from "react"
import styled from "styled-components"
import { IconAlert } from "../icons"
import { theme } from "theme"
import { animated, config, useTransition } from "react-spring"

type Props = {
  message: string
  children?: never
}

export const Warning = (props: Props) => {
  const [warningMessage, setWarningMessage] = React.useState<string>(undefined)

  React.useEffect(() => {
    if (props.message) {
      setWarningMessage(props.message)
    }
  }, [props.message])

  const transitions = useTransition(Boolean(props.message), null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.default,
  })

  return (
    <React.Fragment>
      {transitions.map(
        transition =>
          transition.item && (
            <Container style={transition.props} key={transition.key}>
              <span className={"message"}>{warningMessage}</span>
              <div className={"icon-wrapper"}>
                <IconAlert color={theme.color.warningRed} />
              </div>
            </Container>
          )
      )}
    </React.Fragment>
  )
}

const Container = styled(animated.div)`
  display: flex;
  position: relative;
  justify-content: flex-end;
  justify-self: end;
  grid-area: warning;
  padding-top: 6px;

  .message {
    color: ${theme.color.textNonactive};
    font-size: 12px;
    text-align: right;
  }

  .icon-wrapper {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    margin-top: 1px;
    margin-left: 5px;
    width: 15px;
    height: 15px;
  }
`
