import React, { useState } from "react"
import styled from "styled-components"
import { theme } from "theme"

type Props = {
  isChecked: boolean
  isDisabled?: boolean
  onChange?: ({ value, state }: { value?: string; state?: boolean }) => void
  value?: string
  children?: string
}

export const Checkbox = (props: Props) => {
  const { isChecked, isDisabled, onChange, value, children } = props
  const [isFocused, setIsFocused] = useState(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ value: event.target.value, state: !isChecked })
  }

  const handleFocus = () => setIsFocused(true)

  const handleBlur = () => setIsFocused(false)

  return (
    <ComponentWrapper>
      <CheckboxContainer isDisabled={isDisabled} isFocused={isFocused}>
        <Cube isDisabled={isDisabled} isChecked={isChecked} />
      </CheckboxContainer>
      <CheckboxOptionText isDisabled={isDisabled}>{children}</CheckboxOptionText>
      <CheckboxInput
        type={"checkbox"}
        readOnly={true}
        checked={isChecked}
        onChange={handleChange}
        value={value}
        disabled={isDisabled}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </ComponentWrapper>
  )
}

const ComponentWrapper = styled("div")`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

type CheckboxContainerProps = {
  isDisabled: boolean
  isFocused: boolean
}

const CheckboxContainer = styled("div")`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  border: 1px solid
    ${(props: CheckboxContainerProps) =>
      props.isDisabled
        ? theme.color.backgroundGray
        : props.isFocused
        ? theme.color.textNonactive
        : theme.color.borderGray};
  background-color: ${theme.color.backgroundGray};
  border-radius: ${theme.rounding1};
`

type CubeProps = {
  isChecked: boolean
  isDisabled: boolean
}

const Cube = styled("div")`
  display: flex;
  position: relative;
  width: 13px;
  height: 13px;
  background-color: ${(props: CubeProps) =>
    props.isDisabled ? theme.color.primaryGreenLight : theme.color.primaryGreen};
  border-radius: 1px;
  opacity: ${(props: CubeProps) => (props.isChecked ? "1" : "0")};
`

type CheckboxOptionTextProps = {
  isDisabled: boolean
}

const CheckboxOptionText = styled("span")`
  font-size: 16px;
  font-weight: ${theme.font.weight.regular};
  color: ${(props: CheckboxOptionTextProps) => (props.isDisabled ? theme.color.textNonactive : theme.color.textBlack)};
  margin-left: 10px;
`

const CheckboxInput = styled("input")`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;
  opacity: 0;

  &:disabled {
    cursor: not-allowed;
  }
`
