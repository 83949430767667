import React from "react"
import { Redirect, RouteComponentProps } from "react-router"
import { Query, QueryResult } from "react-apollo"
import { QUERY_ME } from "model/graphql/queries"
import { Me } from "model/types"
import { Role } from "utils"
import { quizzesTabs, routes } from "router"
import { useSelector } from "react-redux"
import { ReduxState } from "redux-store"
import { isEqual } from "lodash"

type Props = {
  children?: never
} & RouteComponentProps

export const RootScreen = (props: Props) => {
  const auth = useSelector((state: ReduxState) => state.auth, isEqual)

  if (!auth.isAuthenticated) return <Redirect to={routes.login} />

  return (
    <Query query={QUERY_ME}>
      {({ error, loading, data }: QueryResult<Me>) => {
        if (error || loading || !data || !data.me) {
          return null
        }

        const role = data.me.groups.edges[0].node.name as Role

        if (role === "Ads Manager") {
          return <Redirect to={routes.vendors} />
        }

        return <Redirect to={routes.quizzes.replace(":tab", quizzesTabs.all)} />
      }}
    </Query>
  )
}
