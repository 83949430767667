import { combineReducers, createStore } from "redux"
import {
  authReducer,
  AuthState,
  listsReducer,
  ListsState,
  quizGeneralReducer,
  QuizGeneralState,
  quizQuestionsReducer,
  QuizQuestionsState,
} from "redux-reducers"

/**
 * Reducers
 */

export type ReduxState = {
  lists: ListsState
  quizGeneral: QuizGeneralState
  quizQuestions: QuizQuestionsState
  auth: AuthState
}

const reducers = combineReducers<ReduxState>({
  lists: listsReducer,
  quizGeneral: quizGeneralReducer,
  quizQuestions: quizQuestionsReducer,
  auth: authReducer,
})

/**
 * Store
 */

export const store = createStore(reducers)
