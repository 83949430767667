import React from "react"
import styled from "styled-components"
import { theme } from "theme"

type Props = {
  children?: React.ReactNode
}

export const SlotsBottomSection = (props: Props) => {
  return <Container>{props.children}</Container>
}

const Container = styled("div")`
  display: grid;
  position: relative;
  grid-auto-flow: row;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, max-content);
  grid-template-areas:
    "page_level_1"
    "page_level_2"
    "page_level_3";
  grid-row-gap: ${theme.offset1};
  width: 100%;
  max-width: 740px;
  padding: ${theme.offset3};
  grid-area: slots-bottom-section;
  border-bottom-left-radius: ${theme.rounding1};
  border-bottom-right-radius: ${theme.rounding1};
`
