import React from "react"
import { IconProps } from "./types"
import { Svg } from "./styles"

type Props = IconProps

export const IconShrink = (props: Props) => {
  return (
    <Svg color={props.color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
      <g>
        <path
          d="M16.273,7.149l-6.445,-0.015l0,-6.428l0,6.428l6.445,-6.428"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M0.708,16.266l6.441,-6.438l-6.441,0l6.441,0l0.001,6.439"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </Svg>
  )
}
