import gql from "graphql-tag"
import { fragments } from "model/graphql/fragments"

export const QUERY_QUIZ = gql`
  query Quiz($id: ID!) {
    quiz(id: $id) {
      ...QuizData
    }
    users {
      edges {
        node {
          ...UserData
        }
      }
    }
    tags {
      edges {
        node {
          ...TagData
        }
      }
    }
  }
  ${fragments.quiz}
  ${fragments.user}
  ${fragments.tag}
`
