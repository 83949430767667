import React, { useContext, useEffect, useRef, useState } from "react"
import { History } from "history"
import { actionTypes, ModalContext } from "providers/modal"
import { RouteComponentProps } from "react-router"
import { routes } from "router"
import { Select } from "components/select"

/**
 * Handle Click Outside
 */

type UseHandleClickOutsideArgs = {
  ref: React.MutableRefObject<any> | undefined
  handler: () => void
}

export const useHandleClickOutside = (args: UseHandleClickOutsideArgs) => {
  const { ref, handler } = args

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref && ref.current.contains(event.target)) {
        return
      }
      handler()
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  })
}

/**
 * Button Focus
 */

type UseFocusOnButtonArgs = {
  ref: React.MutableRefObject<HTMLButtonElement>
}

export const useFocusOnButton = (args: UseFocusOnButtonArgs) => {
  const { ref } = args

  useEffect(() => {
    if (ref.current) {
      ref.current.focus()
    }
  })
}

/**
 * Prevent navigating away
 */

type UsePreventNavigatingAwayArgs = {
  history: History<any>
}

export const usePreventNavigatingAway = (args: UsePreventNavigatingAwayArgs) => {
  const {
    state: { canLeavePage },
    dispatch,
  } = useContext(ModalContext)
  const { history } = args

  useEffect(() => {
    const unblock = history.block(nextLocation => {
      if (!canLeavePage) {
        dispatch({ type: actionTypes.openLeaveModal })
        dispatch({ type: actionTypes.setNextLocation, value: nextLocation })
        return false
      }
    })

    return () => unblock()
  }, [canLeavePage, dispatch, history])
}

/**
 * Activate success modal
 */

export const useActivateSuccessModal = () => {
  const defaultMessage = `The changes have been saved`
  const { dispatch } = useContext(ModalContext)

  const activateSuccessModal = (message: string = defaultMessage) => {
    dispatch({ type: actionTypes.setSuccessModalMessage, value: message })
    dispatch({ type: actionTypes.openSuccessModal })
    window.setTimeout(() => dispatch({ type: actionTypes.closeSuccessModal }), 2000)
  }

  return { activateSuccessModal }
}

/**
 * Open Loading Modal
 */

export const useOpenLoadingModal = () => {
  const defaultMessage = `Loading...`
  const { dispatch } = useContext(ModalContext)

  const openLoadingModal = (message: string = defaultMessage) => {
    dispatch({ type: actionTypes.setLoadingMessage, value: message })
    dispatch({ type: actionTypes.openLoadingModal })
  }

  return { openLoadingModal }
}

/**
 * Close Loading
 */

export const useCloseLoadingModal = () => {
  const { dispatch } = useContext(ModalContext)

  const closeLoadingModal = () => {
    dispatch({ type: actionTypes.closeLoadingModal })
  }

  return { closeLoadingModal }
}

/**
 * Extend Page Title
 */

export const useChangePageTitle = (path: RouteComponentProps["match"]["path"]) => {
  const appTitle = process.env.REACT_APP_TITLE

  useEffect(() => {
    const getTitle = () => {
      switch (path) {
        case routes.login:
          return `Login | ${appTitle}`

        case routes.welcome:
          return `Welcome | ${appTitle}`

        case routes.passwordRecovery:
          return `Password Recovery | ${appTitle}`

        case routes.passwordReset:
          return `Password Reset | ${appTitle}`

        case routes.quizzes:
        case routes.quiz:
        case routes.quizGeneral:
        case routes.quizQuestions:
          return `Quizzes | ${appTitle}`

        case routes.users:
        case routes.user:
          return `Users | ${appTitle}`

        case routes.vendors:
        case routes.vendorCreate:
        case routes.vendorEdit:
          return `Vendors | ${appTitle}`

        case routes.units:
        case routes.unitCreate:
        case routes.unitEdit:
          return `Units | ${appTitle}`

        case routes.layouts:
        case routes.layout:
          return `Layouts | ${appTitle}`

        case routes.layoutRules:
        case routes.layoutRuleCreate:
        case routes.layoutRuleEdit:
          return `Layout Rules | ${appTitle}`

        case routes.myProfile:
          return `My Profile | ${appTitle}`

        case routes.root:
        default:
          return appTitle
      }
    }

    document.title = getTitle()
  }, [path])
}

/**
 * Select Options Filter
 */

export const useSelectOptionsFilter = (selectOptions: React.ComponentProps<typeof Select>["options"]) => {
  const [searchValue, setSearchValue] = useState("")
  const [filteredSelectOptions, setFilteredSelectOptions] = useState(selectOptions)

  useEffect(() => {
    if (selectOptions.length !== 0) {
      const newSelectOptions = selectOptions.filter(option => new RegExp(searchValue, "gi").test(option.value))
      setFilteredSelectOptions(newSelectOptions)
    }
  }, [searchValue, selectOptions])

  return { searchValue, setSearchValue, filteredSelectOptions }
}

/**
 * Is Mounted
 */

export const useIsMounted = () => {
  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  return isMounted
}

/**
 * Gets String value, witch will debounced and Number delay
 *
 * Returns debounced value
 *
 * @param value
 * @param delay
 */

export const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}
