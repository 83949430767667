import React from "react"
import { IconProps } from "./types"
import { Svg } from "./styles"

type Props = IconProps

export const IconSettings = (props: Props) => {
  return (
    <Svg color={props.color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 21">
      <g id="Icon_Settings_Black" transform="translate(-1452.5 -739.5)">
        <path
          id="Path_161"
          d="M400.972,123.11l1.164-2.76a6.926,6.926,0,0,0,1.721-1.04l2.885.327a10.225,10.225,0,0,0,1.618-2.907l-1.714-2.44a7.723,7.723,0,0,0,0-2.08l1.714-2.44a10.225,10.225,0,0,0-1.618-2.907l-2.885.327a6.926,6.926,0,0,0-1.721-1.04l-1.164-2.76a9.35,9.35,0,0,0-3.224,0l-1.164,2.76a6.926,6.926,0,0,0-1.721,1.04l-2.885-.327a10.225,10.225,0,0,0-1.618,2.907l1.714,2.44a7.717,7.717,0,0,0,0,2.08l-1.714,2.44a10.225,10.225,0,0,0,1.618,2.907l2.885-.327a6.926,6.926,0,0,0,1.721,1.04l1.164,2.76a9.35,9.35,0,0,0,3.224,0Z"
          transform="translate(1062.64 636.75)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <circle
          id="Ellipse_52"
          cx="5"
          cy="5"
          r="5"
          transform="translate(1457 745)"
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </g>
    </Svg>
  )
}
