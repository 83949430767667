import React from "react"
import { QUERY_VARIANT } from "model/graphql/queries/variant"
import { Variant, VariantVariables } from "model/types"
import { Query, QueryResult } from "react-apollo"
import { PreviewHead } from "screens/layout/variant-preview/preview-head"
import { Notes } from "screens/layout/variant-preview/notes"
import styled from "styled-components"
import { theme } from "theme"
import { RouteComponentProps } from "react-router"
import { DeleteVariantModal, EditVariantModal } from "screens/layout/modals"
import { Box } from "components/box"
import { PreviewBody } from "screens/layout/variant-preview/preview-body"
import { usePreventNavigatingAway } from "utils"
import { Loading } from "components/data-status"

type Props = {
  children?: never
} & RouteComponentProps<{ layoutId: string; variantId: string }>

export const VariantPreview = (props: Props) => {
  const { history, match } = props
  const { layoutId, variantId } = match.params

  usePreventNavigatingAway({ history })

  return (
    <React.Fragment>
      <Query query={QUERY_VARIANT} variables={{ id: variantId } as VariantVariables}>
        {({ error, loading, data }: QueryResult<Variant>) => {
          if (error) {
            return null
          }
          if (!data || loading) {
            return (
              <Container>
                <StyledBox>
                  <LoadingContainer>
                    <Loading>Loading...</Loading>
                  </LoadingContainer>
                </StyledBox>
              </Container>
            )
          }
          return (
            <Container>
              <StyledBox>
                <React.Fragment>
                  <PreviewHead data={data} variantId={variantId} />
                  <PreviewBody data={data} />
                  <EditVariantModal data={data} variantId={variantId} />
                </React.Fragment>
              </StyledBox>

              <Notes data={data} />
            </Container>
          )
        }}
      </Query>
      <DeleteVariantModal layoutId={layoutId} variantId={variantId} history={history} />
    </React.Fragment>
  )
}

const Container = styled("div")`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`

const StyledBox = styled(Box)`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 1240px;
  padding: ${theme.offset3};
  border-top-left-radius: 0;
`

const LoadingContainer = styled("div")`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: flex-start;
  height: 3775px;
  width: 100%;
  padding: ${theme.offset3};
`
