import React, { useState } from "react"
import { TextField } from "components/text-field"
import { AutoResizableCodeTextarea, AutoResizableTextarea } from "./auto-resizable-textarea"

type Props = {
  placeholder?: string
  value: string
  hasWarning?: boolean
  onChange: (text: string) => void
  isDisabled?: boolean
  hasStaticHeight?: boolean
  hasMinimalHeight?: boolean
  fontType?: "DEFAULT" | "CODE"
  children?: never
}

export const Textarea = (props: Props) => {
  const { isDisabled, hasWarning, fontType, placeholder, value, hasStaticHeight, onChange, hasMinimalHeight } = props
  const [isFocused, setIsFocused] = useState(false)

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value)
  }

  const handleFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    setIsFocused(true)
    if (event.target.value) event.target.select()
  }

  const handleBlur = () => setIsFocused(false)

  return (
    <TextField isFocused={isFocused} hasWarning={hasWarning} isDisabled={isDisabled} hasNoLeftBorder={false}>
      {fontType === "CODE" ? (
        <AutoResizableCodeTextarea
          placeholder={placeholder}
          value={value !== null ? value : ""}
          onChange={handleChange}
          minRows={hasStaticHeight ? 6 : 1}
          maxRows={hasMinimalHeight ? 1 : 6}
          disabled={isDisabled}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      ) : (
        <AutoResizableTextarea
          placeholder={placeholder}
          value={value !== null ? value : ""}
          onChange={handleChange}
          minRows={hasStaticHeight ? 6 : 1}
          maxRows={hasMinimalHeight ? 1 : 6}
          disabled={isDisabled}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      )}
    </TextField>
  )
}
