import React from "react"
import { actionTypes as formActionTypes, FormDispatch, FormState } from "screens/quiz/questions/form-state"
import { ImageUpload, MiniImageUpload } from "components/image-upload"
import { Radio } from "components/radio"
import styled from "styled-components"
import { debounce } from "lodash"
import { QuestionChangeVariables } from "model/types"
import { changeQuestion } from "model/question"
import { getFormStateVariables, setAutosaveStatus } from "screens/quiz/questions/helpers"
import { useDispatch } from "react-redux"

/**
 * Debounced Question Mutations
 */

const debouncedChangeQuestion = debounce(
  (variables: { quizId: string; fromOrder?: number } & QuestionChangeVariables, successCallback: () => void) =>
    changeQuestion(variables, successCallback),
  1000,
  {
    leading: true,
    trailing: true,
  }
)

type Props = {
  children?: never
  formDispatch: FormDispatch
  formState: FormState
}

export const QuestionImageArea = (props: Props) => {
  const { formDispatch, formState } = props
  const dispatch = useDispatch()

  const handleChangeTitleImagePreview = (url: string): void => {
    formDispatch({ type: formActionTypes.setImagePreview, data: { imagePreview: url } })
  }

  const handleChangeTitleImageFile = async (file: File) => {
    setAutosaveStatus({ dispatch, status: "saving" })
    formDispatch({ type: formActionTypes.setImageFile, data: { imageFile: file } })
    await debouncedChangeQuestion(
      {
        ...getFormStateVariables(formState),
        image: file,
        deleteImage: !file,
      },
      () => setAutosaveStatus({ dispatch, status: "saved" })
    )
  }

  const handleChangeExplainImagePreview = (url: string): void => {
    formDispatch({ type: formActionTypes.setExplainImagePreview, data: { explainImagePreview: url } })
  }

  const handleChangeExplainImageFile = async (file: File) => {
    setAutosaveStatus({ dispatch, status: "saving" })
    formDispatch({ type: formActionTypes.setExplainImageFile, data: { explainImageFile: file } })
    await debouncedChangeQuestion(
      {
        ...getFormStateVariables(formState),
        explainImage: file,
        deleteExplainImage: !file,
      },
      () => setAutosaveStatus({ dispatch, status: "saved" })
    )
  }

  const handleChangeCorrectImagePreview = (url: string): void => {
    formDispatch({ type: formActionTypes.setCorrectAnswerImagePreview, data: { correctAnswerImagePreview: url } })
  }

  const handleChangeCorrectImageFile = async (file: File) => {
    setAutosaveStatus({ dispatch, status: "saving" })
    formDispatch({ type: formActionTypes.setCorrectAnswerImageFile, data: { correctAnswerImageFile: file } })

    await debouncedChangeQuestion(
      {
        ...getFormStateVariables(formState),
        correctAnswerImage: file,
        deleteCorrectAnswerImage: !file,
      },
      () => setAutosaveStatus({ dispatch, status: "saved" })
    )

    setAutosaveStatus({ dispatch, status: "saved" })
  }

  const handleChangeWrongImagePreview = (url: string): void => {
    formDispatch({ type: formActionTypes.setWrongAnswerImagePreview, data: { wrongAnswerImagePreview: url } })
  }

  const handleChangeWrongImageFile = async (file: File) => {
    setAutosaveStatus({ dispatch, status: "saving" })
    formDispatch({ type: formActionTypes.setWrongAnswerImageFile, data: { wrongAnswerImageFile: file } })
    await debouncedChangeQuestion(
      {
        ...getFormStateVariables(formState),
        wrongAnswerImage: file,
        deleteWrongAnswerImage: !file,
      },
      () => setAutosaveStatus({ dispatch, status: "saved" })
    )
  }

  const handleChangeQuestionType = async (type: string) => {
    setAutosaveStatus({ dispatch, status: "saving" })
    formDispatch({ type: formActionTypes.setQuestionType, data: { questionType: type } })
    await debouncedChangeQuestion(
      {
        ...getFormStateVariables(formState),
        type: type.toLowerCase(),
      },
      () => setAutosaveStatus({ dispatch, status: "saved" })
    )
  }

  const getImageArea = (state: FormState) => {
    // Question Image for "title" Question Type
    if (state.questionType === "title") {
      return (
        <ImageArea style={{ gridColumnStart: "2", gridColumnEnd: "3", gridRowStart: "1", gridRowEnd: "4" }}>
          <ImageUpload
            previewImage={formState.imagePreview}
            setImageFile={handleChangeTitleImageFile}
            setImagePreview={handleChangeTitleImagePreview}
          />
        </ImageArea>
      )
    }

    // Question Image for "answer" Question Type
    if (state.questionType === "answer") {
      return (
        <React.Fragment>
          <ImageArea style={{ gridColumnStart: "2", gridColumnEnd: "3", gridRowStart: "2", gridRowEnd: "3" }}>
            <MiniImageUpload
              previewImage={formState.correctAnswerImagePreview}
              setImageFile={handleChangeCorrectImageFile}
              setImagePreview={handleChangeCorrectImagePreview}
            />
          </ImageArea>

          <ImageArea style={{ gridColumnStart: "2", gridColumnEnd: "3", gridRowStart: "3", gridRowEnd: "4" }}>
            <MiniImageUpload
              setImageFile={handleChangeWrongImageFile}
              setImagePreview={handleChangeWrongImagePreview}
              previewImage={formState.wrongAnswerImagePreview}
            />
          </ImageArea>
        </React.Fragment>
      )
    }

    // Question Image for "explain" Question Type
    if (state.questionType === "explain") {
      return (
        <React.Fragment>
          <ImageArea style={{ gridColumnStart: "2", gridColumnEnd: "3", gridRowStart: "1", gridRowEnd: "4" }}>
            <ImageUpload
              previewImage={formState.imagePreview}
              setImageFile={handleChangeTitleImageFile}
              setImagePreview={handleChangeTitleImagePreview}
            />
          </ImageArea>
          <ImageArea style={{ gridColumnStart: "2", gridColumnEnd: "3", gridRowStart: "4", gridRowEnd: "5" }}>
            <MiniImageUpload
              previewImage={formState.explainImagePreview}
              setImageFile={handleChangeExplainImageFile}
              setImagePreview={handleChangeExplainImagePreview}
            />
          </ImageArea>
        </React.Fragment>
      )
    }
  }

  return (
    <React.Fragment>
      {getImageArea(formState)}
      <RadioArea
        style={{
          gridColumnStart: "2",
          gridColumnEnd: "3",
          gridRowStart: formState.questionType === "explain" ? "5" : "4",
          gridRowEnd: "6",
        }}
      >
        <Radio isChecked={formState.questionType === "title"} value={"title"} onChange={handleChangeQuestionType}>
          Image for Title
        </Radio>
        <Radio isChecked={formState.questionType === "answer"} value={"answer"} onChange={handleChangeQuestionType}>
          Image for Answers
        </Radio>
        <Radio isChecked={formState.questionType === "explain"} value={"explain"} onChange={handleChangeQuestionType}>
          Image for Explanation
        </Radio>
      </RadioArea>
    </React.Fragment>
  )
}

const RadioArea = styled("div")`
  display: grid;
  max-height: 90px;
  grid-row-gap: 10px;
`
const ImageArea = styled("div")``
