import React from "react"
import { IconProps } from "./types"
import { Svg } from "./styles"

type Props = IconProps

export const IconResize = (props: Props) => {
  return (
    <Svg color={props.color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g>
        <path
          d="M8.001,0.928l7.069,0.002l0.002,7.069l-0.002,-7.069l-14.14,14.14l-0.002,-7.069l0.002,7.069l7.071,0.002"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </Svg>
  )
}
