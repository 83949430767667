import styled from "styled-components"

export const FileInput = styled("input")`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`
