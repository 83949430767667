import gql from "graphql-tag"

/**
 * Start Rebuild
 */

export const MUTATION_START_REBUILD_JS = gql`
  mutation RebuildJs {
    rebuildJs(input: {}) {
      success
      clientMutationId
    }
  }
`
