import { MutationResult, MutationFunction } from "react-apollo"
import { UnitChange, UnitChangeVariables, UnitCreate, UnitCreateVariables, Units, UnitsVariables } from "model/types"
import { QUERY_UNITS } from "model/graphql/queries"
import { formatNameValue } from "utils"
import { ApolloError, MutationUpdaterFn } from "apollo-client"
import { cloneDeep } from "lodash"

/**
 * Create Unit
 */

export type UnitCreateMutation = MutationFunction<UnitCreate, UnitCreateVariables>
export type UnitCreateMutationResult = MutationResult<UnitCreate>
export type UnitCreateMutationUpdater = MutationUpdaterFn<UnitCreate>

export type UseCreateUnitArgs = {
  onSuccessAction: (vendorId: string, unitId: string) => void
  onErrorAction: (errorMessage: string) => void
  queryVariables: UnitsVariables
}

export const useCreateUnit = (args: UseCreateUnitArgs) => {
  const { onSuccessAction, onErrorAction, queryVariables } = args

  const createUnit = async (unitCreateMutation: UnitCreateMutation, variables: UnitCreateVariables) => {
    try {
      const result = (await unitCreateMutation({
        variables: {
          vendorId: variables.vendorId,
          name: formatNameValue(variables.name),
          initialScript: variables.initialScript,
          wipeScript: variables.wipeScript,
          refreshScript: !variables.persistent ? "" : variables.refreshScript,
          persistent: variables.persistent,
          slotCompatibility: variables.slotCompatibility,
          sortBy: variables.sortBy,
          desc: variables.desc,
        },
        update: updateUnits,
      })) as UnitCreateMutationResult
      const { id: unitId } = result.data.unitCreate.unit
      onSuccessAction(variables.vendorId, unitId)
    } catch (error) {
      console.error(error)

      const { message } = error as ApolloError
      onErrorAction(message)
    }
  }

  const updateUnits: UnitCreateMutationUpdater = (cache, payload) => {
    try {
      const { units } = payload.data.unitCreate
      const data: Units = cloneDeep(cache.readQuery({ query: QUERY_UNITS, variables: queryVariables }))
      data.units = units

      cache.writeQuery({
        query: QUERY_UNITS,
        variables: queryVariables,
        data: data,
      })
    } catch (error) {
      console.error(error)
    }
  }

  return { createUnit }
}

/**
 * Change Unit
 */

export type UnitChangeMutation = MutationFunction<UnitChange, UnitChangeVariables>
export type UnitChangeMutationResult = MutationResult<UnitChange>

type UseChangeUnitArgs = {
  onSuccessAction: () => void
  onErrorAction: (errorMessage: string) => void
}

export const useChangeUnit = (args: UseChangeUnitArgs) => {
  const { onSuccessAction, onErrorAction } = args

  const changeUnit = async (unitChangeMutation: UnitChangeMutation, variables: UnitChangeVariables) => {
    try {
      await unitChangeMutation({
        variables: {
          id: variables.id,
          name: formatNameValue(variables.name),
          initialScript: variables.initialScript,
          refreshScript: !variables.persistent ? "" : variables.refreshScript,
          wipeScript: variables.wipeScript,
          persistent: variables.persistent,
          slotCompatibility: variables.slotCompatibility,
          vendorId: variables.vendorId,
        },
      })
      onSuccessAction()
    } catch (error) {
      console.error(error)

      const { message } = error as ApolloError
      onErrorAction(message)
    }
  }
  return { changeUnit }
}

/**
 * Get Formatted Warning Message
 * @param errorMessage
 */

export const getFormattedWarning = (errorMessage: string): string => {
  if (errorMessage.includes("unit_name_already_exists")) {
    return "Unit name must be unique"
  }
  return ""
}
