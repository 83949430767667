import React from "react"
import { Mutation } from "react-apollo"
import {
  MUTATION_PUBLISH_QUIZ,
  PublishQuizMutation,
  PublishQuizMutationResult,
  usePublishQuiz,
  UsePublishQuizArgs,
} from "model/graphql/mutations"
import { Button } from "components/button"
import { useDispatch, useSelector } from "react-redux"
import { ReduxState } from "redux-store"
import { QuizGeneralDispatch, useActivateSuccessModal, useCloseLoadingModal, useOpenLoadingModal } from "utils"
import { ButtonWrapper } from "screens/quiz/page-header/quiz-action-buttons/button-wrapper"
import { quizGeneralActionTypes } from "redux-reducers"
import { isEqual } from "lodash"

/**
 * Component
 */

export const QuizPublishButton = () => {
  const quizId = useSelector((state: ReduxState) => state.quizGeneral.id, isEqual)
  const quizTitle = useSelector((state: ReduxState) => state.quizGeneral.title, isEqual)
  const quizSlug = useSelector((state: ReduxState) => state.quizGeneral.slug, isEqual)
  const slugWarning = useSelector((state: ReduxState) => state.quizGeneral.slugWarning, isEqual)
  const dispatch = useDispatch()

  /**
   * Success and Loading Modal
   */

  const { activateSuccessModal } = useActivateSuccessModal()
  const { openLoadingModal } = useOpenLoadingModal()
  const { closeLoadingModal } = useCloseLoadingModal()

  /**
   * Publish Quiz
   */

  const handlePublishStart: UsePublishQuizArgs["onStartAction"] = () => {
    openLoadingModal("Publishing...")
  }

  const handlePublishSuccess: UsePublishQuizArgs["onSuccessAction"] = () => {
    closeLoadingModal()
    activateSuccessModal("The Quiz was published.")
    dispatch<QuizGeneralDispatch>({
      type: quizGeneralActionTypes.setQuizGeneralIsPublished,
      data: { isPublished: true },
    })
    dispatch<QuizGeneralDispatch>({
      type: quizGeneralActionTypes.setQuizGeneralIsSlugCustom,
      data: { isSlugCustom: true },
    })
  }

  const handlePublishError: UsePublishQuizArgs["onErrorAction"] = errorMessage => {
    closeLoadingModal()
    if (/quiz_slug_already_used/.test(errorMessage)) {
      dispatch<QuizGeneralDispatch>({
        type: quizGeneralActionTypes.setQuizGeneralSlugWarning,
        data: { slugWarning: "This slug has already been used" },
      })
    }
  }

  const { publishQuiz } = usePublishQuiz({
    onStartAction: handlePublishStart,
    onSuccessAction: handlePublishSuccess,
    onErrorAction: handlePublishError,
  })

  /**
   * JSX
   */

  return (
    <Mutation mutation={MUTATION_PUBLISH_QUIZ}>
      {(publishQuizMutation: PublishQuizMutation, { loading }: PublishQuizMutationResult) => (
        <ButtonWrapper>
          <Button
            color={"green"}
            size={"regular"}
            variant={"filled"}
            type={"button"}
            onClick={publishQuiz(publishQuizMutation, { id: quizId })}
            isDisabled={loading || !quizTitle || !quizSlug || !!slugWarning}
          >
            {`Publish`}
          </Button>
        </ButtonWrapper>
      )}
    </Mutation>
  )
}
