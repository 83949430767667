import React, { useContext } from "react"
import { Portal } from "components/portal"
import { Modal, ModalSection } from "components/modal"
import { LayoutStoreContext } from "../layout-context"
import { Heading2, Paragraph } from "components/typography"
import styled from "styled-components"
import { Label } from "components/label"
import { Warning } from "components/warning"
import { Input } from "components/input"
import { Button } from "components/button"
import { Mutation, QueryResult } from "react-apollo"
import { getFormattedPageNumberToSend, getPageOverrideErrorMessage } from "../helpers"
import {
  EditVariantMutation,
  EditVariantMutationResult,
  MUTATION_EDIT_VARIANT,
  useEditVariant,
} from "model/graphql/mutations"
import { layoutModalActionTypes } from "../layout-context/modal-reducer"
import { Variant } from "model/types"

type Props = {
  data: QueryResult<Variant>["data"]
  variantId: string
}

export const EditVariantModal = (props: Props) => {
  const { data, variantId } = props

  const { layoutModalDispatch, layoutModalState } = useContext(LayoutStoreContext)
  const { variant } = data && data.variant ? data : { variant: { pageFrom: "", pageTo: "" } }
  const { pageFrom = `${variant.pageFrom}`, pageTo = `${variant.pageTo}` } = layoutModalState

  /**
   * Change Page From
   *
   * @param value
   */

  const changePageFrom = (value: string) => {
    layoutModalDispatch({ type: layoutModalActionTypes.resetPageOverrideErrorMessage })
    layoutModalDispatch({ type: layoutModalActionTypes.setPageFrom, value: value })
  }

  /**
   * Change Page To
   *
   * @param value
   */

  const changePageTo = (value: string) => {
    layoutModalDispatch({ type: layoutModalActionTypes.resetPageOverrideErrorMessage })
    layoutModalDispatch({ type: layoutModalActionTypes.setPageTo, value: value })
  }

  /**
   * Close Modal
   */

  const closeModal = () => {
    // Reset values
    layoutModalDispatch({ type: layoutModalActionTypes.resetPages })
    layoutModalDispatch({ type: layoutModalActionTypes.resetPageOverrideErrorMessage })

    // Close modal
    layoutModalDispatch({ type: layoutModalActionTypes.closeEditVariantModal })
  }

  /**
   * Edit Variant
   */

  const handleSuccess = () => {
    closeModal()
  }

  const handleError = (errorMessage: string) => {
    layoutModalDispatch({
      type: layoutModalActionTypes.setPageOverrideErrorMessage,
      value: getPageOverrideErrorMessage(errorMessage),
    })
  }

  const { editVariant } = useEditVariant({
    onSuccessAction: handleSuccess,
    onErrorAction: handleError,
  })

  /**
   * Get Save Button Disable State
   *
   * @param loading
   */

  const isSaveButtonDisabled = (loading: boolean) => {
    if ((pageFrom === "" && pageTo === "") || (`${variant.pageFrom}` === pageFrom && `${variant.pageTo}` === pageTo)) {
      return true
    }

    return loading
  }

  /**
   * JSX
   */

  return (
    <Portal>
      <Mutation mutation={MUTATION_EDIT_VARIANT}>
        {(editVariantMutation: EditVariantMutation, { loading }: EditVariantMutationResult) => {
          return (
            <Modal focusIsTrapped={true} isVisible={layoutModalState.isEditVariantModalOpen} closeAction={closeModal}>
              <ModalSection>
                <Heading2>{`Page Override`}</Heading2>
              </ModalSection>

              <ModalSection topOffset={"20px"}>
                <Paragraph>
                  {`You can override ad settings for individual quiz pages. Specify the range of pages to which the new
                    settings should apply.`}
                </Paragraph>
              </ModalSection>

              <ModalSection topOffset={"20px"}>
                <Paragraph>
                  {`If you want the new settings to be applied to a single page only, put the page number to both fields.`}
                </Paragraph>
              </ModalSection>

              <ModalSection topOffset={"20px"}>
                <Paragraph>
                  {`You can also leave one end of the range open by not putting any number to the corresponding field.`}
                </Paragraph>
              </ModalSection>

              <ModalSection topOffset={"40px"}>
                <Form>
                  <FormField>
                    <Label>{`From page`}</Label>
                    <Input
                      value={pageFrom}
                      onChange={changePageFrom}
                      hasWarning={Boolean(layoutModalState.pageOverrideErrorMessage)}
                    />
                  </FormField>

                  <FormField>
                    <Label>{`to`}</Label>
                    <Input
                      value={pageTo}
                      onChange={changePageTo}
                      hasWarning={Boolean(layoutModalState.pageOverrideErrorMessage)}
                    />
                  </FormField>

                  <Warning message={layoutModalState.pageOverrideErrorMessage} />
                </Form>
              </ModalSection>

              <ModalSection topOffset={"40px"}>
                <Button
                  color={"green"}
                  size={"regular"}
                  variant={"filled"}
                  type={"button"}
                  isDisabled={isSaveButtonDisabled(loading)}
                  onClick={editVariant(editVariantMutation, {
                    id: variantId,
                    pageFrom: getFormattedPageNumberToSend(pageFrom),
                    pageTo: getFormattedPageNumberToSend(pageTo),
                  })}
                >
                  {`Save`}
                </Button>
              </ModalSection>
            </Modal>
          )
        }}
      </Mutation>
    </Portal>
  )
}

const Form = styled("form")`
  display: grid;
  position: relative;
  grid-template-columns: repeat(2, max-content);
  grid-template-rows: max-content max-content;
  grid-template-areas:
    ". ."
    "warning warning";
  grid-column-gap: 80px;
  justify-content: center;
  width: 480px;
`

const FormField = styled("div")`
  display: grid;
  position: relative;
  grid-template-columns: max-content 80px;
  grid-template-rows: max-content;
  grid-template-areas: "label .";
  grid-column-gap: 20px;
  grid-row-gap: 6px;
  align-items: center;
  justify-items: end;
  width: min-content;
`
