import { RuleDelete, RuleDeleteVariables, Rules, RulesVariables } from "model/types"
import { QUERY_RULES } from "model/graphql/queries"
import { MutationFunction, MutationResult } from "react-apollo"
import { cloneDeep } from "lodash"
import { ApolloError, MutationUpdaterFn } from "apollo-client"

export type DeleteRuleMutation = MutationFunction<RuleDelete, RuleDeleteVariables>
export type DeleteRuleMutationResult = MutationResult<RuleDelete>
export type DeleteRuleMutationUpdater = MutationUpdaterFn<RuleDelete>

export type UseDeleteRuleArgs = {
  onSuccessAction?: () => void
  onErrorAction?: (errorMessage: string) => void
  queryVariables: RulesVariables
}

export const useDeleteRule = (args: UseDeleteRuleArgs) => {
  const { onSuccessAction, onErrorAction, queryVariables } = args

  const deleteRule = (deleteRuleMutation: DeleteRuleMutation, variables: RuleDeleteVariables) => async () => {
    try {
      await deleteRuleMutation({
        variables: { id: variables.id },
        update: updateRules({
          ruleId: variables.id,
          queryVariables,
        }),
      })

      if (onSuccessAction) {
        onSuccessAction()
      }
    } catch (error) {
      console.error(error)
      if (onErrorAction) {
        const { message } = error as ApolloError
        onErrorAction(message)
      }
    }
  }

  type UpdateRulesArgs = {
    ruleId: string
    queryVariables: RulesVariables
  }

  const updateRules = (args: UpdateRulesArgs): DeleteRuleMutationUpdater => (cache, payload) => {
    const { ruleId, queryVariables } = args

    if (!payload.data.ruleDelete.deleted) {
      return
    }
    try {
      const data: Rules = cloneDeep(cache.readQuery({ query: QUERY_RULES, variables: queryVariables }))

      data.rules.edges = [...data.rules.edges.filter(({ node: rule }) => rule.id !== ruleId)]

      cache.writeQuery({
        query: QUERY_RULES,
        variables: queryVariables,
        data: data,
      })
    } catch (error) {
      console.error(error)
    }
  }

  return { deleteRule }
}
