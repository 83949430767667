import gql from "graphql-tag"
import { fragments } from "model/graphql/fragments"

export const QUERY_USERS = gql`
  query Users($sortBy: String, $desc: Boolean) {
    users(sortBy: $sortBy, desc: $desc) {
      usersAll
      invitedUsers
      edges {
        node {
          ...UserData
        }
      }
    }

    groups {
      edges {
        node {
          ...GroupData
        }
      }
    }
  }
  ${fragments.user}
  ${fragments.group}
`
