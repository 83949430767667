import React, { useRef } from "react"
import { Heading2 } from "components/typography"
import { Button } from "components/button"
import { IconDelete } from "components/icons"
import { AnchorLink } from "components/links"
import { Portal } from "components/portal"
import { Modal, ModalSection } from "components/modal"
import { MUTATION_LAYOUT_DELETE } from "model/graphql/mutations"
import { LayoutDeleteMutation, LayoutDeleteMutationResult, useDeleteLayout } from "./helpers"
import { useFocusOnButton } from "utils"
import { LayoutsVariables } from "model/types"
import { Mutation } from "react-apollo"

//custom types
type Props = {
  isModalOpen: boolean
  layoutToDelete: { id: string; name: string }
  closeModal: () => void
  queryVariables: LayoutsVariables
  children?: never
}

export const DeleteLayoutModal = (props: Props) => {
  const { layoutToDelete, closeModal, isModalOpen, queryVariables } = props
  const buttonRef = useRef<HTMLButtonElement>(null)
  useFocusOnButton({ ref: buttonRef })

  /**
   * Delete Layout
   */

  const handleSuccess = () => {
    closeModal()
  }

  const { deleteLayout } = useDeleteLayout({
    queryVariables,
    onSuccessAction: handleSuccess,
  })

  /**
   * JSXC
   */

  return (
    <Mutation mutation={MUTATION_LAYOUT_DELETE}>
      {(deleteLayoutMutation: LayoutDeleteMutation, { loading }: LayoutDeleteMutationResult) => (
        <Portal>
          <Modal isVisible={isModalOpen}>
            <ModalSection>
              <Heading2>{`Are you sure to delete the Layout ${layoutToDelete.name}?`}</Heading2>
            </ModalSection>
            <ModalSection topOffset={"40px"}>
              <Button
                ref={buttonRef}
                color={"red"}
                size={"regular"}
                variant={"filled"}
                onClick={deleteLayout(deleteLayoutMutation, { id: layoutToDelete.id })}
                icon={<IconDelete />}
                isDisabled={loading}
              >
                Yes, delete it
              </Button>
            </ModalSection>
            <ModalSection topOffset={"20px"}>
              <AnchorLink onClick={closeModal} color={"green"} weight={"regular"} size={"16px"}>
                No, I don’t want to delete it
              </AnchorLink>
            </ModalSection>
          </Modal>
        </Portal>
      )}
    </Mutation>
  )
}
