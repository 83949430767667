import React from "react"
import { IconProps } from "./types"
import { Svg } from "./styles"

type Props = IconProps

export const IconLoading = (props: Props) => {
  return (
    <Svg color={props.color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.997 20.997">
      <g id="Loading_Icon" transform="translate(-899.5 -351.503)">
        <line
          y2="3.619"
          transform="translate(909.999 352.003)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          y2="3.619"
          transform="translate(909.999 368.381)"
          opacity="0.4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          fill="none"
        />
        <line
          x1="2.559"
          y2="2.559"
          transform="translate(914.51 354.931)"
          opacity="0"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          fill="none"
        />
        <line
          x1="2.559"
          y2="2.559"
          transform="translate(902.929 366.513)"
          opacity="0.6"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          fill="none"
        />
        <line
          x1="3.619"
          transform="translate(916.379 362.001)"
          opacity="0.05"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          fill="none"
        />
        <line
          x1="3.619"
          transform="translate(900 362.001)"
          opacity="0.6"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          fill="none"
        />
        <line
          x1="2.559"
          y1="2.559"
          transform="translate(914.51 366.513)"
          opacity="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          fill="none"
        />
        <line
          x1="2.559"
          y1="2.559"
          transform="translate(902.929 354.931)"
          opacity="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          fill="none"
        />
      </g>
    </Svg>
  )
}
