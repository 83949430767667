import { Quizzes_quizzes, Quizzes_quizzes_edges, Quizzes_quizzes_edges_node } from "model/types"

/**
 * Returns count of quizzes in passed quiz list
 *
 * @param quizList
 */

export const getQuizListCount = (quizList: [] | Quizzes_quizzes_edges_node[]): number => {
  return quizList.length
}

/**
 * Sort quizzes by current tab
 *
 * @param data
 * @param sortedBy
 * @param myId
 */

export const getSortedQuizList = (
  data: Quizzes_quizzes,
  sortedBy: string,
  myId: string
): Quizzes_quizzes_edges_node[] | [] => {
  switch (sortedBy) {
    case "published":
      return getQuizNodeFromQuizzes(data.edges.filter(({ node: quiz }) => quiz.publishedAt !== null))

    case "drafts":
      return getQuizNodeFromQuizzes(data.edges.filter(({ node: quiz }) => quiz.publishedAt === null))

    case "my-quizzes":
      return getQuizNodeFromQuizzes(data.edges.filter(({ node: quiz }) => quiz.author.id === myId))

    default:
      return []
  }
}

/**
 * Search quizzes
 *
 * @param quizzes
 * @param query
 */

export const findQuizzes = (
  quizzes: Quizzes_quizzes_edges_node[],
  query: string
): Quizzes_quizzes_edges_node[] | [] => {
  if (quizzes && query.length === 0) {
    return quizzes
  }

  if (quizzes && query.length !== 0) {
    return getFilteredQuizzesBy(quizzes, query)
  }
  return []
}

/**
 * Gets quizzes and username or title quiz as query
 *
 * Returns found quizzes
 *
 * @param quizzes
 * @param query
 */

const getFilteredQuizzesBy = (quizzes: Quizzes_quizzes_edges_node[], query: string) => {
  return quizzes.filter(
    (quiz: Quizzes_quizzes_edges_node) =>
      quiz.title.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
      `${quiz.author.firstName.toLowerCase()} ${quiz.author.lastName.toLowerCase()}`.indexOf(query.toLowerCase()) !== -1
  )
}

/**
 * Returns quiz list from node array
 *
 * @param quizzes
 */

export const getQuizNodeFromQuizzes = (quizzes: Quizzes_quizzes_edges[]): Quizzes_quizzes_edges_node[] => {
  return quizzes.map(({ node: quiz }) => quiz)
}

/**
 * Redirect to new browser tab
 *
 * @param url
 */

export const openQuizInNewTab = (url: string) => (): void => {
  window.open(url, "_blank")
}

/**
 * Parsing date and time from ISO UTC format to local time format
 *
 * @param UTCdata
 */

export const getFormattedDateTime = (UTCdata: string): string => {
  const dateObject = new Date(UTCdata)
  return dateObject.toLocaleString(undefined, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  })
}
