import gql from "graphql-tag"
import { fragments } from "model/graphql/fragments"

export const QUERY_ME = gql`
  query Me {
    me {
      ...UserData
    }
  }
  ${fragments.user}
`
