import React, { useContext, useRef } from "react"
import { Heading2 } from "components/typography"
import { Button } from "components/button"
import { IconDelete } from "components/icons"
import { AnchorLink } from "components/links"
import { Portal } from "components/portal"
import { Modal, ModalSection } from "components/modal"
import { Mutation } from "react-apollo"
import { useFocusOnButton } from "utils"
import { LayoutStoreContext } from "../layout-context"
import {
  DeleteVariantMutation,
  DeleteVariantMutationResult,
  MUTATION_DELETE_VARIANT,
  useDeleteVariant,
  UseDeleteVariantArgs,
} from "model/graphql/mutations"
import { layoutModalActionTypes } from "../layout-context/modal-reducer"
import { RouteComponentProps } from "react-router"
import { routes } from "router"

type Props = {
  layoutId: string
  variantId: string
  history: RouteComponentProps["history"]
  children?: never
}

export const DeleteVariantModal = (props: Props) => {
  const { layoutId, variantId, history } = props
  const { layoutModalState, layoutModalDispatch } = useContext(LayoutStoreContext)

  /**
   * Add Focus On Delete Button
   */

  const buttonRef = useRef<HTMLButtonElement>(null)
  useFocusOnButton({ ref: buttonRef })

  /**
   * Close Modal
   */

  const closeModal = () => {
    layoutModalDispatch({ type: layoutModalActionTypes.closeDeleteVariantModal })
  }

  /**
   * Delete Variant
   */

  const handleSuccess: UseDeleteVariantArgs["onSuccessAction"] = () => {
    closeModal()
  }

  const handleUpdate: UseDeleteVariantArgs["onUpdateAction"] = ({ prevVariantIndex, variants }) => {
    const newIndex = prevVariantIndex % variants.length === 0 ? prevVariantIndex - 1 : prevVariantIndex

    history.push(routes.layoutVariant.replace(":layoutId", layoutId).replace(":variantId", variants[newIndex].node.id))
  }

  const { deleteVariant } = useDeleteVariant({
    onSuccessAction: handleSuccess,
    onUpdateAction: handleUpdate,
  })

  /**
   * JSX
   */

  return (
    <Mutation mutation={MUTATION_DELETE_VARIANT}>
      {(deleteVariantMutation: DeleteVariantMutation, { loading }: DeleteVariantMutationResult) => (
        <Portal>
          <Modal focusIsTrapped={true} isVisible={layoutModalState.isDeleteVariantModalOpen}>
            <ModalSection>
              <Heading2>{`Are you sure to delete the Variant?`}</Heading2>
            </ModalSection>

            <ModalSection topOffset={"40px"}>
              <Button
                ref={buttonRef}
                color={"red"}
                size={"regular"}
                variant={"filled"}
                onClick={deleteVariant(deleteVariantMutation, {
                  id: variantId,
                  layoutId: layoutId,
                })}
                icon={<IconDelete />}
                isDisabled={loading}
              >
                {`Yes, delete it`}
              </Button>
            </ModalSection>

            <ModalSection topOffset={"20px"}>
              <AnchorLink onClick={closeModal} color={"green"} weight={"regular"} size={"16px"}>
                {`No, I don’t want to delete it`}
              </AnchorLink>
            </ModalSection>
          </Modal>
        </Portal>
      )}
    </Mutation>
  )
}
