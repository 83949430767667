import React from "react"
import styled from "styled-components"
import { theme } from "theme"

type Props = {
  color: "green" | "red"
  weight: "semiBold" | "regular"
  size: "16px" | "14px" | "12px"
  isCapitalized?: boolean
  isDisabled?: boolean
  hasVisitedColor?: boolean
  onClick: () => void
  children: React.ReactNode
}

export const AnchorLink = (props: Props) => {
  const { color, size, weight, isCapitalized, isDisabled, hasVisitedColor, onClick, children } = props

  return (
    <Link
      isCapitalized={isCapitalized}
      hasVisitedColor={hasVisitedColor}
      size={size}
      color={color}
      isDisabled={isDisabled}
      weight={weight}
      onClick={isDisabled ? null : onClick}
    >
      {children}
    </Link>
  )
}

type LinkProps = {
  color: Props["color"]
  weight: Props["weight"]
  isDisabled: boolean
  size: Props["size"]
  hasVisitedColor: Props["hasVisitedColor"]
  isCapitalized: Props["isCapitalized"]
}

const Link = styled("a")`
  text-decoration: none;
  font-weight: ${(props: LinkProps) =>
    props.weight === "semiBold" ? theme.font.weight.semiBold : theme.font.weight.regular};
  color: ${(props: LinkProps) => {
    if (props.color === "green") {
      return props.isDisabled ? theme.color.primaryGreenLight : theme.color.primaryGreen
    }
    if (props.color === "red") {
      return props.isDisabled ? theme.color.warningRedLight : theme.color.warningRed
    }
  }};
  font-size: ${(props: LinkProps) => props.size};
  line-height: ${(props: LinkProps) => {
    if (props.size === "16px") {
      return "22px"
    }
    if (props.size === "14px") {
      return "19px"
    }
    if (props.size === "12px") {
      return "17px"
    }
  }};
  ${(props: LinkProps) => (props.isCapitalized ? "text-transform: uppercase" : "")};

  &:hover {
    text-decoration: ${(props: LinkProps) => (props.isDisabled ? "none" : "underline")};
    cursor: ${(props: LinkProps) => (props.isDisabled ? "not-allowed" : "pointer")};
    color: ${(props: LinkProps) => {
      if (props.color === "green") {
        return props.isDisabled ? theme.color.primaryGreenLight : theme.color.primaryGreenDark
      }
      if (props.color === "red") {
        return props.isDisabled ? theme.color.warningRedLight : theme.color.warningRedDark
      }
    }};
  }

  &:visited {
    ${(props: LinkProps) => (props.hasVisitedColor ? `color: ${theme.color.textBlack}` : "")};
  }
`
