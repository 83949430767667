import React, { useContext, useState, useEffect } from "react"
import { QueryResult } from "react-apollo"
import { Variant } from "model/types"
import styled from "styled-components"
import { Heading2 } from "components/typography"
import { Textarea } from "components/textarea"
import { Box } from "components/box"
import { LayoutStoreContext } from "screens/layout/layout-context"
import { variantActionTypes } from "screens/layout/layout-context/variant-reducer"
import { theme } from "theme"
import { Input } from "components/input"
import { Warning } from "components/warning"
import {
  WARNING_NON_NEGATIVE,
  WARNING_NOT_A_NUMBER,
  WARNING_MAX_HIGHER_MIN,
  WARNING_CANNOT_BE_EMPTY,
} from "./preview-head"

type Props = {
  data: QueryResult<Variant>["data"]
}

export const Notes = (props: Props) => {
  const { variantDispatch } = useContext(LayoutStoreContext)
  const { loadDelayMin, loadDelayMax, notes } = props.data.variant

  const [minDelay, setMinDelay] = useState("")
  const [minDelayWarning, setMinDelayWarning] = useState("")
  const [maxDelay, setMaxDelay] = useState("")
  const [maxDelayWarning, setMaxDelayWarning] = useState("")

  useEffect(() => {
    setMinDelay(loadDelayMin.toString())
    variantDispatch({ type: variantActionTypes.setMinDelay, data: { loadDelayMin } })
    variantDispatch({ type: variantActionTypes.setMinDelayWarning, data: { loadDelayMinWarning: "" } })

    setMaxDelay(loadDelayMax.toString())
    variantDispatch({ type: variantActionTypes.setMaxDelay, data: { loadDelayMax } })
    variantDispatch({ type: variantActionTypes.setMaxDelayWarning, data: { loadDelayMaxWarning: "" } })

    variantDispatch({ type: variantActionTypes.setNotes, data: { notes } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data])

  /**
   * Set Min Load Delay
   *
   * @param value
   */

  const setLoadDelayMin = (value: string) => {
    setMinDelay(value)
    if (!value.length) {
      setMinDelayWarning(WARNING_CANNOT_BE_EMPTY)
      variantDispatch({
        type: variantActionTypes.setMinDelayWarning,
        data: { loadDelayMinWarning: WARNING_CANNOT_BE_EMPTY },
      })
    } else if (!isNaN(Number(value))) {
      if (Number(value) < 0) {
        setMinDelayWarning(WARNING_NON_NEGATIVE)
        variantDispatch({
          type: variantActionTypes.setMinDelayWarning,
          data: { loadDelayMinWarning: WARNING_NON_NEGATIVE },
        })
        if (maxDelayWarning === WARNING_MAX_HIGHER_MIN) {
          setMaxDelayWarning("")
          variantDispatch({
            type: variantActionTypes.setMaxDelayWarning,
            data: { loadDelayMaxWarning: "" },
          })
        }
      } else if (Number(value) > Number(maxDelay)) {
        setMinDelayWarning(WARNING_MAX_HIGHER_MIN)
        setMaxDelayWarning(WARNING_MAX_HIGHER_MIN)
        variantDispatch({
          type: variantActionTypes.setMinDelayWarning,
          data: { loadDelayMinWarning: WARNING_MAX_HIGHER_MIN },
        })
        variantDispatch({
          type: variantActionTypes.setMaxDelayWarning,
          data: { loadDelayMaxWarning: WARNING_MAX_HIGHER_MIN },
        })

        variantDispatch({ type: variantActionTypes.setMinDelay, data: { loadDelayMin: Number(value) } })
      } else {
        setMinDelayWarning("")
        variantDispatch({ type: variantActionTypes.setMinDelay, data: { loadDelayMin: Number(value) } })
        variantDispatch({ type: variantActionTypes.setMinDelayWarning, data: { loadDelayMinWarning: "" } })

        if (maxDelayWarning === WARNING_MAX_HIGHER_MIN) {
          setMaxDelayWarning("")
          variantDispatch({
            type: variantActionTypes.setMaxDelayWarning,
            data: { loadDelayMaxWarning: "" },
          })
        }
      }
    } else {
      setMinDelayWarning(WARNING_NOT_A_NUMBER)
      variantDispatch({
        type: variantActionTypes.setMinDelayWarning,
        data: { loadDelayMinWarning: WARNING_NOT_A_NUMBER },
      })
    }
  }

  /**
   * Set Max Load Delay
   *
   * @param value
   */

  const setLoadDelayMax = (value: string) => {
    setMaxDelay(value)
    if (!value.length) {
      setMaxDelayWarning(WARNING_CANNOT_BE_EMPTY)
      variantDispatch({
        type: variantActionTypes.setMaxDelayWarning,
        data: { loadDelayMaxWarning: WARNING_CANNOT_BE_EMPTY },
      })
    } else if (!isNaN(Number(value))) {
      if (Number(value) < 0) {
        setMaxDelayWarning(WARNING_NON_NEGATIVE)
        variantDispatch({
          type: variantActionTypes.setMaxDelayWarning,
          data: { loadDelayMaxWarning: WARNING_NON_NEGATIVE },
        })
        if (minDelayWarning === WARNING_MAX_HIGHER_MIN) {
          setMinDelayWarning("")
          variantDispatch({
            type: variantActionTypes.setMinDelayWarning,
            data: { loadDelayMinWarning: "" },
          })
        }
      } else if (Number(value) < Number(minDelay)) {
        setMinDelayWarning(WARNING_MAX_HIGHER_MIN)
        setMaxDelayWarning(WARNING_MAX_HIGHER_MIN)
        variantDispatch({
          type: variantActionTypes.setMinDelayWarning,
          data: { loadDelayMinWarning: WARNING_MAX_HIGHER_MIN },
        })
        variantDispatch({
          type: variantActionTypes.setMaxDelayWarning,
          data: { loadDelayMaxWarning: WARNING_MAX_HIGHER_MIN },
        })
      } else {
        setMaxDelayWarning("")
        variantDispatch({ type: variantActionTypes.setMaxDelay, data: { loadDelayMax: Number(value) } })
        variantDispatch({
          type: variantActionTypes.setMaxDelayWarning,
          data: { loadDelayMaxWarning: "" },
        })

        if (minDelayWarning === WARNING_MAX_HIGHER_MIN) {
          setMinDelayWarning("")
          variantDispatch({
            type: variantActionTypes.setMinDelayWarning,
            data: { loadDelayMinWarning: "" },
          })
        }
      }
    } else {
      setMaxDelayWarning(WARNING_NOT_A_NUMBER)
      variantDispatch({
        type: variantActionTypes.setMaxDelayWarning,
        data: { loadDelayMaxWarning: WARNING_NOT_A_NUMBER },
      })
    }
  }

  /**
   * Set Variant Notes
   *
   * @param value
   */

  const setVariantNotes = (value: string) => {
    variantDispatch({ type: variantActionTypes.setNotes, data: { notes: value } })
  }

  /**
   * JSX
   */

  return (
    <MyNotesContainer>
      <Box>
        <ContentWrapper>
          <InputWrapper>
            <InputHeadingContainer>
              <Heading2>{`Min load delay (ms)`}</Heading2>
            </InputHeadingContainer>
            <Input onChange={setLoadDelayMin} value={minDelay} />
          </InputWrapper>
          <WarningWrapper>
            <Warning message={minDelayWarning} />
          </WarningWrapper>

          <InputWrapper>
            <InputHeadingContainer>
              <Heading2>{`Max load delay (ms)`}</Heading2>
            </InputHeadingContainer>
            <Input onChange={setLoadDelayMax} value={maxDelay} />
          </InputWrapper>
          <WarningWrapper>
            <Warning message={maxDelayWarning} />
          </WarningWrapper>
        </ContentWrapper>

        <StyledArea>
          <HeadingContainer>
            <Heading2>{`My notes`}</Heading2>
          </HeadingContainer>
          <Textarea onChange={setVariantNotes} value={notes} hasStaticHeight={true} />
        </StyledArea>
      </Box>
    </MyNotesContainer>
  )
}

const MyNotesContainer = styled("div")`
  display: block;
  position: relative;
  width: 100%;
  max-width: 760px;
  grid-area: notes;
  margin-top: ${theme.offset3};
`

const ContentWrapper = styled("div")`
  padding-top: 30px;
  padding-left: 40px;
  padding-right: 40px;
`
const InputWrapper = styled("div")`
  display: grid;
  position: relative;
  grid-template-rows: 50px;
  grid-template-columns: 130px 550px;
  padding-top: 10px;
`

const WarningWrapper = styled("div")`
  width: 100%;
  height: 23px;
`

const StyledArea = styled("div")`
  display: grid;
  position: relative;
  grid-template-rows: 162px;
  grid-template-columns: 130px 550px;
  padding: 40px;
`

const InputHeadingContainer = styled("div")`
  display: flex;
  align-items: center;
`

const HeadingContainer = styled("div")`
  margin-top: 15px;
`
