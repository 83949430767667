import styled from "styled-components"
import { theme } from "theme"
import { animated } from "react-spring"

export const Container = styled(animated.div)`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;

  .text {
    display: block;
    position: relative;
    color: ${theme.color.textNonactive};
    font-size: 16px;
    font-weight: ${theme.font.weight.regular};
    margin-left: 10px;
  }
`

export const IconWrapper = styled("div")`
  display: block;
  position: relative;
  width: 20px;
  height: 20px;
  transform-origin: center center;
`
