import React, { useEffect } from "react"
import { Portal } from "components/portal"
import {
  InviteUserMutation,
  InviteUserMutationResult,
  MUTATION_INVITE_USER,
  useInviteUser,
  UseInviteUserArgs,
} from "model/graphql/mutations"
import { Users_groups, UsersVariables } from "model/types"
import { inviteUserFormActionTypes, useFormState } from "./invite-user-form-state"
import styled from "styled-components"
import { Modal, ModalSection } from "components/modal"
import { Heading2 } from "components/typography"
import { Label } from "components/label"
import { Textarea } from "components/textarea"
import { RadioButton } from "components/radio-button"
import { Button } from "components/button"
import { IconPlus } from "components/icons"
import { useActivateSuccessModal } from "utils"
import { Warning } from "components/warning"
import { includes } from "lodash"
import { Mutation } from "react-apollo"
import { isEmailValid } from "../../../utils/helpers"

type Props = {
  isModalOpen: boolean
  closeModal: () => void
  children?: never
  queryVariables: UsersVariables
  groups: Users_groups
}

export const InviteUserModal = (props: Props) => {
  const { closeModal, isModalOpen, groups, queryVariables } = props
  const { state, dispatch } = useFormState()
  const { firstName, lastName, role, email, emailWarning, firstNameWarning, lastNameWarning } = state

  useEffect(() => {
    if (email.length > 254) {
      setEmailWarning("This Email is too long")
    } else if (email.length && !isEmailValid(email)) {
      setEmailWarning("Email is written in wrong format")
    } else {
      setEmailWarning("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email])

  /**
   * Set First Name
   *
   * @param value
   */

  const setFirstName = (value: typeof firstName) =>
    dispatch({ type: inviteUserFormActionTypes.setFirstName, data: { firstName: value } })

  /**
   * Set Last Name
   *
   * @param value
   */

  const setLastName = (value: typeof lastName) =>
    dispatch({ type: inviteUserFormActionTypes.setLastName, data: { lastName: value } })

  /**
   * Set Email
   *
   * @param value
   */

  const setEmail = (value: typeof email) =>
    dispatch({ type: inviteUserFormActionTypes.setEmail, data: { email: value } })

  /**
   * Set Role
   *
   * @param value
   */

  const setRole = (value: typeof role) => dispatch({ type: inviteUserFormActionTypes.setRole, data: { role: value } })

  /**
   * Set Form Warnings
   *
   * @param warning
   */

  const setEmailWarning = (warning: string) =>
    dispatch({ type: inviteUserFormActionTypes.setEmailWarning, data: { emailWarning: warning } })

  const setFirstNameWarning = (warning: string) =>
    dispatch({ type: inviteUserFormActionTypes.setFirstNameWarning, data: { firstNameWarning: warning } })

  const setLastNameWarning = (warning: string) =>
    dispatch({ type: inviteUserFormActionTypes.setLastNameWarning, data: { lastNameWarning: warning } })

  /**
   * Handle Close
   */

  const handleClose = () => {
    closeModal()
    dispatch({ type: inviteUserFormActionTypes.resetForm })
  }

  /**
   * Invite User
   */

  const { activateSuccessModal } = useActivateSuccessModal()

  const handleSuccess: UseInviteUserArgs["onSuccessAction"] = () => {
    handleClose()
    activateSuccessModal(`User ${email}\n was invited.`)
  }

  /**
   * Handle Validation Error from Server
   *
   * @param errorMessage
   */

  const handleError: UseInviteUserArgs["onErrorAction"] = errorMessage => {
    if (includes(errorMessage, "email_not_valid")) {
      setEmailWarning("Email is written in wrong format")
    }
    if (includes(errorMessage, "email_already_used")) {
      setEmailWarning("This Email is already used")
    }
    if (includes(errorMessage, "user_email_too_long")) {
      setEmailWarning("This Email is too long")
    }
    if (includes(errorMessage, "user_firstname_too_long")) {
      setFirstNameWarning("This First Name is too long")
    }
    if (includes(errorMessage, "user_lastname_too_long")) {
      setLastNameWarning("This Last Name is too long")
    }
  }

  const { inviteUser } = useInviteUser({
    queryVariables,
    onSuccessAction: handleSuccess,
    onErrorAction: handleError,
  })

  const submitForm = (mutation: InviteUserMutation): void => {
    if (!emailWarning.length) {
      inviteUser(mutation, {
        firstName: firstName,
        lastName: lastName,
        email: email,
        groupId: [role],
        desc: queryVariables.desc,
        sortBy: queryVariables.sortBy,
      })()
    }
  }

  /**
   * JSX
   */

  return (
    <Mutation mutation={MUTATION_INVITE_USER}>
      {(inviteUserMutation: InviteUserMutation, { loading }: InviteUserMutationResult) => (
        <Portal>
          <Modal focusIsTrapped={true} isVisible={isModalOpen} closeAction={handleClose}>
            <ModalSection>
              <Heading2>Invite User</Heading2>
            </ModalSection>

            <ModalSection topOffset={"40px"}>
              <FormRow>
                <Label>{`First Name`}</Label>
                <Textarea
                  hasMinimalHeight={true}
                  hasWarning={Boolean(firstNameWarning)}
                  value={firstName}
                  onChange={setFirstName}
                />
                <Warning message={firstNameWarning} />
              </FormRow>
            </ModalSection>

            <ModalSection topOffset={"20px"}>
              <FormRow>
                <Label>{`Last Name`}</Label>
                <Textarea
                  hasMinimalHeight={true}
                  hasWarning={Boolean(lastNameWarning)}
                  value={lastName}
                  onChange={setLastName}
                />
                <Warning message={lastNameWarning} />
              </FormRow>
            </ModalSection>

            <ModalSection topOffset={"20px"}>
              <FormRow>
                <Label>{`Email`}</Label>
                <Textarea
                  hasMinimalHeight={true}
                  hasWarning={Boolean(emailWarning)}
                  value={email}
                  onChange={setEmail}
                />
                <Warning message={emailWarning} />
              </FormRow>
            </ModalSection>

            <ModalSection topOffset={"20px"}>
              <FormRow>
                <Label>{`Role`}</Label>
                <ActionsFormRow gridArea={"input"}>
                  {groups.edges.map(({ node: group }) => (
                    <RadioButton optionValue={group.id} onChange={setRole} isChecked={role === group.id} key={group.id}>
                      {group.name}
                    </RadioButton>
                  ))}
                </ActionsFormRow>
              </FormRow>
            </ModalSection>

            <ModalSection topOffset={"40px"}>
              <SubmitFormRow>
                <Button
                  onClick={() => submitForm(inviteUserMutation)}
                  type={"button"}
                  size={"regular"}
                  color={"green"}
                  variant={"filled"}
                  icon={<IconPlus />}
                  isDisabled={firstName === "" || lastName === "" || role === "" || email === "" || loading}
                >
                  Invite User
                </Button>
              </SubmitFormRow>
            </ModalSection>
          </Modal>
        </Portal>
      )}
    </Mutation>
  )
}

type FormRowProps = {
  gridArea: string
}

const FormRow = styled("div")`
  display: grid;
  position: relative;
  grid-template-columns: 120px 1fr;
  grid-template-rows: 50px 17px;
  grid-template-areas:
    "label input"
    "warning warning";
  grid-column-gap: 6px;
  align-items: start;
  width: 543px;
`
const ActionsFormRow = styled("div")`
  display: grid;
  position: relative;
  grid-template-columns: max-content max-content max-content;
  grid-template-rows: 50px;
  grid-column-gap: 20px;
  grid-area: ${(props: FormRowProps) => props.gridArea};
`
const SubmitFormRow = styled("div")`
  display: grid;
  position: relative;
  align-items: center;
`
