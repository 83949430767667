import { Layout, SlotPairInput, Variant_variant } from "model/types"
import { SlotObject, VariantActionData } from "../layout-context/variant-reducer"
import { Dispatch } from "react"
import { Action } from "utils"

export type Variants = Layout["layout"]["variantSet"]["edges"]
export type Variant = Layout["layout"]["variantSet"]["edges"][0]["node"]

export const getFormattedPageNumberToSend = (pageNumber: string): number => {
  if (pageNumber === "") {
    return 0
  }
  return parseInt(pageNumber)
}

export const getFormattedPageNumberToStore = (pageNumber: number, type: "from" | "to"): string => {
  switch (type) {
    case "from":
      return pageNumber === null || pageNumber === 0 ? "1" : `${pageNumber}`
    case "to":
      return pageNumber === null || pageNumber === 0 ? "" : `${pageNumber}`
    default:
      throw new Error(`"${type}" is not a valid type!`)
  }
}

type GetVariantNameArgs = {
  pageFrom: string | number
  pageTo: string | number
  isDefault: boolean
}

export const getVariantName = (args: GetVariantNameArgs): string => {
  if (args.isDefault) {
    return `Basis`
  }
  const from = args.pageFrom === "" || args.pageFrom === 0 ? "1" : args.pageFrom
  const to = args.pageTo === "" || args.pageTo === 0 ? "end" : args.pageTo

  return `${from} - ${to}`
}

/**
 * Set Slots From Result Query
 *
 * @param slotPairSet
 * @param dispatch
 */

type SetSlotsFromQueryResultArgs = {
  slotPairSet: Variant_variant["slotpairSet"]
  dispatch: Dispatch<Action<VariantActionData>>
}

export const setSlotsFromQueryResult = (args: SetSlotsFromQueryResultArgs) => {
  const { slotPairSet, dispatch } = args

  if (slotPairSet) {
    slotPairSet.edges.forEach(({ node: slotPair }) => {
      dispatch({
        type: `SET_SLOT_${slotPair.slot}_UNIT_ID`,
        data: { unitId: slotPair.unit.id },
      })
      dispatch({
        type: `SET_DEFAULT_SLOT_${slotPair.slot}_UNIT_ID`,
        data: { unitId: slotPair.unit.id },
      })
      dispatch({
        type: `SET_SLOT_SELECTED_OPTIONS_${slotPair.slot}`,
        data: {
          options: {
            selectedVendorOption: {
              id: slotPair.unit.vendor.id,
              value: slotPair.unit.vendor.name,
            },
            selectedUnitOption: {
              id: slotPair.unit.id,
              value: slotPair.unit.name,
            },
          },
        },
      })
    })
  }
}

/**
 * Get Formatted Slots
 *
 * @param slots
 */

export const getFormattedSlots = (slots: { [key: string]: SlotObject }) => {
  return (
    Object.keys(slots)
      // Transform into SlotPairInput
      .map(slot => ({ slot: slots[slot].slot, unitId: slots[slot].unitId } as SlotPairInput))
      // Filter out slots with no unit id
      .filter(slot => slot.unitId !== "")
  )
}

/**
 * Get error message
 *
 * @param errorMessage
 */

export const getPageOverrideErrorMessage = (errorMessage: string): string => {
  switch (errorMessage) {
    case "GraphQL error: variant_can_not_overlap_another_variant":
      return `Variant can not overlap another variant`
    case "GraphQL error: variant_page_to_must_be_greater_than_page_from":
      return `Variant "page to" must be greater than "page from"`
    default:
      return errorMessage
  }
}

/**
 * Gets string value
 *
 * Returns true if string contains only digits or is empty, otherwise false
 *
 * @param value
 */

export const stringContainOnlyDigitsOrEmpty = (value: string): boolean => {
  return /^\d*$/.test(value)
}
