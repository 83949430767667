import React, { useContext } from "react"
import styled from "styled-components"
import { theme } from "theme"
import { SwitchRadioButton } from "screens/layout/variant-switch/switch-radio-button"
import { IconPlusSmall } from "components/icons"
import { Button } from "components/button"
import { LayoutStoreContext } from "screens/layout/layout-context"
import { getVariantName } from "screens/layout/helpers"
import { Layout_layout_variantSet } from "model/types"
import { layoutModalActionTypes } from "screens/layout/layout-context/modal-reducer"
import { RouteComponentProps } from "react-router"
import { routes } from "router"
import { CreateVariantModal } from "screens/layout/modals/create-variant-modal"

type Props = {
  children?: never
  variants: Layout_layout_variantSet["edges"]
  match: RouteComponentProps<{ layoutId: string; variantId: string }>["match"]
  history: RouteComponentProps["history"]
}

export const VariantSwitch = (props: Props) => {
  const { variants, match, history } = props
  const { layoutId, variantId } = match.params
  const { layoutModalDispatch } = useContext(LayoutStoreContext)

  /**
   * Select Variant
   *
   * @param variantId
   */

  const selectVariant = (variantId: string) => {
    history.push(routes.layoutVariant.replace(":layoutId", layoutId).replace(":variantId", variantId))
  }

  /**
   * Open Create Variant Modal
   */

  const openCreateVariantModal = () => {
    layoutModalDispatch({ type: layoutModalActionTypes.openCreateVariantModal })
  }

  /**
   * JSX
   */

  return (
    <Container>
      <SwitchContainer>
        {variants.map(({ node: variant }, index) => (
          <SwitchRadioButton
            key={variant.id}
            isChecked={variantId === variant.id}
            optionValue={variant.id}
            onChange={selectVariant}
            orderPosition={getOrderPosition(index, variants.length)}
          >
            {getVariantName({
              pageFrom: variant.pageFrom,
              pageTo: variant.pageTo,
              isDefault: variant.default,
            })}
          </SwitchRadioButton>
        ))}
      </SwitchContainer>

      <Button
        onClick={openCreateVariantModal}
        size={"small"}
        color={"green"}
        icon={<IconPlusSmall color={theme.color.white} />}
      >
        {`Add Page Override`}
      </Button>

      <CreateVariantModal layoutId={layoutId} history={history} />
    </Container>
  )
}

const Container = styled("aside")`
  display: grid;
  position: relative;
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content;
  grid-row-gap: ${theme.offset1};
  justify-items: start;
  grid-area: variants;
`

const SwitchContainer = styled("div")`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  width: 260px;
`

const getOrderPosition = (index: number, length: number) => {
  if (length === 1) {
    return "single"
  } else if (index === 0) {
    return "first"
  } else if (index === length - 1) {
    return "last"
  } else {
    return undefined
  }
}
