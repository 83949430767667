import React from "react"
import styled from "styled-components"
import { IconDelete } from "components/icons"
import { theme } from "theme"

type Props = {
  onClick: () => void
  isDisabled?: boolean
  children: string
}

export const DeleteButton = (props: Props) => {
  return (
    <Button
      type="button"
      isDisabled={props.isDisabled}
      disabled={props.isDisabled}
      onClick={props.isDisabled ? null : props.onClick}
    >
      <div className={"icon-wrapper"}>
        <IconDelete />
      </div>
      <span className={"text"}>{props.children}</span>
    </Button>
  )
}

type ButtonProps = {
  isDisabled: boolean
}

const Button = styled("button")`
  display: flex;
  position: relative;
  margin: 0;
  padding: 10px 0;
  border: none;
  background-color: transparent;
  cursor: ${(props: ButtonProps) => (props.isDisabled ? "not-allowed" : "pointer")};

  .icon-wrapper {
    display: flex;
    position: relative;
    width: 22px;
    height: 22px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }

  svg {
    stroke: ${(props: ButtonProps) => (props.isDisabled ? theme.color.warningRedLight : theme.color.warningRed)};
  }

  &:hover svg {
    stroke: ${(props: ButtonProps) => (props.isDisabled ? theme.color.warningRedLight : theme.color.warningRedDark)};
  }

  .text {
    color: ${(props: ButtonProps) => (props.isDisabled ? theme.color.warningRedLight : theme.color.warningRed)};
    font-size: 16px;
    font-weight: ${theme.font.weight.semiBold};
  }

  &:hover .text {
    color: ${(props: ButtonProps) => (props.isDisabled ? theme.color.warningRedLight : theme.color.warningRedDark)};
    text-decoration: ${(props: ButtonProps) => (props.isDisabled ? "none" : "underline")};
  }

  &:focus {
    outline: none;
  }
`
