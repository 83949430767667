import React from "react"
import { ApolloProvider } from "react-apollo"
import { client } from "model/client"
import { Router } from "router"
import { GlobalStyle } from "global-styles"
import { Provider } from "react-redux"
import { store } from "redux-store"

export class App extends React.Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <Provider store={store}>
          <GlobalStyle />
          <Router />
        </Provider>
      </ApolloProvider>
    )
  }
}
