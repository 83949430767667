import { useReducer } from "react"
import { Action } from "../../utils"

/**
 * Initial State
 */

export const initialUserFormState = {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  role: "",
  authorBio: "",
  avatarPreview: "",
  avatarFile: null as File,
  emailWarning: "",
}

/**
 * Dispatch Action Types
 */

export const actionTypes = {
  setId: "SET_ID",
  setFirstName: "SET_FIRST_NAME",
  setLastName: "SET_LAST_NAME",
  setEmail: "SET_EMAIL",
  setRole: "SET_ROLE",
  setAuthorBio: "SET_AUTHOR_BIO",
  setAvatarFile: "SET_AVATAR_FILE",
  setAvatarPreview: "SET_AVATAR_PREVIEW",
  setEmailWarning: "SET_EMAIL_WARNING",
}

/**
 * Reducer
 */

export type InitialUserFormState = typeof initialUserFormState

type ActionData = {
  id?: string
  firstName?: string
  lastName?: string
  email?: string
  role?: string
  authorBio?: string
  avatarFile?: File
  avatarPreview?: string
  emailWarning?: string
}

const reducer = (
  state: InitialUserFormState = initialUserFormState,
  action: Action<ActionData>
): InitialUserFormState => {
  switch (action.type) {
    // Id
    case actionTypes.setId:
      return {
        ...state,
        id: action.data.id,
      }

    // First Name
    case actionTypes.setFirstName:
      return {
        ...state,
        firstName: action.data.firstName,
      }

    // Last Name
    case actionTypes.setLastName:
      return {
        ...state,
        lastName: action.data.lastName,
      }

    // Email
    case actionTypes.setEmail:
      return {
        ...state,
        email: action.data.email,
        emailWarning: "",
      }

    case actionTypes.setEmailWarning:
      if (action.data.emailWarning === state.emailWarning) {
        return state
      }
      return {
        ...state,
        emailWarning: action.data.emailWarning,
      }

    // Author Bio
    case actionTypes.setAuthorBio:
      return {
        ...state,
        authorBio: action.data.authorBio,
      }

    // Avatar File
    case actionTypes.setAvatarFile:
      return {
        ...state,
        avatarFile: action.data.avatarFile,
      }

    // Avatar Preview
    case actionTypes.setAvatarPreview:
      return {
        ...state,
        avatarPreview: action.data.avatarPreview,
      }

    // Role
    case actionTypes.setRole:
      return {
        ...state,
        role: action.data.role,
      }

    // Default
    default:
      throw new Error(`"${action.type}" is not a valid action!`)
  }
}

/**
 * Form State Hook
 */

export const useFormState = () => {
  const [formState, formDispatch] = useReducer(reducer, initialUserFormState)

  return { formState, formDispatch }
}

export type FormState = ReturnType<typeof useFormState>["formState"]
export type FormDispatch = ReturnType<typeof useFormState>["formDispatch"]
