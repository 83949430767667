import React from "react"
import AceEditor from "react-ace"
import "brace/theme/dracula"
import "brace/mode/javascript"
import "brace/mode/text"

import { Modal, ModalSection } from "components/modal"
import { Heading2 } from "components/typography"
import styled from "styled-components"
import { RoundButton } from "components/round-button"
import { IconShrink } from "components/icons/shrink"

type Props = {
  editorHeading: string
  isEditorOpened: boolean
  closeEditor: () => void
  defaultEditorState: string
  onChange: (value: string) => void
}

export const CodeEditor = (props: Props) => {
  const { isEditorOpened, closeEditor, onChange, defaultEditorState, editorHeading } = props

  /**
   * Gets editor's mode  JS or TXT
   * @param editorHeading
   */

  const getEditorMode = (editorHeading: string) => {
    if (editorHeading.toLowerCase() === "ads.txt entries") {
      return "text"
    }
    return "javascript"
  }

  /**
   * Render JSX
   */

  return (
    <Modal isVisible={isEditorOpened} isFullSized={true}>
      <ModalSection>
        <Heading2>{editorHeading}</Heading2>
      </ModalSection>
      <ModalSection topOffset={"40px"} hasFullWidth={true}>
        <AceEditor
          style={{ width: "100%", height: "100%" }}
          mode={getEditorMode(editorHeading)}
          theme={"dracula"}
          name="UNIQUE_EDITOR_NAME"
          onChange={(inputCode: string) => onChange(inputCode)}
          value={defaultEditorState}
          defaultValue={defaultEditorState}
          focus={true}
          showPrintMargin={false}
          fontSize={"16px"}
        />
      </ModalSection>
      <ActionWrapper>
        <RoundButton
          onClick={() => {
            closeEditor()
          }}
        >
          <IconShrink />
        </RoundButton>
      </ActionWrapper>
    </Modal>
  )
}

const ActionWrapper = styled("div")`
  position: absolute;
  top: 20px;
  right: 20px;
`
