import React, { useRef, useState } from "react"
import { QuestionToDelete } from "./helpers"
import { useActivateSuccessModal, useFocusOnButton } from "utils"
import { Portal } from "components/portal"
import { Modal, ModalSection } from "components/modal"
import { Heading2 } from "components/typography"
import { Button } from "components/button"
import { IconDelete } from "components/icons"
import { AnchorLink } from "components/links"
import { QuestionDeleteVariables } from "model/types"
import _ from "lodash"
import { deleteQuestion } from "model/question"

type Props = {
  isModalOpen: boolean
  questionToDelete: QuestionToDelete
  closeModal: () => void
  children?: never
}

const debouncedDeleteQuestion = _.debounce(
  (variables: { quizId: string } & QuestionDeleteVariables, handlerSuccess?: () => void) =>
    deleteQuestion(variables, handlerSuccess),
  2000,
  { leading: true, trailing: false }
)

export const DeleteQuestionModal = (props: Props): any => {
  const { questionToDelete, closeModal, isModalOpen } = props
  const [isDeleteActionActive, setIsDeleteActionActive] = useState(true)

  const { activateSuccessModal } = useActivateSuccessModal()

  const handleSuccess = () => {
    closeModal()
    activateSuccessModal(
      `${questionToDelete.questionTitle ? questionToDelete.questionTitle : "Question"}\n was deleted.`
    )
  }

  const buttonRef = useRef<HTMLButtonElement>(null)
  useFocusOnButton({ ref: buttonRef })

  return (
    <Portal>
      <Modal isVisible={isModalOpen} focusIsTrapped={true}>
        <ModalSection>
          <Heading2>{`Are you sure to delete the \n ${
            Boolean(questionToDelete.questionTitle) ? questionToDelete.questionTitle : "Question"
          }?`}</Heading2>
        </ModalSection>
        <ModalSection topOffset={"40px"}>
          <Button
            ref={buttonRef}
            color={"red"}
            size={"regular"}
            variant={"filled"}
            onClick={async () => {
              setIsDeleteActionActive(false)
              await debouncedDeleteQuestion({ id: questionToDelete.id, quizId: questionToDelete.quizId }, handleSuccess)
              setIsDeleteActionActive(true)
            }}
            icon={<IconDelete />}
            isDisabled={!isDeleteActionActive}
          >
            {`Yes, delete it`}
          </Button>
        </ModalSection>
        <ModalSection topOffset={"20px"}>
          <AnchorLink onClick={closeModal} color={"green"} weight={"regular"} size={"16px"}>
            {`No, I don’t want to delete it`}
          </AnchorLink>
        </ModalSection>
      </Modal>
    </Portal>
  )
}
