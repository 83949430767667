import { Location } from "history"

export const initialState = {
  isSuccessModalOpen: false,
  successModalMessage: undefined as string,

  isLeaveModalOpen: false,
  nextLocation: undefined as Location<any>,
  canLeavePage: true,

  isLoadingModalOpen: false,
  loadingModalMessage: undefined as string,
}

export const actionTypes = {
  // Success modal actions
  openSuccessModal: "OPEN_SUCCESS_MODAL",
  closeSuccessModal: "CLOSE_SUCCESS_MODAL",
  setSuccessModalMessage: "SET_SUCCESS_MESSAGE",
  setIsSuccessModalOpen: "SET_SUCCESS_MODAL_VISIBILITY", // deprecated

  // Leave modal actions
  openLeaveModal: "OPEN_LEAVE_MODAL",
  closeLeaveModal: "CLOSE_LEAVE_MODAL",
  setNextLocation: "SET_NEXT_LOCATION",
  setCanLeavePage: "SET_CAN_LEAVE_PAGE",

  // Loading modal actions
  openLoadingModal: "OPEN_LOADING_MODAL",
  closeLoadingModal: "CLOSE_LOADING_MODAL",
  setLoadingMessage: "SET_LOADING_MESSAGE",
}

export type InitialState = typeof initialState
export type Action = { type: string; value?: any }

export const reducer = (state: InitialState = initialState, action: Action): InitialState => {
  switch (action.type) {
    // Success Modal Actions
    case actionTypes.setIsSuccessModalOpen:
      return {
        ...state,
        isSuccessModalOpen: action.value,
      }
    case actionTypes.openSuccessModal:
      return {
        ...state,
        isSuccessModalOpen: true,
      }
    case actionTypes.closeSuccessModal:
      return {
        ...state,
        isSuccessModalOpen: false,
      }
    case actionTypes.setSuccessModalMessage:
      return {
        ...state,
        successModalMessage: action.value,
      }

    // Leave Modal Actions
    case actionTypes.openLeaveModal:
      return {
        ...state,
        isLeaveModalOpen: true,
        canLeavePage: true,
      }
    case actionTypes.closeLeaveModal:
      return {
        ...state,
        isLeaveModalOpen: false,
      }
    case actionTypes.setNextLocation:
      return {
        ...state,
        nextLocation: action.value,
      }
    case actionTypes.setCanLeavePage:
      return {
        ...state,
        canLeavePage: action.value,
      }

    // Loading Modal Actions
    case actionTypes.openLoadingModal:
      return {
        ...state,
        isLoadingModalOpen: true,
      }
    case actionTypes.closeLoadingModal:
      return {
        ...state,
        isLoadingModalOpen: false,
      }
    case actionTypes.setLoadingMessage:
      return {
        ...state,
        loadingModalMessage: action.value,
      }

    default:
      throw new Error(`"${action}" is not a valid action!`)
  }
}
