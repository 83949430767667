import React, { useEffect } from "react"
import { RouteComponentProps } from "react-router"
import { Mutation, QueryResult, Query } from "react-apollo"
import { User, UserVariables } from "model/types"
import { routes } from "router"
import { Form } from "./form"
import { actionTypes, useFormState } from "./form-state"
import styled from "styled-components"
import { Error, Loading } from "components/data-status"
import {
  ChangeUserMutation,
  ChangeUserMutationResult,
  MUTATION_CHANGE_USER,
  useChangeUser,
} from "model/graphql/mutations"
import { QUERY_USER } from "model/graphql/queries"
import { useActivateSuccessModal, useChangePageTitle } from "utils"
import { ContentWrapper, DataStatusWrapper, PageContainer, SubPageHeader } from "components/page"

type Props = {
  children?: never
} & RouteComponentProps<{ userId: string }>

export const UserScreen = (props: Props) => {
  const { userId } = props.match.params

  return (
    <PageContainer>
      <Query query={QUERY_USER} variables={{ id: userId } as UserVariables}>
        {({ data, loading, error }: QueryResult<User>) => {
          if (error) {
            return (
              <DataStatusWrapper>
                <Error>{error.message}</Error>
              </DataStatusWrapper>
            )
          }
          if (!data || !data.user || loading) {
            return (
              <DataStatusWrapper>
                <Loading>Loading...</Loading>
              </DataStatusWrapper>
            )
          }
          return <Content {...props} data={data} />
        }}
      </Query>
    </PageContainer>
  )
}

type ContentProps = {
  data: QueryResult<User>["data"]
} & Props

const Content = (props: ContentProps) => {
  const { data } = props
  const { formState, formDispatch } = useFormState()
  const { id, firstName, lastName, email, authorBio, avatarFile, avatarPreview, role, emailWarning } = formState

  /**
   * Change Page Title
   */

  useChangePageTitle(props.match.path)

  /**
   * Save Data to Form State
   */

  useEffect(() => {
    formDispatch({ type: actionTypes.setId, data: { id: data.user.id } })
  }, [formDispatch, data.user.id])

  useEffect(() => {
    formDispatch({ type: actionTypes.setFirstName, data: { firstName: data.user.firstName } })
  }, [formDispatch, data.user.firstName])

  useEffect(() => {
    formDispatch({ type: actionTypes.setLastName, data: { lastName: data.user.lastName } })
  }, [formDispatch, data.user.lastName])

  useEffect(() => {
    formDispatch({ type: actionTypes.setEmail, data: { email: data.user.email } })
  }, [formDispatch, data.user.email])

  useEffect(() => {
    formDispatch({ type: actionTypes.setRole, data: { role: data.user.groups.edges[0].node.id } })
  }, [formDispatch, data.user.groups])

  useEffect(() => {
    formDispatch({ type: actionTypes.setAuthorBio, data: { authorBio: data.user.description } })
  }, [formDispatch, data.user.description])

  useEffect(() => {
    const avatarPreview = data.user.avatar ? `${process.env.REACT_APP_MEDIA_URL_PREFIX}${data.user.avatar}` : undefined
    formDispatch({ type: actionTypes.setAvatarPreview, data: { avatarPreview: avatarPreview } })
  }, [formDispatch, data.user.avatar])

  /**
   * Change User
   */

  const { activateSuccessModal } = useActivateSuccessModal()

  const handleSuccess = () => {
    activateSuccessModal()
  }

  const { changeUser } = useChangeUser({
    onSuccessAction: handleSuccess,
  })

  const formSubmit = (mutation: ChangeUserMutation) => !emailWarning.length && saveUser(mutation)

  const saveUser = async (changeUserMutation: ChangeUserMutation) => {
    try {
      await changeUser(changeUserMutation, {
        id: id,
        firstName: firstName,
        lastName: lastName,
        email: email,
        groupIds: [role],
        description: authorBio,
        avatar: avatarFile,
        deleteAvatar: !avatarPreview,
      })
    } catch (error) {
      console.error(error)
    }
  }

  /**
   * JSX
   */

  return (
    <React.Fragment>
      <Mutation mutation={MUTATION_CHANGE_USER}>
        {(changeUserMutation: ChangeUserMutation, { loading }: ChangeUserMutationResult) => (
          <SubPageHeader
            breadcrumbLinks={[
              { name: `Users`, path: routes.users },
              { name: `${data.user.firstName} ${data.user.lastName}`, path: routes.user },
            ]}
            heading={`${data.user.firstName} ${data.user.lastName}`}
            button={{
              title: `Save`,
              action: () => formSubmit(changeUserMutation),
              isDisabled: !data.user.email || loading,
            }}
          />
        )}
      </Mutation>

      <ContentWrapper>
        <Container>
          <Form state={formState} dispatch={formDispatch} />
        </Container>
      </ContentWrapper>
    </React.Fragment>
  )
}

const Container = styled("div")`
  display: block;
  position: relative;
  width: 100%;
  max-width: 760px;
`
