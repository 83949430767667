import React, { useState } from "react"
import { Portal } from "components/portal"
import { Modal, ModalSection } from "components/modal"
import { Heading2 } from "components/typography"
import { Button } from "components/button"
import { Mutation, MutationResult, MutationFunction } from "react-apollo"
import { MUTATION_LAYOUT_CREATE } from "model/graphql/mutations"
import { LayoutCreate, LayoutCreateVariables, LayoutsVariables } from "model/types"
import styled from "styled-components"
import { Input } from "components/input"
import { Label } from "components/label"
import { useCreateLayout, getFormattedWarning, UseCreateLayoutArgs } from "./helpers"
import { Warning } from "components/warning"

type Props = {
  isModalOpen: boolean
  closeModal: () => void
  queryVariables: LayoutsVariables
}

type LayoutCreateMutation = MutationFunction<LayoutCreate, LayoutCreateVariables>
type LayoutCreateMutationResult = MutationResult<LayoutCreate>

export const AddLayoutModal = (props: Props) => {
  const { queryVariables } = props
  const [name, setName] = useState("")
  const [nameWarning, setNameWarning] = useState("")

  /**
   * Close Modal
   */

  const closeModal = () => {
    props.closeModal()
    setName("")
    setNameWarning("")
  }

  /**
   * Change Name
   */

  const changeName = (value: string) => {
    setName(value)
    setNameWarning("")
  }

  /**
   * Create Layout
   */

  const handleSuccess: UseCreateLayoutArgs["onSuccessAction"] = () => {
    closeModal()
  }

  const handleError: UseCreateLayoutArgs["onErrorAction"] = errorMessage => {
    setNameWarning(getFormattedWarning(errorMessage))
  }

  const { createLayout } = useCreateLayout({
    queryVariables,
    onSuccessAction: handleSuccess,
    onErrorAction: handleError,
  })

  /**
   * JSX
   */

  return (
    <Mutation mutation={MUTATION_LAYOUT_CREATE}>
      {(layoutCreateMutation: LayoutCreateMutation, { loading }: LayoutCreateMutationResult) => {
        return (
          <Portal>
            <Modal focusIsTrapped={true} closeAction={closeModal} isVisible={props.isModalOpen}>
              <ModalSection hasSideOffset={true}>
                <Heading2>{`Add Layout`}</Heading2>
              </ModalSection>

              <ModalSection topOffset={"40px"}>
                <FormRow>
                  <Label>{`Layout Name`}</Label>
                  <Input value={name} onChange={changeName} hasWarning={Boolean(nameWarning)} />
                  <Warning message={nameWarning} />
                </FormRow>
              </ModalSection>

              <ModalSection topOffset={"40px"}>
                <Button
                  color={"green"}
                  size={"regular"}
                  variant={"filled"}
                  type={"button"}
                  onClick={createLayout(layoutCreateMutation, { name, ...queryVariables })}
                  isDisabled={!name || loading || name.trim().length === 0}
                >
                  {`Save`}
                </Button>
              </ModalSection>
            </Modal>
          </Portal>
        )
      }}
    </Mutation>
  )
}

const FormRow = styled("div")`
  display: grid;
  position: relative;
  grid-template-columns: 120px 1fr;
  grid-template-rows: max-content max-content;
  grid-template-areas:
    "label ."
    "warning warning";
  grid-column-gap: 10px;
  align-items: start;
  width: 420px;
`
