import React from "react"
import { ImageEmojiFace } from "components/images"
import styled from "styled-components"
import { Paragraph } from "components/typography"
import { theme } from "theme"

type Props = {
  children: string
}

export const NoContent = (props: Props) => {
  return (
    <Container>
      <ImageWrapper>
        <ImageEmojiFace />
      </ImageWrapper>
      <Text>{props.children}</Text>
    </Container>
  )
}

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ImageWrapper = styled("div")`
  display: flex;
  position: relative;
  margin-bottom: ${theme.offset2};
`

const Text = styled(Paragraph)`
  color: ${theme.color.textNonactive};
  margin: 0;
`
