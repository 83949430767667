export const theme = {
  color: {
    white: "#ffffff",
    backgroundGray: "#f7f7f7",
    borderGray: "#dedede",
    textNonactive: "#818181",
    textBlack: "#272727",

    primaryGreen: "#359892",
    primaryGreenLight: "#76c6c1",
    primaryGreenDark: "#206e69",

    draftOrange: "#d9ae69",
    draftOrangeLight: "#f0cf9a",
    draftOrangeDark: "#bc9351",

    warningRed: "#e67676",
    warningRedLight: "#faa5a5",
    warningRedDark: "#c74b4b",
  },

  font: {
    family: {
      default: '"Open Sans", sans-serif',
      code: '"Source Code Pro", monospace',
    },
    weight: {
      regular: "400",
      semiBold: "600",
      bold: "700",
    },
  },

  offset1: "20px",
  offset2: "25px",
  offset3: "40px",
  offset4: "80px",

  rounding1: "5px",
  rounding2: "10px",
  rounding3: "15px",
}
