import styled from "styled-components"

type Props = {
  gridArea?: string
}

export const FormRow = styled("div")`
  display: grid;
  position: relative;
  grid-template-columns: 120px 1fr;
  grid-template-rows: max-content max-content;
  grid-template-areas:
    "label ."
    "warning warning";
  grid-column-gap: 10px;
  align-items: start;
  width: 100%;
  ${(props: Props) => (props.gridArea ? `grid-area: ${props.gridArea}` : "")};
`
