import React, { useRef, useState } from "react"
import { IconClose, IconSearch } from "components/icons"
import styled from "styled-components"
import { theme } from "theme"

//Types

type Props = {
  placeholder?: string
  value: string
  onChange: (text: string) => void
  children?: never
}

type IconProp = {
  cursorType: string
}

export const Search = (props: Props) => {
  const [isFocused, setFocused] = useState(false)
  const inputRef = useRef(null)

  const iconColor = isFocused ? theme.color.textBlack : theme.color.borderGray

  const handleOnClick = () => {
    props.onChange("")
    inputRef.current.focus()
  }

  return (
    <SearchWrapper>
      <IconWrapper cursorType={Boolean(props.value) && "pointer"} onClick={!props.value ? null : handleOnClick}>
        {!props.value ? <IconSearch color={iconColor} /> : <IconClose color={iconColor} />}
      </IconWrapper>
      <Input
        placeholder={props.placeholder}
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        ref={inputRef}
      />
    </SearchWrapper>
  )
}

const SearchWrapper = styled("div")`
  display: block;
  position: relative;
  width: 100%;
  max-width: 400px;
  height: 50px;
`
const Input = styled("input")`
  width: 100%;
  border-radius: 25px;
  border: 1px solid ${theme.color.borderGray};
  padding: 14px 45px 14px 20px;
  color: ${theme.color.textBlack};
  font-family: ${theme.font.family.default};
  font-size: 16px;
  height: 50px;
  box-sizing: border-box;
  &::placeholder {
    color: ${theme.color.textNonactive};
  }
  &:focus {
    outline: 0;
    border-color: ${theme.color.textNonactive};
  }
`

const IconWrapper = styled("div")`
  width: 20px;
  position: absolute;
  right: 20px;
  top: 15px;
  cursor: ${(props: IconProp) => props.cursorType || "text"};
`
