import React, { useEffect, useState } from "react"
import { QuestionForm } from "screens/quiz/questions/question-form"
import styled from "styled-components"
import { List, ListRowProps, WindowScroller, WindowScrollerChildProps } from "react-virtualized"
import { DeleteQuestionModal } from "screens/quiz/questions/delete-question-modal"
import { QuestionToDelete } from "screens/quiz/questions/helpers"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { QuizQuestionsDispatch } from "utils"
import { quizQuestionsActionTypes } from "redux-reducers"
import { QuestionsQueryResult } from "screens/quiz/questions/quiz-questions-screen"

type Props = {
  data: QuestionsQueryResult["data"]
  children?: never
}

/**
 * Question List Component
 * @param props
 * @constructor
 */

export const QuestionsList = React.memo((props: Props) => {
  const { data } = props
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false)
  const [questionToDelete, setQuestionToDelete] = useState<QuestionToDelete>({
    id: "",
    quizId: "",
    questionTitle: "",
    order: null as number,
  })
  const dispatch = useDispatch()

  /**
   * Set quiz data
   */

  useEffect(() => {
    dispatch<QuizQuestionsDispatch>({
      type: quizQuestionsActionTypes.setQuizQuestionsData,
      data: { id: data.quiz.id, title: data.quiz.title },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  /**
   * Open Delete Modal Click Handler
   */

  const openDeleteModal = (question: QuestionToDelete) => {
    setIsDeleteModalOpened(true)
    setQuestionToDelete({
      quizId: data.quiz.id,
      id: question.id,
      questionTitle: question.questionTitle,
      order: question.order,
    })
  }

  /**
   * Fuse for data from BE
   */

  const sortedQuestions = _.sortBy(data.questions.edges, ({ node: question }) => question.order)

  /**
   * List Row Renderer
   * @param key
   * @param index
   * @param style
   */

  const rowRenderer = ({ key, index, style }: ListRowProps) => {
    return (
      <div key={key} style={style}>
        <ContentWrapper>
          <QuestionForm
            totalQuestionCount={sortedQuestions.length}
            openDeleteModal={openDeleteModal}
            data={sortedQuestions[index].node}
          />
        </ContentWrapper>
      </div>
    )
  }

  /**
   * Render JSX
   */

  return (
    <React.Fragment>
      <WindowScroller>
        {({ height, isScrolling, scrollTop, width }: WindowScrollerChildProps) => (
          <List
            autoHeight
            height={height}
            isScrolling={isScrolling}
            rowHeight={510}
            scrollTop={scrollTop}
            overscanRowCount={2}
            rowRenderer={rowRenderer}
            rowCount={data.questions.edges.length}
            width={width}
          />
        )}
      </WindowScroller>
      <DeleteQuestionModal
        isModalOpen={isDeleteModalOpened}
        questionToDelete={questionToDelete}
        closeModal={() => {
          setIsDeleteModalOpened(false)
        }}
      />
    </React.Fragment>
  )
})

/**
 * Prevent Render
 */

const ContentWrapper = styled("div")`
  display: grid;
  justify-items: center;
  position: relative;
`
