import React, { useState } from "react"
import { Box } from "components/box"
import { Textarea } from "components/textarea"
import { actionTypes, FormDispatch, FormState } from "./form-state"
import styled from "styled-components"
import { Label } from "components/label"
import { theme } from "theme"
import { CodeEditor } from "components/code-editor"
import { RoundButton } from "components/round-button"
import { IconResize } from "components/icons/resize"
import { Warning } from "components/warning"

type Props = {
  state: FormState
  dispatch: FormDispatch
  children?: never
}

type EditorValueType = "initialScript" | "adsTxt"

export const Form = (props: Props) => {
  const { state, dispatch } = props
  const { name, adsTxtEntry, initialScript, nameWarning } = state
  const [isEditorOpened, setIsEditorOpened] = useState(false)
  const [codeToEdit, setCodeToEdit] = useState<EditorValueType>(null)
  const [editorHeading, setEditorHeading] = useState("")

  const getDefaultEditorState = (editorValueType: EditorValueType): string => {
    if (editorValueType === "initialScript") {
      return state.initialScript
    }
    if (editorValueType === "adsTxt") {
      return state.adsTxtEntry
    }
    return ""
  }

  /**
   * Set Name
   * @param value
   */

  const setName = (value: string) => {
    dispatch({ type: actionTypes.setName, data: { name: value } })
    dispatch({ type: actionTypes.setNameWarning, data: { nameWarning: "" } })
  }

  /**
   * Set Initial Script
   * @param value
   */

  const setInitialScript = (value: string) =>
    dispatch({ type: actionTypes.setInitialScript, data: { initialScript: value } })

  /**
   * Set Ads Txt Entry
   * @param value
   */

  const setAdsTxtEntry = (value: string) => dispatch({ type: actionTypes.setAdsTxtEntry, data: { adsTxtEntry: value } })

  /**
   * Set Initial Script or Ads Txt Entry in Editor
   * @param editorValueType
   */

  const setEditorValue = (editorValueType: EditorValueType) => {
    if (editorValueType === "initialScript") {
      return setInitialScript
    }
    if (editorValueType === "adsTxt") {
      return setAdsTxtEntry
    }
  }

  return (
    <React.Fragment>
      <Container>
        <Box>
          <StyledForm>
            <FormRow gridArea={"vendor-name"}>
              <Label>{`Vendor Name`}</Label>
              <Textarea value={name} onChange={setName} hasWarning={Boolean(nameWarning)} />
              <Warning message={nameWarning} />
            </FormRow>

            <FormRow gridArea={"initial-script"}>
              <Label>{`Initialization Routine`}</Label>
              <Textarea value={initialScript} onChange={setInitialScript} fontType={"CODE"} hasStaticHeight={true} />
              <OpenEditorActionWrapper>
                <RoundButton
                  onClick={() => {
                    setCodeToEdit("initialScript")
                    setEditorHeading("Initialization Routine")
                    setIsEditorOpened(true)
                  }}
                >
                  <IconResize />
                </RoundButton>
              </OpenEditorActionWrapper>
            </FormRow>

            <FormRow gridArea={"ads-txt-entries"}>
              <Label>{`Ads.txt Entries`}</Label>
              <Textarea value={adsTxtEntry} onChange={setAdsTxtEntry} fontType={"CODE"} hasStaticHeight={true} />
              <OpenEditorActionWrapper>
                <RoundButton
                  onClick={() => {
                    setCodeToEdit("adsTxt")
                    setEditorHeading("Ads.txt Entries")
                    setIsEditorOpened(true)
                  }}
                >
                  <IconResize />
                </RoundButton>
              </OpenEditorActionWrapper>
            </FormRow>
          </StyledForm>
        </Box>
      </Container>
      <CodeEditor
        onChange={setEditorValue(codeToEdit)}
        editorHeading={editorHeading}
        defaultEditorState={getDefaultEditorState(codeToEdit)}
        isEditorOpened={isEditorOpened}
        closeEditor={() => {
          setIsEditorOpened(false)
        }}
      />
    </React.Fragment>
  )
}

const Container = styled("div")`
  display: block;
  position: relative;
  width: 100%;
  max-width: 760px;
`

const StyledForm = styled("form")`
  display: grid;
  position: relative;
  padding: 40px;
  width: 760px;
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content max-content max-content;
  grid-row-gap: ${theme.offset3};
  grid-template-areas:
    "vendor-name"
    "initial-script"
    "ads-txt-entries";
`

type FormRowProps = {
  gridArea: string
}

const FormRow = styled("div")`
  display: grid;
  position: relative;
  grid-template-columns: 120px 1fr;
  grid-template-rows: max-content max-content;
  grid-template-areas:
    "label ."
    "warning warning";
  grid-column-gap: 10px;
  align-items: start;
  grid-area: ${(props: FormRowProps) => props.gridArea};
`

const OpenEditorActionWrapper = styled("div")`
  position: absolute;
  bottom: -20px;
  right: -20px;
`
