import React, { useCallback } from "react"
import { ActionButton, ActionContainer, ColumnHeading, Table, TableAvatar } from "components/table"
import { NavigationLink } from "components/links"
import { routes } from "router"
import { UserToDelete } from "./users-screen"
import { Users_users_edges_node } from "model/types"
import styled from "styled-components"
import { theme } from "theme"
import { useDispatch } from "react-redux"
import { getSortingIndicator, ListsDispatch } from "utils"
import { listsActionTypes } from "redux-reducers"

type Props = {
  tab: string
  users: Users_users_edges_node[]
  openDeleteModal: (user: UserToDelete) => () => void
  children?: never
  groupName?: string
  isSortedBy: string
  isDescending: boolean
}

export const UsersTable = (props: Props) => {
  const { users, openDeleteModal, groupName, isSortedBy, isDescending } = props

  /**
   * Set List Sorting
   */
  const dispatch = useDispatch()

  const setSortBy = useCallback(
    (sortBy: string) => dispatch<ListsDispatch>({ type: listsActionTypes.setUsersSortBy, data: { users: { sortBy } } }),
    [dispatch]
  )
  const setDesc = useCallback(
    (desc: boolean) => dispatch<ListsDispatch>({ type: listsActionTypes.setUsersDesc, data: { users: { desc } } }),
    [dispatch]
  )

  const sortList = (sortBy: string) => () => {
    if (sortBy !== isSortedBy) {
      setSortBy(sortBy)
      setDesc(false)
      return
    }
    if (sortBy === isSortedBy && !isDescending) {
      setDesc(true)
      return
    }
    if (sortBy === isSortedBy && isDescending) {
      setDesc(false)
      return
    }
  }

  /**
   * JSX
   */

  return (
    <React.Fragment>
      <Table
        pageHeaderHasNavigation={true}
        contentType={"users"}
        noContentNotification={"Ups! There is no such User…"}
        content={{
          head: [
            <ColumnHeading />,
            <ColumnHeading
              onClick={sortList("last_name")}
              arrowState={getSortingIndicator({ sortBy: "last_name", isSortedBy, isDescending })}
            >{`Name`}</ColumnHeading>,
            <ColumnHeading
              onClick={sortList("email")}
              arrowState={getSortingIndicator({ sortBy: "email", isSortedBy, isDescending })}
            >{`Email`}</ColumnHeading>,
            <ColumnHeading>{`Role`}</ColumnHeading>,
            <ColumnHeading
              onClick={sortList("quizzes_count")}
              arrowState={getSortingIndicator({ sortBy: "quizzes_count", isSortedBy, isDescending })}
            >{`Quizzes`}</ColumnHeading>,
            <ColumnHeading>{`Action`}</ColumnHeading>,
          ],

          body: users.map((user, index) => [
            <TableAvatar avatar={user.avatar} key={`user-avatar-row-${index}`} />,
            <NavigationLink
              key={`user-name-row-${index}`}
              color={"green"}
              weight={"semiBold"}
              hasVisitedColor={!user.isActive}
              isDisabled={!user.isActive}
              size={"16px"}
              to={routes.user.replace(":tab", props.tab).replace(":userId", user.id)}
            >
              {`${user.firstName} ${user.lastName}`}
            </NavigationLink>,
            <PlainTableText key={`user-email-row-${index}`}>{user.email}</PlainTableText>,
            <PlainTableText key={`user-role-row-${index}`}>
              {Boolean(groupName) ? groupName : user.groups.edges.map(({ node: group }) => group.name)}
            </PlainTableText>,
            <PlainTableText key={`user-quizzes-row-${index}`}>{user.quizzesCount}</PlainTableText>,
            <ActionContainer key={`user-delete-row-${index}`}>
              <ActionButton
                actionType={"delete"}
                onClick={openDeleteModal({
                  id: user.id,
                  name: `${user.firstName} ${user.lastName}`,
                  userName: user.email,
                })}
              />
            </ActionContainer>,
          ]),
        }}
      />
    </React.Fragment>
  )
}

const PlainTableText = styled("div")`
  font-family: ${theme.font.family.default};
  font-weight: ${theme.font.weight.regular};
  font-size: 16px;
`
