import React, { useEffect, useRef, useState } from "react"
import { useHandleClickOutside } from "utils"
import styled from "styled-components"
import { IconDown } from "../icons"
import { theme } from "theme"

type Props = {
  children?: never
  placeholder?: string
  selectedOption?: { id: string; name: string }
  options: {
    id: string
    value: string
  }[]
  optionsCount: number
  search: {
    placeholder: string
    value: string
    onChange: (value: string) => void
  }
  onSelect: ({ id, value }: { id: string; value: string }) => void
  isDisabled?: boolean
}

export const Select = (props: Props) => {
  const { placeholder = "", selectedOption = { id: "", name: "" }, isDisabled = false, options } = props

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [selectedOptionName, setSelectedOptionName] = useState(selectedOption.name)
  const [selectedOptionsIndex, setSelectedOptionsIndex] = useState(0)
  const [optionsRefs, setOptionsRefs] = useState<React.RefObject<HTMLLIElement>[]>(undefined)

  useEffect(() => {
    if (options.length > 0 && !optionsRefs) {
      setOptionsRefs(
        options.map((option, index) => {
          if (option.id === selectedOption.id) {
            setSelectedOptionsIndex(index)
          }
          return React.createRef<HTMLLIElement>()
        })
      )
    }
  }, [options, optionsRefs, selectedOption])

  const selectRef = useRef<HTMLDivElement>(null)
  const toggleButtonRef = useRef<HTMLDivElement>(null)
  const searchRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setSelectedOptionName(selectedOption.name)
  }, [selectedOption])

  useEffect(() => {
    if (isMenuOpen && searchRef.current) {
      searchRef.current.focus()
    }
  }, [isMenuOpen])

  useHandleClickOutside({
    ref: selectRef,
    handler: () => setIsMenuOpen(false),
  })

  const toggleOnClick = () => {
    if (isDisabled) {
      return
    }
    setIsMenuOpen(!isMenuOpen)
    props.search.onChange("")
  }

  const handleKeyDown = (id?: string, value?: string) => (
    event: React.KeyboardEvent<HTMLDivElement | HTMLLIElement>
  ) => {
    if (isDisabled) {
      return
    }

    // Open select
    if ((event.key === "ArrowDown" || event.key === "ArrowUp") && !isMenuOpen) {
      event.preventDefault()
      setIsMenuOpen(true)
    }

    // Close select
    if (event.key === "Escape" && isMenuOpen) {
      event.preventDefault()
      setIsMenuOpen(false)
      toggleButtonRef.current.focus()
      props.search.onChange("")
    }

    // Navigate down from selected option
    if (event.key === "ArrowDown" && isMenuOpen) {
      event.preventDefault()
      const nextIndex = selectedOptionsIndex < options.length - 1 ? selectedOptionsIndex + 1 : selectedOptionsIndex
      if (nextIndex !== undefined) {
        optionsRefs[nextIndex].current.focus()
        setSelectedOptionsIndex(nextIndex)
      }
    }

    // Navigate up from selected option
    if (event.key === "ArrowUp" && isMenuOpen) {
      event.preventDefault()
      const prevIndex = selectedOptionsIndex > 0 ? selectedOptionsIndex - 1 : selectedOptionsIndex
      if (prevIndex !== undefined) {
        optionsRefs[prevIndex].current.focus()
        setSelectedOptionsIndex(prevIndex)
      }
    }

    // Select option
    if (event.key === "Enter" && isMenuOpen && id && value) {
      event.preventDefault()
      props.onSelect({ id, value })
      toggleButtonRef.current.focus()
      setIsMenuOpen(false)
    }
  }

  // Select option on mouse click
  const selectOnClick = (id: string, value: string, index: number) => () => {
    props.onSelect({ id, value })
    setIsMenuOpen(false)
    setSelectedOptionsIndex(index)
  }

  return (
    <Container ref={selectRef}>
      <input type={"hidden"} name={"select"} />

      <ToggleButton
        ref={toggleButtonRef}
        tabIndex={0}
        role={"button"}
        aria-haspopup={"listbox"}
        isDisabled={isDisabled}
        isMenuOpen={isMenuOpen}
        onClick={toggleOnClick}
        onKeyDown={handleKeyDown()}
      >
        <Text isDisabled={isDisabled}>{selectedOptionName === undefined ? placeholder : selectedOptionName}</Text>
        <IconWrapper isMenuOpen={isMenuOpen}>
          <IconDown
            color={
              isDisabled ? theme.color.backgroundGray : isMenuOpen ? theme.color.textBlack : theme.color.textNonactive
            }
          />
        </IconWrapper>
      </ToggleButton>

      {isMenuOpen && (
        <Menu role={"listbox"}>
          <MenuTopBorder />
          {props.optionsCount > 2 && (
            <Search
              ref={searchRef}
              placeholder={props.search.placeholder}
              value={props.search.value}
              onChange={e => props.search.onChange(e.target.value)}
            />
          )}

          <List>
            {props.options.map((option, index) => (
              <ListItem
                tabIndex={-1}
                ref={optionsRefs[index]}
                key={`${option.value}_${option.id}_${index}`}
                role="option"
                onClick={selectOnClick(option.id, option.value, index)}
                onKeyDown={handleKeyDown(option.id, option.value)}
                isFocused={index === selectedOptionsIndex}
              >
                {option.value}
              </ListItem>
            ))}
          </List>
        </Menu>
      )}
    </Container>
  )
}

const Container = styled("div")`
  display: block;
  position: relative;
`

type ToggleButtonProps = {
  isMenuOpen: boolean
  isDisabled: boolean
}

const ToggleButton = styled("div")`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0 20px 0 14px;
  border-radius: 5px;
  background-color: ${(props: ToggleButtonProps) =>
    props.isDisabled || props.isMenuOpen ? theme.color.white : theme.color.backgroundGray};
  border-width: 1px;
  border-style: solid;
  border-color: ${(props: ToggleButtonProps) => {
    if (props.isDisabled) {
      return theme.color.backgroundGray
    }
    if (props.isMenuOpen) {
      return theme.color.textNonactive
    }
    return theme.color.borderGray
  }};
  cursor: ${(props: ToggleButtonProps) => (props.isDisabled ? "not-allowed" : "pointer")};

  &:focus {
    outline: none;
    border-color: ${(props: ToggleButtonProps) =>
      props.isDisabled ? theme.color.backgroundGray : theme.color.textNonactive};
  }
`

type TextProps = {
  isDisabled: boolean
}

const Text = styled("span")`
  color: ${(props: TextProps) => (props.isDisabled ? theme.color.borderGray : theme.color.textBlack)};
  font-size: 16px;
  font-weight: ${theme.font.weight.regular};
`

type IconWrapperProps = {
  isMenuOpen: boolean
}

const IconWrapper = styled("div")`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  transform-origin: center center;
  transform: rotate3d(1, 0, 0, ${(props: IconWrapperProps) => (props.isMenuOpen ? "180deg" : "0deg")});
`

const Menu = styled("div")`
  display: flex;
  position: absolute;
  flex-direction: column;
  top: 42px;
  left: 0;
  width: 100%;
  max-height: 220px;
  border-radius: 0 0 ${theme.rounding1} ${theme.rounding1};
  border-color: ${theme.color.textNonactive};
  border-style: solid;
  border-top-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  padding-top: 6px;
  margin-bottom: ${theme.offset3};
  background: ${theme.color.white};
  overflow: hidden;
  z-index: 9;
`

const MenuTopBorder = styled("div")`
  display: block;
  position: absolute;
  width: 100%;
  top: 1px;
  left: -1px;
  height: 6px;
  border-radius: 0 0 ${theme.rounding1} ${theme.rounding1};
  border-color: ${theme.color.textNonactive};
  border-style: solid;
  border-top-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  box-sizing: content-box;
`

const Search = styled("input")`
  display: flex;
  position: relative;
  align-items: center;
  height: 36px;
  min-height: 36px;
  border: none;
  line-height: 22px;
  padding: 0 20px 0 14px;
  font-size: 16px;
  color: ${theme.color.textBlack};
  background-color: transparent;
  margin-top: 7px;

  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${theme.color.textNonactive};
  }
`

const List = styled("ul")`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  list-style: none;
`

type ListItemProps = {
  isFocused: boolean
}

const ListItem = styled("li")`
  display: flex;
  position: relative;
  align-items: center;
  height: 36px;
  flex-shrink: 0;
  padding: 0 20px 0 14px;
  cursor: pointer;
  font-size: 16px;
  font-weight: ${(props: ListItemProps) => (props.isFocused ? theme.font.weight.semiBold : theme.font.weight.regular)};
  line-height: 22px;

  &:hover {
    font-weight: ${theme.font.weight.semiBold};
  }

  &:focus {
    outline: none;
  }
`
