import React from "react"
import styled from "styled-components"
import { theme } from "theme"

type Props = {
  children?: React.ReactNode
}

export const SlotsSideSection = (props: Props) => {
  return <Container>{props.children}</Container>
}

const Container = styled("div")`
  display: grid;
  position: relative;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, max-content);
  grid-template-areas:
    "right_rail_upper"
    "right_rail_middle"
    "right_rail_lower";
  grid-row-gap: ${theme.offset3};
  width: 100%;
  height: 100%;
  background-color: ${theme.color.backgroundGray};
  padding: ${theme.offset3} ${theme.offset3} ${theme.offset3} calc(${theme.offset3} - 1px);
  grid-area: slots-side-section;
  border-bottom: 1px solid ${theme.color.textNonactive};
  border-left: 1px solid ${theme.color.textNonactive};
  border-top-right-radius: ${theme.rounding1};
`
