import React, { useEffect } from "react"
import styled from "styled-components"
import { theme } from "theme"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

type Props = {
  onChange: any
  onBlur?: () => void
  isFocused?: boolean
  selected?: Date
  hasWarning?: boolean
}

export const CustomDatePicker = (props: Props) => {
  const inputRef = React.useRef(null)
  const [isFocused, setIsFocused] = React.useState(false)

  useEffect(() => {
    if (props.isFocused && Boolean(inputRef.current)) {
      inputRef.current.focus()
    }
  }, [props.isFocused])

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true)
    if (event.target.value) event.target.select()
  }

  const handleBlur = () => {
    setIsFocused(false)
    if (props.onBlur) {
      props.onBlur()
    }
  }

  return (
    <Wrapper onFocus={handleFocus} onBlur={handleBlur} isFocused={isFocused} hasWarning={props.hasWarning}>
      <DatePicker selected={props.selected} onChange={props.onChange} />
    </Wrapper>
  )
}

type ContainerProps = {
  isFocused: boolean
  hasWarning: boolean
}

const Wrapper = styled.div`
  .react-datepicker__day--selected {
    background-color: ${theme.color.primaryGreen};
  }
  .react-datepicker__day--keyboard-selected {
    background-color: ${theme.color.primaryGreenLight};
  }
  .react-datepicker__triangle {
    left: -50% !important;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker-popper {
    z-index: 1000;
  }
  .react-datepicker__input-container {
    display: flex;
    position: relative;
    width: 100%;
    border-top-left-radius: ${theme.rounding1};
    border-top-right-radius: ${theme.rounding1};
    border-bottom-left-radius: ${theme.rounding1};
    border-bottom-right-radius: ${theme.rounding1};
    background-color: ${theme.color.white};
    border-style: solid;
    border-color: ${(props: ContainerProps) => {
      return theme.color.borderGray
    }};
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: ${"1px"};
    overflow: hidden;
  }

  .react-datepicker__input-container input {
    display: flex;
    border: none;
    font-family: ${theme.font.family.default};
    font-weight: ${theme.font.weight.regular};
    font-size: 16px;
    background-color: transparent;
    line-height: 22px;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 14px;
    padding-right: 14px;
    color: ${theme.color.textBlack};
    height: 48px;
    width: 100%;

    &::placeholder {
      font-family: ${theme.font.family.default};
      color: ${theme.color.textNonactive};
      line-height: 22px;
    }

    &:focus {
      outline: 0;
      border: none;
    }

    &:disabled {
      color: ${theme.color.textNonactive};
      cursor: not-allowed;

      &::placeholder {
        color: ${theme.color.borderGray};
      }
    }
  }
`
