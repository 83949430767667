import React from "react"
import { Container, IconWrapper } from "./shared-components"
import { theme } from "theme"
import { Spinner } from "components/spinner"

type Props = {
  children: string
}

export const Loading = (props: Props) => {
  return (
    <Container>
      <IconWrapper>
        <Spinner color={theme.color.textNonactive} />
      </IconWrapper>
      <span className={"text"}>{props.children}</span>
    </Container>
  )
}
