import gql from "graphql-tag"
import { fragments } from "model/graphql/fragments"

export const QUERY_UNIT = gql`
  query Unit($id: ID!) {
    unit(id: $id) {
      ...UnitData
      vendor {
        ...VendorData
      }
    }
  }
  ${fragments.unit}
  ${fragments.vendor}
`
