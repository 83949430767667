import React from "react"
import styled from "styled-components"
import { theme } from "theme"
import imagePlaceholder from "screens/layout/variant-preview/slot-sections/image-placeholder.png"

type Props = {
  children?: React.ReactNode
}

export const SlotsMiddleSection = (props: Props) => {
  return (
    <Container>
      <PageContent1 />

      <PageContent2>
        <div className={"line heading-line"} />
        <div className={"empty-image"}>
          <img src={imagePlaceholder} alt="placeholder" />
        </div>
      </PageContent2>

      <PageContent3>
        <div className={"box"} />
        <div className={"box"} />
      </PageContent3>

      <PageContent5>
        <div className={"box"} />
        <div className={"box"} />
      </PageContent5>

      <PageContent4>
        <div className={"heading-box"}>
          <div className={"empty-image"}>
            <img src={imagePlaceholder} alt="placeholder" />
          </div>
          <div className={"heading"}>
            <div className={"line first-heading-line"} />
            <div className={"line second-heading-line"} />
          </div>
        </div>
        <div className={"line first-line"} />
        <div className={"line second-line"} />
        <div className={"line third-line"} />
      </PageContent4>

      {props.children}
    </Container>
  )
}

const Container = styled("div")`
  display: grid;
  position: relative;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(10, max-content);
  grid-template-areas:
    "above_headline"
    "page-content-1"
    "below_headline"
    "page-content-2"
    "above_buttons"
    "page-content-3"
    "below_buttons_1"
    "below_buttons_2"
    "below_buttons_3"
    "page-content-5"
    "below_second_buttons_1"
    "below_second_buttons_2"
    "below_second_buttons_3"
    "page-content-4";
  grid-row-gap: ${theme.offset3};
  width: 100%;
  background-color: ${theme.color.backgroundGray};
  border-bottom: 1px solid ${theme.color.textNonactive};
  padding: ${theme.offset3};
  grid-area: slots-middle-section;
`

const PageContent1 = styled("div")`
  display: block;
  position: relative;
  justify-self: start;
  width: 280px;
  height: 20px;
  border-radius: 10px;
  background-color: ${theme.color.textNonactive};
  margin: 0 20px;
  grid-area: page-content-1;
`

const PageContent2 = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  justify-self: start;
  width: 100%;
  padding: 0 20px;
  grid-area: page-content-2;

  .line {
    display: block;
    position: relative;
    background-color: ${theme.color.textNonactive};
  }

  .heading-line {
    width: 180px;
    height: 10px;
    border-radius: 5px;
  }

  .empty-image {
    display: block;
    position: relative;
    background-color: ${theme.color.white};
    border: 1px solid ${theme.color.textNonactive};
    width: 100%;
    height: 120px;
    margin-top: 20px;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
    }
  }
`

const PageContent3 = styled("div")`
  display: flex;
  justify-content: center;
  position: relative;
  justify-self: start;
  width: 100%;
  padding: 0 20px;
  grid-area: page-content-3;

  .box {
    display: block;
    position: relative;
    border-radius: ${theme.rounding1};
    background-color: ${theme.color.textNonactive};
    height: 30px;
    width: 100%;

    &:first-of-type {
      margin-right: 10px;
    }
    &:last-of-type {
      margin-left: 10px;
    }
  }
`

const PageContent5 = styled("div")`
  display: flex;
  justify-content: center;
  position: relative;
  justify-self: start;
  width: 100%;
  padding: 0 20px;
  grid-area: page-content-5;

  .box {
    display: block;
    position: relative;
    border-radius: ${theme.rounding1};
    background-color: ${theme.color.textNonactive};
    height: 30px;
    width: 100%;

    &:first-of-type {
      margin-right: 10px;
    }
    &:last-of-type {
      margin-left: 10px;
    }
  }
`

const PageContent4 = styled("div")`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 0 20px;
  grid-area: page-content-4;

  .heading-box {
    display: flex;
    position: relative;
    height: 60px;
  }

  .heading {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: center;
    height: 100%;
  }

  .empty-image {
    display: block;
    position: relative;
    background-color: ${theme.color.white};
    border: 1px solid ${theme.color.textNonactive};
    width: 60px;
    height: 60px;
    border-radius: 30px;
    overflow: hidden;
    margin-right: ${theme.offset1};

    img {
      height: 100%;
      width: 100%;
    }
  }

  .line {
    display: block;
    position: relative;
    background-color: ${theme.color.textNonactive};
  }

  .first-heading-line {
    border-radius: 5px;
    height: 10px;
    width: 180px;
  }

  .second-heading-line {
    border-radius: 5px;
    height: 10px;
    width: 77px;
    margin-top: 10px;
  }

  .first-line {
    width: 100%;
    height: 6px;
    margin-top: 15px;
    border-radius: 3px;
  }

  .second-line {
    width: 80%;
    height: 6px;
    margin-top: 10px;
    border-radius: 3px;
  }

  .third-line {
    width: 90%;
    height: 6px;
    margin-top: 10px;
    border-radius: 3px;
  }
`
