import React, { useRef } from "react"
import { Heading2 } from "components/typography"
import { Button } from "components/button"
import { IconDelete } from "components/icons"
import { AnchorLink } from "components/links"
import { Portal } from "components/portal"
import { Modal, ModalSection } from "components/modal"
import { Mutation } from "react-apollo"
import {
  DeleteQuizMutation,
  DeleteQuizMutationResult,
  MUTATION_DELETE_QUIZ,
  useDeleteQuiz,
} from "model/graphql/mutations"
import { useActivateSuccessModal, useFocusOnButton } from "utils"
import { QuizzesVariables } from "model/types"

/**
 * Props Types
 */

type Props = {
  isModalOpen: boolean
  quizToDelete: { id: string; title: string }
  closeModal: () => void
  children?: never
  queryVariables: QuizzesVariables
}

export const DeleteQuizModal = (props: Props): any => {
  const { quizToDelete, closeModal, isModalOpen, queryVariables } = props

  /**
   * Focus on Delete Button
   */

  const buttonRef = useRef<HTMLButtonElement>(null)
  useFocusOnButton({ ref: buttonRef })

  /**
   * Delete Quiz
   */

  const { activateSuccessModal } = useActivateSuccessModal()

  /**
   * Closing Delete Modal & Open Success Modal
   */

  const handleSuccess = () => {
    closeModal()
    activateSuccessModal(`Quiz "${quizToDelete.title}"\n was deleted.`)
  }

  const { deleteQuiz } = useDeleteQuiz({
    queryVariables,
    onSuccessAction: handleSuccess,
  })

  /**
   * Render JSX
   */

  return (
    <Mutation mutation={MUTATION_DELETE_QUIZ}>
      {(quizDeleteMutation: DeleteQuizMutation, { loading }: DeleteQuizMutationResult) => (
        <Portal>
          <Modal isVisible={isModalOpen} focusIsTrapped={true}>
            <ModalSection>
              <Heading2>{`Quiz "${quizToDelete.title}" will be,\n deleted from the "${process.env.REACT_APP_TITLE}"`}</Heading2>
            </ModalSection>
            <ModalSection topOffset={"40px"}>
              <Button
                ref={buttonRef}
                color={"red"}
                size={"regular"}
                variant={"filled"}
                onClick={deleteQuiz(quizDeleteMutation, { id: quizToDelete.id })}
                icon={<IconDelete />}
                isDisabled={loading}
              >
                {`Yes, delete it`}
              </Button>
            </ModalSection>
            <ModalSection topOffset={"20px"}>
              <AnchorLink onClick={closeModal} color={"green"} weight={"regular"} size={"16px"}>
                {`No, I don’t want to delete it`}
              </AnchorLink>
            </ModalSection>
          </Modal>
        </Portal>
      )}
    </Mutation>
  )
}
