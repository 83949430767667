import React from "react"
import { IconProps } from "./types"
import { Svg } from "./styles"

type Props = IconProps

export const IconLogOut = (props: Props) => {
  return (
    <Svg color={props.color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.002 21.002">
      <g id="Log_Out_Icon" transform="translate(-784.5 -739.5)">
        <path
          d="M1402.2,643.332h-5.884a4,4,0,0,1-4-4v-12a4,4,0,0,1,4-4h5.884"
          transform="translate(-607.309 116.669)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M1437.8,640.478l3.951,3.951-3.951,3.951"
          transform="translate(-636.75 105.572)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          x2="13.73"
          transform="translate(791.272 750.001)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </Svg>
  )
}
