import React from "react"
import styled from "styled-components"
import { theme } from "theme"

export const NavSeparator = () => {
  return <VerticalLine />
}

const VerticalLine = styled("div")`
  display: block;
  position: relative;
  width: 1px;
  background: ${theme.color.borderGray};
  height: 70px;
  grid-area: separator;
`
