import React from "react"
import { BreadcrumbNavigation } from "components/breadcrumb"
import { BreadcrumbWrapper } from "components/page"
import {
  QuizGeneralBreadcrumbLink,
  QuizGeneralTabBreadcrumbLink,
  QuizzesBreadcrumbLink,
} from "screens/quiz/page-header/quiz-breadcrumb/breadcrumb-links"

export const QuizGeneralBreadcrumb = () => {
  return (
    <BreadcrumbWrapper>
      <BreadcrumbNavigation>
        <QuizzesBreadcrumbLink />
        <QuizGeneralBreadcrumbLink />
        <QuizGeneralTabBreadcrumbLink />
      </BreadcrumbNavigation>
    </BreadcrumbWrapper>
  )
}
