import React from "react"
import { ImageUploadProps } from "components/image-upload/types"

/**
 * Set Image
 */

type SetImageArgs = {
  event: React.ChangeEvent<HTMLInputElement>
  setImageFile: ImageUploadProps["setImageFile"]
  setImagePreview: ImageUploadProps["setImagePreview"]
}

export const setImage = (args: SetImageArgs) => {
  const { event, setImagePreview, setImageFile } = args

  event.preventDefault()

  const reader = new FileReader()
  const file = event.target.files[0]

  if (/image.*/.test(file.type)) {
    reader.onloadend = () => {
      const url = reader.result as string
      setImagePreview(url)
      setImageFile(file)
    }
  }

  reader.readAsDataURL(file)
}

/**
 * Remove Image
 */

type RemoveImageArgs = {
  setImageFile: ImageUploadProps["setImageFile"]
  setImagePreview: ImageUploadProps["setImagePreview"]
}

export const removeImage = (args: RemoveImageArgs) => {
  const { setImagePreview, setImageFile } = args

  setImagePreview(undefined)
  setImageFile(null)
}
