import React, { useContext, useEffect, useRef } from "react"
import { RouteComponentProps } from "react-router"
import { Mutation, Query, QueryResult } from "react-apollo"
import { routes } from "router"
import { actionTypes as formActionTypes, useFormState } from "./form-state"
import { Form } from "./form"
import { MUTATION_RULE_CREATE } from "model/graphql/mutations"
import { useActivateSuccessModal, useChangePageTitle, usePreventNavigatingAway } from "utils"
import {
  getDefaultLayoutId,
  getFormattedNameWarning,
  getFormattedUtmSourceWarning,
  isSaveButtonDisabled,
  RuleCreateMutation,
  RuleCreateMutationResult,
  useCreateRule,
} from "./helpers"
import { QUERY_LAYOUTS } from "model/graphql/queries"
import { Layouts } from "model/types"
import { Error, Loading } from "components/data-status"
import { actionTypes as modalActionTypes, ModalContext } from "providers/modal"
import { ContentWrapper, DataStatusWrapper, PageContainer, SubPageHeader } from "components/page"
import { useSelector } from "react-redux"
import { ReduxState } from "redux-store"
import { isEqual } from "lodash"

type Props = {
  children?: never
} & RouteComponentProps

export const CreateRuleScreen = (props: Props) => (
  <PageContainer>
    <Query query={QUERY_LAYOUTS}>
      {({ data, loading, error }: QueryResult<Layouts>) => {
        if (error) {
          return (
            <DataStatusWrapper>
              <Error>{error.message}</Error>
            </DataStatusWrapper>
          )
        }
        if (!data || !data.layouts || loading) {
          return (
            <DataStatusWrapper>
              <Loading>Loading...</Loading>
            </DataStatusWrapper>
          )
        }

        return <ScreenContent {...props} data={data} />
      }}
    </Query>
  </PageContainer>
)

type ContentProps = {
  data: QueryResult<Layouts>["data"]
} & Props

const ScreenContent = (props: ContentProps) => {
  const { history } = props

  /**
   * Change Page Title
   */

  useChangePageTitle(props.match.path)

  usePreventNavigatingAway({ history })

  const { state: formState, dispatch: formDispatch } = useFormState()
  const { dispatch: modalDispatch } = useContext(ModalContext)

  const initialName = useRef("")
  const initialUtmSource = useRef("")
  const initialLayoutId = useRef(getDefaultLayoutId(props.data.layouts))

  useEffect(() => {
    if (
      initialName.current !== formState.name ||
      initialUtmSource.current !== formState.utmSource ||
      initialLayoutId.current !== formState.layoutId
    ) {
      modalDispatch({ type: modalActionTypes.setCanLeavePage, value: false })
    } else {
      modalDispatch({ type: modalActionTypes.setCanLeavePage, value: true })
    }
  }, [
    modalDispatch,
    initialName,
    initialUtmSource,
    initialLayoutId,
    formState.name,
    formState.utmSource,
    formState.layoutId,
  ])

  /**
   * Create Rule
   */

  const { activateSuccessModal } = useActivateSuccessModal()

  const handleCreateRuleSuccess = (ruleId: string) => {
    activateSuccessModal()
    modalDispatch({ type: modalActionTypes.setCanLeavePage, value: true })
    window.setTimeout(() => history.replace(routes.layoutRuleEdit.replace(":ruleId", ruleId)), 1000)
  }

  const handleCreateRuleError = (errorMessage: string) => {
    formDispatch({ type: formActionTypes.setNameWarning, data: { nameWarning: getFormattedNameWarning(errorMessage) } })
    formDispatch({
      type: formActionTypes.setUtmSourceWarning,
      data: { utmSourceWarning: getFormattedUtmSourceWarning(errorMessage) },
    })
  }

  const isSortedBy = useSelector((state: ReduxState) => state.lists.layoutRules.sortBy, isEqual)
  const isDescending = useSelector((state: ReduxState) => state.lists.layoutRules.desc, isEqual)

  const { createRule } = useCreateRule({
    onSuccessAction: handleCreateRuleSuccess,
    onErrorAction: handleCreateRuleError,
    queryVariables: {
      sortBy: isSortedBy,
      desc: isDescending,
    },
  })

  const saveRule = (ruleCreateMutation: RuleCreateMutation) => async () => {
    await createRule(ruleCreateMutation, {
      name: formState.name,
      utmSource: formState.utmSource,
      layoutId: formState.layoutId,
      sortBy: isSortedBy,
      desc: isDescending,
    })
  }

  /**
   * JSX
   */

  return (
    <React.Fragment>
      <Mutation mutation={MUTATION_RULE_CREATE}>
        {(ruleCreateMutation: RuleCreateMutation, { loading }: RuleCreateMutationResult) => {
          return (
            <React.Fragment>
              <SubPageHeader
                breadcrumbLinks={[
                  { name: "Layout Rules", path: routes.layoutRules },
                  { name: "New Rule", path: routes.layoutRuleCreate },
                ]}
                heading={`New Rule`}
                button={{
                  title: `Save`,
                  action: saveRule(ruleCreateMutation),
                  isDisabled: isSaveButtonDisabled(formState, loading),
                }}
              />

              <ContentWrapper>
                <Form state={formState} dispatch={formDispatch} layouts={props.data.layouts} />
              </ContentWrapper>
            </React.Fragment>
          )
        }}
      </Mutation>
    </React.Fragment>
  )
}
