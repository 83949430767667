import React from "react"
import { AutosaveStatus } from "components/autosave-status"
import { useSelector } from "react-redux"
import { ReduxState } from "redux-store"
import { isEqual } from "lodash"

export const QuizGeneralAutosave = () => {
  const quizGeneralAutosaveStatus = useSelector((state: ReduxState) => state.quizGeneral.autosaveStatus, isEqual)

  return (
    <AutosaveStatus
      savingMessage={`Saving...`}
      savedMessage={`Draft is automatically saved`}
      state={quizGeneralAutosaveStatus}
    />
  )
}
