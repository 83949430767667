import React from "react"
import styled, { keyframes } from "styled-components"
import { theme } from "theme"
import { IconDelete, IconUpload, IconLoading } from "components/icons"
import { removeImage, setImage } from "components/image-upload/helpers"
import { ImageUploadProps } from "components/image-upload/types"
import { FileInput } from "components/image-upload/file-input"

type Props = ImageUploadProps

export const ImageUpload = (props: Props) => {
  const { setImagePreview, setImageFile, previewImage, isUploading } = props

  /**
   * Set New Image
   */

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImage({
      event,
      setImageFile,
      setImagePreview,
    })
  }

  /**
   * Remove Image
   */

  const handleClick = () => {
    removeImage({
      setImagePreview,
      setImageFile,
    })
  }

  /**
   * JSX
   */

  return (
    <Container>
      {!previewImage && !isUploading && (
        <React.Fragment>
          <IconWrapper>
            <IconUpload color={theme.color.primaryGreen} />
          </IconWrapper>
          <Text color={theme.color.primaryGreen}>{`Upload Image`}</Text>
        </React.Fragment>
      )}

      {previewImage && !isUploading && (
        <React.Fragment>
          <PreviewImage src={previewImage} alt={"preview"} />
          <RemoveButton onClick={handleClick}>
            <IconWrapper>
              <IconDelete color={theme.color.textBlack} />
            </IconWrapper>
            <Text>{`Remove Image`}</Text>
          </RemoveButton>
        </React.Fragment>
      )}

      {previewImage && isUploading && (
        <React.Fragment>
          <IconWrapper>
            <IconLoading />
          </IconWrapper>
          <Text weight={theme.font.weight.regular}>{`Uploading…`}</Text>
          <ProgressBarContainer>
            <ProgressBar />
          </ProgressBarContainer>
        </React.Fragment>
      )}

      {!previewImage && !isUploading && (
        <FileInput type={"file"} multiple={false} onChange={handleChange} accept="image/png, image/jpeg" />
      )}
    </Container>
  )
}

/**
 * Styles
 */

const Container = styled("div")`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  background-color: ${theme.color.white};
  border-style: dashed;
  border-width: 1px;
  border-color: ${theme.color.borderGray};
  border-radius: ${theme.rounding1};
`

const IconWrapper = styled("div")`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 100%;
  max-width: 34px;
  margin-bottom: 10px;
`

type TextProps = {
  color?: string
  weight?: string
}

const Text = styled("span")`
  font-size: 16px;
  font-weight: ${(props: TextProps) => (props.weight ? props.weight : theme.font.weight.semiBold)};
  color: ${(props: TextProps) => (props.color ? props.color : theme.color.textBlack)};
`

const PreviewImage = styled("img")`
  display: flex;
  position: absolute;
  top: -1px;
  left: -1px;
  width: 200px;
  height: 200px;
  border-radius: ${theme.rounding1};
  object-fit: cover;
`

const RemoveButton = styled("button")`
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: -1px;
  left: -1px;
  width: 200px;
  height: 200px;
  opacity: 0;
  border: none;
  background: rgba(255, 255, 255, 0.75);
  transition: opacity 200ms ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &:focus {
    outline: none;
  }
`

const ProgressBarContainer = styled("div")`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 8px);
  height: 6px;
  margin: 4px;
  border-radius: 6px;
  background-color: ${theme.color.borderGray};
  overflow: hidden;
`

const slide = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`

const ProgressBar = styled("div")`
  display: block;
  position: relative;
  width: 100%;
  height: 6px;
  border-radius: 6px;
  background-color: ${theme.color.primaryGreen};
  animation: ${slide} 900ms ease-in-out infinite;
`
