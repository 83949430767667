import React, { useContext, useEffect } from "react"
import { RouteComponentProps } from "react-router"
import { Mutation, QueryResult, Query } from "react-apollo"
import { routes } from "router"
import { Vendor, VendorVariables } from "model/types"
import { Form } from "./form"
import { actionTypes, actionTypes as formActionTypes, useFormState } from "./form-state"
import { Error, Loading } from "components/data-status"
import { MUTATION_VENDOR_CHANGE } from "model/graphql/mutations"
import { QUERY_VENDOR } from "model/graphql/queries"
import { formatNameValue, useActivateSuccessModal, useChangePageTitle, usePreventNavigatingAway } from "utils"
import {
  getFormattedWarning,
  useChangeVendor,
  UseCreateVendorArgs,
  VendorChangeMutation,
  VendorChangeMutationResult,
} from "./helpers"
import { actionTypes as modalActionTypes, ModalContext } from "../../providers/modal"
import { ContentWrapper, DataStatusWrapper, PageContainer, SubPageHeader } from "components/page"

type Props = {
  children?: never
} & RouteComponentProps<{ vendorId: string }>

export const EditVendorScreen = (props: Props) => {
  const { vendorId } = props.match.params

  return (
    <PageContainer>
      <Query query={QUERY_VENDOR} variables={{ id: vendorId } as VendorVariables}>
        {({ data, loading, error }: QueryResult<Vendor>) => {
          if (error) {
            return (
              <DataStatusWrapper>
                <Error>{error.message}</Error>
              </DataStatusWrapper>
            )
          }
          if (!data || !data.vendor || loading) {
            return (
              <DataStatusWrapper>
                <Loading>Loading...</Loading>
              </DataStatusWrapper>
            )
          }
          return <Content {...props} data={data} />
        }}
      </Query>
    </PageContainer>
  )
}

type ContentProps = {
  data: QueryResult<Vendor>["data"]
} & Props

const Content = (props: ContentProps) => {
  const { data, history } = props
  const { formState, formDispatch } = useFormState()

  /**
   * Change Page Title
   */

  useChangePageTitle(props.match.path)

  /**
   * Save Data to Form State
   */

  useEffect(() => {
    formDispatch({ type: formActionTypes.setName, data: { name: data.vendor.name } })
  }, [formDispatch, data.vendor.name])

  useEffect(() => {
    formDispatch({ type: formActionTypes.setInitialScript, data: { initialScript: data.vendor.initialScript } })
  }, [formDispatch, data.vendor.initialScript])

  useEffect(() => {
    formDispatch({ type: formActionTypes.setAdsTxtEntry, data: { adsTxtEntry: data.vendor.adsTxtEntry } })
  }, [formDispatch, data.vendor.adsTxtEntry])

  /**
   * Prevent navigating away when there are unsaved changes
   * - use prevent navigating away hook
   * - compare values
   */

  usePreventNavigatingAway({ history })

  const { dispatch: modalDispatch } = useContext(ModalContext)

  useEffect(() => {
    if (
      formState.name !== data.vendor.name ||
      formState.initialScript !== data.vendor.initialScript ||
      formState.adsTxtEntry !== data.vendor.adsTxtEntry
    ) {
      modalDispatch({ type: modalActionTypes.setCanLeavePage, value: false })
    } else {
      modalDispatch({ type: modalActionTypes.setCanLeavePage, value: true })
    }
  }, [
    formState.name,
    formState.initialScript,
    formState.adsTxtEntry,

    data.vendor.name,
    data.vendor.initialScript,
    data.vendor.adsTxtEntry,

    modalDispatch,
  ])

  /**
   * Change Vendor
   */

  const { activateSuccessModal } = useActivateSuccessModal()

  const handleChangeVendorSuccess = () => {
    activateSuccessModal()
    formDispatch({
      type: formActionTypes.setName,
      data: { name: formatNameValue(formState.name) },
    })
  }

  const handleError: UseCreateVendorArgs["onErrorAction"] = errorMessage => {
    formDispatch({ type: actionTypes.setNameWarning, data: { nameWarning: getFormattedWarning(errorMessage) } })
  }

  const { changeVendor } = useChangeVendor({
    onSuccessAction: handleChangeVendorSuccess,
    onErrorAction: handleError,
  })

  const saveVendor = (vendorChangeMutation: VendorChangeMutation) => async () => {
    try {
      await changeVendor(vendorChangeMutation, {
        id: data.vendor.id,
        name: formState.name,
        initialScript: formState.initialScript,
        adsTxtEntry: formState.adsTxtEntry,
      })
    } catch (error) {
      console.error(error)
    }
  }

  /**
   * JSX
   */

  return (
    <React.Fragment>
      <Mutation mutation={MUTATION_VENDOR_CHANGE}>
        {(vendorChangeMutation: VendorChangeMutation, { loading }: VendorChangeMutationResult) => (
          <React.Fragment>
            <SubPageHeader
              breadcrumbLinks={[
                { name: `Vendors`, path: routes.vendors },
                { name: data.vendor.name, path: routes.vendorEdit },
              ]}
              heading={data.vendor.name}
              navigationTabs={[
                { name: `General`, to: routes.vendorEdit.replace(":vendorId", data.vendor.id) },
                { name: `Units`, to: routes.units.replace(":vendorId", data.vendor.id) },
              ]}
              button={{
                title: `Save`,
                action: saveVendor(vendorChangeMutation),
                isDisabled: loading || !formState.name.trim(),
              }}
            />

            <ContentWrapper hasNavigation={true}>
              <Form state={formState} dispatch={formDispatch} />
            </ContentWrapper>
          </React.Fragment>
        )}
      </Mutation>
    </React.Fragment>
  )
}
