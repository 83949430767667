import React from "react"
import { Action } from "utils"

/**
 * Initial State
 */

export const initialVendorFormState = {
  name: "",
  initialScript: "",
  adsTxtEntry: "",

  nameWarning: "",
}

/**
 * Dispatch Action Types
 */

export const actionTypes = {
  setName: "SET_NAME",
  setAdsTxtEntry: "SET_ADS_TXT_ENTRY",
  setInitialScript: "SET_INITIAL_SCRIPT",

  setNameWarning: "SET_NAME_ERROR",
}

/**
 * Reducer
 */

export type InitialVendorFormState = typeof initialVendorFormState

type ActionData = {
  name?: string
  initialScript?: string
  adsTxtEntry?: string

  nameWarning?: string
}

const reducer = (
  state: InitialVendorFormState = initialVendorFormState,
  action: Action<ActionData>
): typeof initialVendorFormState => {
  switch (action.type) {
    // Name
    case actionTypes.setName:
      return {
        ...state,
        name: action.data.name,
      }

    // Initial Script
    case actionTypes.setInitialScript:
      return {
        ...state,
        initialScript: action.data.initialScript,
      }

    // Ads Text Entry
    case actionTypes.setAdsTxtEntry:
      return {
        ...state,
        adsTxtEntry: action.data.adsTxtEntry,
      }

    // Name Warning
    case actionTypes.setNameWarning:
      return {
        ...state,
        nameWarning: action.data.nameWarning,
      }

    // Default
    default:
      throw new Error(`"${action.type}" is not a valid action!`)
  }
}

/**
 * Use Form State Hook
 */

export const useFormState = () => {
  const [formState, formDispatch] = React.useReducer(reducer, initialVendorFormState)

  return { formState, formDispatch }
}

export type FormState = ReturnType<typeof useFormState>["formState"]
export type FormDispatch = ReturnType<typeof useFormState>["formDispatch"]
