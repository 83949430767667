import {
  VendorChange,
  VendorChangeVariables,
  VendorCreate,
  VendorCreateVariables,
  Vendors,
  VendorsVariables,
} from "model/types"
import { QUERY_VENDORS } from "model/graphql/queries"
import { MutationResult, MutationFunction } from "react-apollo"
import { formatNameValue } from "utils"
import { ApolloError, MutationUpdaterFn } from "apollo-client"
import { cloneDeep } from "lodash"

/**
 * Create Vendor
 */

export type VendorCreateMutation = MutationFunction<VendorCreate, VendorCreateVariables>
export type VendorCreateMutationResult = MutationResult<VendorCreate>
export type VendorCreateMutationUpdater = MutationUpdaterFn<VendorCreate>

export type UseCreateVendorArgs = {
  onSuccessAction: (vendorId: string) => void
  onErrorAction: (errorMessage: string) => void
  queryVariables: VendorsVariables
}

export const useCreateVendor = (args: UseCreateVendorArgs) => {
  const { onSuccessAction, onErrorAction, queryVariables } = args

  const createVendor = async (vendorCreateMutation: VendorCreateMutation, variables: VendorCreateVariables) => {
    try {
      const result = (await vendorCreateMutation({
        variables: {
          name: formatNameValue(variables.name),
          initialScript: variables.initialScript,
          adsTxtEntry: variables.adsTxtEntry,
          sortBy: variables.sortBy,
          desc: variables.desc,
        },
        update: updateVendors,
      })) as VendorCreateMutationResult

      const { id: vendorId } = result.data.vendorCreate.vendor

      onSuccessAction(vendorId)
    } catch (error) {
      console.error(error)

      const { message } = error as ApolloError
      onErrorAction(message)
    }
  }

  const updateVendors: VendorCreateMutationUpdater = (cache, payload) => {
    try {
      const { vendors } = payload.data.vendorCreate
      const data: Vendors = cloneDeep(cache.readQuery({ query: QUERY_VENDORS, variables: queryVariables }))
      data.vendors = vendors

      cache.writeQuery({
        query: QUERY_VENDORS,
        variables: queryVariables,
        data: data,
      })
    } catch (error) {
      console.error(error)
    }
  }

  return { createVendor }
}

/**
 * Change Vendor
 */

export type VendorChangeMutation = MutationFunction<VendorChange, VendorChangeVariables>
export type VendorChangeMutationResult = MutationResult<VendorChange>

type UseChangeVendorArgs = {
  onSuccessAction: () => void
  onErrorAction: (errorMessage: string) => void
}

export const useChangeVendor = (args: UseChangeVendorArgs) => {
  const { onSuccessAction, onErrorAction } = args

  const changeVendor = async (vendorChangeMutation: VendorChangeMutation, variables: VendorChangeVariables) => {
    try {
      await vendorChangeMutation({
        variables: {
          id: variables.id,
          name: formatNameValue(variables.name),
          initialScript: variables.initialScript,
          adsTxtEntry: variables.adsTxtEntry,
        },
      })

      onSuccessAction()
    } catch (error) {
      console.error(error)

      const { message } = error as ApolloError
      onErrorAction(message)
    }
  }

  return { changeVendor }
}

/**
 * Get Formatted Warning Message
 * @param errorMessage
 */

export const getFormattedWarning = (errorMessage: string): string => {
  if (errorMessage.includes("vendor_name_already_exists")) return "Vendor name must be unique"
  return ""
}
