import React, { useEffect } from "react"
import { ImageUpload } from "components/image-upload"
import { Textarea } from "components/textarea"
import styled from "styled-components"
import { Label } from "components/label"
import { Input } from "components/input"
import { actionTypes, FormDispatch, FormState } from "./form-state"
import { Warning } from "components/warning"
import { isEmailValid } from "../../utils/helpers"

type Props = {
  state: FormState
  dispatch: FormDispatch
  children?: never
}

export const Form = (props: Props) => {
  const { state, dispatch } = props
  const { firstName, lastName, email, authorBio, avatarPreview, emailWarning } = state

  /**
   * Set First Name
   * @param value
   */

  const setFirstName = (value: string) => dispatch({ type: actionTypes.setFirstName, data: { firstName: value } })

  /**
   * Set Last Name
   * @param value
   */

  const setLastName = (value: string) => dispatch({ type: actionTypes.setLastName, data: { lastName: value } })

  /**
   * Set Email
   * @param value
   */

  const setEmail = (value: string) => dispatch({ type: actionTypes.setEmail, data: { email: value } })

  /**
   * Set Author Bio
   * @param value
   */

  const setAuthorBio = (value: string) => dispatch({ type: actionTypes.setAuthorBio, data: { authorBio: value } })

  /**
   * Set Avatar File
   * @param file
   */

  const setAvatarFile = (file: File) => dispatch({ type: actionTypes.setAvatarFile, data: { avatarFile: file } })

  /**
   * Set Avatar Preview
   * @param url
   */

  const setAvatarPreview = (url: string) =>
    dispatch({ type: actionTypes.setAvatarPreview, data: { avatarPreview: url } })

  const setEmailWarning = (warning: string) =>
    dispatch({ type: actionTypes.setEmailWarning, data: { emailWarning: warning } })

  useEffect(() => {
    if (email.length > 254) {
      setEmailWarning("This Email is too long")
    } else if (email.length && !isEmailValid(email)) {
      setEmailWarning("Email is written in wrong format")
    } else {
      setEmailWarning("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email])

  /**
   * JSX
   */

  return (
    <Container>
      <StyledForm>
        <FormRow gridArea={"first-name"}>
          <Label>{`First Name`}</Label>
          <Input value={firstName} onChange={setFirstName} />
        </FormRow>

        <FormRow gridArea={"last-name"}>
          <Label>{`Last Name`}</Label>
          <Input value={lastName} onChange={setLastName} />
        </FormRow>

        <FormRow gridArea={"email"}>
          <Label>{`Email`}</Label>
          <Input value={email} onChange={setEmail} />
          <Warning message={emailWarning} />
        </FormRow>

        <FormRow gridArea={"author-bio"}>
          <Label>{`Author Bio`}</Label>
          <Textarea value={authorBio} onChange={setAuthorBio} />
        </FormRow>

        <FormRow gridArea={"author-image"}>
          <ImageUpload previewImage={avatarPreview} setImageFile={setAvatarFile} setImagePreview={setAvatarPreview} />
        </FormRow>
      </StyledForm>
    </Container>
  )
}

const Container = styled("div")`
  display: block;
  position: relative;
  width: 100%;
  max-width: 760px;
`

const StyledForm = styled("form")`
  display: grid;
  position: relative;
  padding: 40px;
  width: 710px;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 20px;
  grid-template-rows: auto auto auto 1px auto;
  grid-row-gap: 20px;
  grid-template-areas:
    "first-name author-image"
    "last-name author-image"
    "email author-image"
    "space2 space2"
    "author-bio author-bio";
`

type FormRowProps = {
  gridArea: string
}

const FormRow = styled("div")`
  display: grid;
  position: relative;
  grid-template-columns: 120px 1fr;
  grid-template-rows: max-content max-content;
  grid-template-areas:
    "label ."
    "warning warning";
  grid-column-gap: 10px;
  align-items: start;
  grid-area: ${(props: FormRowProps) => props.gridArea};
`
