import React, { useContext, useEffect } from "react"
import { RouteComponentProps } from "react-router"
import { routes } from "router"
import { Form } from "./form"
import { actionTypes as modalActionTypes, ModalContext } from "../../providers/modal"
import { actionTypes, actionTypes as formActionTypes, useFormState } from "./form-state"
import { Mutation, QueryResult, Query } from "react-apollo"
import { QUERY_UNIT } from "model/graphql/queries"
import { MUTATION_UNIT_CHANGE } from "model/graphql/mutations"
import { Unit, UnitVariables } from "model/types"
import { Error, Loading } from "components/data-status"
import {
  getFormattedWarning,
  UnitChangeMutation,
  UnitChangeMutationResult,
  useChangeUnit,
  UseCreateUnitArgs,
} from "./helpers"
import { formatNameValue, useActivateSuccessModal, useChangePageTitle, usePreventNavigatingAway } from "utils"
import { difference } from "lodash"
import { ContentWrapper, DataStatusWrapper, PageContainer, SubPageHeader } from "components/page"

type Props = {
  children?: never
} & RouteComponentProps<{ unitId: string; vendorId: string }>

export const EditUnitScreen = (props: Props) => {
  const { unitId } = props.match.params

  return (
    <PageContainer>
      <Query query={QUERY_UNIT} variables={{ id: unitId } as UnitVariables}>
        {({ data, error, loading }: QueryResult<Unit>) => {
          if (error) {
            return (
              <DataStatusWrapper>
                <Error>{error.message}</Error>
              </DataStatusWrapper>
            )
          }
          if (!data || !data.unit || loading) {
            return (
              <DataStatusWrapper>
                <Loading>Loading...</Loading>
              </DataStatusWrapper>
            )
          }
          return <Content {...props} data={data} />
        }}
      </Query>
    </PageContainer>
  )
}

/**
 * Screen Content
 */

type ContentProps = {
  data: QueryResult<Unit>["data"]
} & Props

const Content = (props: ContentProps) => {
  const { data, history } = props
  const { unitId, vendorId } = props.match.params
  const { formState, formDispatch } = useFormState()
  const { dispatch: modalDispatch } = useContext(ModalContext)

  /**
   * Change Page Title
   */

  useChangePageTitle(props.match.path)

  /**
   * Save Data to Form State
   */

  useEffect(() => {
    formDispatch({ type: formActionTypes.setName, data: { name: data.unit.name } })
  }, [formDispatch, data.unit.name])

  useEffect(() => {
    formDispatch({ type: formActionTypes.setInitialScript, data: { initialScript: data.unit.initialScript } })
  }, [formDispatch, data.unit.initialScript])

  useEffect(() => {
    formDispatch({ type: formActionTypes.setRefreshScript, data: { refreshScript: data.unit.refreshScript } })
  }, [formDispatch, data.unit.refreshScript])

  useEffect(() => {
    formDispatch({ type: formActionTypes.setWipeScript, data: { wipeScript: data.unit.wipeScript } })
  }, [formDispatch, data.unit.wipeScript])

  useEffect(() => {
    formDispatch({ type: formActionTypes.setIsPersistent, data: { isPersistent: data.unit.persistent } })
  }, [formDispatch, data.unit.persistent])

  useEffect(() => {
    formDispatch({
      type: formActionTypes.setSlotCompatibility,
      data: { slotCompatibility: data.unit.slotCompatibility },
    })
  }, [formDispatch, data.unit.slotCompatibility])

  /**
   * Prevent navigating away when there are unsaved changes
   * - use prevent navigating away hook
   * - compare values
   */

  usePreventNavigatingAway({ history })

  useEffect(() => {
    if (
      formState.name !== data.unit.name ||
      formState.initialScript !== data.unit.initialScript ||
      formState.refreshScript !== data.unit.refreshScript ||
      formState.wipeScript !== data.unit.wipeScript ||
      formState.isPersistent !== data.unit.persistent ||
      difference(formState.slotCompatibility, data.unit.slotCompatibility).length > 0
    ) {
      modalDispatch({ type: modalActionTypes.setCanLeavePage, value: false })
    } else {
      modalDispatch({ type: modalActionTypes.setCanLeavePage, value: true })
    }
  }, [
    formState.name,
    formState.initialScript,
    formState.refreshScript,
    formState.wipeScript,
    formState.isPersistent,
    formState.slotCompatibility,

    data.unit.name,
    data.unit.initialScript,
    data.unit.refreshScript,
    data.unit.wipeScript,
    data.unit.persistent,
    data.unit.slotCompatibility,

    modalDispatch,
  ])

  /**
   * Handle Unit Change
   */

  const { activateSuccessModal } = useActivateSuccessModal()

  const handleSuccess = () => {
    activateSuccessModal()
    formDispatch({
      type: formActionTypes.setName,
      data: { name: formatNameValue(formState.name) },
    })
  }

  const handleError: UseCreateUnitArgs["onErrorAction"] = errorMessage => {
    formDispatch({ type: actionTypes.setNameWarning, data: { nameWarning: getFormattedWarning(errorMessage) } })
  }

  const { changeUnit } = useChangeUnit({
    onSuccessAction: handleSuccess,
    onErrorAction: handleError,
  })

  const saveUnit = (unitChangeMutation: UnitChangeMutation) => async () => {
    try {
      await changeUnit(unitChangeMutation, {
        id: unitId,
        vendorId: vendorId,
        name: formState.name,
        initialScript: formState.initialScript,
        refreshScript: formState.refreshScript,
        wipeScript: formState.wipeScript,
        persistent: formState.isPersistent,
        slotCompatibility: formState.slotCompatibility,
      })
    } catch (error) {
      console.error(error)
    }
  }

  /**
   * JSX
   */

  return (
    <React.Fragment>
      <Mutation mutation={MUTATION_UNIT_CHANGE}>
        {(unitChangeMutation: UnitChangeMutation, { loading }: UnitChangeMutationResult) => (
          <React.Fragment>
            <SubPageHeader
              breadcrumbLinks={[
                { name: `Vendors`, path: routes.vendors },
                { name: data.unit.vendor.name, path: routes.unitEdit.replace(":vendorId", data.unit.vendor.id) },
                { name: `Units`, path: routes.units },
                { name: data.unit.name, path: routes.unitEdit },
              ]}
              heading={data.unit.name}
              button={{
                title: `Save`,
                action: saveUnit(unitChangeMutation),
                isDisabled: loading || !formState.name.trim(),
              }}
            />

            <ContentWrapper>
              <Form state={formState} dispatch={formDispatch} />
            </ContentWrapper>
          </React.Fragment>
        )}
      </Mutation>
    </React.Fragment>
  )
}
