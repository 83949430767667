import React from "react"
import styled from "styled-components"

type Props = {
  size?: "20px" | "30px" | string
  children: React.ReactNode
}

export const ModalIcon = (props: Props) => {
  return <Container size={props.size}>{props.children}</Container>
}

type ContainerProps = {
  size: Props["size"]
}

const Container = styled("div")<ContainerProps>`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: ${(props: ContainerProps) => (props.size ? props.size : "30px")};
  height: ${(props: ContainerProps) => (props.size ? props.size : "30px")};
`
