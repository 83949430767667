import React, { Dispatch, useReducer } from "react"
import { initialLayoutModalState, LayoutModalAction, layoutModalReducer, LayoutModalState } from "./modal-reducer"
import { initialVariantState, VariantActionData, variantReducer, VariantState } from "./variant-reducer"
import { Action } from "utils"

type Context = {
  layoutModalState: LayoutModalState
  layoutModalDispatch: Dispatch<LayoutModalAction>

  variantState: VariantState
  variantDispatch: Dispatch<Action<VariantActionData>>
}

export const LayoutStoreContext = React.createContext<Context>({
  layoutModalState: initialLayoutModalState,
  layoutModalDispatch: null,

  variantState: initialVariantState,
  variantDispatch: null,
})

type Props = {
  children: React.ReactNode
}

export const LayoutStoreProvider = (props: Props) => {
  const [layoutModalState, layoutModalDispatch] = useReducer(layoutModalReducer, initialLayoutModalState)
  const [variantState, variantDispatch] = useReducer(variantReducer, initialVariantState)

  return (
    <LayoutStoreContext.Provider value={{ layoutModalState, layoutModalDispatch, variantState, variantDispatch }}>
      {props.children}
    </LayoutStoreContext.Provider>
  )
}
