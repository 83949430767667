import gql from "graphql-tag"
import { fragments } from "../fragments"

export const QUERY_QUESTIONS = gql`
  query Questions($id: ID!) {
    quiz(id: $id) {
      title
      id
    }
    questions(id: $id) {
      edges {
        node {
          ...QuestionData
          answerSet {
            edges {
              node {
                ...AnswerData
              }
            }
          }
        }
      }
    }
  }
  ${fragments.question}
  ${fragments.answer}
`
