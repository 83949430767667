import { MutationFunction, MutationResult } from "react-apollo"
import {
  Layouts_layouts,
  RuleChange,
  RuleChangeVariables,
  RuleCreate,
  RuleCreateVariables,
  Rules,
  RulesVariables,
} from "model/types"
import { QUERY_RULES } from "model/graphql/queries"
import { FormState } from "./form-state"
import { cloneDeep } from "lodash"
import { MutationUpdaterFn } from "apollo-client"

/**
 * Change Rule
 */

export type RuleChangeMutation = MutationFunction<RuleChange, RuleChangeVariables>
export type RuleChangeMutationResult = MutationResult<RuleChange>

type UseChangeRuleArgs = {
  onSuccessAction: () => void
  onErrorAction: (errorMessage: string) => void
}

export const useChangeRule = (args: UseChangeRuleArgs) => {
  const { onSuccessAction, onErrorAction } = args

  const changeRule = async (ruleChangeMutation: RuleChangeMutation, variables: RuleChangeVariables) => {
    try {
      await ruleChangeMutation({
        variables: {
          id: variables.id,
          name: variables.name,
          utmSource: variables.utmSource,
          layoutId: variables.layoutId,
        },
      })
      onSuccessAction()
    } catch (error) {
      console.error(error)

      const { message: errorMessage }: RuleChangeMutationResult["error"] = error
      onErrorAction(errorMessage)
    }
  }

  return { changeRule }
}

/**
 * Create Rule
 */

export type RuleCreateMutation = MutationFunction<RuleCreate, RuleCreateVariables>
export type RuleCreateMutationResult = MutationResult<RuleCreate>
export type RuleCreateMutationUpdater = MutationUpdaterFn<RuleCreate>

type UseCreateRuleArgs = {
  onSuccessAction: (ruleId: string) => void
  onErrorAction: (errorMessage: string) => void
  queryVariables: RulesVariables
}

export const useCreateRule = (args: UseCreateRuleArgs) => {
  const { onSuccessAction, onErrorAction, queryVariables } = args

  const createRule = async (ruleCreateMutation: RuleCreateMutation, variables: RuleCreateVariables) => {
    try {
      const result = (await ruleCreateMutation({
        variables,
        update: updateRules,
      })) as MutationResult<RuleCreate>

      const { id: ruleId } = result.data.ruleCreate.rule

      onSuccessAction(ruleId)
    } catch (error) {
      console.error(error)

      const { message }: RuleCreateMutationResult["error"] = error
      onErrorAction(message)
    }
  }

  const updateRules: RuleCreateMutationUpdater = (cache, payload) => {
    try {
      const { rules } = payload.data.ruleCreate
      const data: Rules = cloneDeep(cache.readQuery({ query: QUERY_RULES, variables: queryVariables }))
      data.rules = rules

      cache.writeQuery({
        query: QUERY_RULES,
        variables: queryVariables,
        data: data,
      })
    } catch (error) {
      console.error(error)
    }
  }

  return { createRule }
}

export const isSaveButtonDisabled = (formState: FormState, loading: boolean): boolean => {
  return (
    !(formState.name.trim().length !== 0 && formState.utmSource.trim().length !== 0 && formState.layoutId) || loading
  )
}

export const getDefaultLayoutId = (layouts: Layouts_layouts) => {
  const {
    node: { id },
  } = layouts.edges.find(({ node: layout }) => layout.default)
  return id
}

/**
 * Get Formatted Warning Message
 *
 * @param errorMessage
 */

export const getFormattedNameWarning = (errorMessage: string): string => {
  if (errorMessage.includes("rule_name_already_exists")) {
    return "Rule must be unique"
  }
  return ""
}

export const getFormattedUtmSourceWarning = (errorMessage: string): string => {
  if (errorMessage.includes("utm_source_already_exists")) {
    return "utm_source must be unique"
  }
  return ""
}
