import React from "react"
import Emoji2x from "./static/Emoji@2x.png"

type Props = {
  children?: never
}

export const ImageEmojiFace = (props: Props) => {
  return <img src={Emoji2x} alt="emoji face" />
}
