import React, { useCallback, useState } from "react"
import { Query, QueryResult } from "react-apollo"
import { RouteComponentProps } from "react-router"
import { ActionButton, ActionContainer, ColumnHeading, Table } from "components/table"
import { NavigationLink } from "components/links"
import { Rules, RulesVariables } from "model/types"
import { routes } from "router"
import { DeleteRuleModal } from "./delete-rule-modal"
import { Error, Loading } from "components/data-status"
import { QUERY_RULES } from "model/graphql/queries"
import { getSortingIndicator, ListsDispatch, useChangePageTitle } from "utils"
import { useDispatch, useSelector } from "react-redux"
import { ReduxState } from "redux-store"
import { isEqual } from "lodash"
import { listsActionTypes } from "redux-reducers"
import { ContentWrapper, DataStatusWrapper, PageContainer, PageHeader } from "components/page"

type Props = {
  children?: never
} & RouteComponentProps

export const LayoutRulesScreen = (props: Props) => {
  const isSortedBy = useSelector((state: ReduxState) => state.lists.layoutRules.sortBy, isEqual)
  const isDescending = useSelector((state: ReduxState) => state.lists.layoutRules.desc, isEqual)

  return (
    <PageContainer>
      <Query query={QUERY_RULES} variables={{ sortBy: isSortedBy, desc: isDescending } as RulesVariables}>
        {({ loading, error, data }: QueryResult<Rules>) => {
          if (error) {
            return (
              <DataStatusWrapper>
                <Error>{error.message}</Error>
              </DataStatusWrapper>
            )
          }
          if (!data || !data.rules || loading) {
            return (
              <DataStatusWrapper>
                <Loading>Loading...</Loading>
              </DataStatusWrapper>
            )
          }
          return <ScreenContent {...props} data={data} isSortedBy={isSortedBy} isDescending={isDescending} />
        }}
      </Query>
    </PageContainer>
  )
}

/**
 * Screen Content
 */

type ContentProps = {
  data: QueryResult<Rules>["data"]
  isSortedBy: string
  isDescending: boolean
} & Props

type RuleToDelete = React.ComponentProps<typeof DeleteRuleModal>["ruleToDelete"]

const ScreenContent = (props: ContentProps) => {
  const { data, history, isSortedBy, isDescending } = props
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [ruleToDelete, setRuleToDelete] = useState<RuleToDelete>({
    id: "",
    name: "",
  })

  /**
   * Change Page Title
   */

  useChangePageTitle(props.match.path)

  const navigateToCreateNewRuleScreen = () => history.push(routes.layoutRuleCreate)

  const openDeleteModal = (rule: RuleToDelete) => () => {
    setIsDeleteModalOpen(true)
    setRuleToDelete({ id: rule.id, name: rule.name })
  }

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false)
  }

  /**
   * Set List Sorting
   */

  const dispatch = useDispatch()

  const setSortBy = useCallback(
    (sortBy: string) =>
      dispatch<ListsDispatch>({ type: listsActionTypes.setLayoutRulesSortBy, data: { layoutRules: { sortBy } } }),
    [dispatch]
  )
  const setDesc = useCallback(
    (desc: boolean) =>
      dispatch<ListsDispatch>({ type: listsActionTypes.setLayoutRulesDesc, data: { layoutRules: { desc } } }),
    [dispatch]
  )

  const sortList = (sortBy: string) => () => {
    if (sortBy !== isSortedBy) {
      setSortBy(sortBy)
      setDesc(false)
      return
    }
    if (sortBy === isSortedBy && !isDescending) {
      setDesc(true)
      return
    }
    if (sortBy === isSortedBy && isDescending) {
      setDesc(false)
      return
    }
  }

  /**
   * JSX
   */

  return (
    <React.Fragment>
      <PageHeader heading={`Layout Rules`} button={{ title: `Add Rule`, action: navigateToCreateNewRuleScreen }} />

      <ContentWrapper>
        <Table
          noContentNotification={`There are no Rules yet`}
          content={{
            head: [
              <ColumnHeading
                onClick={sortList("name")}
                arrowState={getSortingIndicator({ sortBy: "name", isSortedBy, isDescending })}
              >{`Rule`}</ColumnHeading>,
              <ColumnHeading>{`Action`}</ColumnHeading>,
            ],

            body: data.rules.edges.map(({ node: rule }) => [
              <NavigationLink
                color={"green"}
                weight={"semiBold"}
                size={"14px"}
                to={routes.layoutRuleEdit.replace(":ruleId", rule.id)}
              >
                {rule.name}
              </NavigationLink>,
              <ActionContainer>
                <ActionButton actionType={"delete"} onClick={openDeleteModal({ id: rule.id, name: rule.name })} />
              </ActionContainer>,
            ]),
          }}
        />
      </ContentWrapper>
      <DeleteRuleModal
        isModalOpen={isDeleteModalOpen}
        ruleToDelete={ruleToDelete}
        closeModal={closeDeleteModal}
        queryVariables={{ sortBy: isSortedBy, desc: isDescending }}
      />
    </React.Fragment>
  )
}
