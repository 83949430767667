import gql from "graphql-tag"
import { fragments } from "../fragments"
import { MutationFunction, MutationResult } from "react-apollo"
import {
  CreateVariant,
  CreateVariantVariables,
  DeleteVariant,
  DeleteVariantVariables,
  EditVariant,
  EditVariantVariables,
  Layout,
  Layout_layout_variantSet,
  LayoutVariables,
  SaveVariant,
  SaveVariantVariables,
} from "model/types"
import { ApolloError, MutationUpdaterFn } from "apollo-client"
import { cloneDeep, findIndex } from "lodash"
import { QUERY_LAYOUT } from "model/graphql/queries"

/**
 * Create Variant
 */

// Mutation

export const MUTATION_CREATE_VARIANT = gql`
  mutation CreateVariant($pageFrom: Int, $pageTo: Int, $layoutId: ID!) {
    variantCreate(input: { pageFrom: $pageFrom, pageTo: $pageTo, layoutId: $layoutId }) {
      variant {
        ...VariantData
        slotpairSet {
          edges {
            node {
              ...SlotData
              unit {
                ...UnitData
                vendor {
                  ...VendorData
                }
              }
            }
          }
        }
      }
      layout {
        ...LayoutData
        variantSet {
          edges {
            node {
              ...VariantData
              slotpairSet {
                edges {
                  node {
                    ...SlotData
                    unit {
                      ...UnitData
                      vendor {
                        ...VendorData
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      clientMutationId
    }
  }
  ${fragments.variant}
  ${fragments.slot}
  ${fragments.unit}
  ${fragments.vendor}
  ${fragments.layout}
`

// Hook

export type UseCreateVariantArgs = {
  onSuccessAction: (variantId: string) => void
  onErrorAction: (errorMessage: ApolloError["message"]) => void
}

export const useCreateVariant = (args: UseCreateVariantArgs) => {
  const { onSuccessAction, onErrorAction } = args

  const createVariant = (
    createVariantMutation: CreateVariantMutation,
    variables: CreateVariantVariables
  ) => async () => {
    try {
      const result = (await createVariantMutation({
        variables,
        update: updateVariants,
      })) as CreateVariantMutationResult
      const { variant } = result.data.variantCreate
      onSuccessAction(variant.id)
    } catch (error) {
      console.error(error)

      const { message: errorMessage }: ApolloError = error
      onErrorAction(errorMessage)
    }
  }

  const updateVariants: CreateVariantMutationUpdater = (cache, payload) => {
    try {
      const { layout } = payload.data.variantCreate

      const data: Layout = cloneDeep(
        cache.readQuery({ query: QUERY_LAYOUT, variables: { id: layout.id } as LayoutVariables })
      )

      data.layout = layout

      cache.writeQuery({
        query: QUERY_LAYOUT,
        data: data,
      })
    } catch (error) {
      console.error(error)
    }
  }

  return { createVariant }
}

export type CreateVariantMutation = MutationFunction<CreateVariant, CreateVariantVariables>
export type CreateVariantMutationResult = MutationResult<CreateVariant>
export type CreateVariantMutationUpdater = MutationUpdaterFn<CreateVariant>

/**
 * Edit Variant
 */

// Mutation

export const MUTATION_EDIT_VARIANT = gql`
  mutation EditVariant($pageFrom: Int, $pageTo: Int, $id: ID!) {
    variantChange(input: { pageFrom: $pageFrom, pageTo: $pageTo, id: $id }) {
      variant {
        ...VariantData
        slotpairSet {
          edges {
            node {
              ...SlotData
              unit {
                ...UnitData
                vendor {
                  ...VendorData
                }
              }
            }
          }
        }
      }
      clientMutationId
    }
  }
  ${fragments.variant}
  ${fragments.slot}
  ${fragments.unit}
  ${fragments.vendor}
`

// Hook

export type UseEditVariantArgs = {
  onSuccessAction: () => void
  onErrorAction: (errorMessage: string) => void
}

export const useEditVariant = (args: UseEditVariantArgs) => {
  const { onSuccessAction, onErrorAction } = args

  const editVariant = (editVariantMutation: EditVariantMutation, variables: EditVariantVariables) => async () => {
    try {
      await editVariantMutation({
        variables,
      })
      onSuccessAction()
    } catch (error) {
      console.error(error)

      const { message: errorMessage }: EditVariantMutationResult["error"] = error
      onErrorAction(errorMessage)
    }
  }

  return { editVariant }
}

export type EditVariantMutation = MutationFunction<EditVariant, EditVariantVariables>
export type EditVariantMutationResult = MutationResult<EditVariant>

/**
 * Save Variant
 */

// Mutation

export const MUTATION_SAVE_VARIANT = gql`
  mutation SaveVariant($notes: String, $slots: [SlotPairInput], $id: ID!, $loadDelayMin: Int, $loadDelayMax: Int) {
    variantChange(
      input: { notes: $notes, slots: $slots, id: $id, loadDelayMin: $loadDelayMin, loadDelayMax: $loadDelayMax }
    ) {
      variant {
        ...VariantData
        slotpairSet {
          edges {
            node {
              ...SlotData
              unit {
                ...UnitData
                vendor {
                  ...VendorData
                }
              }
            }
          }
        }
      }
      clientMutationId
    }
  }
  ${fragments.variant}
  ${fragments.slot}
  ${fragments.unit}
  ${fragments.vendor}
`

// Hook

export type UseSaveVariantArgs = {
  onSuccessAction: () => void
  onErrorAction: (error: any) => void
}

export const useSaveVariant = (args: UseSaveVariantArgs) => {
  const { onSuccessAction, onErrorAction } = args

  const saveVariant = (saveVariantMutation: SaveVariantMutation, variables: SaveVariantVariables) => async () => {
    try {
      await saveVariantMutation({
        variables,
      })
      onSuccessAction()
    } catch (error) {
      onErrorAction(error)
      console.error(error)
    }
  }

  return { saveVariant }
}

export type SaveVariantMutation = MutationFunction<SaveVariant, SaveVariantVariables>
export type SaveVariantMutationResult = MutationResult<SaveVariant>

/**
 * Delete Variant
 */

// Mutation

export const MUTATION_DELETE_VARIANT = gql`
  mutation DeleteVariant($id: ID!) {
    variantDelete(input: { id: $id }) {
      deleted
      clientMutationId
    }
  }
`

// Hook

export type UseDeleteVariantArgs = {
  onSuccessAction: () => void
  onUpdateAction: ({
    prevVariantIndex,
    variants,
  }: {
    prevVariantIndex: number
    variants: Layout_layout_variantSet["edges"]
  }) => void
}

export const useDeleteVariant = (args: UseDeleteVariantArgs) => {
  const { onSuccessAction, onUpdateAction } = args

  const deleteVariant = (
    deleteVariantMutation: DeleteVariantMutation,
    variables: { layoutId: string } & DeleteVariantVariables
  ) => async () => {
    const { layoutId, id: variantId } = variables
    try {
      await deleteVariantMutation({
        variables: { id: variantId },
        update: updateVariants(variantId, layoutId),
      })
      onSuccessAction()
    } catch (error) {
      console.error(error)
    }
  }

  const updateVariants = (variantId: string, layoutId: string): DeleteVariantMutationUpdater => (cache, payload) => {
    if (!payload.data.variantDelete.deleted) {
      return
    }

    try {
      const data: Layout = cloneDeep(
        cache.readQuery({ query: QUERY_LAYOUT, variables: { id: layoutId } as LayoutVariables })
      )

      const prevVariantIndex = findIndex(data.layout.variantSet.edges, ({ node: variant }) => variant.id === variantId)

      data.layout.variantSet.edges = data.layout.variantSet.edges.filter(
        ({ node: variant }) => variant.id !== variantId
      )

      cache.writeQuery({
        query: QUERY_LAYOUT,
        data: data,
      })

      onUpdateAction({
        prevVariantIndex: prevVariantIndex,
        variants: data.layout.variantSet.edges,
      })
    } catch (error) {
      console.error(error)
    }
  }

  return { deleteVariant }
}

export type DeleteVariantMutation = MutationFunction<DeleteVariant, DeleteVariantVariables>
export type DeleteVariantMutationResult = MutationResult<DeleteVariant>
export type DeleteVariantMutationUpdater = MutationUpdaterFn<DeleteVariant>
