import React from "react"
import styled from "styled-components"
import { theme } from "theme"

type Props = {
  isChecked: boolean
  optionValue: string
  onChange: (value: string) => void
  orderPosition?: "first" | "last" | "single"
  children: string
}

export const SwitchRadioButton = (props: Props) => {
  const { isChecked, onChange, optionValue, orderPosition } = props

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }

  return (
    <Container isChecked={isChecked} orderPosition={orderPosition}>
      <Text isChecked={isChecked}>{props.children}</Text>
      <RadioInput type={"radio"} readOnly={true} checked={isChecked} onChange={handleChange} value={optionValue} />
    </Container>
  )
}

type ContainerProps = {
  isChecked: Props["isChecked"]
  orderPosition: Props["orderPosition"]
}

const Container = styled("div")`
  display: flex;
  position: relative;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 50px;
  height: auto;
  border-style: solid;
  border-color: ${(props: ContainerProps) => (props.isChecked ? theme.color.primaryGreenDark : theme.color.borderGray)};
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-width: 1px;
  border-bottom-width: ${(props: ContainerProps) =>
    props.orderPosition === "last" || props.orderPosition === "single" ? "1px" : "0"};
  background-color: ${(props: ContainerProps) => (props.isChecked ? theme.color.primaryGreenDark : theme.color.white)};
  border-top-left-radius: ${(props: ContainerProps) =>
    props.orderPosition === "first" || props.orderPosition === "single" ? theme.rounding2 : 0};
  border-bottom-left-radius: ${(props: ContainerProps) =>
    props.orderPosition === "last" || props.orderPosition === "single" ? theme.rounding2 : 0};
  padding: 10px ${theme.offset1};

  transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out;
`

const RadioInput = styled("input")`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;
  opacity: 0;
`

type TextProps = {
  isChecked: boolean
}

const Text = styled("span")`
  font-size: 16px;
  font-weight: ${theme.font.weight.regular};
  color: ${(props: TextProps) => (props.isChecked ? theme.color.white : theme.color.textBlack)};
  white-space: nowrap;
  transition: color 200ms ease-in-out;
`
