import React from "react"
import { Action } from "utils"

/**
 * Initial State
 */

export const initialUnitFormState = {
  name: "",
  isPersistent: false,
  initialScript: "",
  refreshScript: "",
  wipeScript: "",
  slotCompatibility: [] as string[],

  nameWarning: "",
}

/**
 * Dispatch Action Types
 */

export const actionTypes = {
  setName: "SET_NAME",
  setIsPersistent: "SET_PERSISTENT",
  setInitialScript: "SET_INITIAL_SCRIPT",
  setRefreshScript: "SET_REFRESH_SCRIPT",
  setWipeScript: "SET_WIPE_SCRIPT",
  setSlotCompatibility: "SET_SLOT_COMPATIBILITY",

  setNameWarning: "SET_NAME_WARNING",
}

/**
 * Reducer
 */

export type InitialUnitFormState = typeof initialUnitFormState

type ActionData = {
  name?: string
  isPersistent?: boolean
  initialScript?: string
  refreshScript?: string
  wipeScript?: string
  slotCompatibility?: string[]

  nameWarning?: string
}

const reducer = (
  state: InitialUnitFormState = initialUnitFormState,
  action: Action<ActionData>
): InitialUnitFormState => {
  switch (action.type) {
    // Name
    case actionTypes.setName:
      return { ...state, name: action.data.name }

    // Initial Script
    case actionTypes.setInitialScript:
      return { ...state, initialScript: action.data.initialScript }

    // Refresh Script
    case actionTypes.setRefreshScript:
      return { ...state, refreshScript: action.data.refreshScript }

    // Wipe Script
    case actionTypes.setWipeScript:
      return { ...state, wipeScript: action.data.wipeScript }

    // Persistent
    case actionTypes.setIsPersistent:
      return { ...state, isPersistent: action.data.isPersistent }

    // Set Slot Compatibility
    case actionTypes.setSlotCompatibility:
      return {
        ...state,
        slotCompatibility: action.data.slotCompatibility,
      }

    // Name Warning
    case actionTypes.setNameWarning:
      return {
        ...state,
        nameWarning: action.data.nameWarning,
      }

    // Default
    default:
      throw new Error(`"${action.type}" is not a valid action!`)
  }
}

/**
 * Use Form State Hook
 */

export const useFormState = () => {
  const [formState, formDispatch] = React.useReducer(reducer, initialUnitFormState)

  return { formState, formDispatch }
}

export type FormState = ReturnType<typeof useFormState>["formState"]
export type FormDispatch = ReturnType<typeof useFormState>["formDispatch"]
