import gql from "graphql-tag"
import { QueryResult } from "react-apollo"
import { InvitedUserData } from "../../types"

/**
 * Invited User Data
 */

export const QUERY_INVITED_USER_DATA = gql`
  query InvitedUserData($invitationCode: String!) {
    userInviteData(invitationCode: $invitationCode) {
      id
      firstName
    }
  }
`

export type InvitedUserDataResult = QueryResult<InvitedUserData>
