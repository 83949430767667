import gql from "graphql-tag"
import { fragments } from "model/graphql/fragments"
import { PublishQuiz, PublishQuizVariables } from "model/types"
import { MutationFunction, MutationResult } from "react-apollo"
import { ApolloError } from "apollo-client"

/**
 * Publish Quiz
 */

// Mutation

export const MUTATION_PUBLISH_QUIZ = gql`
  mutation PublishQuiz($id: ID!) {
    quizPublish(input: { id: $id }) {
      clientMutationId
      published
      quiz {
        ...QuizData
      }
    }
  }
  ${fragments.quiz}
`

// Hook

export type UsePublishQuizArgs = {
  onStartAction: () => void
  onSuccessAction: () => void
  onErrorAction: (errorMessage: string) => void
}

export const usePublishQuiz = (args: UsePublishQuizArgs) => {
  const { onStartAction, onSuccessAction, onErrorAction } = args

  const publishQuiz = (publishQuizMutation: PublishQuizMutation, variables: PublishQuizVariables) => async () => {
    try {
      onStartAction()
      await publishQuizMutation({ variables })
      onSuccessAction()
    } catch (error) {
      console.error(error)
      const { message } = error as ApolloError
      onErrorAction(message)
    }
  }

  return { publishQuiz }
}

/**
 * Publish Quiz Mutation Types
 */

export type PublishQuizMutation = MutationFunction<PublishQuiz, PublishQuizVariables>
export type PublishQuizMutationResult = MutationResult<PublishQuiz>
