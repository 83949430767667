import React, { useState } from "react"
import { Users, Users_users, Users_users_edges_node, UsersVariables } from "model/types"
import { routes, usersTabs } from "router"
import { RouteComponentProps } from "react-router"
import { DeleteUserModal, InviteUserModal } from "./modals"
import { Query, QueryResult } from "react-apollo"
import { QUERY_USERS } from "model/graphql/queries"
import { Error, Loading } from "components/data-status"
import { UsersTable } from "./users-table"
import { findUsers, getSortedUserList, getUserListCount, getUserRoleByCurrentTab, UsersTab } from "./helpers"
import { useChangePageTitle, useDebounce } from "utils"
import { useSelector } from "react-redux"
import { ReduxState } from "redux-store"
import { isEqual } from "lodash"
import { ContentWrapper, DataStatusWrapper, PageContainer, PageHeader } from "components/page"

export type UserToDelete = React.ComponentProps<typeof DeleteUserModal>["userToDelete"]

export type UserRole = "Administrator" | "Author" | "Ads Manager"

type Props = {
  children?: never
} & RouteComponentProps<{ tab: string }>

export const UsersScreen = (props: Props) => {
  const { tab: currentTab } = props.match.params
  const [toSearch, setToSearch] = useState<string>("")
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false)
  const debouncedToSearch = useDebounce(toSearch, 400)
  const [userToDelete, setUserToDelete] = React.useState<UserToDelete>({
    id: "",
    name: "",
    userName: "",
  })
  const isSortedBy = useSelector((state: ReduxState) => state.lists.users.sortBy, isEqual)
  const isDescending = useSelector((state: ReduxState) => state.lists.users.desc, isEqual)

  /**
   * Change Page Title
   */

  useChangePageTitle(props.match.path)

  const openDeleteModal = (user: UserToDelete) => () => {
    setIsDeleteModalOpen(true)
    setUserToDelete({
      id: user.id,
      name: user.name,
      userName: user.userName,
    })
  }

  const getUserNodeFromUsers = (users: Users_users): Users_users_edges_node[] => {
    return users.edges.map(({ node: user }) => user)
  }

  /**
   * Open Invite User Modal
   */

  const openInviteUserModal = () => setIsInviteModalOpen(true)

  /**
   * JSX
   */

  return (
    <PageContainer>
      <Query
        query={QUERY_USERS}
        variables={
          {
            sortBy: isSortedBy,
            desc: isDescending,
          } as UsersVariables
        }
      >
        {({ data, loading, error }: QueryResult<Users>) => {
          if (error) {
            return (
              <DataStatusWrapper>
                <Error>{error.message}</Error>
              </DataStatusWrapper>
            )
          }
          if (!data.users || loading) {
            return (
              <DataStatusWrapper>
                <Loading>Loading...</Loading>
              </DataStatusWrapper>
            )
          }

          return (
            <React.Fragment>
              <PageHeader
                heading={`Users`}
                button={{
                  title: `Invite User`,
                  action: openInviteUserModal,
                }}
                search={{
                  value: toSearch,
                  onChange: setToSearch,
                }}
                navigationTabs={[
                  {
                    name: `All`,
                    to: routes.users.replace(":tab", usersTabs.all),
                    notificationCount: getUserListCount(data.users.edges),
                  },
                  {
                    name: `Administrator`,
                    to: routes.users.replace(":tab", usersTabs.admin),
                    notificationCount: getUserListCount(getSortedUserList(data, "admin")),
                  },
                  {
                    name: `Author`,
                    to: routes.users.replace(":tab", usersTabs.author),
                    notificationCount: getUserListCount(getSortedUserList(data, "author")),
                  },
                  {
                    name: `Ads Manager`,
                    to: routes.users.replace(":tab", usersTabs.adsManager),
                    notificationCount: getUserListCount(getSortedUserList(data, "ads-manager")),
                  },
                  {
                    name: `Invited`,
                    to: routes.users.replace(":tab", usersTabs.invited),
                    notificationCount: getUserListCount(getSortedUserList(data, "invited")),
                  },
                ]}
              />

              <ContentWrapper hasNavigation={true}>
                <UsersTable
                  tab={currentTab}
                  openDeleteModal={openDeleteModal}
                  users={findUsers(
                    currentTab === "all"
                      ? getUserNodeFromUsers(data.users)
                      : getSortedUserList(data, currentTab as UsersTab),
                    debouncedToSearch
                  )}
                  groupName={currentTab !== ("invited" || "all") ? getUserRoleByCurrentTab(currentTab) : undefined}
                  isSortedBy={isSortedBy}
                  isDescending={isDescending}
                />
              </ContentWrapper>

              <DeleteUserModal
                isModalOpen={isDeleteModalOpen}
                userToDelete={userToDelete}
                closeModal={() => {
                  setIsDeleteModalOpen(false)
                }}
                queryVariables={{
                  sortBy: isSortedBy,
                  desc: isDescending,
                }}
              />

              <InviteUserModal
                groups={data.groups}
                isModalOpen={isInviteModalOpen}
                closeModal={() => {
                  setIsInviteModalOpen(false)
                }}
                queryVariables={{
                  sortBy: isSortedBy,
                  desc: isDescending,
                }}
              />
            </React.Fragment>
          )
        }}
      </Query>
    </PageContainer>
  )
}
