import React from "react"
import styled from "styled-components"
import { theme } from "theme"

type Props = {
  state: "up" | "down" | "none"
  children?: never
}

export const SortingIndicator = (props: Props) => {
  return (
    <Container>
      <TriangleWrapper rotate={"0deg"}>
        <Triangle color={props.state === "up" ? theme.color.primaryGreen : theme.color.borderGray} />
      </TriangleWrapper>
      <TriangleWrapper rotate={"180deg"}>
        <Triangle color={props.state === "down" ? theme.color.primaryGreen : theme.color.borderGray} />
      </TriangleWrapper>
    </Container>
  )
}

/**
 * Styles
 */

const Container = styled("div")`
  display: grid;
  position: relative;
  grid-template-columns: max-content;
  grid-template-rows: repeat(2, max-content);
  grid-row-gap: 4px;
`

type TriangleWrapperProps = {
  rotate: "0deg" | "180deg"
}

const TriangleWrapper = styled("div")`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 10px;
  height: 8px;
  transform-origin: center center;
  transform: rotate(${(props: TriangleWrapperProps) => props.rotate});
`

/**
 * Triangle
 */

type TriangleProps = {
  color: string
}

const Triangle = (props: TriangleProps) => (
  <Svg
    viewBox="0 0 10 8"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    color={props.color}
  >
    <path id="Polygon_1" d="M5,0l5,8l-10,0l5,-8Z" />
  </Svg>
)

type SvgProps = {
  color: string
}

const Svg = styled("svg")`
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linejoin: round;
  stroke-miterlimit: 2;
  width: 100%;
  height: 100%;

  path {
    fill-rule: nonzero;
    fill: ${(props: SvgProps) => props.color};
  }
`
