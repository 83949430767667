import { useReducer } from "react"
import { Action } from "utils"

/**
 * Initial State
 */

export const initialQuestionFormState = {
  //id data
  questionId: "",
  quizId: "",

  //visible text fields
  question: "",
  correctAnswerText: "",
  wrongAnswerText: "",
  answerExplain: "",
  order: 1,

  //visible image fields
  imagePreview: "",
  imageFile: null as File,

  correctAnswerImagePreview: "",
  correctAnswerImageFile: null as File,

  wrongAnswerImagePreview: "",
  wrongAnswerImageFile: null as File,

  explainImagePreview: "",
  explainImageFile: null as File,

  //toggle question type
  questionType: "title",

  //variables for internal using
  isActionsVisible: false,
}

/**
 * Dispatch Action Types
 */

export const actionTypes = {
  setQuestionId: "SET_QUESTION_ID",
  setQuizId: "SET_QUIZ_ID",
  setOrder: "SET_ORDER",

  setQuestion: "SET_QUESTION",
  setCorrectAnswerText: "SET_CORRECT_ANSWER_TEXT",
  setWrongAnswerText: "SET_WRONG_ANSWER_TEXT",
  setAnswerExplain: "SET_ANSWER_EXPLAIN",

  setImagePreview: "SET_IMAGE_PREVIEW",
  setImageFile: "SET_IMAGE_FILE",

  setExplainImagePreview: "SET_EXPLAIN_IMAGE_PREVIEW",
  setExplainImageFile: "SET_EXPLAIN_IMAGE_FILE",

  setCorrectAnswerImagePreview: "SET_CORRECT_ANSWER_IMAGE_PREVIEW",
  setCorrectAnswerImageFile: "SET_CORRECT_ANSWER_IMAGE_FILE",

  setWrongAnswerImagePreview: "SET_WRONG_ANSWER_IMAGE_PREVIEW",
  setWrongAnswerImageFile: "SET_WRONG_ANSWER_IMAGE_FILE",

  setQuestionType: "SET_QUESTION_TYPE",

  setIsActionsVisible: "SET_IS_ACTIONS_VISIBLE",
}

/**
 * Action Data Types
 */

export type InitialQuestionFormState = typeof initialQuestionFormState

type ActionData = {
  questionId?: string
  quizId?: string

  question?: string
  correctAnswerText?: string
  wrongAnswerText?: string
  answerExplain?: string
  order?: number

  imagePreview?: string
  imageFile?: File

  correctAnswerImagePreview?: string
  correctAnswerImageFile?: File

  wrongAnswerImagePreview?: string
  wrongAnswerImageFile?: File

  explainImagePreview?: string
  explainImageFile?: File

  questionType?: string

  isActionsVisible?: boolean
}

/**
 * Reducer
 */

const reducer = (state = initialQuestionFormState, action: Action<ActionData>): InitialQuestionFormState => {
  switch (action.type) {
    //Question ID
    case actionTypes.setQuestionId:
      if (action.data.questionId === state.questionId) {
        return state
      }
      return { ...state, questionId: action.data.questionId }

    //Quiz ID
    case actionTypes.setQuizId:
      if (action.data.quizId === state.quizId) {
        return state
      }
      return { ...state, quizId: action.data.quizId }

    //Question
    case actionTypes.setQuestion:
      if (action.data.question === state.question) {
        return state
      }
      return { ...state, question: action.data.question }

    //Title Image Preview
    case actionTypes.setImagePreview:
      if (action.data.imagePreview === state.imagePreview) {
        return state
      }
      return { ...state, imagePreview: action.data.imagePreview }

    //Title Image File
    case actionTypes.setImageFile:
      if (action.data.imageFile === state.imageFile) {
        return state
      }
      return { ...state, imageFile: action.data.imageFile }

    //Explain Answer
    case actionTypes.setAnswerExplain:
      if (action.data.answerExplain === state.answerExplain) {
        return state
      }
      return { ...state, answerExplain: action.data.answerExplain }

    //Explain Image Preview
    case actionTypes.setExplainImagePreview:
      if (action.data.explainImagePreview === state.explainImagePreview) {
        return state
      }
      return { ...state, explainImagePreview: action.data.explainImagePreview }

    //Explain Image File
    case actionTypes.setExplainImageFile:
      if (action.data.explainImageFile === state.explainImageFile) {
        return state
      }
      return { ...state, explainImageFile: action.data.explainImageFile }

    //Right Answer Text
    case actionTypes.setCorrectAnswerText:
      if (action.data.correctAnswerText === state.correctAnswerText) {
        return state
      }
      return { ...state, correctAnswerText: action.data.correctAnswerText }

    //Right Answer Image Preview
    case actionTypes.setCorrectAnswerImagePreview:
      if (action.data.correctAnswerImagePreview === state.correctAnswerImagePreview) {
        return state
      }
      return { ...state, correctAnswerImagePreview: action.data.correctAnswerImagePreview }

    //Right Answer Image File
    case actionTypes.setCorrectAnswerImageFile:
      if (action.data.correctAnswerImageFile === state.correctAnswerImageFile) {
        return state
      }
      return { ...state, correctAnswerImageFile: action.data.correctAnswerImageFile }

    //Wrong Answer Text
    case actionTypes.setWrongAnswerText:
      if (action.data.wrongAnswerText === state.wrongAnswerText) {
        return state
      }
      return { ...state, wrongAnswerText: action.data.wrongAnswerText }

    //Wrong Answer Image Preview
    case actionTypes.setWrongAnswerImagePreview:
      if (action.data.wrongAnswerImagePreview === state.wrongAnswerImagePreview) {
        return state
      }
      return { ...state, wrongAnswerImagePreview: action.data.wrongAnswerImagePreview }

    //Wrong Answer Image File
    case actionTypes.setWrongAnswerImageFile:
      if (action.data.wrongAnswerImageFile === state.wrongAnswerImageFile) {
        return state
      }
      return { ...state, wrongAnswerImageFile: action.data.wrongAnswerImageFile }

    //Question Order
    case actionTypes.setOrder:
      if (action.data.order === state.order) {
        return state
      }
      return { ...state, order: action.data.order }

    //Question Type
    case actionTypes.setQuestionType:
      if (action.data.questionType === state.questionType) {
        return state
      }
      return { ...state, questionType: action.data.questionType }

    //Visibility Form Action Buttons
    case actionTypes.setIsActionsVisible:
      if (action.data.isActionsVisible === state.isActionsVisible) {
        return state
      }
      return { ...state, isActionsVisible: action.data.isActionsVisible }

    default:
      throw new Error(`"${action.type}" is not a valid action!`)
  }
}

/**
 * Use Form State Hook
 */

export const useFormState = () => {
  const [formState, formDispatch] = useReducer(reducer, initialQuestionFormState)

  return { formState, formDispatch }
}

export type FormState = ReturnType<typeof useFormState>["formState"]
export type FormDispatch = ReturnType<typeof useFormState>["formDispatch"]
