import { Action, InitialState } from "./state"
import React from "react"

type Context = {
  state: InitialState
  dispatch: (value: Action) => void
  activateSuccessModal: () => void
}

export const ModalContext = React.createContext<Context>(null)
