import React, { useEffect } from "react"
import { ImageUpload } from "components/image-upload"
import { Textarea } from "components/textarea"
import styled from "styled-components"
import { Label } from "components/label"
import { Input } from "components/input"
import { actionTypes, FormDispatch, FormState } from "./form-state"
import { QUERY_GROUPS } from "model/graphql/queries"
import { DataStatusWrapper } from "../screen"
import { Groups } from "model/types"
import { Error, Loading } from "components/data-status"
import { QueryResult, Query } from "react-apollo"
import { RadioButton } from "components/radio-button"
import { Warning } from "components/warning"
import { isEmailValid } from "../../utils/helpers"

type Props = {
  state: FormState
  dispatch: FormDispatch
  children?: never
}

export const Form = (props: Props) => {
  const { state, dispatch } = props
  const { firstName, lastName, email, authorBio, role, avatarPreview, emailWarning } = state

  /**
   * Set First Name
   * @param value
   */

  const setFirstName = (value: string) => dispatch({ type: actionTypes.setFirstName, data: { firstName: value } })

  /**
   * Set Last Name
   * @param value
   */

  const setLastName = (value: string) => dispatch({ type: actionTypes.setLastName, data: { lastName: value } })

  /**
   * Set Email
   * @param value
   */

  const setEmail = (value: string) => dispatch({ type: actionTypes.setEmail, data: { email: value } })

  /**
   * Set Author Bio
   * @param value
   */

  const setAuthorBio = (value: string) => dispatch({ type: actionTypes.setAuthorBio, data: { authorBio: value } })

  /**
   * Set Role
   * @param value
   */

  const setRole = (value: string) => dispatch({ type: actionTypes.setRole, data: { role: value } })

  /**
   * Set Avatar File
   * @param file
   */

  const setAvatarFile = (file: File) => dispatch({ type: actionTypes.setAvatarFile, data: { avatarFile: file } })

  /**
   * Set Avatar Preview
   * @param url
   */

  const setAvatarPreview = (url: string) =>
    dispatch({ type: actionTypes.setAvatarPreview, data: { avatarPreview: url } })

  const setEmailWarning = (warning: string) =>
    dispatch({ type: actionTypes.setEmailWarning, data: { emailWarning: warning } })

  useEffect(() => {
    if (email.length > 254) {
      setEmailWarning("This Email is too long")
    } else if (email.length && !isEmailValid(email)) {
      setEmailWarning("Email is written in wrong format")
    } else {
      setEmailWarning("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email])

  /**
   * JSX
   */

  return (
    <Container>
      <StyledForm>
        {/* First Name */}
        <FormRow gridArea={"first-name"}>
          <Label>{`First Name`}</Label>
          <Input value={firstName} onChange={setFirstName} />
        </FormRow>

        {/* Last Name */}
        <FormRow gridArea={"last-name"}>
          <Label>{`Last Name`}</Label>
          <Input value={lastName} onChange={setLastName} />
        </FormRow>

        {/* Email */}
        <FormRow gridArea={"email"}>
          <Label>{`Email`}</Label>
          <Input value={email} onChange={setEmail} />
          <Warning message={emailWarning} />
        </FormRow>

        {/* Author Bio */}
        <FormRow gridArea={"author-bio"}>
          <Label>{`Author Bio`}</Label>
          <Textarea hasStaticHeight value={authorBio} onChange={setAuthorBio} />
        </FormRow>

        {/* Role */}
        <FormRow gridArea={"role"}>
          <Label>{`Role`}</Label>
          <Query query={QUERY_GROUPS}>
            {({ data, loading, error }: QueryResult<Groups>) => {
              if (error) {
                return (
                  <DataStatusWrapper gridArea={"error"}>
                    <Error>{error.message}</Error>
                  </DataStatusWrapper>
                )
              }

              if (!data || loading) {
                return (
                  <DataStatusWrapper gridArea={"loading"}>
                    <Loading>Loading...</Loading>
                  </DataStatusWrapper>
                )
              }

              return (
                <RoleSelect>
                  {data.groups.edges.map(({ node: group }) => (
                    <RadioButtonWrapper key={group.id}>
                      <RadioButton optionValue={group.id} onChange={setRole} isChecked={role === group.id}>
                        {group.name}
                      </RadioButton>
                    </RadioButtonWrapper>
                  ))}
                </RoleSelect>
              )
            }}
          </Query>
        </FormRow>

        {/* Avatar Preview */}
        <FormRow gridArea={"author-image"}>
          <ImageUpload previewImage={avatarPreview} setImageFile={setAvatarFile} setImagePreview={setAvatarPreview} />
        </FormRow>
      </StyledForm>
    </Container>
  )
}

const RadioButtonWrapper = styled("div")`
  display: block;
  position: relative;
  margin-bottom: 20px;
  margin-right: 20px;
`

const RoleSelect = styled("div")`
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
`

const Container = styled("div")`
  display: block;
  position: relative;
  width: 100%;
  max-width: 760px;
`

const StyledForm = styled("form")`
  display: grid;
  position: relative;
  padding: 40px;
  width: 710px;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 20px;
  grid-auto-rows: auto;
  grid-row-gap: 20px;
  grid-template-areas:
    "first-name author-image"
    "last-name author-image"
    "email author-image"
    "space2 space2"
    "role role"
    "author-bio author-bio"
    "actions actions";
`

type FormRowProps = {
  gridArea: string
}

const FormRow = styled("div")`
  display: grid;
  position: relative;
  grid-template-columns: 120px 1fr;
  grid-template-rows: max-content max-content;
  grid-template-areas:
    "label ."
    "warning warning";
  grid-column-gap: 10px;
  align-items: start;
  grid-area: ${(props: FormRowProps) => props.gridArea};
`
