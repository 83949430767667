import styled, { css } from "styled-components"
import { theme } from "theme"

const primaryTextProps = css`
  color: ${theme.color.textBlack};
  margin: 0;
  white-space: pre-line;
`

export const Heading1 = styled.h1`
  ${primaryTextProps};
  font-weight: ${theme.font.weight.bold};
  font-size: 34px;
`
export const Heading2 = styled.h2`
  ${primaryTextProps};
  font-weight: ${theme.font.weight.semiBold};
  font-size: 16px;
`
export const Paragraph = styled.p`
  ${primaryTextProps};
  font-weight: ${theme.font.weight.regular};
  font-size: 16px;
`
