import React, { useState } from "react"
import { Button } from "components/button"
import { IconPlusSmall } from "components/icons"
import styled from "styled-components"
import { theme } from "theme"

type Props = {
  leftButton: {
    text: string
    onClick: () => void
  }
  rightButton: {
    text: string
    onClick: () => void
  }
  children?: never
}

export const NewQuestion = (props: Props) => {
  const [isDisabled, setIsDisabled] = useState(true)

  return (
    <Container onMouseLeave={() => setIsDisabled(true)} onMouseEnter={() => setIsDisabled(false)}>
      <Line isDisabled={isDisabled} />
      <ButtonWrapper position={"LEFT"}>
        <Button
          onClick={props.leftButton.onClick}
          icon={<IconPlusSmall />}
          color={"lightGreen"}
          size={"small"}
          variant={"filled"}
          isDisabled={isDisabled}
        >
          {props.leftButton.text}
        </Button>
      </ButtonWrapper>
      <ButtonWrapper position={"RIGHT"}>
        <Button
          onClick={props.rightButton.onClick}
          icon={<IconPlusSmall />}
          color={"lightGreen"}
          size={"small"}
          variant={"filled"}
          isDisabled={isDisabled}
        >
          {props.rightButton.text}
        </Button>
      </ButtonWrapper>
      <Line isDisabled={isDisabled} />
    </Container>
  )
}

const Container = styled("div")`
  display: flex;
  position: relative;
  align-items: center;
`

type ButtonWrapperProps = {
  position: "LEFT" | "RIGHT"
}

const ButtonWrapper = styled("div")`
  padding-left: ${(props: ButtonWrapperProps) => (props.position === "LEFT" ? "40px" : "10px")};
  padding-right: ${(props: ButtonWrapperProps) => (props.position === "LEFT" ? "10px" : "40px")};
`

type LineProps = {
  isDisabled: boolean
}

const Line = styled("div")`
  background: ${(props: LineProps) => (props.isDisabled ? theme.color.borderGray : theme.color.primaryGreenLight)};
  border-radius: 20px;
  height: 10px;
  width: 332px;
`
