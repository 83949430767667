import React from "react"
import { Link, LinkProps, Route } from "react-router-dom"
import styled, { css } from "styled-components"
import { theme } from "theme"
import { RouteChildrenProps } from "react-router"

type TextProps = {
  isActive?: boolean
  isDisabled?: boolean
}

type Props = {
  isExact?: boolean
  isDisabled?: boolean
  notificationsCount?: number
  children: string
  pathForActiveState?: string
} & LinkProps

export const Tab = (props: Props) => {
  const { to, pathForActiveState, isExact = false, isDisabled, notificationsCount, children, ...rest } = props

  const path = pathForActiveState ? pathForActiveState : typeof to === "string" ? to : to.pathname

  return (
    <Route path={path}>
      {({ match }: RouteChildrenProps) => {
        const isActive = isExact ? match && match.isExact : Boolean(match)

        if (isDisabled) {
          return (
            <DisabledLink>
              <Text isDisabled={isDisabled}>{children}</Text>
              {notificationsCount !== undefined && <NotificationBadge>{notificationsCount}</NotificationBadge>}
            </DisabledLink>
          )
        }

        return (
          <ActiveLink to={to} {...rest}>
            <Text isActive={isActive}>{children}</Text>
            {notificationsCount !== undefined && <NotificationBadge>{notificationsCount}</NotificationBadge>}
            {isActive && <Underline />}
          </ActiveLink>
        )
      }}
    </Route>
  )
}

const tabStyle = css`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: flex-start;
  height: 100%;
  text-decoration: none;
`
const ActiveLink = styled(Link)`
  ${tabStyle};
`
const DisabledLink = styled("span")`
  ${tabStyle};
`
const Text = styled("span")`
  font-size: 16px;
  font-weight: ${theme.font.weight.semiBold};
  color: ${(props: TextProps) => {
    if (props.isDisabled) {
      return theme.color.primaryGreenLight
    }
    return props.isActive ? theme.color.textBlack : theme.color.primaryGreen
  }};
  line-height: 22px;
`
const Underline = styled("div")`
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: ${theme.color.primaryGreen};
`
const NotificationBadge = styled("span")`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 20px;
  border-radius: ${theme.rounding2};
  background-color: ${theme.color.borderGray};
  color: ${theme.color.textNonactive};
  font-size: 12px;
  font-weight: ${theme.font.weight.regular};
  margin-top: 2px;
  margin-left: 8px;
  padding: 0 8px;
`
