export const formatNameValue = (value: string) => {
  return (
    value
      // Replace New Line with Space
      .replace(/\s*\n\s*/g, " ")
      // Trim
      .replace(/^\s+|\s+$/g, "")
  )
}

/**
 * Get Slugified String
 */

export const slugify = (string: string) => {
  const a = "àáäâãåăæąçćčđďèéěėëêęǵḧìíïîįłḿǹńňñòóöôœøṕŕřßśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;"
  const b = "aaaaaaaaacccddeeeeeeeghiiiiilmnnnnooooooprrssssttuuuuuuuuuwxyyzzz------"
  const p = new RegExp(a.split("").join("|"), "g")

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w-]+/g, "") // Remove all non-word characters
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, "") // Trim - from end of text
}

/**
 * Get Image Source
 */

export const getImageSource = (relativePath: string) => {
  return `${process.env.REACT_APP_MEDIA_URL_PREFIX}${relativePath}`
}
