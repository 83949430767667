import React from "react"
import { IconProps } from "./types"
import { Svg } from "./styles"

type Props = IconProps

export const IconEye = (props: Props) => {
  return (
    <Svg color={props.color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 11">
      <g id="Eye_Icon" transform="translate(-579.5 -253.499)">
        <path
          d="M1194.31,142.076a11.893,11.893,0,0,0-10,5,12.5,12.5,0,0,0,20,0A11.892,11.892,0,0,0,1194.31,142.076Z"
          transform="translate(-604.311 111.923)"
          fill="none"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <path
          d="M1195.812,144.911a3,3,0,1,0,3,3A3,3,0,0,0,1195.812,144.911Z"
          transform="translate(-605.813 111.092)"
          fill="none"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
      </g>
    </Svg>
  )
}
