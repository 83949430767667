import React, { useEffect } from "react"
import { Form } from "./form"
import { Error, Loading } from "components/data-status"
import { actionTypes, useFormState } from "./form-state"
import { Me } from "model/types"
import { QUERY_ME } from "model/graphql/queries"
import { Mutation, QueryResult, Query } from "react-apollo"
import { MUTATION_CHANGE_MY_PROFILE } from "model/graphql/mutations"
import { useChangeUser, UserChangeMutation, UserChangeMutationResult } from "./helpers"
import { useActivateSuccessModal, useChangePageTitle } from "utils"
import { RouteComponentProps } from "react-router"
import { ContentWrapper, DataStatusWrapper, PageContainer, SubPageHeader } from "components/page"

type Props = {
  children?: never
} & RouteComponentProps

export const MyProfileScreen = (props: Props) => {
  return (
    <PageContainer>
      <Query query={QUERY_ME}>
        {({ data, loading, error }: QueryResult<Me>) => {
          if (error) {
            return (
              <DataStatusWrapper>
                <Error>{error.message}</Error>
              </DataStatusWrapper>
            )
          }
          if (!data || loading) {
            return (
              <DataStatusWrapper>
                <Loading>Loading...</Loading>
              </DataStatusWrapper>
            )
          }
          return <Content {...props} data={data} />
        }}
      </Query>
    </PageContainer>
  )
}

/**
 * Content
 */

type ContentProps = {
  data: QueryResult<Me>["data"]
} & Props

const Content = (props: ContentProps) => {
  const { data } = props
  const { formState, formDispatch } = useFormState()
  const { emailWarning } = formState

  /**
   * Change Page Title
   */

  useChangePageTitle(props.match.path)

  /**
   * Save Data to Form State
   */

  useEffect(() => {
    formDispatch({ type: actionTypes.setId, data: { id: data.me.id } })
  }, [formDispatch, data.me.id])

  useEffect(() => {
    formDispatch({ type: actionTypes.setFirstName, data: { firstName: data.me.firstName } })
  }, [formDispatch, data.me.firstName])

  useEffect(() => {
    formDispatch({ type: actionTypes.setLastName, data: { lastName: data.me.lastName } })
  }, [formDispatch, data.me.lastName])

  useEffect(() => {
    formDispatch({ type: actionTypes.setEmail, data: { email: data.me.email } })
  }, [formDispatch, data.me.email])

  useEffect(() => {
    formDispatch({ type: actionTypes.setAuthorBio, data: { authorBio: data.me.description } })
  }, [formDispatch, data.me.description])

  useEffect(() => {
    const avatarPreview = data.me.avatar ? `${process.env.REACT_APP_MEDIA_URL_PREFIX}${data.me.avatar}` : undefined
    formDispatch({ type: actionTypes.setAvatarPreview, data: { avatarPreview: avatarPreview } })
  }, [formDispatch, data.me.avatar])

  /**
   * Handle User Change
   */

  const { activateSuccessModal } = useActivateSuccessModal()

  const handleSuccess = () => {
    activateSuccessModal()
  }

  const { changeUser } = useChangeUser({
    onSuccessAction: handleSuccess,
  })

  const formSubmit = (mutation: UserChangeMutation) => !emailWarning.length && saveProfile(mutation)

  const saveProfile = async (userChangeMutation: UserChangeMutation) => {
    try {
      await changeUser(userChangeMutation, {
        id: formState.id,
        firstName: formState.firstName,
        lastName: formState.lastName,
        email: formState.email,
        authorBio: formState.authorBio,
        avatar: formState.avatarFile,
        deleteAvatar: !formState.avatarPreview,
      })
    } catch (error) {
      console.error(error)
    }
  }

  /**
   * JSX
   */

  return (
    <React.Fragment>
      <Mutation mutation={MUTATION_CHANGE_MY_PROFILE}>
        {(userChangeMutation: UserChangeMutation, { loading }: UserChangeMutationResult) => (
          <SubPageHeader
            heading={`My Profile`}
            button={{
              title: `Save`,
              action: () => formSubmit(userChangeMutation),
              isDisabled: !formState.email || loading,
            }}
          />
        )}
      </Mutation>

      <ContentWrapper>
        <Form state={formState} dispatch={formDispatch} />
      </ContentWrapper>
    </React.Fragment>
  )
}
