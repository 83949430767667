import React, { useCallback, useState } from "react"
import { Query, QueryResult } from "react-apollo"
import { Vendors, VendorsVariables } from "model/types"
import { routes } from "router"
import { ActionButton, ActionContainer, ColumnHeading, Table } from "components/table"
import { NavigationLink } from "components/links"
import { Error, Loading } from "components/data-status"
import { DeleteVendorModal } from "./delete-vendor-modal"
import { RouteComponentProps } from "react-router"
import { QUERY_VENDORS } from "model/graphql/queries"
import { getSortingIndicator, ListsDispatch, useChangePageTitle } from "utils"
import { useDispatch, useSelector } from "react-redux"
import { ReduxState } from "redux-store"
import { isEqual } from "lodash"
import { listsActionTypes } from "redux-reducers"
import { ContentWrapper, DataStatusWrapper, PageContainer, PageHeader } from "components/page"

type Props = {
  children?: never
} & RouteComponentProps

type VendorToDelete = React.ComponentProps<typeof DeleteVendorModal>["vendorToDelete"]

export const VendorsScreen = (props: Props) => {
  const isSortedBy = useSelector((state: ReduxState) => state.lists.vendors.sortBy, isEqual)
  const isDescending = useSelector((state: ReduxState) => state.lists.vendors.desc, isEqual)

  return (
    <PageContainer>
      <Query query={QUERY_VENDORS} variables={{ sortBy: isSortedBy, desc: isDescending } as VendorsVariables}>
        {({ data, loading, error }: QueryResult<Vendors>) => {
          if (error) {
            return (
              <DataStatusWrapper>
                <Error>{error.message}</Error>
              </DataStatusWrapper>
            )
          }
          if (!data || !data.vendors || loading) {
            return (
              <DataStatusWrapper>
                <Loading>Loading...</Loading>
              </DataStatusWrapper>
            )
          }
          return <ScreenContent {...props} data={data} isSortedBy={isSortedBy} isDescending={isDescending} />
        }}
      </Query>
    </PageContainer>
  )
}

type ContentProps = {
  data: QueryResult<Vendors>["data"]
  isSortedBy: string
  isDescending: boolean
} & Props

const ScreenContent = (props: ContentProps) => {
  const { data, history, isSortedBy, isDescending } = props

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [vendorToDelete, setVendorToDelete] = useState<VendorToDelete>({
    id: "",
    name: "",
  })

  /**
   * Change Page Title
   */

  useChangePageTitle(props.match.path)

  const navigateToCreateNewVendorScreen = () => history.push(routes.vendorCreate)

  const openDeleteModal = (vendor: VendorToDelete) => () => {
    setIsDeleteModalOpen(true)
    setVendorToDelete({
      id: vendor.id,
      name: vendor.name,
    })
  }

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false)
  }

  /**
   * Set List Sorting
   */
  const dispatch = useDispatch()

  const setSortBy = useCallback(
    (sortBy: string) =>
      dispatch<ListsDispatch>({ type: listsActionTypes.setVendorsSortBy, data: { vendors: { sortBy } } }),
    [dispatch]
  )
  const setDesc = useCallback(
    (desc: boolean) => dispatch<ListsDispatch>({ type: listsActionTypes.setVendorsDesc, data: { vendors: { desc } } }),
    [dispatch]
  )

  const sortList = (sortBy: string) => () => {
    if (sortBy !== isSortedBy) {
      setSortBy(sortBy)
      setDesc(false)
      return
    }
    if (sortBy === isSortedBy && !isDescending) {
      setDesc(true)
      return
    }
    if (sortBy === isSortedBy && isDescending) {
      setDesc(false)
      return
    }
  }

  /**
   * JSX
   */

  return (
    <React.Fragment>
      <PageHeader heading={`Vendors`} button={{ title: `Add Vendor`, action: navigateToCreateNewVendorScreen }} />

      <ContentWrapper>
        <Table
          noContentNotification={`There are no Vendors yet`}
          content={{
            head: [
              <ColumnHeading
                onClick={sortList("name")}
                arrowState={getSortingIndicator({
                  sortBy: "name",
                  isSortedBy,
                  isDescending,
                })}
              >{`Title`}</ColumnHeading>,
              <ColumnHeading>{`Action`}</ColumnHeading>,
            ],

            body: data.vendors.edges.map(({ node: vendor }) => [
              <NavigationLink
                color={"green"}
                weight={"semiBold"}
                size={"16px"}
                to={routes.vendorEdit.replace(":vendorId", vendor.id)}
              >
                {vendor.name}
              </NavigationLink>,
              <ActionContainer>
                <ActionButton actionType={"delete"} onClick={openDeleteModal({ id: vendor.id, name: vendor.name })} />
              </ActionContainer>,
            ]),
          }}
        />
      </ContentWrapper>

      <DeleteVendorModal
        isModalOpen={isDeleteModalOpen}
        vendorToDelete={vendorToDelete}
        closeModal={closeDeleteModal}
        queryVariables={{ sortBy: isSortedBy, desc: isDescending }}
      />
    </React.Fragment>
  )
}
