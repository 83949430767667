import React, { useContext, useEffect } from "react"
import styled from "styled-components"
import { theme } from "theme"
import {
  SlotsBottomSection,
  SlotsMiddleSection,
  SlotsSideSection,
  SlotsTopSection,
} from "screens/layout/variant-preview/slot-sections"
import { SelectBox } from "screens/layout/variant-preview/select-box"
import { slotNames } from "utils"
import { variantActionTypes } from "screens/layout/layout-context/variant-reducer"
import { QueryResult } from "react-apollo"
import { Variant } from "model/types"
import { setSlotsFromQueryResult } from "screens/layout/helpers"
import { LayoutStoreContext } from "screens/layout/layout-context"
import { actionTypes as modalActionTypes, ModalContext } from "providers/modal"
import { isEqual } from "lodash"

type Props = {
  data: QueryResult<Variant>["data"]
  children?: never
}

export const PreviewBody = (props: Props) => {
  const { data } = props
  const { variantDispatch, variantState } = useContext(LayoutStoreContext)
  const { dispatch: modalDispatch } = useContext(ModalContext)

  /**
   * Reset State + Set New Data
   */

  useEffect(() => {
    variantDispatch({ type: variantActionTypes.resetVariantState })
    setSlotsFromQueryResult({
      slotPairSet: data.variant.slotpairSet,
      dispatch: variantDispatch,
    })
    variantDispatch({ type: variantActionTypes.setNotes, data: { notes: data.variant.notes } })
    variantDispatch({ type: variantActionTypes.setDefaultNotes, data: { notes: data.variant.notes } })
  }, [data.variant.notes, data.variant.slotpairSet, variantDispatch])

  /**
   * Check If User Can Leave The Layout Page
   */

  useEffect(() => {
    if (variantState.defaultNotes !== variantState.notes || !isEqual(variantState.defaultSlots, variantState.slots)) {
      modalDispatch({ type: modalActionTypes.setCanLeavePage, value: false })
    } else {
      modalDispatch({ type: modalActionTypes.setCanLeavePage, value: true })
    }
  }, [modalDispatch, variantState.defaultNotes, variantState.defaultSlots, variantState.notes, variantState.slots])

  /**
   * JSX
   */

  return (
    <Container>
      {/* Top */}
      <SlotsTopSection>
        <SelectBox
          slotName={slotNames.below_score}
          slotActionType={variantActionTypes.setSlotBelowScoreUnitId}
          selectedOptions={variantState.slotsSelectedOptions.belowScore}
          selectedOptionsActionType={variantActionTypes.setSlotSelectedOptionsBelowScore}
        />
        <SelectBox
          slotName={slotNames.below_response}
          slotActionType={variantActionTypes.setSlotBelowResponseUnitId}
          selectedOptions={variantState.slotsSelectedOptions.belowResponse}
          selectedOptionsActionType={variantActionTypes.setSlotSelectedOptionsBelowResponse}
        />
        <SelectBox
          slotName={slotNames.above_correct_answer}
          slotActionType={variantActionTypes.setSlotAboveCorrectAnswerUnitId}
          selectedOptions={variantState.slotsSelectedOptions.aboveCorrectAnswer}
          selectedOptionsActionType={variantActionTypes.setSlotSelectedOptionsAboveCorrectAnswer}
        />
        <SelectBox
          slotName={slotNames.above_answer_pic}
          slotActionType={variantActionTypes.setSlotAboveAnswerPicUnitId}
          selectedOptions={variantState.slotsSelectedOptions.aboveAnswerPic}
          selectedOptionsActionType={variantActionTypes.setSlotSelectedOptionsAboveAnswerPic}
        />
        <SelectBox
          slotName={slotNames.below_answer_pic}
          slotActionType={variantActionTypes.setSlotBelowAnswerPicUnitId}
          selectedOptions={variantState.slotsSelectedOptions.belowAnswerPic}
          selectedOptionsActionType={variantActionTypes.setSlotSelectedOptionsBelowAnswerPic}
        />
        <SelectBox
          slotName={slotNames.paragraph_splitter}
          slotActionType={variantActionTypes.setSlotParagraphSplitterUnitId}
          selectedOptions={variantState.slotsSelectedOptions.paragraphSplitter}
          selectedOptionsActionType={variantActionTypes.setSlotSelectedOptionsParagraphSplitter}
        />
        <SelectBox
          slotName={slotNames.below_correct_answer}
          slotActionType={variantActionTypes.setSlotBelowCorrectAnswerUnitId}
          selectedOptions={variantState.slotsSelectedOptions.belowCorrectAnswer}
          selectedOptionsActionType={variantActionTypes.setSlotSelectedOptionsBelowCorrectAnswer}
        />
      </SlotsTopSection>

      {/* Middle */}
      <SlotsMiddleSection>
        <SelectBox
          slotName={slotNames.above_headline}
          slotActionType={variantActionTypes.setSlotAboveHeadlineUnitId}
          selectedOptions={variantState.slotsSelectedOptions.aboveHeadline}
          selectedOptionsActionType={variantActionTypes.setSlotSelectedOptionsAboveHeadline}
        />
        <SelectBox
          slotName={slotNames.below_headline}
          slotActionType={variantActionTypes.setSlotBelowHeadlineUnitId}
          selectedOptions={variantState.slotsSelectedOptions.belowHeadline}
          selectedOptionsActionType={variantActionTypes.setSlotSelectedOptionsBelowHeadline}
        />
        <SelectBox
          slotName={slotNames.above_buttons}
          slotActionType={variantActionTypes.setSlotAboveButtonsUnitId}
          selectedOptions={variantState.slotsSelectedOptions.aboveButtons}
          selectedOptionsActionType={variantActionTypes.setSlotSelectedOptionsAboveButtons}
        />
        <SelectBox
          slotName={slotNames.below_buttons_1}
          slotActionType={variantActionTypes.setSlotBelowButtons1UnitId}
          selectedOptions={variantState.slotsSelectedOptions.belowButtons1}
          selectedOptionsActionType={variantActionTypes.setSlotSelectedOptionsBelowButtons1}
        />
        <SelectBox
          slotName={slotNames.below_buttons_2}
          slotActionType={variantActionTypes.setSlotBelowButtons2UnitId}
          selectedOptions={variantState.slotsSelectedOptions.belowButtons2}
          selectedOptionsActionType={variantActionTypes.setSlotSelectedOptionsBelowButtons2}
        />
        <SelectBox
          slotName={slotNames.below_buttons_3}
          slotActionType={variantActionTypes.setSlotBelowButtons3UnitId}
          selectedOptions={variantState.slotsSelectedOptions.belowButtons3}
          selectedOptionsActionType={variantActionTypes.setSlotSelectedOptionsBelowButtons3}
        />
        <SelectBox
          slotName={slotNames.below_second_buttons_1}
          slotActionType={variantActionTypes.setSlotBelowSecondButtons1UnitId}
          selectedOptions={variantState.slotsSelectedOptions.belowSecondButtons1}
          selectedOptionsActionType={variantActionTypes.setSlotSelectedOptionsBelowSecondButtons1}
        />
        <SelectBox
          slotName={slotNames.below_second_buttons_2}
          slotActionType={variantActionTypes.setSlotBelowSecondButtons2UnitId}
          selectedOptions={variantState.slotsSelectedOptions.belowSecondButtons2}
          selectedOptionsActionType={variantActionTypes.setSlotSelectedOptionsBelowSecondButtons2}
        />
        <SelectBox
          slotName={slotNames.below_second_buttons_3}
          slotActionType={variantActionTypes.setSlotBelowSecondButtons3UnitId}
          selectedOptions={variantState.slotsSelectedOptions.belowSecondButtons3}
          selectedOptionsActionType={variantActionTypes.setSlotSelectedOptionsBelowSecondButtons3}
        />
      </SlotsMiddleSection>

      {/* Bottom */}
      <SlotsBottomSection>
        <SelectBox
          slotName={slotNames.page_level_1}
          slotActionType={variantActionTypes.setSlotPageLevel1UnitId}
          selectedOptions={variantState.slotsSelectedOptions.pageLevel1}
          selectedOptionsActionType={variantActionTypes.setSlotSelectedOptionsPageLevel1}
        />
        <SelectBox
          slotName={slotNames.page_level_2}
          slotActionType={variantActionTypes.setSlotPageLevel2UnitId}
          selectedOptions={variantState.slotsSelectedOptions.pageLevel2}
          selectedOptionsActionType={variantActionTypes.setSlotSelectedOptionsPageLevel2}
        />
        <SelectBox
          slotName={slotNames.page_level_3}
          slotActionType={variantActionTypes.setSlotPageLevel3UnitId}
          selectedOptions={variantState.slotsSelectedOptions.pageLevel3}
          selectedOptionsActionType={variantActionTypes.setSlotSelectedOptionsPageLevel3}
        />
      </SlotsBottomSection>

      {/* Side */}
      <SlotsSideSection>
        <SelectBox
          slotName={slotNames.right_rail_upper}
          slotActionType={variantActionTypes.setSlotRightRailUpperUnitId}
          selectedOptions={variantState.slotsSelectedOptions.rightRailUpper}
          selectedOptionsActionType={variantActionTypes.setSlotSelectedOptionsRightRailUpper}
        />
        <SelectBox
          slotName={slotNames.right_rail_middle}
          slotActionType={variantActionTypes.setSlotRightRailMiddleUnitId}
          selectedOptions={variantState.slotsSelectedOptions.rightRailMiddle}
          selectedOptionsActionType={variantActionTypes.setSlotSelectedOptionsRightRailMiddle}
        />
        <SelectBox
          slotName={slotNames.right_rail_lower}
          slotActionType={variantActionTypes.setSlotRightRailLowerUnitId}
          selectedOptions={variantState.slotsSelectedOptions.rightRailLower}
          selectedOptionsActionType={variantActionTypes.setSlotSelectedOptionsRightRailLower}
        />
      </SlotsSideSection>
    </Container>
  )
}

const Container = styled("div")`
  display: grid;
  position: relative;
  grid-template-columns: minmax(420px, 740px) 420px;
  grid-template-rows: max-content max-content max-content;
  grid-template-areas:
    "slots-top-section slots-side-section"
    "slots-middle-section slots-side-section"
    "slots-bottom-section .";
  border-radius: ${theme.rounding1};
  background-color: ${theme.color.borderGray};
  border: 1px solid ${theme.color.textNonactive};
  box-sizing: content-box;
  margin-top: ${theme.offset3};
`
