import React from "react"
import { IconProps } from "./types"
import { Svg } from "./styles"

type Props = IconProps

export const IconDone = (props: Props) => {
  return (
    <Svg color={props.color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 22">
      <g id="Done_Icon" transform="translate(-1539 -644)">
        <path
          d="M376.068,50.188l9.2,8.842,20.8-20"
          transform="translate(1163.932 605.97)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </Svg>
  )
}
