import styled from "styled-components"
import { theme } from "theme"

type Props = {
  hasShadow?: boolean
}

export const Box = styled("div")`
  display: block;
  position: relative;
  background-color: ${theme.color.white};
  border-radius: ${theme.rounding2};
  ${(props: Props) => (props.hasShadow ? "box-shadow: 0 10px 50px rgba(0, 0, 0, 0.15)" : "")};
`
