import React, { useContext } from "react"
import { Mutation, QueryResult } from "react-apollo"
import { Variant } from "model/types"
import { getFormattedSlots, getVariantName } from "screens/layout/helpers"
import { DeleteButton } from "screens/layout/variant-preview/delete-button"
import { EditButton } from "screens/layout/variant-preview/edit-button"
import {
  MUTATION_SAVE_VARIANT,
  SaveVariantMutation,
  SaveVariantMutationResult,
  useSaveVariant,
} from "model/graphql/mutations"
import { Button } from "components/button"
import styled from "styled-components"
import { theme } from "theme"
import { LayoutStoreContext } from "screens/layout/layout-context"
import { layoutModalActionTypes } from "screens/layout/layout-context/modal-reducer"
import { useActivateSuccessModal } from "utils"
import { variantActionTypes } from "screens/layout/layout-context/variant-reducer"

type Props = {
  data: QueryResult<Variant>["data"]
  variantId: string
  children?: never
}

export const WARNING_NON_NEGATIVE = "Value cannot be negative"
export const WARNING_NOT_A_NUMBER = "Value must be a number"
export const WARNING_MAX_HIGHER_MIN = "Minimal value cannot be higher than maximal"
export const WARNING_CANNOT_BE_EMPTY = "Value cannot be empty"

export const PreviewHead = (props: Props) => {
  const { data, variantId } = props
  const { layoutModalDispatch, variantState, variantDispatch } = useContext(LayoutStoreContext)

  /**
   * Open Delete Variant Modal
   */

  const openDeleteVariantModal = () => {
    layoutModalDispatch({ type: layoutModalActionTypes.openDeleteVariantModal })
  }

  /**
   * Open Edit Variant Modal
   */

  const openEditVariantModal = () => {
    layoutModalDispatch({ type: layoutModalActionTypes.openEditVariantModal })
  }

  /**
   * Save Variant
   */

  const { activateSuccessModal } = useActivateSuccessModal()

  const handleSaveSuccess = () => {
    activateSuccessModal()
  }

  const handleError = (error: any) => {
    if (error.includes("variant_load_delay_can_not_be_negative")) {
      if (variantState.loadDelayMin < 0) {
        variantDispatch({
          type: variantActionTypes.setMinDelayWarning,
          data: { loadDelayMinWarning: WARNING_NON_NEGATIVE },
        })
      }
      if (variantState.loadDelayMax < 0) {
        variantDispatch({
          type: variantActionTypes.setMaxDelayWarning,
          data: { loadDelayMaxWarning: WARNING_NON_NEGATIVE },
        })
      }
    } else if (error.includes("variant_load_delay_max_must_be_greater_than_load_delay_min")) {
      variantDispatch({
        type: variantActionTypes.setMaxDelayWarning,
        data: { loadDelayMaxWarning: WARNING_MAX_HIGHER_MIN },
      })
      variantDispatch({
        type: variantActionTypes.setMinDelayWarning,
        data: { loadDelayMinWarning: WARNING_MAX_HIGHER_MIN },
      })
    } else if (error.includes("variant_needs_both_load_delay")) {
      if (!variantState.loadDelayMin && variantState.loadDelayMin !== 0) {
        variantDispatch({
          type: variantActionTypes.setMinDelayWarning,
          data: { loadDelayMinWarning: WARNING_CANNOT_BE_EMPTY },
        })
      }
      if (!variantState.loadDelayMax && variantState.loadDelayMax !== 0) {
        variantDispatch({
          type: variantActionTypes.setMinDelayWarning,
          data: { loadDelayMinWarning: WARNING_CANNOT_BE_EMPTY },
        })
      }
    }
  }

  const { saveVariant } = useSaveVariant({
    onSuccessAction: handleSaveSuccess,
    onErrorAction: handleError,
  })

  /**
   * Variables
   */

  const isDefault = data && data.variant && data.variant.default

  /**
   * JSX
   */

  return (
    <Section>
      <Title>
        {getVariantName({
          isDefault: data.variant.default,
          pageFrom: data.variant.pageFrom,
          pageTo: data.variant.pageTo,
        })}
      </Title>

      {!isDefault && (
        <React.Fragment>
          <DeleteButton onClick={openDeleteVariantModal}>{`Delete Override`}</DeleteButton>
          <EditButton onClick={openEditVariantModal}>{`Edit Override`}</EditButton>
        </React.Fragment>
      )}

      <Mutation mutation={MUTATION_SAVE_VARIANT}>
        {(saveVariantMutation: SaveVariantMutation, { loading: mutationLoading }: SaveVariantMutationResult) => (
          <SaveButtonWrapper>
            <Button
              color={"green"}
              isDisabled={
                mutationLoading ||
                !!variantState.loadDelayMinWarning.length ||
                !!variantState.loadDelayMaxWarning.length
              }
              onClick={saveVariant(saveVariantMutation, {
                id: variantId,
                slots: getFormattedSlots(variantState.slots),
                notes: variantState.notes,
                loadDelayMin: variantState.loadDelayMin,
                loadDelayMax: variantState.loadDelayMax,
              })}
            >
              {`Save`}
            </Button>
          </SaveButtonWrapper>
        )}
      </Mutation>
    </Section>
  )
}

const Section = styled("section")`
  display: grid;
  position: relative;
  height: 50px;
  width: 100%;
  align-items: center;
  grid-template-columns: 1fr max-content max-content max-content;
  grid-column-gap: ${theme.offset3};
  grid-template-rows: 1fr;
  grid-template-areas: "title . . save";
`

const Title = styled("span")`
  color: ${theme.color.textBlack};
  font-size: 16px;
  font-weight: ${theme.font.weight.semiBold};
  grid-area: title;
`

const SaveButtonWrapper = styled("div")`
  display: flex;
  position: relative;
  grid-area: save;
`
