import React, { useCallback, useState } from "react"
import { RouteComponentProps } from "react-router"
import { routes } from "router"
import { Query, QueryResult } from "react-apollo"
import { Units, UnitsVariables } from "model/types"
import { ActionButton, ActionContainer, ColumnHeading, Table } from "components/table"
import { AnchorLink } from "components/links"
import { NoContent } from "components/no-content"
import { Error, Loading } from "components/data-status"
import styled from "styled-components"
import { DeleteUnitModal } from "./delete-unit-modal"
import { QUERY_UNITS } from "model/graphql/queries"
import { getSortingIndicator, ListsDispatch, useChangePageTitle } from "utils"
import { useDispatch, useSelector } from "react-redux"
import { listsActionTypes } from "redux-reducers"
import { ReduxState } from "redux-store"
import { isEqual } from "lodash"
import { ContentWrapper, DataStatusWrapper, PageContainer, SubPageHeader } from "components/page"

type Props = {
  children?: never
} & RouteComponentProps<{ vendorId: string }>

type UnitToDelete = React.ComponentProps<typeof DeleteUnitModal>["unitToDelete"]

export const UnitsScreen = (props: Props) => {
  const { vendorId } = props.match.params

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [unitToDelete, setUnitToDelete] = useState<UnitToDelete>({
    id: "",
    name: "",
  })

  const isSortedBy = useSelector((state: ReduxState) => state.lists.units.sortBy, isEqual)
  const isDescending = useSelector((state: ReduxState) => state.lists.units.desc, isEqual)

  /**
   * Change Page Title
   */

  useChangePageTitle(props.match.path)

  const navigateToUnitCreateScreen = () => props.history.push(routes.unitCreate.replace(":vendorId", vendorId))

  const editUnit = (unitId: string) => {
    props.history.push(routes.unitEdit.replace(":unitId", unitId).replace(":vendorId", vendorId))
  }

  const openDeleteModal = (unit: UnitToDelete) => () => {
    setIsDeleteModalOpen(true)
    setUnitToDelete({ id: unit.id, name: unit.name })
  }

  /**
   * Set List Sorting
   */
  const dispatch = useDispatch()

  const setSortBy = useCallback(
    (sortBy: string) => dispatch<ListsDispatch>({ type: listsActionTypes.setUnitsSortBy, data: { units: { sortBy } } }),
    [dispatch]
  )
  const setDesc = useCallback(
    (desc: boolean) => dispatch<ListsDispatch>({ type: listsActionTypes.setUnitsDesc, data: { units: { desc } } }),
    [dispatch]
  )

  const sortList = (sortBy: string) => () => {
    if (sortBy !== isSortedBy) {
      setSortBy(sortBy)
      setDesc(false)
      return
    }
    if (sortBy === isSortedBy && !isDescending) {
      setDesc(true)
      return
    }
    if (sortBy === isSortedBy && isDescending) {
      setDesc(false)
      return
    }
  }

  /**
   * JSX
   */

  return (
    <PageContainer>
      <Query query={QUERY_UNITS} variables={{ id: vendorId, desc: isDescending, sortBy: isSortedBy } as UnitsVariables}>
        {({ loading, error, data }: QueryResult<Units>) => {
          if (error) {
            return (
              <DataStatusWrapper>
                <Error>{error.message}</Error>
              </DataStatusWrapper>
            )
          }
          if (!data || !data.units || !data.vendor || loading) {
            return (
              <DataStatusWrapper>
                <Loading>Loading...</Loading>
              </DataStatusWrapper>
            )
          }

          return (
            <React.Fragment>
              <SubPageHeader
                breadcrumbLinks={[
                  { name: `Vendors`, path: routes.vendors },
                  { name: data.vendor.name, path: routes.units.replace(":vendorId", vendorId) },
                ]}
                heading={data.vendor.name}
                button={{ title: `Add Unit`, action: navigateToUnitCreateScreen, hasIcon: true }}
                navigationTabs={[
                  { name: `General`, to: routes.vendorEdit.replace(":vendorId", vendorId), isExact: true },
                  { name: `Units`, to: routes.units.replace(":vendorId", vendorId), isExact: true },
                ]}
              />

              <ContentWrapper hasNavigation={true}>
                <Table
                  noContentNotification={"There are not Units yet"}
                  content={{
                    head: [
                      <ColumnHeading
                        onClick={sortList("name")}
                        arrowState={getSortingIndicator({ sortBy: "name", isSortedBy, isDescending })}
                      >
                        {`Title`}
                      </ColumnHeading>,
                      <ColumnHeading>{`Action`}</ColumnHeading>,
                    ],

                    body: data.units.edges.map(({ node: unit }) => [
                      <AnchorLink color={"green"} weight={"semiBold"} size={"14px"} onClick={() => editUnit(unit.id)}>
                        {unit.name}
                      </AnchorLink>,
                      <ActionContainer>
                        <ActionButton
                          actionType={"delete"}
                          onClick={openDeleteModal({
                            id: unit.id,
                            name: unit.name,
                          })}
                        />
                      </ActionContainer>,
                    ]),
                  }}
                />

                {data.units.edges.length === 0 && (
                  <NoContentWrapper>
                    <NoContent>There are no Units yet</NoContent>
                  </NoContentWrapper>
                )}
              </ContentWrapper>

              <DeleteUnitModal
                isModalOpen={isDeleteModalOpen}
                unitToDelete={unitToDelete}
                closeModal={() => setIsDeleteModalOpen(false)}
                queryVariables={{ sortBy: isSortedBy, desc: isDescending, id: data.vendor.id }}
              />
            </React.Fragment>
          )
        }}
      </Query>
    </PageContainer>
  )
}

const NoContentWrapper = styled("div")`
  display: block;
  position: relative;
  margin-top: 120px;
`
