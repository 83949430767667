import React from "react"
import styled from "styled-components"
import { theme } from "theme"

type Props = {
  children: string
}

export const Label = (props: Props) => {
  return <StyledLabel>{props.children}</StyledLabel>
}

const StyledLabel = styled("label")`
  color: ${theme.color.textBlack};
  font-weight: ${theme.font.weight.semiBold};
  font-size: 16px;
  line-height: 22px;
  grid-area: label;
  justify-self: start;
  padding-top: 15px;
`
