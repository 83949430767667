import React from "react"
import styled, { css } from "styled-components"
import { theme } from "theme"

type Props = {
  hasSideOffset?: boolean
  hasFullWidth?: boolean
  topOffset?: "40px" | "20px" | string
  children: React.ReactNode
}

export const ModalSection = (props: Props) => {
  return (
    <Container hasSideOffset={props.hasSideOffset} hasFullWidth={props.hasFullWidth} topOffset={props.topOffset}>
      {props.children}
    </Container>
  )
}

type ContainerProps = {
  hasSideOffset: Props["hasSideOffset"]
  hasFullWidth: Props["hasFullWidth"]
  topOffset: Props["topOffset"]
}

const Container = styled("div")<ContainerProps>`
  display: flex;
  position: relative;
  ${(props: ContainerProps) =>
    props.hasFullWidth
      ? css`
          width: 100%;
          height: 100%;
        `
      : ""};
  justify-content: center;
  margin-top: ${(props: ContainerProps) => (props.topOffset ? props.topOffset : "0")};
  padding: 0 ${(props: ContainerProps) => (props.hasSideOffset ? theme.offset3 : "0")};
`
