import gql from "graphql-tag"
import { fragments } from "model/graphql/fragments"

export const QUERY_UNITS = gql`
  query Units($id: ID!, $desc: Boolean, $sortBy: String) {
    units(id: $id, desc: $desc, sortBy: $sortBy) {
      edges {
        node {
          ...UnitData
        }
      }
    }
    vendor(id: $id) {
      ...VendorData
    }
  }
  ${fragments.unit}
  ${fragments.vendor}
`
