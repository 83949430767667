import React, { useState } from "react"
import { Redirect, RouteComponentProps } from "react-router"
import { DataStatusWrapper, Grid, ParagraphWrapper } from "../screen"
import { Heading1, Paragraph } from "components/typography"
import { Box } from "components/box"
import { Button } from "components/button"
import { FormRow } from "components/form-row"
import { Input } from "components/input"
import { routes } from "router"
import { Mutation, Query } from "react-apollo"
import {
  ActivateUserMutation,
  ActivateUserMutationResult,
  MUTATION_ACTIVATE_USER,
  useActivateUser,
  UseActivateUserArgs,
} from "model/graphql/mutations"
import { InvitedUserDataResult, QUERY_INVITED_USER_DATA } from "model/graphql/queries/user"
import { InvitedUserDataVariables } from "model/types"
import { Error, Loading } from "components/data-status"
import { Form } from "components/form"
import { Warning } from "components/warning"
import { useChangePageTitle } from "utils"
import { Label } from "components/label"
import { useSelector } from "react-redux"
import { ReduxState } from "redux-store"
import { isEqual } from "lodash"

/**
 * Props
 */

type Props = {
  children?: never
} & RouteComponentProps<{ invitationCode: string }>

/**
 * Welcome Screen
 */

export const WelcomeScreen = (props: Props) => {
  const { invitationCode } = props.match.params
  const auth = useSelector((state: ReduxState) => state.auth, isEqual)
  const { from } = props.location.state || { from: { pathname: routes.root } }

  /**
   * Change Page Title
   */

  useChangePageTitle(props.match.path)

  /**
   * Redirect if user is authenticated
   */

  if (auth.isAuthenticated) return <Redirect to={from} />

  return (
    <Grid type={1}>
      <Query query={QUERY_INVITED_USER_DATA} variables={{ invitationCode } as InvitedUserDataVariables}>
        {({ loading, error, data }: InvitedUserDataResult) => {
          if (error) {
            return (
              <DataStatusWrapper gridArea={"error"}>
                <Error>{error.message}</Error>
              </DataStatusWrapper>
            )
          }

          if (loading || !data) {
            return (
              <DataStatusWrapper gridArea={"loading"}>
                <Loading>Loading...</Loading>
              </DataStatusWrapper>
            )
          }

          return <ScreenContent {...props} data={data} />
        }}
      </Query>
    </Grid>
  )
}

/**
 * Screen Content Props
 */

type ScreenContentProps = {
  data: InvitedUserDataResult["data"]
} & Props

/**
 * Screen Content
 */

const ScreenContent = (props: ScreenContentProps) => {
  const { invitationCode } = props.match.params
  const { firstName } = props.data.userInviteData
  const [password, setPassword] = useState("")
  const [warning, setWarning] = useState("")

  /**
   * Change Password
   */

  const handlePasswordChange = (value: string) => {
    setPassword(value)

    if (warning !== "") {
      setWarning("")
    }
  }

  /**
   * Activate user
   */

  const handleSuccess: UseActivateUserArgs["onSuccessAction"] = () => {
    props.history.replace(routes.login)
  }

  const handleError: UseActivateUserArgs["onErrorAction"] = errorMessage => {
    if (/invitation_code_not_valid/.test(errorMessage)) {
      return setWarning("Invitation code not valid")
    }

    if (/invitation_code_expired/.test(errorMessage)) {
      return setWarning("Invitation code expired")
    }

    return setWarning(errorMessage)
  }

  const { activateUser } = useActivateUser({
    onSuccessAction: handleSuccess,
    onErrorAction: handleError,
  })

  const handleSubmit = (activateUserMutation: ActivateUserMutation) => async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault()
    await activateUser(activateUserMutation, { invitationCode: invitationCode, password: password })
  }

  return (
    <React.Fragment>
      <Heading1>{`Welcome ${firstName}!`}</Heading1>
      <Box>
        <Mutation mutation={MUTATION_ACTIVATE_USER}>
          {(activateUserMutation: ActivateUserMutation, { loading }: ActivateUserMutationResult) => (
            <Form method={"post"} onSubmit={handleSubmit(activateUserMutation)} type={1}>
              <ParagraphWrapper>
                <Paragraph>{`Please, set your password`}</Paragraph>
              </ParagraphWrapper>

              <FormRow>
                <Label>{"Password"}</Label>
                <Input type={"password"} value={password} onChange={handlePasswordChange} isDisabled={loading} />
                <Warning message={warning} />
              </FormRow>

              <Button
                type={"submit"}
                size={"regular"}
                color={"green"}
                variant={"filled"}
                isDisabled={!password || loading}
              >
                {`Save`}
              </Button>
            </Form>
          )}
        </Mutation>
      </Box>
    </React.Fragment>
  )
}
