import { Users, Users_users_edges, Users_users_edges_node } from "model/types"
import { includes } from "lodash"
import { UserRole } from "screens/users/users-screen"

export type UsersTab = "author" | "admin" | "ads-manager" | "invited"

/**
 * Finds Users by First Name and Last Name, Returns Found Users or Empty Array
 *
 * @param users
 * @param query
 */

export const findUsers = (users: Users_users_edges_node[], query: string): Users_users_edges_node[] | [] => {
  if (users && query.length === 0) {
    return users
  }
  if (users && query.length !== 0) {
    return getFilteredUsersBy(users, query)
  }
  return []
}

/**
 * Filtered users by passed query
 *
 * @param users
 * @param query
 */

const getFilteredUsersBy = (users: Users_users_edges_node[], query: string) => {
  return users.filter(
    (user: Users_users_edges_node) =>
      `${user.firstName} ${user.lastName} ${user.email}`.toLowerCase().indexOf(query.toLowerCase()) !== -1
  )
}

/**
 * Returns Sorted User List By Passed Sort Argument "sortBy"
 *
 * @param data
 * @param sortedBy "author" | "admin" | "ads-manager" | "invited"
 */

export const getSortedUserList = (data: Users, sortedBy: UsersTab): Users_users_edges_node[] | [] => {
  switch (sortedBy) {
    case "author":
      return getFilteredUsersByRole(data, "Author")

    case "admin":
      return getFilteredUsersByRole(data, "Administrator")

    case "ads-manager":
      return getFilteredUsersByRole(data, "Ads Manager")

    case "invited":
      return data.users.edges.filter(({ node: user }) => !user.isActive).map(({ node: user }) => user)
    default:
      return []
  }
}

/**
 * Gets Unsorted User List and Role
 *
 * Returns Filtered User List by Passed Role
 *
 * @param data
 * @param targetRole
 */

const getFilteredUsersByRole = (data: Users, targetRole: UserRole): Users_users_edges_node[] => {
  const foundUsers = data.users.edges.filter(({ node: user }) => {
    const userRoles: string[] = user.groups.edges.map(({ node: role }) => role.name)
    return includes(userRoles, targetRole)
  })
  return foundUsers.map(({ node: user }) => user) as Users_users_edges_node[]
}

/**
 * Gets Current Tab From User Screen
 *
 * Returns User Role
 *
 * @param currentTab
 */
export const getUserRoleByCurrentTab = (currentTab: string) => {
  switch (currentTab) {
    case "admin":
      return "Administrator"
    case "author":
      return "Author"
    case "ads-manager":
      return "Ads Manager"
    default:
      return ""
  }
}

/**
 * Gets User Array
 *
 * Returns Length of Passed Array
 *
 * @param userList
 */

export const getUserListCount = (userList: [] | Users_users_edges_node[] | Users_users_edges[]): number => {
  return userList.length
}
