import React from "react"
import { Quizzes, QuizzesVariables } from "model/types"
import { quizzesTabs, routes } from "router"
import { RouteComponentProps } from "react-router"
import { Mutation, Query, QueryResult } from "react-apollo"
import { QUERY_QUIZZES } from "model/graphql/queries"
import { Error, Loading } from "components/data-status"
import { useChangePageTitle, useDebounce } from "utils"
import { findQuizzes, getQuizListCount, getQuizNodeFromQuizzes, getSortedQuizList } from "./helpers"
import { QuizzesTable } from "./quizzes-table"
import { DeleteQuizModal } from "./modals/delete-quiz-modal"
import {
  CreateQuizMutation,
  CreateQuizMutationResult,
  MUTATION_QUIZ_CREATE,
  useCreateQuiz,
  UseCreateQuizArgs,
} from "model/graphql/mutations"
import { useSelector } from "react-redux"
import { ReduxState } from "redux-store"
import { isEqual } from "lodash"
import { ContentWrapper, DataStatusWrapper, PageContainer, PageHeader } from "components/page"

export type QuizToDelete = React.ComponentProps<typeof DeleteQuizModal>["quizToDelete"]

type Props = {
  children?: never
} & RouteComponentProps<{ tab: string; quizTab: string }>

export const QuizzesScreen = (props: Props) => {
  const { tab: currentTab } = props.match.params
  const [toSearch, setToSearch] = React.useState<string>("")
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false)
  const [quizToDelete, setQuizToDelete] = React.useState<QuizToDelete>({ id: "", title: "" })
  const isSortedBy = useSelector((state: ReduxState) => state.lists.quizzes.sortBy, isEqual)
  const isDescending = useSelector((state: ReduxState) => state.lists.quizzes.desc, isEqual)
  const debouncedToSearch = useDebounce(toSearch, 400)

  /**
   * Redirect to General Quiz Screen
   */

  const navigateToGeneralQuizScreen = (quizId: string) => {
    props.history.push(
      routes.quiz
        .replace(":tab", currentTab)
        .replace(":quizId", quizId)
        .replace(":quizTab", "general")
    )
  }

  /**
   * Create Quiz
   */

  const handleSuccess: UseCreateQuizArgs["onSuccessAction"] = quizId => {
    navigateToGeneralQuizScreen(quizId)
  }

  const { createQuiz } = useCreateQuiz({
    queryVariables: { sortBy: isSortedBy, desc: isDescending },
    onSuccessAction: handleSuccess,
  })

  const handleCreateQuiz = (createQuizMutation: CreateQuizMutation, authorId: string) => () => {
    createQuiz(createQuizMutation, { authorId, sortBy: isSortedBy, desc: isDescending })
  }

  /**
   * Change Page Title
   */

  useChangePageTitle(props.match.path)

  /**
   * Activate Delete Modal
   * @param quiz
   */

  const openDeleteModal = (quiz: QuizToDelete) => () => {
    setIsDeleteModalOpen(true)
    setQuizToDelete({
      id: quiz.id,
      title: quiz.title,
    })
  }

  /**
   * Render JSX
   */

  return (
    <PageContainer>
      <Query query={QUERY_QUIZZES} variables={{ sortBy: isSortedBy, desc: isDescending } as QuizzesVariables}>
        {({ data, loading, error }: QueryResult<Quizzes>) => {
          if (error) {
            return (
              <DataStatusWrapper>
                <Error>{error.message}</Error>
              </DataStatusWrapper>
            )
          }
          if (!data.quizzes || loading) {
            return (
              <DataStatusWrapper>
                <Loading>Loading...</Loading>
              </DataStatusWrapper>
            )
          }

          return (
            <React.Fragment>
              <Mutation mutation={MUTATION_QUIZ_CREATE}>
                {(createQuizMutation: CreateQuizMutation, { loading }: CreateQuizMutationResult) => (
                  <PageHeader
                    heading={`Quizzes`}
                    button={{
                      title: `Create Quiz`,
                      action: handleCreateQuiz(createQuizMutation, data.me.id),
                      isDisabled: loading,
                    }}
                    search={{
                      value: toSearch,
                      onChange: setToSearch,
                    }}
                    navigationTabs={[
                      {
                        name: `All`,
                        to: routes.quizzes.replace(":tab", quizzesTabs.all),
                        notificationCount: getQuizListCount(getQuizNodeFromQuizzes(data.quizzes.edges)),
                      },
                      {
                        name: `Published`,
                        to: routes.quizzes.replace(":tab", quizzesTabs.published),
                        notificationCount: getQuizListCount(getSortedQuizList(data.quizzes, "published", data.me.id)),
                      },
                      {
                        name: `Drafts`,
                        to: routes.quizzes.replace(":tab", quizzesTabs.drafts),
                        notificationCount: getQuizListCount(getSortedQuizList(data.quizzes, "drafts", data.me.id)),
                      },
                      {
                        name: `My Quizzes`,
                        to: routes.quizzes.replace(":tab", quizzesTabs.myQuizzes),
                        notificationCount: getQuizListCount(getSortedQuizList(data.quizzes, "my-quizzes", data.me.id)),
                      },
                    ]}
                  />
                )}
              </Mutation>

              {/*Quiz list*/}
              <ContentWrapper hasNavigation={true}>
                <QuizzesTable
                  tab={currentTab}
                  openDeleteModal={openDeleteModal}
                  quizzes={findQuizzes(
                    currentTab === "all"
                      ? getQuizNodeFromQuizzes(data.quizzes.edges)
                      : getSortedQuizList(data.quizzes, currentTab, data.me.id),
                    debouncedToSearch
                  )}
                  isSortedBy={isSortedBy}
                  isDescending={isDescending}
                />
              </ContentWrapper>

              {/*Delete Quiz Modal*/}
              <DeleteQuizModal
                isModalOpen={isDeleteModalOpen}
                quizToDelete={quizToDelete}
                closeModal={() => {
                  setIsDeleteModalOpen(false)
                }}
                queryVariables={{
                  sortBy: isSortedBy,
                  desc: isDescending,
                }}
              />
            </React.Fragment>
          )
        }}
      </Query>
    </PageContainer>
  )
}
