import styled from "styled-components"
import { theme } from "theme"
import React from "react"
import { QuizGeneralHeading, QuizQuestionsHeading } from "screens/quiz/page-header/quiz-heading"
import { ActionWrapper } from "components/page"
import { QuizNavigationTabs } from "screens/quiz/page-header/quiz-navigation-tabs"
import { QuizScreenRouteParams } from "utils"

/**
 * Component
 */

type Props = {
  breadcrumb: React.ReactNode
  autosave: React.ReactNode
  action?: React.ReactNode
  page?: "general" | "questions"
  routeParams: QuizScreenRouteParams
  children?: never
}

export const QuizPageHeader = (props: Props) => {
  const { breadcrumb, autosave, action, routeParams, page } = props

  return (
    <Container>
      {/* Breadcrumb */}
      {breadcrumb}

      <GridContainer>
        {/* Heading */}
        {page === "general" && <QuizGeneralHeading />}
        {page === "questions" && <QuizQuestionsHeading />}

        {/* Navigation */}
        <QuizNavigationTabs tab={routeParams.tab} quizId={routeParams.quizId} />

        <ActionWrapper>
          {/* Autosave */}
          {autosave}

          {/* Action */}
          {action && action}
        </ActionWrapper>
      </GridContainer>
    </Container>
  )
}

/**
 * Styles
 */

const Container = styled("div")`
  display: grid;
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  height: 130px;
  grid-template-columns: 1fr;
  grid-template-rows: 20px max-content;
  grid-template-areas:
    "breadcrumb"
    "main";
  padding-top: ${theme.offset1};
  padding-bottom: ${theme.offset3};
  padding-left: ${theme.offset4};
  padding-right: ${theme.offset4};
  z-index: 990;

  background-image: linear-gradient(0deg, rgba(255, 255, 255, 0) 0, ${theme.color.backgroundGray} 25%);
  background-origin: padding-box;
  background-clip: border-box;
  background-size: auto auto;
  background-position: 50% 50%;

  @media all and (max-width: 1440px) {
    height: 202px;
  }
`

const GridContainer = styled("div")`
  display: grid;
  position: relative;
  width: 100%;
  grid-template-columns: 1fr max-content 1fr;
  grid-template-rows: 50px;
  grid-template-areas: "heading navigation action";
  grid-column-gap: ${theme.offset3};
  grid-area: main;

  @media all and (max-width: 1440px) {
    grid-template-rows: 50px 32px;
    grid-row-gap: ${theme.offset3};
    grid-template-areas:
      "heading . action"
      "navigation navigation navigation";
  }
`
