import React from "react"
import { IconProps } from "./types"
import { Svg } from "./styles"

type Props = IconProps

export const IconDeleteSmall = (props: Props) => {
  return (
    <Svg color={props.color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.996 10.995">
      <g id="icon_Delete_Small_Black" transform="translate(-1738.5 -556.505)">
        <path
          id="Path_11"
          d="M502.614,117.558H498.52l-1.365-7.466h6.824Z"
          transform="translate(1243.431 449.442)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_12"
          d="M501.692,103.25h2.749l.916,1.255h-4.581Z"
          transform="translate(1240.931 453.755)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_4"
          x2="9.996"
          transform="translate(1739 558.26)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </Svg>
  )
}
