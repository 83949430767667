import React from "react"
import { IconProps } from "./types"
import { Svg } from "./styles"

type Props = IconProps

export const IconDuplicate = (props: Props) => {
  return (
    <Svg color={props.color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <g id="Icon_Duplicate_Green_Dark" transform="translate(-1065 -740)">
        <g id="Rectangle_157" transform="translate(1065 742)" fill="none" strokeWidth="1">
          <rect width="18" height="18" rx="4" stroke="none" />
          <rect x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="none" />
        </g>
        <g id="Rectangle_158" transform="translate(1067 740)" fill="#fff" strokeWidth="1">
          <rect width="18" height="18" rx="4" stroke="none" />
          <rect x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="none" />
        </g>
        <g id="Icon_" transform="translate(207)">
          <line
            id="Line_136"
            x1="10"
            transform="translate(863.999 749)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            id="Line_137"
            y1="10"
            transform="translate(868.999 744)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </Svg>
  )
}
