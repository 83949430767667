import { Action, AutosaveStatus } from "utils"

/**
 * Initial State
 */

export const quizQuestionsInitialState = {
  id: "",
  title: "...",
  autosaveStatus: "initial" as AutosaveStatus,
}

export type QuizQuestionsState = typeof quizQuestionsInitialState

/**
 * Action Types
 */

export const quizQuestionsActionTypes = {
  setQuizQuestionsData: "SET_QUIZ_QUESTIONS_DATA",
  setQuizQuestionsAutosaveStatus: "SET_QUIZ_QUESTIONS_AUTOSAVE_STATUS",
}

/**
 * Reducer
 */

export type QuizQuestionsActionData = {
  id?: string
  title?: string
  autosaveStatus?: AutosaveStatus
}

export const quizQuestionsReducer = (
  state: QuizQuestionsState = quizQuestionsInitialState,
  action: Action<QuizQuestionsActionData>
): QuizQuestionsState => {
  switch (action.type) {
    // Set Quiz Questions Data
    case quizQuestionsActionTypes.setQuizQuestionsData:
      return {
        ...quizQuestionsInitialState,
        ...action.data,
      }

    // Set Quiz Autosave Status
    case quizQuestionsActionTypes.setQuizQuestionsAutosaveStatus:
      if (action.data.autosaveStatus === state.autosaveStatus) {
        return state
      }
      return {
        ...state,
        autosaveStatus: action.data.autosaveStatus,
      }

    // Default
    default:
      return state
  }
}
