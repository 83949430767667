import React from "react"
import styled from "styled-components"
import { theme } from "theme"

type Props = {
  value: string
  children?: never
}

export const Tooltip = (props: Props) => {
  return (
    <Container>
      <Text>{props.value}</Text>
    </Container>
  )
}

const Container = styled("div")`
  display: inline-flex;
  position: relative;
  max-width: 300px;
  background-color: ${theme.color.textBlack};
  border-radius: ${theme.rounding1};
  transform: translate(-50%);

  &:before {
    content: "";
    width: 17px;
    height: 17px;
    position: absolute;
    bottom: -10px;
    left: 50%;
    background: ${theme.color.textBlack};
    transform: rotate(45deg) translateX(calc(-50% - 3px));
    z-index: -1;
    border-radius: 0 0 2px;
  }
`

const Text = styled("div")`
  display: initial;
  color: ${theme.color.white};
  font-size: 14px;
  font-weight: ${theme.font.weight.regular};
  padding: 8px 20px;
  text-align: center;
`
