import React from "react"
import { Modal, ModalSection } from "components/modal"
import { Portal } from "components/portal"
import { Heading2 } from "components/typography"
import { AnchorLink } from "components/links"
import { Button } from "components/button"

type Props = {
  children?: never
  isModalOpen: boolean
  confirmModal: () => void
  closeModal: () => void
}

export const LeaveModal = (props: Props) => {
  const { isModalOpen, closeModal, confirmModal } = props

  return (
    <Portal>
      <Modal focusIsTrapped={true} isVisible={isModalOpen}>
        <ModalSection>
          <Heading2>{`Are you sure you want to leave this page without saving your changes?`}</Heading2>
        </ModalSection>

        <ModalSection topOffset={"40px"}>
          <Button color={"green"} size={"regular"} variant={"filled"} onClick={confirmModal}>
            {`Leave this page`}
          </Button>
        </ModalSection>

        <ModalSection topOffset={"20px"}>
          <AnchorLink onClick={closeModal} color={"red"} weight={"regular"} size={"16px"}>
            {`Stay on this page`}
          </AnchorLink>
        </ModalSection>
      </Modal>
    </Portal>
  )
}
