import { client } from "model/client"
import {
  QuestionChangeVariables,
  QuestionCreateVariables,
  QuestionDeleteVariables,
  Questions,
  QuestionSwapVariables,
} from "model/types"
import {
  MUTATION_QUESTION_CHANGE,
  MUTATION_QUESTION_CREATE,
  MUTATION_QUESTION_DELETE,
  MUTATION_QUESTION_SWAP,
  QuestionCreateMutationUpdater,
  QuestionDeleteMutationUpdater,
} from "model/graphql/mutations"
import { cloneDeep } from "lodash"
import { QUERY_QUESTIONS } from "model/graphql/queries"

/**
 * Change Question
 * @param variables
 * @param successCallback
 */

export const changeQuestion = async (variables: QuestionChangeVariables, successCallback: () => void) => {
  try {
    await client.mutate({
      mutation: MUTATION_QUESTION_CHANGE,
      variables: {
        ...variables,
      },
    })
    if (successCallback) successCallback()
  } catch (error) {
    console.error(error)
  }
}

/**
 * Delete Question from Question Set
 * @param variables
 * @param handlerSuccess
 */

export const deleteQuestion = async (
  variables: { quizId: string } & QuestionDeleteVariables,
  handlerSuccess?: () => void
) => {
  try {
    await client.mutate({
      mutation: MUTATION_QUESTION_DELETE,
      variables,
      update: updateQuestionSetAfterDelete(variables.id, variables.quizId),
    })
    if (handlerSuccess) handlerSuccess()
  } catch (error) {
    console.error(error)
  }
}

const updateQuestionSetAfterDelete = (questionId: string, quizId: string): QuestionDeleteMutationUpdater => (
  cache,
  payload
) => {
  if (!payload.data.questionDelete) {
    return
  }
  const { questionSet } = payload.data.questionDelete.quiz
  try {
    const data: Questions = cloneDeep(cache.readQuery({ query: QUERY_QUESTIONS, variables: { id: quizId } }))
    data.questions.edges = questionSet.edges
    client.writeQuery({
      query: QUERY_QUESTIONS,
      variables: { id: quizId },
      data: data,
    })
  } catch (error) {
    console.error(error)
  }
}

/**
 * Add Question to Question Set
 * @param variables
 * @param successCallback
 */

export const createQuestion = async (
  variables: { quizId: string } & QuestionCreateVariables,
  successCallback: () => void
) => {
  try {
    await client.mutate({
      mutation: MUTATION_QUESTION_CREATE,
      variables,
      update: updateQuestionSetAfterAdd(variables.quizId),
    })
    if (successCallback) successCallback()
  } catch (error) {
    console.error(error)
  }
}

const updateQuestionSetAfterAdd = (quizId: string): QuestionCreateMutationUpdater => (cache, payload) => {
  try {
    const { questionSet } = payload.data.questionCreate.quiz
    const data: Questions = cloneDeep(cache.readQuery({ query: QUERY_QUESTIONS, variables: { id: quizId } }))
    const newData: Questions = {
      ...data,
      questions: { ...data.questions, edges: [...questionSet.edges] },
    }
    client.writeQuery({
      query: QUERY_QUESTIONS,
      variables: { id: quizId },
      data: newData,
    })
  } catch (error) {
    console.error(error)
  }
}

/**
 * Swap Question
 * @param variables
 * @param successCallback
 */

export const swapQuestion = async (variables: QuestionSwapVariables, successCallback: () => void) => {
  try {
    await client.mutate({
      mutation: MUTATION_QUESTION_SWAP,
      variables: {
        ...variables,
      },
    })
    if (successCallback) successCallback()
  } catch (error) {
    console.error(error)
  }
}
