import React from "react"
import styled from "styled-components"

type Props = {
  children: React.ReactNode
}

export const ActionContainer = (props: Props) => {
  return <Container>{props.children}</Container>
}

const Container = styled("div")`
  display: grid;
  grid-template-rows: 20px;
  grid-auto-columns: 20px;
  grid-auto-flow: column;
  justify-content: end;
`
