import React from "react"
import styled, { createGlobalStyle, css } from "styled-components"
import { IconClose } from "components/icons"
import { theme } from "theme"
import { animated, config, useSpring, useTransition } from "react-spring"
import FocusTrap from "focus-trap-react"

type Props = {
  isVisible?: boolean
  closeAction?: () => void
  children: React.ReactNode
  focusIsTrapped?: boolean
  isFullSized?: boolean
}

export const Modal = (props: Props) => {
  const { focusIsTrapped, isVisible = false, isFullSized = false } = props
  const containerTransitionAnimations = useTransition(isVisible, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.gentle,
  })

  const boxSpringAnimation = useSpring({
    transform: isVisible ? `scale(1)` : `scale(0.9)`,
    config: config.wobbly,
    height: isFullSized ? "100%" : "auto",
  })

  return (
    <React.Fragment>
      {containerTransitionAnimations.map(
        containerTransitionAnimation =>
          containerTransitionAnimation.item && (
            <AnimatedContainerWrapper key={containerTransitionAnimation.key} style={containerTransitionAnimation.props}>
              <Container isFullSized={isFullSized}>
                <GlobalStyle />
                {focusIsTrapped ? (
                  <FocusTrap active={isVisible}>
                    <AnimatedBoxWrapper style={boxSpringAnimation}>
                      <Box isFullSized={isFullSized}>
                        {props.children}
                        {props.closeAction && (
                          <CloseButton onClick={props.closeAction} tabIndex={-1}>
                            <IconClose />
                          </CloseButton>
                        )}
                      </Box>
                    </AnimatedBoxWrapper>
                  </FocusTrap>
                ) : (
                  <AnimatedBoxWrapper style={boxSpringAnimation}>
                    <Box isFullSized={isFullSized}>
                      {props.children}
                      {props.closeAction && (
                        <CloseButton onClick={props.closeAction}>
                          <IconClose />
                        </CloseButton>
                      )}
                    </Box>
                  </AnimatedBoxWrapper>
                )}
              </Container>
            </AnimatedContainerWrapper>
          )
      )}
    </React.Fragment>
  )
}

const AnimatedContainerWrapper = styled(animated.div)`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  height: 100vh;
  z-index: 9999;
`

type ContainerProps = {
  isFullSized: boolean
}

const Container = styled("div")`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  align-items: ${(props: ContainerProps) => (props.isFullSized ? "flex-start" : "center")};
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  padding: ${theme.offset1};
  overflow-x: hidden;
  overflow-y: scroll;
`

const AnimatedBoxWrapper = styled(animated.div)`
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
`

type BoxProps = {
  isFullSized: boolean
}

const Box = styled("div")`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: ${theme.color.white};
  border-radius: ${theme.rounding2};
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.25);
  padding: ${theme.offset3};
  text-align: center;
  ${(props: BoxProps) =>
    props.isFullSized
      ? css`
          width: 100%;
          max-width: 1240px;
          margin-top: 7vh;
          margin-bottom: 7vh;
          height: 100%;
          max-height: calc(100vh - 2 * 7vh - 2 * ${theme.offset3});
          min-height: 400px;
        `
      : ""};
`

const CloseButton = styled("button")`
  display: flex;
  position: absolute;
  width: 20px;
  height: 20px;
  top: ${theme.offset3};
  right: ${theme.offset3};
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  svg {
    stroke: ${theme.color.textNonactive};
    transition: stroke 200ms ease-in-out;
  }

  &:hover svg {
    stroke: ${theme.color.textBlack};
  }
`

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`
