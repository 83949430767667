import React from "react"
import styled, { css } from "styled-components"
import { Heading1 } from "components/typography"
import { ActionWrapper, HeadingWrapper, NavigationWrapper, SearchWrapper } from "components/page/wrappers"
import { Button } from "components/button"
import { IconPlus } from "components/icons"
import { Search } from "components/search"
import { theme } from "theme"
import { Tab } from "components/tab"
import { truncate } from "lodash"

type Props = {
  heading: string
  search?: {
    value: string
    onChange: (value: string) => void
    placeholder?: string
  }
  navigationTabs?: {
    name: string
    to: string
    notificationCount: number
  }[]
  button: {
    title: string
    action: () => void
    isDisabled?: boolean
  }
  children?: never
}

export const PageHeader = (props: Props) => {
  const { heading, button, search, navigationTabs } = props

  return (
    <Container hasNavigation={Boolean(navigationTabs)}>
      {/* Heading */}
      <HeadingWrapper>
        <Heading1>{truncate(heading)}</Heading1>
      </HeadingWrapper>

      {/* Search */}
      {search && (
        <SearchWrapper>
          <Search
            value={search.value}
            onChange={search.onChange}
            placeholder={search.placeholder ? search.placeholder : "Search"}
          />
        </SearchWrapper>
      )}

      {/* Navigation */}
      {navigationTabs && (
        <NavigationWrapper>
          {navigationTabs.map((tab, index) => (
            <Tab key={tab.name + index} to={tab.to} notificationsCount={tab.notificationCount}>
              {tab.name}
            </Tab>
          ))}
        </NavigationWrapper>
      )}

      {/* Action */}
      <ActionWrapper>
        <Button
          icon={<IconPlus />}
          color={"green"}
          size={"regular"}
          variant={"filled"}
          type={"button"}
          onClick={button.action}
          isDisabled={button.isDisabled}
        >
          {button.title}
        </Button>
      </ActionWrapper>
    </Container>
  )
}

/**
 * Styles
 */

type ContainerProps = {
  hasNavigation: boolean
}

const Container = styled("div")`
  display: grid;
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  height: 130px;
  grid-template-columns: max-content minmax(200px, 400px) 1fr max-content;
  grid-template-rows: 50px;
  grid-template-areas: "heading search navigation action";
  grid-column-gap: ${theme.offset3};
  padding-top: ${theme.offset3};
  padding-bottom: ${theme.offset3};
  padding-left: ${theme.offset4};
  padding-right: ${theme.offset4};
  z-index: 990;

  background-image: linear-gradient(0deg, rgba(255, 255, 255, 0) 0, ${theme.color.backgroundGray} 25%);
  background-origin: padding-box;
  background-clip: border-box;
  background-size: auto auto;
  background-position: 50% 50%;

  ${(props: ContainerProps) =>
    props.hasNavigation
      ? css`
          @media all and (max-width: 1440px) {
            height: 202px;
            grid-template-rows: 50px 32px;
            grid-row-gap: ${theme.offset3};
            grid-template-areas:
              "heading search . action"
              "navigation navigation navigation navigation";
          }
        `
      : ""};
`
