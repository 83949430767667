import React, { useState } from "react"
import { Redirect, RouteComponentProps } from "react-router"
import { routes } from "router"
import { Grid, ParagraphWrapper } from "../screen"
import { Heading1, Paragraph } from "components/typography"
import { Box } from "components/box"
import { FormRow } from "components/form-row"
import { Input } from "components/input"
import { Button } from "components/button"
import { useActivateSuccessModal, useChangePageTitle } from "utils"
import {
  MUTATION_RESET_PASSWORD,
  ResetPasswordMutation,
  ResetPasswordMutationResult,
  useResetPassword,
  UseResetPasswordArgs,
} from "model/graphql/mutations"
import { Form } from "components/form"
import { Mutation } from "react-apollo"
import { Warning } from "components/warning"
import { Label } from "components/label"
import { useSelector } from "react-redux"
import { ReduxState } from "redux-store"
import { isEqual } from "lodash"

type Props = {
  children?: never
} & RouteComponentProps<{ passwordResetCode: string }>

export const PasswordResetScreen = (props: Props) => {
  const { passwordResetCode } = props.match.params
  const [password, setPassword] = useState("")
  const [warning, setWarning] = useState("")
  const auth = useSelector((state: ReduxState) => state.auth, isEqual)
  const { from } = props.location.state || { from: { pathname: routes.root } }

  /**
   * Change Page Title
   */

  useChangePageTitle(props.match.path)

  /**
   * Change Password
   */

  const handlePasswordChange = (value: string) => {
    setPassword(value)

    if (warning !== "") {
      setWarning("")
    }
  }

  /**
   * Reset Password
   */

  const { activateSuccessModal } = useActivateSuccessModal()

  const handleSuccess: UseResetPasswordArgs["onSuccessAction"] = () => {
    activateSuccessModal("The password has been changed.")
    props.history.replace(routes.login)
  }

  const handleError: UseResetPasswordArgs["onErrorAction"] = errorMessage => {
    if (/no_access/.test(errorMessage)) {
      return setWarning("User does not have access to requested attribute")
    }
    if (/password_not_valid/.test(errorMessage)) {
      return setWarning("Password is not valid")
    }
    if (/password_reset_code_expired/.test(errorMessage)) {
      return setWarning("Password reset code expired")
    }
    if (/password_reset_code_not_valid/.test(errorMessage)) {
      return setWarning("Password reset code not valid")
    }
    return setWarning(errorMessage)
  }

  const { resetPassword } = useResetPassword({
    onSuccessAction: handleSuccess,
    onErrorAction: handleError,
  })

  const handleSubmit = (resetPasswordMutation: ResetPasswordMutation) => async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault()
    await resetPassword(resetPasswordMutation, { passwordResetCode, password })
  }

  /**
   * Redirect if user is authenticated
   */

  if (auth.isAuthenticated) return <Redirect to={from} />

  return (
    <Grid type={1}>
      <Heading1>{`Reset your password`}</Heading1>
      <Box>
        <Mutation mutation={MUTATION_RESET_PASSWORD}>
          {(resetPasswordMutation: ResetPasswordMutation, { loading }: ResetPasswordMutationResult) => (
            <Form method={"post"} onSubmit={handleSubmit(resetPasswordMutation)} type={1}>
              <ParagraphWrapper>
                <Paragraph>{`Please, set your new password`}</Paragraph>
              </ParagraphWrapper>

              <FormRow>
                <Label>{"Password"}</Label>
                <Input type={"password"} value={password} onChange={handlePasswordChange} isDisabled={false} />
                <Warning message={warning} />
              </FormRow>

              <Button
                type={"submit"}
                size={"regular"}
                color={"green"}
                variant={"filled"}
                isDisabled={!password || loading}
              >
                {`Save`}
              </Button>
            </Form>
          )}
        </Mutation>
      </Box>
    </Grid>
  )
}
