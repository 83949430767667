import React, { useState } from "react"
import { Redirect, RouteComponentProps } from "react-router"
import { routes } from "router"
import { Box } from "components/box"
import { Heading1, Paragraph } from "components/typography"
import { Button } from "components/button"
import { FormRow } from "components/form-row"
import { Input } from "components/input"
import { Grid, ParagraphWrapper } from "../screen"
import { useChangePageTitle } from "utils"
import { Form } from "components/form"
import {
  MUTATION_REQUEST_PASSWORD_RESET,
  RequestPasswordResetMutation,
  RequestPasswordResetMutationResponse,
  useRequestPasswordReset,
  UseRequestPasswordResetArgs,
} from "model/graphql/mutations"
import { Mutation } from "react-apollo"
import { Warning } from "components/warning"
import { Label } from "components/label"
import { useSelector } from "react-redux"
import { ReduxState } from "redux-store"
import { isEqual } from "lodash"

type Props = {
  children?: never
} & RouteComponentProps

export const PasswordRecoveryScreen = (props: Props) => {
  const [email, setEmail] = useState("")
  const [warning, setWarning] = useState("")
  const auth = useSelector((state: ReduxState) => state.auth, isEqual)
  const { from } = props.location.state || { from: { pathname: routes.root } }

  /**
   * Change Page Title
   */

  useChangePageTitle(props.match.path)

  /**
   * Change Email
   */

  const handleEmailChange = (value: string) => {
    setEmail(value)

    if (warning !== "") {
      setWarning("")
    }
  }

  /**
   * Request Password Reset
   */

  const handleSuccess: UseRequestPasswordResetArgs["onSuccessAction"] = () => {
    props.history.replace(routes.login)
  }

  const handleError: UseRequestPasswordResetArgs["onErrorAction"] = errorMessage => {
    if (/email_not_valid/.test(errorMessage)) {
      return setWarning("Email is not valid")
    }
    if (/no_access/.test(errorMessage)) {
      return setWarning("User does not have access to requested attribute")
    }
    return setWarning(errorMessage)
  }

  const { requestPasswordReset } = useRequestPasswordReset({
    onSuccessAction: handleSuccess,
    onErrorAction: handleError,
  })

  const handleSubmit = (requestPasswordResetMutation: RequestPasswordResetMutation) => async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault()

    await requestPasswordReset(requestPasswordResetMutation, { email })
  }

  /**
   * Redirect if user is authenticated
   */

  if (auth.isAuthenticated) return <Redirect to={from} />

  return (
    <Grid type={1}>
      <Heading1>{`I forgot my password`}</Heading1>
      <Box>
        <Mutation mutation={MUTATION_REQUEST_PASSWORD_RESET}>
          {(
            requestPasswordResetMutation: RequestPasswordResetMutation,
            { loading }: RequestPasswordResetMutationResponse
          ) => (
            <Form method={"post"} onSubmit={handleSubmit(requestPasswordResetMutation)} type={1}>
              <ParagraphWrapper>
                <Paragraph>
                  {`Don’t worry, just enter your e-mail\nand we’ll send you a link to recover your password.`}
                </Paragraph>
              </ParagraphWrapper>

              <FormRow>
                <Label>{"Your email"}</Label>
                <Input value={email} onChange={handleEmailChange} isDisabled={false} />
                <Warning message={warning} />
              </FormRow>

              <Button
                type={"submit"}
                color={"green"}
                variant={"filled"}
                size={"regular"}
                isDisabled={!email || loading}
              >
                {`Recover my Password`}
              </Button>
            </Form>
          )}
        </Mutation>
      </Box>
    </Grid>
  )
}
