import React, { useEffect, useState } from "react"
import { actionTypes as formActionTypes, useFormState } from "./form-state"
import styled from "styled-components"
import { Box } from "components/box"
import { Label } from "components/label"
import { RoundButton } from "components/round-button"
import { IconDown } from "components/icons"
import { NewQuestion } from "components/new-question"
import { theme } from "theme"
import { ActionButton, ActionContainer } from "components/table"
import { debounce, isEqual } from "lodash"
import {
  getAnswerDataFromAnswerSet,
  getFormStateVariables,
  prependPrefixToImageSrc,
  QuestionToDelete,
  setAutosaveStatus,
} from "./helpers"
import {
  QuestionChangeVariables,
  QuestionCreateVariables,
  Questions_questions_edges_node,
  QuestionSwapVariables,
} from "model/types"
import { AnswerTextarea, Textarea } from "components/textarea"
import { changeQuestion, createQuestion, swapQuestion } from "model/question"
import { QuestionImageArea } from "screens/quiz/questions/question-image-area"
import { useDispatch, useSelector } from "react-redux"
import { ReduxState } from "redux-store"

/**
 * Debounced Question Mutations
 */

const debouncedSwapQuestion = debounce(
  (variables: QuestionSwapVariables, successCallback: () => void) => swapQuestion(variables, successCallback),
  500,
  {
    leading: true,
    trailing: false,
  }
)

const debouncedChangeQuestion = debounce(
  (variables: QuestionChangeVariables, successCallback: () => void) => changeQuestion(variables, successCallback),
  1000,
  {
    leading: false,
    trailing: true,
  }
)

const debouncedCreateQuestion = debounce(
  (variables: { quizId: string } & QuestionCreateVariables, successCallback: () => void) =>
    createQuestion(variables, successCallback),
  2000,
  {
    leading: true,
    trailing: false,
  }
)

/**
 * Question PropTypes
 */

type QuestionProps = {
  data: Questions_questions_edges_node
  totalQuestionCount: number
  children?: never
  openDeleteModal: (question: QuestionToDelete) => void
}

/**
 * Question Form Component
 * @param props
 * @constructor
 */

export const Form = (props: QuestionProps) => {
  const { data, openDeleteModal, totalQuestionCount } = props
  const { formState, formDispatch } = useFormState()
  const quiz = useSelector((state: ReduxState) => state.quizQuestions, isEqual)
  const dispatch = useDispatch()

  const [question, setQuestion] = useState(data.question)

  /**
   * Set Form State from Received Question Data
   */

  //Quiz ID
  useEffect(() => {
    formDispatch({ type: formActionTypes.setQuizId, data: { quizId: data.quiz.id } })
  }, [formDispatch, data.quiz.id])

  //Question ID
  useEffect(() => {
    formDispatch({ type: formActionTypes.setQuestionId, data: { questionId: data.id } })
  }, [formDispatch, data.id])

  //Question Order
  useEffect(() => {
    formDispatch({ type: formActionTypes.setOrder, data: { order: data.order } })
  }, [formDispatch, data.order])

  //Question Type
  useEffect(() => {
    if (data.type)
      formDispatch({ type: formActionTypes.setQuestionType, data: { questionType: data.type.toLowerCase() } })
  }, [formDispatch, data.type])

  //Question
  useEffect(() => {
    formDispatch({ type: formActionTypes.setQuestion, data: { question: data.question } })
  }, [formDispatch, data.question])

  //Correct Question Answer
  useEffect(() => {
    formDispatch({
      type: formActionTypes.setCorrectAnswerText,
      data: {
        correctAnswerText: getAnswerDataFromAnswerSet(data.answerSet, "text", true),
      },
    })
  }, [data.answerSet, formDispatch])

  useEffect(() => {
    const correctAnswerImagePreview = getAnswerDataFromAnswerSet(data.answerSet, "image", true)
    formDispatch({
      type: formActionTypes.setCorrectAnswerImagePreview,
      data: {
        correctAnswerImagePreview: correctAnswerImagePreview
          ? prependPrefixToImageSrc(process.env.REACT_APP_MEDIA_URL_PREFIX, correctAnswerImagePreview)
          : undefined,
      },
    })
  }, [data.answerSet, formDispatch])

  //Wrong Question Answer
  useEffect(() => {
    formDispatch({
      type: formActionTypes.setWrongAnswerText,
      data: {
        wrongAnswerText: getAnswerDataFromAnswerSet(data.answerSet, "text", false),
      },
    })
  }, [data.answerSet, formDispatch])

  //Wrong Question Image
  useEffect(() => {
    const wrongAnswerImagePreview = getAnswerDataFromAnswerSet(data.answerSet, "image", false)
    formDispatch({
      type: formActionTypes.setWrongAnswerImagePreview,
      data: {
        wrongAnswerImagePreview: wrongAnswerImagePreview
          ? prependPrefixToImageSrc(process.env.REACT_APP_MEDIA_URL_PREFIX, wrongAnswerImagePreview)
          : undefined,
      },
    })
  }, [formDispatch, data.answerSet])

  //Explain Question Answer
  useEffect(() => {
    formDispatch({ type: formActionTypes.setAnswerExplain, data: { answerExplain: data.answerExplain } })
  }, [data.answerExplain, formDispatch])

  //Explain Question Image
  useEffect(() => {
    const explainImagePreview = data.explainImage
      ? prependPrefixToImageSrc(process.env.REACT_APP_MEDIA_URL_PREFIX, data.explainImage)
      : undefined
    formDispatch({ type: formActionTypes.setExplainImagePreview, data: { explainImagePreview: explainImagePreview } })
  }, [data.explainImage, formDispatch])

  //Question Image
  useEffect(() => {
    const imagePreview = data.image
      ? prependPrefixToImageSrc(process.env.REACT_APP_MEDIA_URL_PREFIX, data.image)
      : undefined
    formDispatch({ type: formActionTypes.setImagePreview, data: { imagePreview: imagePreview } })
  }, [data.image, formDispatch])

  /**
   *  ****** START EVENT HANDLERS ******
   */

  const handleAddOneClick = async () => {
    setAutosaveStatus({ dispatch, status: "saving" })
    await debouncedCreateQuestion(
      {
        quizId: formState.quizId,
        order: formState.order + 1,
        question: "New Question",
      },
      () => setAutosaveStatus({ dispatch, status: "saved" })
    )
  }

  const setActionVisibility = (value: boolean) => () => {
    formDispatch({ type: formActionTypes.setIsActionsVisible, data: { isActionsVisible: value } })
  }

  const handleAddTenClick = async () => {
    setAutosaveStatus({ dispatch, status: "saving" })
    await debouncedCreateQuestion(
      {
        quizId: formState.quizId,
        order: formState.order + 1,
        question: "New Question",
        ten: true,
      },
      () => setAutosaveStatus({ dispatch, status: "saved" })
    )
  }
  const handleQuestionOnChange = async (question: string) => {
    setQuestion(question)

    setAutosaveStatus({ dispatch, status: "saving" })

    formDispatch({ type: formActionTypes.setQuestion, data: { question: question } })

    await debouncedChangeQuestion(
      {
        ...getFormStateVariables(formState),
        question: question,
      },
      () => setAutosaveStatus({ dispatch, status: "saved" })
    )
  }

  const handleQuestionUpClick = async () => {
    setAutosaveStatus({ dispatch, status: "saving" })
    await debouncedSwapQuestion(
      {
        id: formState.questionId,
        order: formState.order - 1,
      },
      () => setAutosaveStatus({ dispatch, status: "saved" })
    )
  }

  const handleQuestionDownClick = async () => {
    setAutosaveStatus({ dispatch, status: "saving" })
    await debouncedSwapQuestion(
      {
        id: formState.questionId,
        order: formState.order + 1,
      },
      () => setAutosaveStatus({ dispatch, status: "saved" })
    )
  }

  const handleCorrectAnswerOnChange = async (correctAnswerText: string) => {
    setAutosaveStatus({ dispatch, status: "saving" })
    formDispatch({ type: formActionTypes.setCorrectAnswerText, data: { correctAnswerText: correctAnswerText } })
    await debouncedChangeQuestion(
      {
        ...getFormStateVariables(formState),
        correctAnswerText: correctAnswerText,
      },
      () => setAutosaveStatus({ dispatch, status: "saved" })
    )
  }

  const handleWrongAnswerOnChange = async (wrongAnswerText: string) => {
    setAutosaveStatus({ dispatch, status: "saving" })
    formDispatch({ type: formActionTypes.setWrongAnswerText, data: { wrongAnswerText: wrongAnswerText } })
    await debouncedChangeQuestion(
      {
        ...getFormStateVariables(formState),
        wrongAnswerText: wrongAnswerText,
      },
      () => setAutosaveStatus({ dispatch, status: "saved" })
    )
  }

  const handleAnswerExplainOnChange = async (answerExplainText: string) => {
    setAutosaveStatus({ dispatch, status: "saving" })
    formDispatch({ type: formActionTypes.setAnswerExplain, data: { answerExplain: answerExplainText } })
    await debouncedChangeQuestion(
      {
        ...getFormStateVariables(formState),
        answerExplain: answerExplainText,
      },
      () => setAutosaveStatus({ dispatch, status: "saved" })
    )
  }

  /**
   *  ****** END EVENT HANDLERS ******
   */

  return (
    <Container>
      {/*Question Order*/}
      <QuestionNumber>{data.order < 10 ? `0${data.order}` : data.order}</QuestionNumber>
      <Box>
        <StyledForm onMouseEnter={setActionVisibility(true)} onMouseLeave={setActionVisibility(false)}>
          <React.Fragment>
            {/*Question*/}
            <FormRow gridArea={"question"}>
              <Label>{"Question"}</Label>
              <Textarea hasMinimalHeight={true} value={question} onChange={handleQuestionOnChange} />
            </FormRow>
            {/*Right Answer*/}
            <FormRow gridArea={"correct-answer"}>
              <Label>{"Right Answer"}</Label>
              <AnswerTextarea
                answerType={"YES"}
                placeholder={"Yes"}
                value={formState.correctAnswerText}
                onChange={handleCorrectAnswerOnChange}
              />
            </FormRow>
            {/*Wrong Answer*/}
            <FormRow gridArea={"wrong-answer"}>
              <Label>{"Wrong Answer"}</Label>
              <AnswerTextarea
                answerType={"NO"}
                placeholder={"No"}
                value={formState.wrongAnswerText}
                onChange={handleWrongAnswerOnChange}
              />
            </FormRow>
            {/*Explanation*/}
            <FormRow gridArea={"explanation"}>
              <Label>{"Explanation"}</Label>
              <Textarea value={formState.answerExplain} onChange={handleAnswerExplainOnChange} hasStaticHeight={true} />
            </FormRow>
            <QuestionImageArea formDispatch={formDispatch} formState={formState} />
          </React.Fragment>
        </StyledForm>
      </Box>

      {/*Form Actions*/}
      <QuestionActionArea
        onMouseEnter={setActionVisibility(true)}
        onMouseLeave={setActionVisibility(false)}
        isVisible={formState.isActionsVisible}
      >
        {/*Move Up*/}
        <StyledActionWrapper gridArea={"up"}>
          <RoundButton onClick={handleQuestionUpClick} rotate={"180deg"} isDisabled={formState.order === 1}>
            <IconDown />
          </RoundButton>
        </StyledActionWrapper>

        {/*Move Down*/}
        <StyledActionWrapper gridArea={"down"}>
          <RoundButton onClick={handleQuestionDownClick} isDisabled={formState.order === totalQuestionCount}>
            <IconDown />
          </RoundButton>
        </StyledActionWrapper>

        {/*Delete Question Button*/}
        <StyledActionWrapper gridArea={"delete"}>
          <ActionContainer>
            <ActionButton
              actionType={"delete"}
              onClick={() =>
                openDeleteModal({
                  order: formState.order,
                  id: formState.questionId,
                  questionTitle: formState.question,
                  quizId: quiz.id,
                })
              }
              isDefaultVisible={formState.isActionsVisible}
              isDisabled={totalQuestionCount === 1}
            />
          </ActionContainer>
        </StyledActionWrapper>
      </QuestionActionArea>

      {/*Add New Question Actions*/}
      <AddQuestionArea>
        <NewQuestion
          leftButton={{
            text: "1 more",
            onClick: handleAddOneClick,
          }}
          rightButton={{
            text: "10 more",
            onClick: handleAddTenClick,
          }}
        />
      </AddQuestionArea>
    </Container>
  )
}

/**
 * Prevent re-Render
 */

export const QuestionForm = React.memo(Form)

/**
 * Styled Components
 */

type StyledProps = {
  gridArea?: string
  isVisible?: boolean
}

const Container = styled("div")`
  display: grid;
  position: relative;
  grid-template-columns: min-content 1000px min-content;
  grid-template-rows: 460px 50px;
  grid-template-areas:
    "order form actions"
    ". new .";
`

const AddQuestionArea = styled("div")`
  padding-top: 10px;
  padding-bottom: 10px;
  width: max-content;
  grid-area: new;
`

const QuestionActionArea = styled("div")`
  display: grid;
  position: relative;
  align-self: center;
  margin-left: -20px;
  align-items: center;
  grid-template-rows: 40px 40px;
  grid-template-columns: 40px 40px;
  grid-row-gap: 10px;
  grid-template-areas:
    "up delete"
    "down delete";
  grid-area: actions;
  visibility: ${(props: StyledProps) => (props.isVisible ? "visible" : "hidden")};
`

const QuestionNumber = styled("div")`
  display: grid;
  position: relative;
  align-self: center;
  padding-right: 40px;
  font-size: 60px;
  font-weight: ${theme.font.weight.bold};
  color: ${theme.color.borderGray};

  grid-area: order;
`

const StyledForm = styled("form")`
  display: grid;
  position: relative;
  padding: 40px;

  grid-template-columns: 680px 200px;
  grid-row-gap: 20px;
  grid-column-gap: 40px;

  grid-template-areas:
    "question ."
    "correct-answer ."
    "wrong-answer ."
    "explanation ."
    "explanation .";

  grid-area: form;
`

const FormRow = styled("div")`
  display: grid;
  position: relative;
  grid-template-columns: 130px 550px;
  grid-template-rows: min-content;
  grid-template-areas:
    "label ."
    "warning warning";
  align-items: start;
  grid-area: ${(props: StyledProps) => props.gridArea};
`

const StyledActionWrapper = styled("div")`
  grid-area: ${(props: StyledProps) => props.gridArea};
`
