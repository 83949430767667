import React from "react"
import { Redirect, Route, Router as ReactRouter, Switch } from "react-router-dom"
import { LoginScreen } from "screens/login"
import { PasswordRecoveryScreen } from "screens/password-recovery"
import { VendorsScreen } from "screens/vendors"
import { Screen } from "screens/screen"
import { LayoutsScreen } from "screens/layouts"
import { LayoutScreen, VariantPreview } from "screens/layout"
import { CreateVendorScreen, EditVendorScreen } from "screens/vendor"
import { CreateUnitScreen, EditUnitScreen } from "screens/unit"
import { CreateRuleScreen, EditRuleScreen } from "screens/layout-rule"
import { LayoutRulesScreen } from "screens/layout-rules"
import { ModalProvider } from "providers/modal"
import { UnitsScreen } from "screens/units"
import { createBrowserHistory } from "history"
import { WelcomeScreen } from "screens/welcome"
import { PasswordResetScreen } from "screens/password-reset"
import { MyProfileScreen } from "screens/my-profile"
import { UserScreen } from "screens/user"
import { UsersScreen } from "screens/users"
import { QuizzesScreen } from "screens/quizzes/quizzes-screen"
import { RootScreen } from "screens/root-screen"
import { QuizGeneralScreen, QuizQuestionsScreen } from "screens/quiz"
import { useSelector } from "react-redux"
import { ReduxState } from "redux-store"
import { isEqual } from "lodash"

export const history = createBrowserHistory()

export const routes = {
  root: "/",

  welcome: "/welcome/:invitationCode",

  login: "/login",

  passwordRecovery: "/password-recovery",

  passwordReset: "/password-reset/:passwordResetCode",

  quizzes: "/quizzes/:tab",
  quiz: "/quizzes/:tab/:quizId/:quizTab",
  quizGeneral: "/quizzes/:tab/:quizId/general",
  quizQuestions: "/quizzes/:tab/:quizId/questions",

  users: "/users/:tab",
  user: "/users/:tab/:userId",

  vendors: "/vendors",
  vendorEdit: "/vendors/:vendorId",
  vendorCreate: "/vendors/new-vendor",

  units: "/vendors/:vendorId/units",
  unitEdit: "/vendors/:vendorId/units/:unitId",
  unitCreate: "/vendors/:vendorId/units/new-unit",

  layouts: "/layouts",
  layout: "/layouts/:layoutId/:variantId",
  layoutVariant: "/layouts/:layoutId/:variantId",

  layoutRules: "/layout-rules",
  layoutRuleEdit: "/layout-rules/:ruleId",
  layoutRuleCreate: "/layout-rules/new-rule",

  myProfile: "/my-profile",
}

export const usersTabs = {
  all: "all",
  admin: "admin",
  author: "author",
  adsManager: "ads-manager",
  invited: "invited",
}

export const quizzesTabs = {
  all: "all",
  published: "published",
  drafts: "drafts",
  myQuizzes: "my-quizzes",
}

const PrivateRoute = (props: Route["props"]) => {
  const auth = useSelector((state: ReduxState) => state.auth, isEqual)

  if (!auth.isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: routes.login,
          state: { from: props.location },
        }}
      />
    )
  }

  return <Route {...props} />
}

export const Router = () => {
  return (
    <ReactRouter history={history}>
      <Screen>
        <ModalProvider>
          <Switch>
            {/* Root */}
            <PrivateRoute exact={true} path={routes.root} component={RootScreen} />

            {/* Welcome */}
            <Route exact={true} path={routes.welcome} component={WelcomeScreen} />

            {/* Login */}
            <Route exact={true} path={routes.login} component={LoginScreen} />

            {/* Password Recovery */}
            <Route exact={true} path={routes.passwordRecovery} component={PasswordRecoveryScreen} />

            {/* Password Reset */}
            <Route exact={true} path={routes.passwordReset} component={PasswordResetScreen} />

            {/* Vendors */}
            <PrivateRoute exact={true} path={routes.vendors} component={VendorsScreen} />

            {/* Vendor (Create/Edit) */}
            <PrivateRoute exact={true} path={routes.vendorCreate} component={CreateVendorScreen} />
            <PrivateRoute exact={true} path={routes.vendorEdit} component={EditVendorScreen} />

            {/* Units */}
            <PrivateRoute exact={true} path={routes.units} component={UnitsScreen} />

            {/* Unit (Create/Edit */}
            <PrivateRoute exact={true} path={routes.unitCreate} component={CreateUnitScreen} />
            <PrivateRoute exact={true} path={routes.unitEdit} component={EditUnitScreen} />

            {/* Layouts */}
            <PrivateRoute exact={true} path={routes.layouts} component={LayoutsScreen} />

            {/* Layout */}
            <PrivateRoute
              path={routes.layout}
              render={props => (
                <LayoutScreen {...props}>
                  <PrivateRoute exact={true} path={routes.layoutVariant} component={VariantPreview} />
                </LayoutScreen>
              )}
            />

            {/* Layout-Rules */}
            <PrivateRoute exact={true} path={routes.layoutRules} component={LayoutRulesScreen} />

            {/* Layout-Rule (Create/Edit) */}
            <PrivateRoute exact={true} path={routes.layoutRuleCreate} component={CreateRuleScreen} />
            <PrivateRoute exact={true} path={routes.layoutRuleEdit} component={EditRuleScreen} />

            {/* Quizzes Screen */}
            <PrivateRoute exact={true} path={routes.quizzes} component={QuizzesScreen} />

            {/* Quiz General Screen */}
            <PrivateRoute exact={true} path={routes.quizGeneral} component={QuizGeneralScreen} />

            {/* Quiz Questions Screen */}
            <PrivateRoute exact={true} path={routes.quizQuestions} component={QuizQuestionsScreen} />

            {/* My Profile Screen */}
            <PrivateRoute exact={true} path={routes.myProfile} component={MyProfileScreen} />

            {/* Users Screen */}
            <PrivateRoute exact={true} path={routes.users} component={UsersScreen} />

            {/* User Screen */}
            <PrivateRoute exact={true} path={routes.user} component={UserScreen} />
          </Switch>
        </ModalProvider>
      </Screen>
    </ReactRouter>
  )
}
