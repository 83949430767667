import { debounce, includes, without } from "lodash"
import { ChangeQuizMutation } from "model/graphql/mutations"
import { Dispatch } from "react"
import { QuizGeneralDispatch, slugify } from "utils"
import { quizGeneralActionTypes, QuizGeneralState } from "redux-reducers"
import { ChangeQuizVariables } from "model/types"

/**
 * Debounce Change Function
 */

export const debounceFn = debounce(changeFn => changeFn(), 500)

/**
 * Save Quiz
 */

type SaveQuizArgs = {
  changeQuizMutation: ChangeQuizMutation
  variables: ChangeQuizVariables
  dispatch: Dispatch<QuizGeneralDispatch>
}

export const saveQuiz = async (args: SaveQuizArgs) => {
  const { changeQuizMutation, variables, dispatch } = args

  try {
    await changeQuizMutation({
      variables,
    })
    dispatch({ type: quizGeneralActionTypes.setQuizGeneralAutosaveStatus, data: { autosaveStatus: "saved" } })
  } catch (error) {
    console.error(error)
    dispatch({ type: quizGeneralActionTypes.setQuizGeneralAutosaveStatus, data: { autosaveStatus: "initial" } })
  }
}

/**
 * Convert Quiz State Data To Mutation Variables Object
 */

const getMutationVariables = (quizState: QuizGeneralState): ChangeQuizVariables => ({
  title: quizState.title,
  slug: quizState.slug,
  customSlug: quizState.isSlugCustom,
  featuredImage: quizState.featuredImageFile,
  deleteFeaturedImage: !quizState.featuredImagePreview,
  description: quizState.description,
  authorId: quizState.authorId,
  tagIds: quizState.tagIds,
  includeAtHomepage: quizState.includeAtHomepage,
  id: quizState.id,
  publishAt: !quizState.publishAt,
})

/**
 * Change Quiz Data
 */

type ChangeQuizDataArgs = {
  quizState: QuizGeneralState
  dispatch: Dispatch<QuizGeneralDispatch>
  changeQuizMutation: ChangeQuizMutation
  debounceFn: typeof debounceFn
}

// Change Quiz Title

export const changeQuizTitle = (args: ChangeQuizDataArgs) => (value: string) => {
  const { quizState, dispatch, changeQuizMutation, debounceFn } = args

  dispatch({ type: quizGeneralActionTypes.setQuizGeneralTitle, data: { title: value } })

  const canChangeSlug = !quizState.isSlugCustom && !quizState.isPublished

  const newSlug = slugify(value)
  if (canChangeSlug) {
    dispatch({ type: quizGeneralActionTypes.setQuizGeneralSlug, data: { slug: newSlug } })
  }

  const mutationVariables = getMutationVariables(quizState)

  if (value !== "") {
    dispatch({ type: quizGeneralActionTypes.setQuizGeneralAutosaveStatus, data: { autosaveStatus: "saving" } })

    debounceFn(() =>
      saveQuiz({
        dispatch,
        changeQuizMutation,
        variables: {
          ...mutationVariables,
          title: value,
          slug: canChangeSlug ? newSlug : quizState.slug,
        },
      })
    )
  }
}

// Change Custom Slug
export const changeQuizSlug = (args: ChangeQuizDataArgs) => (value: string) => {
  const { quizState, dispatch, changeQuizMutation, debounceFn } = args

  dispatch({ type: quizGeneralActionTypes.setQuizGeneralSlug, data: { slug: value } })
  if (!quizState.isSlugCustom) {
    dispatch({ type: quizGeneralActionTypes.setQuizGeneralIsSlugCustom, data: { isSlugCustom: true } })
  }
  dispatch({ type: quizGeneralActionTypes.setQuizGeneralSlug, data: { slug: slugify(value) } })

  if (value.length) {
    const mutationVariables = getMutationVariables(quizState)
    dispatch({ type: quizGeneralActionTypes.setQuizGeneralAutosaveStatus, data: { autosaveStatus: "saving" } })
    debounceFn(async () => {
      await saveQuiz({
        dispatch,
        changeQuizMutation,
        variables: {
          ...mutationVariables,
          slug: slugify(value),
        },
      })
    })
  }
}

// Change Quiz Description

export const changeQuizDescription = (args: ChangeQuizDataArgs) => (value: string) => {
  const { quizState, dispatch, changeQuizMutation, debounceFn } = args

  dispatch({ type: quizGeneralActionTypes.setQuizGeneralDescription, data: { description: value } })
  dispatch({ type: quizGeneralActionTypes.setQuizGeneralAutosaveStatus, data: { autosaveStatus: "saving" } })

  const mutationVariables = getMutationVariables(quizState)

  debounceFn(() =>
    saveQuiz({
      dispatch,
      changeQuizMutation,
      variables: {
        ...mutationVariables,
        description: value,
      },
    })
  )
}

// Change Quiz Tag Ids

export const changeQuizTagIds = (args: ChangeQuizDataArgs, tagIds: string[]) => (value: string) => {
  const { quizState, dispatch, changeQuizMutation, debounceFn } = args

  let newTagIds: string[]

  if (includes(tagIds, value)) {
    newTagIds = without(tagIds, value)
  } else {
    newTagIds = [...tagIds, value]
  }

  dispatch({ type: quizGeneralActionTypes.setQuizGeneralTagIds, data: { tagIds: newTagIds } })
  dispatch({ type: quizGeneralActionTypes.setQuizGeneralAutosaveStatus, data: { autosaveStatus: "saving" } })

  const mutationVariables = getMutationVariables(quizState)

  debounceFn(() =>
    saveQuiz({
      dispatch,
      changeQuizMutation,
      variables: {
        ...mutationVariables,
        tagIds: newTagIds,
      },
    })
  )
}

// Change Author Id

export const changeQuizAuthorId = (args: ChangeQuizDataArgs) => (author: { id: string; value: string }) => {
  const { quizState, dispatch, changeQuizMutation, debounceFn } = args

  dispatch({ type: quizGeneralActionTypes.setQuizGeneralAuthorId, data: { authorId: author.id } })
  dispatch({
    type: quizGeneralActionTypes.setQuizGeneralSelectedAuthor,
    data: { selectedAuthor: { id: author.id, name: author.value } },
  })
  dispatch({ type: quizGeneralActionTypes.setQuizGeneralAutosaveStatus, data: { autosaveStatus: "saving" } })

  const mutationVariables = getMutationVariables(quizState)

  debounceFn(() =>
    saveQuiz({
      dispatch,
      changeQuizMutation,
      variables: {
        ...mutationVariables,
        authorId: author.id,
      },
    })
  )
}

// Change Include At Homepage

export const changeQuizIncludeAtHomepage = (args: ChangeQuizDataArgs) => ({ state: value }: { state?: boolean }) => {
  const { quizState, dispatch, changeQuizMutation, debounceFn } = args

  dispatch({ type: quizGeneralActionTypes.setQuizGeneralIncludeAtHomepage, data: { includeAtHomepage: value } })
  dispatch({ type: quizGeneralActionTypes.setQuizGeneralAutosaveStatus, data: { autosaveStatus: "saving" } })

  const mutationVariables = getMutationVariables(quizState)

  debounceFn(() =>
    saveQuiz({
      dispatch,
      changeQuizMutation,
      variables: {
        ...mutationVariables,
        includeAtHomepage: value,
      },
    })
  )
}

// Change Featured Image Preview

export const changeQuizFeaturedImagePreview = (args: { dispatch: Dispatch<QuizGeneralDispatch> }) => (
  value: string
) => {
  const { dispatch } = args

  dispatch({ type: quizGeneralActionTypes.setQuizGeneralFeaturedImagePreview, data: { featuredImagePreview: value } })
}

// Change Featured Image File

export const changeQuizFeaturedImageFile = (args: ChangeQuizDataArgs) => (value: File) => {
  const { quizState, dispatch, changeQuizMutation, debounceFn } = args

  dispatch({ type: quizGeneralActionTypes.setQuizGeneralFeaturedImageFile, data: { featuredImageFile: value } })
  dispatch({ type: quizGeneralActionTypes.setQuizGeneralAutosaveStatus, data: { autosaveStatus: "saving" } })

  const mutationVariables = getMutationVariables(quizState)

  debounceFn(() =>
    saveQuiz({
      dispatch,
      changeQuizMutation,
      variables: {
        ...mutationVariables,
        featuredImage: value,
        deleteFeaturedImage: !value,
      },
    })
  )
}

// Change Quiz Publish At

export const changeQuizPublishAt = (args: ChangeQuizDataArgs) => (value: string) => {
  const { quizState, dispatch, changeQuizMutation, debounceFn } = args

  dispatch({ type: quizGeneralActionTypes.setQuizGeneralPublishAt, data: { publishAt: value } })
  dispatch({ type: quizGeneralActionTypes.setQuizGeneralAutosaveStatus, data: { autosaveStatus: "saving" } })

  const mutationVariables = getMutationVariables(quizState)

  debounceFn(() =>
    saveQuiz({
      dispatch,
      changeQuizMutation,
      variables: {
        ...mutationVariables,
        publishAt: value,
      },
    })
  )
}
