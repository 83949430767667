import React from "react"
import styled from "styled-components"
import { theme } from "theme"

type Props = {
  isFocused: boolean
  hasWarning: boolean
  isDisabled: boolean
  hasNoLeftBorder: boolean
  children: React.ReactNode
}

export const TextField = (props: Props) => {
  const { children, ...rest } = props

  return <Container {...rest}>{children}</Container>
}

type ContainerProps = {
  isFocused: boolean
  hasWarning: boolean
  isDisabled: boolean
  hasNoLeftBorder: boolean
}

const Container = styled("div")`
  display: flex;
  position: relative;
  width: 100%;
  border-top-left-radius: ${(props: ContainerProps) => (props.hasNoLeftBorder ? "0" : theme.rounding1)};
  border-top-right-radius: ${theme.rounding1};
  border-bottom-left-radius: ${(props: ContainerProps) => (props.hasNoLeftBorder ? "0" : theme.rounding1)};
  border-bottom-right-radius: ${theme.rounding1};
  background-color: ${theme.color.white};
  border-style: solid;
  border-color: ${(props: ContainerProps) => {
    if (props.isDisabled && !props.hasWarning) {
      return theme.color.backgroundGray
    }
    if (props.isDisabled && props.hasWarning) {
      return theme.color.warningRedLight
    }
    if (props.isFocused && !props.hasWarning) {
      return theme.color.textNonactive
    }
    if (props.isFocused && props.hasWarning) {
      return theme.color.warningRedDark
    }
    if (props.hasWarning) {
      return theme.color.warningRed
    }
    return theme.color.borderGray
  }};
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: ${(props: ContainerProps) => (props.hasNoLeftBorder ? "0" : "1px")};
  ${(props: ContainerProps) => (props.isDisabled ? "cursor: not-allowed" : "")};
  overflow: hidden;
`
