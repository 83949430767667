import React from "react"
import { Query, QueryResult } from "react-apollo"
import { QUERY_LAYOUT } from "model/graphql/queries"
import { Layout, LayoutVariables } from "model/types"
import { RouteComponentProps } from "react-router"
import { Error, Loading } from "components/data-status"
import { LayoutStoreProvider } from "./layout-context"
import { routes } from "router"
import { VariantSwitch } from "screens/layout/variant-switch"
import styled from "styled-components"
import { useChangePageTitle } from "utils"
import { capitalize } from "lodash"
import { ContentWrapper, DataStatusWrapper, PageContainer, SubPageHeader } from "components/page"

type Props = {
  children: React.ReactNode
} & RouteComponentProps<{ layoutId: string; variantId: string }>

export const LayoutScreen = (props: Props) => {
  const { children, ...rest } = props
  const { layoutId } = props.match.params

  return (
    <LayoutStoreProvider>
      <PageContainer>
        <Query query={QUERY_LAYOUT} variables={{ id: layoutId } as LayoutVariables}>
          {({ error, loading, data }: QueryResult<Layout>) => {
            if (error) {
              return (
                <DataStatusWrapper>
                  <Error>{error.message}</Error>
                </DataStatusWrapper>
              )
            }

            if (!data || !data.layout || loading) {
              return (
                <DataStatusWrapper>
                  <Loading>Loading...</Loading>
                </DataStatusWrapper>
              )
            }

            return (
              <ScreenContent {...rest} data={data}>
                {children}
              </ScreenContent>
            )
          }}
        </Query>
      </PageContainer>
    </LayoutStoreProvider>
  )
}

/**
 * Screen Content
 */

type ContentProps = {
  data: QueryResult<Layout>["data"]
} & Props

const ScreenContent = (props: ContentProps) => {
  const { layout } = props.data

  /**
   * Change Page Title
   */

  useChangePageTitle(props.match.path)

  /**
   * JSX
   */

  const layoutName = capitalize(layout.name)

  return (
    <React.Fragment>
      <SubPageHeader
        breadcrumbLinks={[{ name: `Layouts`, path: routes.layouts }, { name: layoutName, path: routes.layout }]}
        heading={layoutName}
      />

      <ContentWrapper>
        <Content>
          <VariantSwitch variants={layout.variantSet.edges} history={props.history} match={props.match} />
          <VariantWrapper>{props.children}</VariantWrapper>
        </Content>
      </ContentWrapper>
    </React.Fragment>
  )
}

const Content = styled("div")`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
`

const VariantWrapper = styled("div")`
  display: grid;
  position: relative;
  grid-template-columns: minmax(920px, 1240px);
`
