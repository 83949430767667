import { DeleteVendor, DeleteVendorVariables, Vendors, VendorsVariables } from "model/types"
import { QUERY_VENDORS } from "model/graphql/queries"
import { MutationResult, MutationFunction } from "react-apollo"
import { ApolloError, MutationUpdaterFn } from "apollo-client"
import { cloneDeep } from "lodash"

/**
 * Delete Vendor
 */

export type VendorDeleteMutation = MutationFunction<DeleteVendor, DeleteVendorVariables>
export type VendorDeleteMutationResult = MutationResult<DeleteVendor>
export type VendorDeleteMutationUpdater = MutationUpdaterFn<DeleteVendor>

export type UseDeleteVendorArgs = {
  onSuccessAction?: () => void
  onErrorAction?: (errorMessage: string) => void
  queryVariables: VendorsVariables
}

export const useDeleteVendor = (args: UseDeleteVendorArgs) => {
  const { onSuccessAction, onErrorAction, queryVariables } = args

  const deleteVendor = (deleteVendorMutation: VendorDeleteMutation, variables: DeleteVendorVariables) => async () => {
    try {
      await deleteVendorMutation({
        variables: { id: variables.id },
        update: updateVendors({
          vendorId: variables.id,
          queryVariables,
        }),
      })
      if (onSuccessAction) {
        onSuccessAction()
      }
    } catch (error) {
      console.error(error)
      if (onErrorAction) {
        const { message } = error as ApolloError
        onErrorAction(message)
      }
    }
  }

  type UpdateVendorsArgs = {
    vendorId: string
    queryVariables: VendorsVariables
  }

  const updateVendors = (args: UpdateVendorsArgs): VendorDeleteMutationUpdater => (cache, payload) => {
    const { vendorId, queryVariables } = args

    if (!payload.data.vendorDelete.deleted) {
      return
    }
    try {
      const data: Vendors = cloneDeep(cache.readQuery({ query: QUERY_VENDORS, variables: queryVariables }))

      data.vendors.edges = [...data.vendors.edges.filter(({ node: vendor }) => vendor.id !== vendorId)]

      cache.writeQuery({
        query: QUERY_VENDORS,
        variables: queryVariables,
        data: data,
      })
    } catch (error) {
      console.error(error)
    }
  }

  return { deleteVendor }
}
