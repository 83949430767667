import React, { useState } from "react"
import styled from "styled-components"
import { theme } from "theme"

type Props = {
  isChecked: boolean
  value?: string
  onChange?: (value: string) => void
  label?: string // TODO: deprecated, remove
  isDisabled?: boolean
  onClick?: () => void // TODO: deprecated, remove
  children?: string
}

export const Radio = (props: Props) => {
  const { isDisabled, onChange, isChecked, value, children } = props
  const [isFocused, setIsFocused] = useState(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }

  const handleFocus = () => setIsFocused(true)

  const handleBlur = () => setIsFocused(false)

  return (
    <ComponentWrapper>
      <RadioContainer isDisabled={isDisabled} isFocused={isFocused}>
        <Dot isChecked={isChecked} isDisabled={isDisabled} />
      </RadioContainer>
      <RadioOptionText isDisabled={isDisabled}>{children}</RadioOptionText>
      <RadioInput
        type={"radio"}
        readOnly={true}
        checked={isChecked}
        onChange={handleChange}
        value={value}
        disabled={isDisabled}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </ComponentWrapper>
  )
}

const ComponentWrapper = styled("div")`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

type RadioContainerProps = {
  isDisabled: boolean
  isFocused: boolean
}

const RadioContainer = styled("div")`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  border: 1px solid
    ${(props: RadioContainerProps) =>
      props.isDisabled
        ? theme.color.backgroundGray
        : props.isFocused
        ? theme.color.textNonactive
        : theme.color.borderGray};
  background-color: ${theme.color.backgroundGray};
  border-radius: 25px;
`

type DotProps = {
  isChecked: boolean
  isDisabled: boolean
}

const Dot = styled("div")`
  display: flex;
  position: relative;
  width: 13px;
  height: 13px;
  background-color: ${(props: DotProps) =>
    props.isDisabled ? theme.color.primaryGreenLight : theme.color.primaryGreen};
  border-radius: 13px;
  opacity: ${(props: DotProps) => (props.isChecked ? "1" : "0")};
`

type RadioOptionTextProps = {
  isDisabled: boolean
}

const RadioOptionText = styled("span")`
  font-size: 16px;
  font-weight: ${theme.font.weight.regular};
  color: ${(props: RadioOptionTextProps) => (props.isDisabled ? theme.color.textNonactive : theme.color.textBlack)};
  margin-left: 10px;
`

const RadioInput = styled("input")`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;
  opacity: 0;

  &:disabled {
    cursor: not-allowed;
  }
`
