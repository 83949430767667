import React, { useContext, useEffect } from "react"
import { RouteComponentProps } from "react-router"
import { Mutation } from "react-apollo"
import { routes } from "router"
import { Form } from "./form"
import { actionTypes, useFormState } from "./form-state"
import { actionTypes as modalActionTypes, ModalContext } from "../../providers/modal"
import { MUTATION_VENDOR_CREATE } from "model/graphql/mutations"
import {
  getFormattedWarning,
  useCreateVendor,
  UseCreateVendorArgs,
  VendorCreateMutation,
  VendorCreateMutationResult,
} from "./helpers"
import { useActivateSuccessModal, useChangePageTitle, usePreventNavigatingAway } from "utils"
import { ContentWrapper, PageContainer, SubPageHeader } from "components/page"
import { useSelector } from "react-redux"
import { ReduxState } from "redux-store"
import { isEqual } from "lodash"

type Props = {
  children?: never
} & RouteComponentProps

export const CreateVendorScreen = (props: Props) => {
  const { history } = props
  const { dispatch: modalDispatch } = useContext(ModalContext)
  const { formState, formDispatch } = useFormState()
  const isSortedBy = useSelector((state: ReduxState) => state.lists.vendors.sortBy, isEqual)
  const isDescending = useSelector((state: ReduxState) => state.lists.vendors.desc, isEqual)

  /**
   * Change Page Title
   */

  useChangePageTitle(props.match.path)

  /**
   * Prevent navigating away when there are unsaved changes
   * - use prevent navigating away hook
   * - compare values
   */

  usePreventNavigatingAway({ history })

  useEffect(() => {
    if (formState.name !== "" || formState.initialScript !== "" || formState.adsTxtEntry !== "") {
      modalDispatch({ type: modalActionTypes.setCanLeavePage, value: false })
    } else {
      modalDispatch({ type: modalActionTypes.setCanLeavePage, value: true })
    }
  }, [formState.name, formState.initialScript, formState.adsTxtEntry, modalDispatch])

  /**
   * Create Vendor
   */

  const { activateSuccessModal } = useActivateSuccessModal()

  const handleSuccess: UseCreateVendorArgs["onSuccessAction"] = vendorId => {
    activateSuccessModal()
    modalDispatch({ type: modalActionTypes.setCanLeavePage, value: true })
    window.setTimeout(() => props.history.replace(routes.vendorEdit.replace(":vendorId", vendorId)), 1000)
  }

  const handleError: UseCreateVendorArgs["onErrorAction"] = errorMessage => {
    formDispatch({ type: actionTypes.setNameWarning, data: { nameWarning: getFormattedWarning(errorMessage) } })
  }

  const { createVendor } = useCreateVendor({
    onSuccessAction: handleSuccess,
    onErrorAction: handleError,
    queryVariables: {
      sortBy: isSortedBy,
      desc: isDescending,
    },
  })

  const saveVendor = (vendorCreateMutation: VendorCreateMutation) => async () => {
    try {
      await createVendor(vendorCreateMutation, {
        name: formState.name,
        initialScript: formState.initialScript,
        adsTxtEntry: formState.adsTxtEntry,
        sortBy: isSortedBy,
        desc: isDescending,
      })
    } catch (error) {
      console.error(error)
    }
  }

  /**
   * JSX
   */

  return (
    <PageContainer>
      <Mutation mutation={MUTATION_VENDOR_CREATE}>
        {(vendorCreateMutation: VendorCreateMutation, { loading }: VendorCreateMutationResult) => (
          <React.Fragment>
            <SubPageHeader
              breadcrumbLinks={[
                { name: `Vendors`, path: routes.vendors },
                { name: `New Vendor`, path: routes.vendorCreate },
              ]}
              heading={`New Vendor`}
              navigationTabs={[
                { name: `General`, to: routes.vendorCreate },
                { name: `Units`, to: "", isDisabled: true },
              ]}
              button={{
                title: `Save`,
                action: saveVendor(vendorCreateMutation),
                isDisabled: loading || !formState.name.trim(),
              }}
            />

            <ContentWrapper hasNavigation={true}>
              <Form state={formState} dispatch={formDispatch} />
            </ContentWrapper>
          </React.Fragment>
        )}
      </Mutation>
    </PageContainer>
  )
}
