import React, { useEffect } from "react"
import { Portal } from "components/portal"
import { Modal, ModalIcon, ModalSection } from "components/modal"
import { Heading2 } from "components/typography"
import { animated, config, useSpring } from "react-spring"
import styled from "styled-components"
import { IconLoading } from "components/icons"
import { theme } from "theme"

type Props = {
  isModalOpen: boolean
  message: string
  children?: never
}

export const LoadingModal = (props: Props) => {
  const { isModalOpen, message } = props
  const [isSpinning, setIsSpinning] = React.useState(true)

  useEffect(() => {
    if (!isSpinning) {
      setIsSpinning(true)
    }
  }, [isSpinning])

  const animation = useSpring({
    from: { transform: "rotate3d(0, 0, 1, 0deg)" },
    to: { transform: "rotate3d(0, 0, 1, 360deg)" },
    reset: !isSpinning,
    onRest: () => setIsSpinning(false),
    config: config.default,
  })

  return (
    <Portal>
      <Modal isVisible={isModalOpen}>
        <ModalSection>
          <ModalIcon>
            <IconWrapper style={animation}>
              <IconLoading color={theme.color.textNonactive} />
            </IconWrapper>
          </ModalIcon>
        </ModalSection>
        <ModalSection topOffset={"40px"}>
          <Heading2>{message}</Heading2>
        </ModalSection>
      </Modal>
    </Portal>
  )
}

export const IconWrapper = styled(animated.div)`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  transform-origin: center center;
`
