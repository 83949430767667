import { client } from "./client"
import {
  RefreshAuthToken,
  RefreshAuthTokenVariables,
  TokenAuth,
  TokenAuthVariables,
  TokenRevoke,
  TokenRevokeVariables,
} from "./types"
import gql from "graphql-tag"
import {
  AuthDispatch,
  getRefreshToken,
  getRemainingTime,
  getToken,
  JwtTokenPayload,
  removeRefreshToken,
  removeToken,
  setRefreshToken,
  setToken,
} from "utils"
import { MutationResult } from "react-apollo"
import jwtDecode from "jwt-decode"
import { store } from "redux-store"
import { authActionTypes } from "redux-reducers"

const KEY_AUTH_TOKEN = "auth_token"
const KEY_REFRESH_TOKEN = "auth_refreshToken"

const MUTATION_LOGIN = gql`
  mutation TokenAuth($username: String!, $password: String!) {
    tokenAuth(username: $username, password: $password) {
      token
      refreshToken
    }
  }
`

const MUTATION_LOGOUT = gql`
  mutation TokenRevoke($refreshToken: String!) {
    revokeToken(refreshToken: $refreshToken) {
      revoked
    }
  }
`

export const login = async (username: string, password: string): Promise<boolean> => {
  try {
    const result = await client.mutate<TokenAuth, TokenAuthVariables>({
      mutation: MUTATION_LOGIN,
      variables: {
        username,
        password,
      },
    })
    if (result.data) {
      window.localStorage.setItem(KEY_AUTH_TOKEN, result.data.tokenAuth.token)
      window.localStorage.setItem(KEY_REFRESH_TOKEN, result.data.tokenAuth.refreshToken)
      return true
    }
  } catch (e) {}
  return false
}

export const logout = async () => {
  const refreshToken = getRefreshToken()
  if (refreshToken) {
    await client.mutate<TokenRevoke, TokenRevokeVariables>({
      mutation: MUTATION_LOGOUT,
      variables: {
        refreshToken,
      },
    })
  }
  window.localStorage.removeItem(KEY_AUTH_TOKEN)
  window.localStorage.removeItem(KEY_REFRESH_TOKEN)
}

// Refresh Authorization Token

const MUTATION_REFRESH_AUTH_TOKEN = gql`
  mutation RefreshAuthToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
      refreshToken
      payload
    }
  }
`

export const refreshAuthToken = async (): Promise<{ newToken: string }> => {
  const token = getToken()
  const jwtTokenPayload: JwtTokenPayload = jwtDecode(token)
  const remainingTime = getRemainingTime(jwtTokenPayload.exp)

  const refreshToken = getRefreshToken()

  const redirectToLogin = () => {
    removeToken()
    removeRefreshToken()
    store.dispatch<AuthDispatch>({ type: authActionTypes.setIsAuthenticated, data: { isAuthenticated: false } })
  }

  if (refreshToken && remainingTime > -600000) {
    try {
      const result: MutationResult<RefreshAuthToken> = await client.mutate<RefreshAuthToken, RefreshAuthTokenVariables>(
        {
          mutation: MUTATION_REFRESH_AUTH_TOKEN,
          variables: { refreshToken },
        }
      )

      const { token: newToken, refreshToken: newRefreshToken } = result.data.refreshToken
      setToken(newToken)
      setRefreshToken(newRefreshToken)

      return { newToken }
    } catch (error) {
      console.error(error)
      redirectToLogin()
      return null
    }
  } else {
    redirectToLogin()
  }
}
