import gql from "graphql-tag"
import { fragments } from "../fragments"

// Vendor

export const QUERY_VENDOR = gql`
  query Vendor($id: ID!) {
    vendor(id: $id) {
      ...VendorData
      unitSet {
        edges {
          node {
            ...UnitData
          }
        }
      }
    }
  }
  ${fragments.vendor}
  ${fragments.unit}
`

// Layout

export const QUERY_LAYOUT = gql`
  query Layout($id: ID!) {
    layout(id: $id) {
      ...LayoutData
      variantSet {
        edges {
          node {
            ...VariantData
            slotpairSet {
              edges {
                node {
                  ...SlotData
                  unit {
                    ...UnitData
                    vendor {
                      ...VendorData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    vendors {
      edges {
        node {
          ...VendorData
          unitSet {
            edges {
              node {
                ...UnitData
              }
            }
          }
        }
      }
    }
  }
  ${fragments.layout}
  ${fragments.variant}
  ${fragments.slot}
  ${fragments.vendor}
  ${fragments.unit}
`

// Quiz

export const QUERY_QUIZ_FOR_QUESTION = gql`
  query QuizForQuestion($id: ID!) {
    quiz(id: $id) {
      id
      title
      questionSet {
        edges {
          node {
            answerExplain
            explainImage
            id
            image
            order
            question
            answerSet {
              edges {
                node {
                  answer
                  correct
                  id
                  image
                  order
                }
              }
            }
          }
        }
      }
    }
  }
`

// User

export const QUERY_USER = gql`
  query User($id: ID!) {
    user(id: $id) {
      ...UserData
      groups {
        edges {
          node {
            ...GroupData
          }
        }
      }
    }
  }
  ${fragments.user}
  ${fragments.group}
`

// Groups

export const QUERY_GROUPS = gql`
  query Groups {
    groups {
      edges {
        node {
          ...GroupData
        }
      }
    }
  }
  ${fragments.group}
`

//Query for Quiz Form

export const QUERY_DATA_FOR_QUIZ_FORM = gql`
  query QuizFormData($id: ID!) {
    quiz(id: $id) {
      ...QuizData
    }
    me {
      id
      firstName
      lastName
    }
    groups {
      edges {
        node {
          id
          name
          userSet {
            edges {
              node {
                ...UserData
              }
            }
          }
        }
      }
    }
    tags {
      edges {
        node {
          ...TagData
        }
      }
    }
    quizzes {
      edges {
        node {
          ...QuizData
        }
      }
    }
  }
  ${fragments.tag}
  ${fragments.user}
  ${fragments.quiz}
`
