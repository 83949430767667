import React from "react"
import styled from "styled-components"
import { theme } from "../../theme"

/**
 * Props
 */

type Props = {
  type: 1
  children: React.ReactNode
  method?: string
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void
}

export const Form = (props: Props) => {
  const { type, children, ...rest } = props

  if (type === 1) {
    return <Form1 {...rest}>{children}</Form1>
  }

  return null
}

const Form1 = styled("form")`
  display: grid;
  grid-auto-rows: max-content;
  grid-row-gap: ${theme.offset3};
  padding: ${theme.offset3};
  justify-items: center;
  position: relative;
  width: 760px;
`
