import gql from "graphql-tag"
import { fragments } from "model/graphql/fragments"

/**
 * Variant
 */

export const QUERY_VARIANT = gql`
  query Variant($id: ID!) {
    variant(id: $id) {
      ...VariantData
      slotpairSet {
        edges {
          node {
            ...SlotData
            unit {
              ...UnitData
              vendor {
                ...VendorData
              }
            }
          }
        }
      }
    }
  }
  ${fragments.variant}
  ${fragments.slot}
  ${fragments.unit}
  ${fragments.vendor}
`
