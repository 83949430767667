import { MutationResult, MutationFunction } from "react-apollo"
import { UnitDelete, UnitDeleteVariables, Units, UnitsVariables } from "model/types"
import { QUERY_UNITS } from "model/graphql/queries"
import { cloneDeep } from "lodash"
import { ApolloError, MutationUpdaterFn } from "apollo-client"

/**
 * Delete Unit
 */

export type DeleteUnitMutation = MutationFunction<UnitDelete, UnitDeleteVariables>
export type DeleteUnitMutationResult = MutationResult<UnitDelete>
export type DeleteUnitMutationUpdater = MutationUpdaterFn<UnitDelete>

export type UseDeleteUnitArgs = {
  onSuccessAction?: () => void
  onErrorAction?: (errorMessage: string) => void
  queryVariables: UnitsVariables
}

export const useDeleteUnit = (args: UseDeleteUnitArgs) => {
  const { onSuccessAction, onErrorAction, queryVariables } = args

  const deleteUnit = (deleteUnitMutation: DeleteUnitMutation, variables: UnitDeleteVariables) => async () => {
    try {
      await deleteUnitMutation({
        variables: { id: variables.id },
        update: updateUnits({ unitId: variables.id, queryVariables }),
      })
      if (onSuccessAction) {
        onSuccessAction()
      }
    } catch (error) {
      console.error(error)
      if (onErrorAction) {
        const { message } = error as ApolloError
        onErrorAction(message)
      }
    }
  }

  type UpdateUnitsArgs = {
    unitId: string
    queryVariables: UnitsVariables
  }

  const updateUnits = (args: UpdateUnitsArgs): DeleteUnitMutationUpdater => (cache, payload) => {
    const { unitId, queryVariables } = args

    if (!payload.data.unitDelete.deleted) {
      return
    }
    try {
      const data: Units = cloneDeep(cache.readQuery({ query: QUERY_UNITS, variables: queryVariables }))

      data.units.edges = [...data.units.edges.filter(({ node: unit }) => unit.id !== unitId)]

      cache.writeQuery({
        query: QUERY_UNITS,
        variables: queryVariables,
        data: data,
      })
    } catch (error) {
      console.error(error)
    }
  }

  return { deleteUnit }
}
