import React from "react"
import { IconProps } from "./types"
import { Svg } from "./styles"

type Props = IconProps

export const IconAlert = (props: Props) => {
  return (
    <Svg color={props.color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g id="Alert_Icon" transform="translate(-529.501 -554.5)">
        <g transform="translate(532.679 562.5) rotate(-45)">
          <line
            x1="4.123"
            y2="4.11"
            transform="translate(2.696)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            transform="translate(0.574 6.231)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
        <circle
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(530.001 555)"
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </g>
    </Svg>
  )
}
