import React from "react"
import styled from "styled-components"
import { Header, height as headerHeight } from "components/header"
import { Tab } from "components/tab"
import { NavSeparator } from "components/nav-separator"
import { Dropdown } from "components/dropdown"
import { quizzesTabs, routes, usersTabs } from "router"
import { Query, QueryResult } from "react-apollo"
import { Me } from "model/types"
import { QUERY_ME } from "model/graphql/queries"
import { Role } from "utils"
import { useSelector } from "react-redux"
import { ReduxState } from "redux-store"
import { isEqual } from "lodash"
import { RebuildButton } from "screens/screen/rebuild-button"
import { RebuildJSButton } from "screens/screen/rebuild-javascript-button"

type Props = {
  children: React.ReactNode
}

export const Screen = (props: Props) => {
  const auth = useSelector((state: ReduxState) => state.auth, isEqual)

  return (
    <React.Fragment>
      <Header>
        {auth.isAuthenticated && (
          <React.Fragment>
            <Query query={QUERY_ME}>
              {({ loading, error, data }: QueryResult<Me>) => {
                if (loading || error || !data) {
                  return null
                }
                return <HeaderContent data={data} />
              }}
            </Query>
          </React.Fragment>
        )}
      </Header>

      <ContentContainer>{props.children}</ContentContainer>
    </React.Fragment>
  )
}

type HeaderContentProps = {
  data: QueryResult<Me>["data"]
}

const HeaderContent = (props: HeaderContentProps) => {
  const { firstName, lastName, avatar, groups } = props.data.me
  const role = groups.edges[0].node.name as Role

  return (
    <React.Fragment>
      {/* Content */}
      <HeaderNavigationContainer>
        {(role === "Administrator" || role === "Author") && (
          <TabsWrapper>
            <Tab pathForActiveState={routes.quizzes} to={routes.quizzes.replace(":tab", quizzesTabs.all)}>
              {"Quizzes"}
            </Tab>
            {role === "Administrator" && (
              <Tab pathForActiveState={routes.users} to={routes.users.replace(":tab", usersTabs.all)}>
                {"Users"}
              </Tab>
            )}
          </TabsWrapper>
        )}
        {role === "Administrator" && <NavSeparator />}
        {(role === "Administrator" || role === "Ads Manager") && (
          <TabsWrapper>
            <Tab to={routes.vendors}>{"Vendors"}</Tab>
            <Tab to={routes.layouts}>{"Layouts"}</Tab>
            <Tab to={routes.layoutRules}>{"Layout Rules"}</Tab>
          </TabsWrapper>
        )}
        <NavSeparator />
        <RebuildButton />
        <RebuildJSButton />
      </HeaderNavigationContainer>

      <NavSeparator />

      <HeaderDropdownContainer>
        <Dropdown avatar={avatar} userName={`${firstName} ${lastName}`} />
      </HeaderDropdownContainer>
    </React.Fragment>
  )
}

const HeaderNavigationContainer = styled("nav")`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-end;
  grid-area: header-navigation;
  height: 100%;
`

const TabsWrapper = styled("div")`
  display: grid;
  position: relative;
  grid-auto-columns: auto;
  grid-template-rows: 1fr;
  grid-auto-flow: column;
  height: 46px;
  margin: 0 40px;
  grid-column-gap: 40px;
`

const HeaderDropdownContainer = styled("div")`
  display: flex;
  position: relative;
  flex-direction: row;
  grid-area: header-dropdown;
`

const ContentContainer = styled("main")`
  display: block;
  position: relative;
  padding-top: ${headerHeight};
  width: 100%;
  height: 100%;
  min-height: 100vh;
`
