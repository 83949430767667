import React, { useRef } from "react"
import { Heading2 } from "components/typography"
import { Button } from "components/button"
import { IconDelete } from "components/icons"
import { AnchorLink } from "components/links"
import { Portal } from "components/portal"
import { Modal, ModalSection } from "components/modal"
import { MUTATION_RULE_DELETE } from "model/graphql/mutations"
import { useFocusOnButton } from "utils"
import { DeleteRuleMutation, DeleteRuleMutationResult, useDeleteRule } from "./helpers"
import { RulesVariables } from "model/types"
import { Mutation } from "react-apollo"

type Props = {
  isModalOpen: boolean
  ruleToDelete: { id: string; name: string }
  queryVariables: RulesVariables
  closeModal: () => void
  children?: never
}

export const DeleteRuleModal = (props: Props) => {
  const { ruleToDelete, closeModal, isModalOpen, queryVariables } = props

  const buttonRef = useRef<HTMLButtonElement>(null)
  useFocusOnButton({ ref: buttonRef })

  /**
   * Delete Rule
   */

  const handleSuccess = () => {
    closeModal()
  }

  const { deleteRule } = useDeleteRule({
    queryVariables,
    onSuccessAction: handleSuccess,
  })

  /**
   * JSX
   */

  return (
    <Mutation mutation={MUTATION_RULE_DELETE}>
      {(deleteRuleMutation: DeleteRuleMutation, { loading }: DeleteRuleMutationResult) => (
        <Portal>
          <Modal isVisible={isModalOpen}>
            <ModalSection>
              <Heading2>{`Are you sure to delete the Rule ${ruleToDelete.name}?`}</Heading2>
            </ModalSection>
            <ModalSection topOffset={"40px"}>
              <Button
                ref={buttonRef}
                color={"red"}
                size={"regular"}
                variant={"filled"}
                onClick={deleteRule(deleteRuleMutation, { id: ruleToDelete.id })}
                icon={<IconDelete />}
                isDisabled={loading}
              >
                Yes, delete it
              </Button>
            </ModalSection>
            <ModalSection topOffset={"20px"}>
              <AnchorLink onClick={closeModal} color={"green"} weight={"regular"} size={"16px"}>
                No, I don’t want to delete it
              </AnchorLink>
            </ModalSection>
          </Modal>
        </Portal>
      )}
    </Mutation>
  )
}
