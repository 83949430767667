import React from "react"
import styled from "styled-components"
import { theme } from "theme"

type Props = {
  isChecked: boolean
  optionValue: string
  onChange: (value: string) => void
  children: string
}

export const RadioButton = (props: Props) => {
  const { onChange, children, optionValue, isChecked } = props

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }

  return (
    <Container isChecked={isChecked}>
      <Text isChecked={isChecked}>{children}</Text>
      <RadioInput type={"radio"} readOnly={true} checked={isChecked} onChange={handleChange} value={optionValue} />
    </Container>
  )
}

type ContainerProps = {
  isChecked: boolean
}

const Container = styled("div")`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: auto;
  border-radius: 25px;
  border: 1px solid ${(props: ContainerProps) => (props.isChecked ? theme.color.primaryGreen : theme.color.borderGray)};
  background-color: ${(props: ContainerProps) => (props.isChecked ? theme.color.primaryGreen : theme.color.white)};
  padding: 0 ${theme.offset1};
  flex-shrink: 0;
  transition: background-color 200ms ease-in-out;
`

const RadioInput = styled("input")`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;
  opacity: 0;
`

type TextProps = {
  isChecked: boolean
}

const Text = styled("span")`
  font-size: 16px;
  font-weight: ${theme.font.weight.regular};
  color: ${(props: TextProps) => (props.isChecked ? theme.color.white : theme.color.textBlack)};
  white-space: nowrap;
`
