import React, { useContext, useEffect } from "react"
import { routes } from "router"
import { Form } from "./form"
import { actionTypes, initialUnitFormState, useFormState } from "./form-state"
import { RouteComponentProps } from "react-router"
import { MUTATION_UNIT_CREATE } from "model/graphql/mutations"
import { Mutation, Query, QueryResult } from "react-apollo"
import { Vendor, VendorVariables } from "model/types"
import { actionTypes as modalActionTypes, ModalContext } from "../../providers/modal"
import { QUERY_VENDOR } from "model/graphql/queries"
import { Error, Loading } from "components/data-status"
import {
  getFormattedWarning,
  UnitCreateMutation,
  UnitCreateMutationResult,
  useCreateUnit,
  UseCreateUnitArgs,
} from "./helpers"
import { useActivateSuccessModal, useChangePageTitle, usePreventNavigatingAway } from "utils"
import { difference } from "lodash"
import { ContentWrapper, DataStatusWrapper, PageContainer, SubPageHeader } from "components/page"
import { useSelector } from "react-redux"
import { ReduxState } from "redux-store"
import { isEqual } from "lodash"

type Props = {
  children?: never
} & RouteComponentProps<{ vendorId: string }>

export const CreateUnitScreen = (props: Props) => {
  const { vendorId } = props.match.params

  return (
    <PageContainer>
      <Query query={QUERY_VENDOR} variables={{ id: vendorId } as VendorVariables}>
        {({ loading, error, data }: QueryResult<Vendor>) => {
          if (error) {
            return (
              <DataStatusWrapper>
                <Error>{error.message}</Error>
              </DataStatusWrapper>
            )
          }
          if (!data || !data.vendor || loading) {
            return (
              <DataStatusWrapper>
                <Loading>Loading...</Loading>
              </DataStatusWrapper>
            )
          }

          return <Content {...props} data={data} />
        }}
      </Query>
    </PageContainer>
  )
}

/**
 * Screen Content
 */

type ContentProps = {
  data: QueryResult<Vendor>["data"]
} & Props

const Content = (props: ContentProps) => {
  const { data, history } = props
  const { formState, formDispatch } = useFormState()
  const { dispatch: modalDispatch } = useContext(ModalContext)
  const isSortedBy = useSelector((state: ReduxState) => state.lists.units.sortBy, isEqual)
  const isDescending = useSelector((state: ReduxState) => state.lists.units.desc, isEqual)

  /**
   * Change Page Title
   */

  useChangePageTitle(props.match.path)

  /**
   * Prevent navigating away when there are unsaved changes
   * - use prevent navigating away hook
   * - compare values
   */

  usePreventNavigatingAway({ history })

  useEffect(() => {
    if (
      formState.name !== initialUnitFormState.name ||
      formState.initialScript !== initialUnitFormState.initialScript ||
      formState.refreshScript !== initialUnitFormState.refreshScript ||
      formState.wipeScript !== initialUnitFormState.wipeScript ||
      formState.isPersistent !== initialUnitFormState.isPersistent ||
      difference(formState.slotCompatibility, initialUnitFormState.slotCompatibility).length > 0
    ) {
      modalDispatch({ type: modalActionTypes.setCanLeavePage, value: false })
    } else {
      modalDispatch({ type: modalActionTypes.setCanLeavePage, value: true })
    }
  }, [
    formState.name,
    formState.initialScript,
    formState.refreshScript,
    formState.wipeScript,
    formState.isPersistent,
    formState.slotCompatibility,

    modalDispatch,
  ])

  /**
   * Handle Unit Create
   */

  const { activateSuccessModal } = useActivateSuccessModal()

  const handleSuccess: UseCreateUnitArgs["onSuccessAction"] = (vendorId: string, unitId: string) => {
    modalDispatch({ type: modalActionTypes.setCanLeavePage, value: true })
    activateSuccessModal()
    window.setTimeout(
      () => props.history.replace(routes.unitEdit.replace(":vendorId", vendorId).replace(":unitId", unitId)),
      1000
    )
  }

  const handleError: UseCreateUnitArgs["onErrorAction"] = errorMessage => {
    formDispatch({ type: actionTypes.setNameWarning, data: { nameWarning: getFormattedWarning(errorMessage) } })
  }

  const { createUnit } = useCreateUnit({
    onSuccessAction: handleSuccess,
    onErrorAction: handleError,
    queryVariables: {
      id: data.vendor.id,
      sortBy: isSortedBy,
      desc: isDescending,
    },
  })

  const saveUnit = (unitCreateMutation: UnitCreateMutation) => async () => {
    try {
      await createUnit(unitCreateMutation, {
        name: formState.name,
        initialScript: formState.initialScript,
        refreshScript: formState.refreshScript,
        wipeScript: formState.wipeScript,
        persistent: formState.isPersistent,
        slotCompatibility: formState.slotCompatibility,
        vendorId: data.vendor.id,
        sortBy: isSortedBy,
        desc: isDescending,
      })
    } catch (error) {
      console.error(error)
    }
  }

  /**
   * JSX
   */

  return (
    <React.Fragment>
      <Mutation mutation={MUTATION_UNIT_CREATE}>
        {(unitCreateMutation: UnitCreateMutation, { loading }: UnitCreateMutationResult) => (
          <React.Fragment>
            <SubPageHeader
              breadcrumbLinks={[
                { name: `Vendors`, path: routes.vendors },
                { name: data.vendor.name, path: routes.vendorEdit.replace(":vendorId", data.vendor.id) },
                { name: `Units`, path: routes.units },
                { name: `New Unit`, path: routes.unitCreate },
              ]}
              heading={`New Unit`}
              button={{
                title: `Save`,
                action: saveUnit(unitCreateMutation),
                isDisabled: loading || !formState.name.trim(),
              }}
            />

            <ContentWrapper>
              <Form state={formState} dispatch={formDispatch} />
            </ContentWrapper>
          </React.Fragment>
        )}
      </Mutation>
    </React.Fragment>
  )
}
