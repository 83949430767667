import React from "react"
import { AutosaveStatus } from "components/autosave-status"
import { useSelector } from "react-redux"
import { ReduxState } from "redux-store"
import { isEqual } from "lodash"

export const QuizQuestionsAutosave = () => {
  const quizQuestionsAutosaveStatus = useSelector((state: ReduxState) => state.quizQuestions.autosaveStatus, isEqual)

  return (
    <AutosaveStatus
      savingMessage={`Saving...`}
      savedMessage={`Draft is automatically saved`}
      state={quizQuestionsAutosaveStatus}
    />
  )
}
