import React from "react"
import { IconProps } from "./types"
import { Svg } from "./styles"

type Props = IconProps

export const IconSearch = (props: Props) => {
  return (
    <Svg color={props.color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.209 21.21">
      <g id="Search_Icon" transform="translate(-1059.502 -351.5)">
        <ellipse
          cx="8.247"
          cy="8.248"
          rx="8.247"
          ry="8.248"
          transform="translate(1060.002 352)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          x2="5.817"
          y2="5.857"
          transform="translate(1074.187 366.146)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </Svg>
  )
}
