import React from "react"
import { Mutation } from "react-apollo"
import {
  MUTATION_UNPUBLISH_QUIZ,
  UnpublishQuizMutation,
  UnpublishQuizMutationResult,
  useUnpublishQuiz,
} from "model/graphql/mutations"
import { Button } from "components/button"
import { useDispatch, useSelector } from "react-redux"
import { ReduxState } from "redux-store"
import { QuizGeneralDispatch, useActivateSuccessModal, useCloseLoadingModal, useOpenLoadingModal } from "utils"
import { ButtonWrapper } from "screens/quiz/page-header/quiz-action-buttons/button-wrapper"
import { quizGeneralActionTypes } from "redux-reducers"
import { isEqual } from "lodash"

/**
 * Component
 */

export const QuizUnpublishButton = () => {
  const quizId = useSelector((state: ReduxState) => state.quizGeneral.id, isEqual)
  const dispatch = useDispatch()

  /**
   * Success and Loading Modal
   */

  const { activateSuccessModal } = useActivateSuccessModal()
  const { openLoadingModal } = useOpenLoadingModal()
  const { closeLoadingModal } = useCloseLoadingModal()

  /**
   * Unpublish Quiz
   */

  const handleUnpublishStart = () => {
    openLoadingModal("Unpublishing...")
  }

  const handleUnpublishSuccess = () => {
    closeLoadingModal()
    activateSuccessModal("The Quiz was unpublished.")
    dispatch<QuizGeneralDispatch>({
      type: quizGeneralActionTypes.setQuizGeneralIsPublished,
      data: { isPublished: false },
    })
  }

  const { unpublishQuiz } = useUnpublishQuiz({
    onStartAction: handleUnpublishStart,
    onSuccessAction: handleUnpublishSuccess,
    onErrorAction: () => {},
  })

  /**
   * JSX
   */

  return (
    <Mutation mutation={MUTATION_UNPUBLISH_QUIZ}>
      {(unpublishQuizMutation: UnpublishQuizMutation, { loading }: UnpublishQuizMutationResult) => (
        <ButtonWrapper>
          <Button
            color={"red"}
            size={"regular"}
            variant={"outlined"}
            type={"button"}
            onClick={unpublishQuiz(unpublishQuizMutation, { id: quizId })}
            isDisabled={loading}
          >
            {`Unpublish`}
          </Button>
        </ButtonWrapper>
      )}
    </Mutation>
  )
}
