import gql from "graphql-tag"

/**
 * Start Rebuild
 */

export const MUTATION_START_REBUILD = gql`
  mutation StartRebuild {
    rebuildStart(input: {}) {
      success
      clientMutationId
    }
  }
`

/**
 * Check Rebuild Status
 */

export const MUTATION_CHECK_REBUILD_STATUS = gql`
  mutation CheckRebuildStatus {
    rebuildStatus(input: {}) {
      report {
        canBuild
      }
      clientMutationId
    }
  }
`
