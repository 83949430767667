import gql from "graphql-tag"
import { fragments } from "../fragments"
import {
  ActivateUser,
  ActivateUserVariables,
  ChangeUser,
  ChangeUserVariables,
  DeleteUser,
  DeleteUserVariables,
  InviteUser,
  InviteUserVariables,
  RequestPasswordReset,
  RequestPasswordResetVariables,
  ResetPassword,
  ResetPasswordVariables,
  Users,
  Users_groups_edges,
  UsersVariables,
} from "../../types"
import { cloneDeep } from "lodash"
import { QUERY_USERS } from "../queries"
import { ApolloError, MutationUpdaterFn } from "apollo-client"
import { MutationResult, MutationFunction } from "react-apollo"

/**
 * Change My Profile Mutation
 */

export const MUTATION_CHANGE_MY_PROFILE = gql`
  mutation UserChange(
    $email: String
    $firstName: String
    $lastName: String
    $authorBio: String
    $id: ID!
    $avatar: Upload
    $deleteAvatar: Boolean
  ) {
    userChange(
      input: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        email: $email
        description: $authorBio
        avatar: $avatar
        deleteAvatar: $deleteAvatar
      }
    ) {
      user {
        ...UserData
      }
      clientMutationId
    }
  }
  ${fragments.user}
`

/**
 * Change User Mutation
 */

export const MUTATION_CHANGE_USER = gql`
  mutation ChangeUser(
    $id: ID!
    $firstName: String
    $lastName: String
    $email: String
    $groupIds: [ID]
    $description: String
    $avatar: Upload
    $deleteAvatar: Boolean
  ) {
    userChange(
      input: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        email: $email
        groupIds: $groupIds
        description: $description
        avatar: $avatar
        deleteAvatar: $deleteAvatar
      }
    ) {
      user {
        ...UserData
      }
      clientMutationId
    }
  }
  ${fragments.user}
`

export type UseChangeUserArgs = {
  onSuccessAction: () => void
}

/**
 * Change User Hook
 *
 * @param args
 */

export const useChangeUser = (args: UseChangeUserArgs) => {
  const { onSuccessAction } = args

  const changeUser = async (changeUserMutation: ChangeUserMutation, variables: ChangeUserVariables) => {
    try {
      await changeUserMutation({
        variables,
      })
      onSuccessAction()
    } catch (error) {
      console.error(error)
    }
  }

  return { changeUser }
}

export type ChangeUserMutation = MutationFunction<ChangeUser, ChangeUserVariables>
export type ChangeUserMutationResult = MutationResult<ChangeUser>

/**
 * Delete User Mutation
 */

export const MUTATION_DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    userDelete(input: { id: $id }) {
      deleted
      clientMutationId
    }
  }
`

export type UseDeleteUserArgs = {
  queryVariables: UsersVariables
  onSuccessAction: () => void
}

/**
 * Delete User Hook
 *
 * @param args
 */

export const useDeleteUser = (args: UseDeleteUserArgs) => {
  const { onSuccessAction, queryVariables } = args

  const deleteUser = (deleteUserMutation: DeleteUserMutation, variables: DeleteUserVariables) => async () => {
    try {
      await deleteUserMutation({
        variables,
        update: updateUsers({
          queryVariables,
          userId: variables.id,
        }),
      })
      onSuccessAction()
    } catch (error) {
      console.error(error)
    }
  }

  type UpdateUsersArgs = {
    userId: string
    queryVariables: UsersVariables
  }

  const updateUsers = (args: UpdateUsersArgs): DeleteUserMutationUpdater => (cache, payload) => {
    const { userId, queryVariables } = args
    if (!payload.data.userDelete.deleted) {
      return
    }
    try {
      const data: Users = cloneDeep(cache.readQuery({ query: QUERY_USERS, variables: queryVariables }))

      data.users.edges = data.users.edges.filter(({ node: user }) => user.id !== userId)

      data.groups.edges = data.groups.edges.map((group: Users_groups_edges) => {
        return {
          ...group,
          node: {
            ...group.node,
            userSet: {
              ...group.node.userSet,
              edges: [...group.node.userSet.edges.filter(({ node: user }) => user.id !== userId)],
            },
          },
        }
      })

      cache.writeQuery({
        query: QUERY_USERS,
        variables: queryVariables,
        data: data,
      })
    } catch (error) {
      console.error(error)
    }
  }

  return { deleteUser }
}

export type DeleteUserMutation = MutationFunction<DeleteUser, DeleteUserVariables>
export type DeleteUserMutationResult = MutationResult<DeleteUser>
export type DeleteUserMutationUpdater = MutationUpdaterFn<DeleteUser>

/**
 * Invite User Mutation
 */

export const MUTATION_INVITE_USER = gql`
  mutation InviteUser(
    $email: String!
    $firstName: String
    $lastName: String
    $groupId: [ID]
    $sortBy: String
    $desc: Boolean
  ) {
    userInvite(
      input: {
        email: $email
        lastName: $lastName
        firstName: $firstName
        groupIds: $groupId
        sortBy: $sortBy
        desc: $desc
      }
    ) {
      user {
        ...UserData
      }
      users {
        invitedUsers
        usersAll

        edges {
          node {
            ...UserData
          }
        }
      }
      groups {
        edges {
          node {
            ...GroupData
          }
        }
      }
      clientMutationId
    }
  }
  ${fragments.user}
  ${fragments.group}
`

export type UseInviteUserArgs = {
  queryVariables: UsersVariables
  onSuccessAction: () => void
  onErrorAction: (errorMessage: ApolloError["message"]) => void
}

/**
 * Invite User Hook
 *
 * @param args
 */

export const useInviteUser = (args: UseInviteUserArgs) => {
  const { onSuccessAction, onErrorAction, queryVariables } = args

  const inviteUser = (inviteUserMutation: InviteUserMutation, variables: InviteUserVariables) => async () => {
    try {
      await inviteUserMutation({
        variables,
        update: updateUsers,
      })
      onSuccessAction()
    } catch (error) {
      console.error(error)

      const { message } = error as ApolloError
      onErrorAction(message)
    }
  }

  const updateUsers: InviteUserMutationUpdater = (cache, payload) => {
    if (payload.data.userInvite === null) {
      return
    }

    try {
      const { users, groups } = payload.data.userInvite
      const data: Users = cloneDeep(cache.readQuery({ query: QUERY_USERS, variables: queryVariables }))

      data.users = users
      data.groups = groups

      cache.writeQuery({
        query: QUERY_USERS,
        variables: queryVariables,
        data: data,
      })
    } catch (error) {
      console.error(error)
    }
  }
  return { inviteUser }
}

export type InviteUserMutation = MutationFunction<InviteUser, InviteUserVariables>
export type InviteUserMutationResult = MutationResult<InviteUser>
export type InviteUserMutationUpdater = MutationUpdaterFn<InviteUser>

/**
 * Activate User Mutation
 */

export const MUTATION_ACTIVATE_USER = gql`
  mutation ActivateUser($invitationCode: String!, $password: String!) {
    userActivate(input: { invitationCode: $invitationCode, password: $password }) {
      success
      clientMutationId
    }
  }
`

export type UseActivateUserArgs = {
  onSuccessAction: () => void
  onErrorAction: (errorMessage: ApolloError["message"]) => void
}

/**
 * Activate User Hook
 *
 * @param args
 */

export const useActivateUser = (args: UseActivateUserArgs) => {
  const { onSuccessAction, onErrorAction } = args

  const activateUser = async (activateUserMutation: ActivateUserMutation, variables: ActivateUserVariables) => {
    try {
      await activateUserMutation({ variables })
      onSuccessAction()
    } catch (error) {
      console.error(error)

      const { message } = error as ApolloError
      onErrorAction(message)
    }
  }

  return { activateUser }
}

export type ActivateUserMutation = MutationFunction<ActivateUser, ActivateUserVariables>
export type ActivateUserMutationResult = MutationResult<ActivateUser>

/**
 * Request Password Reset Mutation
 */

export const MUTATION_REQUEST_PASSWORD_RESET = gql`
  mutation RequestPasswordReset($email: String!) {
    userRequestPasswordReset(input: { email: $email }) {
      success
      clientMutationId
    }
  }
`

export type UseRequestPasswordResetArgs = {
  onSuccessAction: () => void
  onErrorAction: (errorMessage: ApolloError["message"]) => void
}

/**
 * Request Password Reset Hook
 *
 * @param args
 */

export const useRequestPasswordReset = (args: UseRequestPasswordResetArgs) => {
  const { onSuccessAction, onErrorAction } = args

  const requestPasswordReset = async (
    requestPasswordResetMutation: RequestPasswordResetMutation,
    variables: RequestPasswordResetVariables
  ) => {
    try {
      await requestPasswordResetMutation({ variables })

      onSuccessAction()
    } catch (error) {
      console.error(error)

      const { message } = error as ApolloError
      onErrorAction(message)
    }
  }

  return { requestPasswordReset }
}

export type RequestPasswordResetMutation = MutationFunction<RequestPasswordReset, RequestPasswordResetVariables>
export type RequestPasswordResetMutationResponse = MutationResult<RequestPasswordReset>

/**
 * Reset Password Mutation
 */

export const MUTATION_RESET_PASSWORD = gql`
  mutation ResetPassword($passwordResetCode: String!, $password: String!) {
    userResetPassword(input: { passwordResetCode: $passwordResetCode, password: $password }) {
      success
      clientMutationId
    }
  }
`

export type UseResetPasswordArgs = {
  onSuccessAction: () => void
  onErrorAction: (errorMessage: ApolloError["message"]) => void
}

/**
 * Reset Password Hook
 *
 * @param args
 */

export const useResetPassword = (args: UseResetPasswordArgs) => {
  const { onSuccessAction, onErrorAction } = args

  const resetPassword = async (resetPasswordMutation: ResetPasswordMutation, variables: ResetPasswordVariables) => {
    try {
      await resetPasswordMutation({ variables })
      onSuccessAction()
    } catch (error) {
      console.error(error)

      const { message } = error as ApolloError
      onErrorAction(message)
    }
  }

  return { resetPassword }
}

export type ResetPasswordMutation = MutationFunction<ResetPassword, ResetPasswordVariables>
export type ResetPasswordMutationResult = MutationResult<ResetPassword>
