import React, { useState } from "react"
import { ImageUploadProps } from "components/image-upload/types"
import styled from "styled-components"
import { IconDelete, IconUpload, IconLoading } from "components/icons"
import { theme } from "theme"
import { FileInput } from "components/image-upload/file-input"
import { removeImage, setImage } from "components/image-upload/helpers"

type Props = ImageUploadProps

export const MiniImageUpload = (props: Props) => {
  const { setImagePreview, setImageFile, previewImage, isUploading } = props
  const [isRemoveIconVisible, setIsRemoveIconVisible] = useState(false)

  /**
   * Set New Image
   */

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImage({
      event,
      setImageFile,
      setImagePreview,
    })
  }

  /**
   * Remove Image
   */

  const handleClick = () => {
    removeImage({
      setImagePreview,
      setImageFile,
    })
  }

  /**
   * Set Remove Icon Visibility
   */

  const handleMouseEnter = () => setIsRemoveIconVisible(true)

  const handleMouseLeave = () => setIsRemoveIconVisible(false)

  /**
   * JSX
   */

  return (
    <Container>
      <ImageContainer>
        {!previewImage && !isUploading && (
          <IconWrapper>
            <IconUpload color={theme.color.primaryGreen} />
          </IconWrapper>
        )}

        {previewImage && !isUploading && (
          <React.Fragment>
            <PreviewImage src={previewImage} alt={"preview"} />
            <RemoveImageCover isVisible={isRemoveIconVisible}>
              <IconWrapper>
                <IconDelete color={theme.color.textBlack} />
              </IconWrapper>
            </RemoveImageCover>
          </React.Fragment>
        )}

        {previewImage && isUploading && (
          <IconWrapper>
            <IconLoading />
          </IconWrapper>
        )}
      </ImageContainer>

      {!previewImage && !isUploading && <Text color={theme.color.primaryGreen}>{`Upload Image`}</Text>}

      {previewImage && !isUploading && <Text color={theme.color.primaryGreen}>{`Remove Image`}</Text>}

      {previewImage && isUploading && <Text weight={theme.font.weight.regular}>{`Uploading…`}</Text>}

      {!previewImage && !isUploading && <FileInput type={"file"} multiple={false} onChange={handleChange} />}

      {previewImage && !isUploading && (
        <RemoveButton onClick={handleClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
      )}
    </Container>
  )
}

/**
 * Styles
 */

const Container = styled("div")`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

const ImageContainer = styled("div")`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: ${theme.color.white};
  border-style: dashed;
  border-width: 1px;
  border-color: ${theme.color.borderGray};
  border-radius: ${theme.rounding1};
`

const IconWrapper = styled("div")`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 100%;
  max-width: 32px;
`

type TextProps = {
  color?: string
  weight?: string
}

const Text = styled("span")`
  font-size: 16px;
  font-weight: ${(props: TextProps) => (props.weight ? props.weight : theme.font.weight.semiBold)};
  color: ${(props: TextProps) => (props.color ? props.color : theme.color.textBlack)};
  margin-left: 10px;
`

const PreviewImage = styled("img")`
  display: flex;
  position: absolute;
  top: -1px;
  left: -1px;
  width: 50px;
  height: 50px;
  border-radius: ${theme.rounding1};
  object-fit: cover;
`

const RemoveButton = styled("button")`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
  opacity: 1;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`

type RemoveImageCoverProps = {
  isVisible: boolean
}

const RemoveImageCover = styled("div")`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: -1px;
  left: -1px;
  width: 50px;
  height: 50px;
  opacity: ${(props: RemoveImageCoverProps) => (props.isVisible ? "1" : "0")};
  background: rgba(255, 255, 255, 0.75);
  transition: opacity 200ms ease-in-out;
`
