import React, { useEffect, useState } from "react"
import { Button } from "components/button"
import { IconUpload } from "components/icons"
import { theme } from "theme"
import styled from "styled-components"
import { Spinner } from "components/spinner"
import { useMutation } from "@apollo/react-hooks"
import { MUTATION_START_REBUILD_JS } from "model/graphql/mutations/rebuildJS"
import { RebuildJs } from "model/types"

type Props = {
  children?: never
}

export const RebuildJSButton: React.FC<Props> = () => {
  const [isBuilding, setIsBuilding] = useState(false)
  const [startRebuildJSMutation, { loading, data, error }] = useMutation<RebuildJs>(MUTATION_START_REBUILD_JS)

  useEffect(() => {
    if ((data && data.rebuildJs.success) || error) {
      setIsBuilding(false)
    }
  }, [data, error])

  /**
   * Initiate Rebuild
   */
  const handleClick = async () => {
    setIsBuilding(true)
    startRebuildJSMutation()
  }

  /**
   * JSX
   */
  return (
    <ButtonWrapper>
      <Button
        icon={isBuilding ? <Spinner color={theme.color.white} /> : <IconUpload color={theme.color.white} />}
        width={"221px"}
        onClick={handleClick}
        isDisabled={isBuilding || loading}
      >
        {isBuilding ? "Rebuilding JS..." : "Rebuild JS"}
      </Button>
    </ButtonWrapper>
  )
}

const ButtonWrapper = styled("div")`
  display: flex;
  position: relative;
  align-items: center;
  height: 100%;
  padding: 0 10px;
`
