import { FormState } from "./form-state"
import { Questions_questions_edges_node_answerSet, Questions_questions_edges_node_answerSet_edges } from "model/types"
import _ from "lodash"
import { QuizQuestionsDispatch } from "utils"
import { quizQuestionsActionTypes } from "redux-reducers"
import { Dispatch } from "react"

/**
 * Question Type, that will be delete
 */

export type QuestionToDelete = {
  id: string
  quizId: string
  questionTitle: string
  order: number
}

/**
 * Returns Current Form State Object
 **/

export const getFormStateVariables = (state: FormState) => {
  return {
    question: state.question,
    image: state.imageFile,
    deleteImage: !state.imagePreview,
    explainImage: state.explainImageFile,
    deleteExplainImage: !state.explainImagePreview,
    answerExplain: state.answerExplain,
    correctAnswerText: state.correctAnswerText,
    correctAnswerImage: state.correctAnswerImageFile,
    deleteCorrectAnswerImage: !state.correctAnswerImagePreview,
    wrongAnswerText: state.wrongAnswerText,
    wrongAnswerImage: state.wrongAnswerImageFile,
    deleteWrongAnswerImage: !state.wrongAnswerImagePreview,
    order: state.order,
    id: state.questionId,
    quizId: state.quizId,
    type: state.questionType,
  }
}

/**
 * Concatenate Prefix with Image Source
 * @param prefix
 * @param imgSrc
 */

export const prependPrefixToImageSrc = (prefix: string, imgSrc: string): string => {
  return `${prefix}${imgSrc}`
}

/**
 * Returns Answer Data by Passed Arguments
 * @param answerSet
 * @param targetValue
 * @param isCorrect
 */

export const getAnswerDataFromAnswerSet = (
  answerSet: Questions_questions_edges_node_answerSet,
  targetValue: "image" | "text",
  isCorrect: boolean
): string => {
  //correct edge
  if (answerSet && isCorrect) {
    const correctAnswerEdge: Questions_questions_edges_node_answerSet_edges = _.find(
      answerSet.edges,
      ({ node: answerData }) => answerData.correct
    )
    switch (targetValue) {
      case "text":
        return _.get(correctAnswerEdge, "node.answer", undefined)
      case "image":
        return _.get(correctAnswerEdge, "node.image", undefined)
      default:
        return undefined
    }
  }
  //wrong edge
  if (answerSet && !isCorrect) {
    const correctAnswerEdge: Questions_questions_edges_node_answerSet_edges = _.find(
      answerSet.edges,
      ({ node: answerData }) => !answerData.correct
    )
    switch (targetValue) {
      case "text":
        return _.get(correctAnswerEdge, "node.answer", undefined)
      case "image":
        return _.get(correctAnswerEdge, "node.image", undefined)
      default:
        return undefined
    }
  }
  return undefined
}

/**
 * Set auto save status to "saved" or "saving"
 *
 * @param args
 * @return void
 */
export const setAutosaveStatus = (args: { dispatch: Dispatch<QuizQuestionsDispatch>; status: "saving" | "saved" }) => {
  args.dispatch({
    type: quizQuestionsActionTypes.setQuizQuestionsAutosaveStatus,
    data: { autosaveStatus: args.status },
  })
}
