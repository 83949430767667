import React from "react"
import { Container, IconWrapper } from "./shared-components"
import { IconAlert } from "../icons"
import { config, useSpring } from "react-spring"
import { theme } from "theme"

type Props = {
  children: string
}

export const Error = (props: Props) => {
  const animation = useSpring({
    from: { opacity: "0" },
    to: { opacity: "1" },
    config: config.gentle,
  })

  return (
    <Container style={animation}>
      <IconWrapper>
        <IconAlert color={theme.color.textNonactive} />
      </IconWrapper>
      <span className={"text"}>{props.children}</span>
    </Container>
  )
}
