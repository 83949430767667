import React from "react"
import { RouteComponentProps } from "react-router"
import { ContentWrapper, DataStatusWrapper, PageContainer } from "components/page"
import { QuizPageHeader, QuizQuestionsAutosave, QuizQuestionsBreadcrumb } from "screens/quiz/page-header"
import { QuestionsList } from "screens/quiz/questions/questions-list"
import { QueryResult, useQuery } from "react-apollo"
import { QUERY_QUESTIONS } from "model/graphql/queries"
import { Questions, QuestionsVariables } from "model/types"
import { Error, Loading } from "components/data-status"

type Props = {
  children?: never
} & RouteComponentProps<{ tab: string; quizId: string }>

export type QuestionsQueryResult = QueryResult<Questions>

export const QuizQuestionsScreen = (props: Props) => {
  const { quizId } = props.match.params
  const { loading, error, data }: QuestionsQueryResult = useQuery(QUERY_QUESTIONS, {
    variables: { id: quizId } as QuestionsVariables,
  })

  if (error) {
    return (
      <DataStatusWrapper>
        <Error>{error.message}</Error>
      </DataStatusWrapper>
    )
  }
  if (!data || !data.questions || loading) {
    return (
      <DataStatusWrapper>
        <Loading>Loading...</Loading>
      </DataStatusWrapper>
    )
  }

  return (
    <PageContainer>
      <QuizPageHeader
        page={"questions"}
        breadcrumb={<QuizQuestionsBreadcrumb />}
        autosave={<QuizQuestionsAutosave />}
        routeParams={props.match.params}
      />

      <ContentWrapper hasNavigation={true}>
        <QuestionsList data={data} />
      </ContentWrapper>
    </PageContainer>
  )
}
