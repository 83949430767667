import React from "react"
import { createPortal } from "react-dom"
import { useEffect, useRef } from "react"

type Props = {
  children: React.ReactNode
}

const dialogRoot = document.getElementById("modal")

export const Portal = (props: Props) => {
  const elRef = useRef(null)

  if (elRef.current === null) {
    elRef.current = document.createElement("div")
  }

  useEffect(() => {
    dialogRoot.appendChild(elRef.current)
    return () => dialogRoot.removeChild(elRef.current)
  }, [elRef])

  return createPortal(props.children, elRef.current)
}
