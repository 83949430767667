import React from "react"
import styled from "styled-components"
import { theme } from "theme"
import { IconDone } from "components/icons"
import { Spinner } from "components/spinner"

type Props = {
  state: "saving" | "saved" | "initial"
  savingMessage: string
  savedMessage: string
  children?: never
}

export const AutosaveStatus = React.memo((props: Props) => {
  const { state, savingMessage, savedMessage } = props

  if (state === "initial") {
    return null
  }

  return (
    <Container>
      {state === "saving" && <Spinner color={theme.color.textNonactive} size={"20px"} />}
      {state === "saved" && (
        <DoneIconWrapper>
          <IconDone color={theme.color.primaryGreen} />
        </DoneIconWrapper>
      )}
      <TextWrapper>{state === "saving" ? savingMessage : savedMessage}</TextWrapper>
    </Container>
  )
})

/**
 * Styles
 */

const TextWrapper = styled("div")`
  color: ${theme.color.textNonactive};
  font-family: ${theme.font.family.default};
  font-weight: ${theme.font.weight.regular};
  font-size: 14px;
`
const DoneIconWrapper = styled("div")`
  display: grid;
  position: relative;
  align-self: center;
  height: 10px;
  width: 15px;
`
const Container = styled("div")`
  display: grid;
  position: relative;
  grid-template-rows: 19px;
  grid-template-columns: 15px max-content;
  grid-column-gap: 10px;
`
