import { useReducer } from "react"
import { Action } from "utils"

const initialFormState = {
  name: "",
  utmSource: "",
  layoutId: "",

  nameWarning: "",
  utmSourceWarning: "",
}

export const actionTypes = {
  setName: "SET_NAME",
  setUtmSource: "SET_UTM_SOURCE",
  setLayoutId: "SET_LAYOUT_ID",

  setNameWarning: "SET_NAME_WARNING",
  setUtmSourceWarning: "SET_UTM_SOURCE_WARNING",
}

export const useFormState = () => {
  const [state, dispatch] = useReducer(reducer, initialFormState)

  return { state, dispatch }
}

type ActionData = {
  name?: string
  utmSource?: string
  layoutId?: string

  nameWarning?: string
  utmSourceWarning?: string
}

const reducer = (state = initialFormState, action: Action<ActionData>): typeof initialFormState => {
  switch (action.type) {
    case actionTypes.setName:
      return {
        ...state,
        name: action.data.name,
        nameWarning: "",
      }

    case actionTypes.setUtmSource:
      return {
        ...state,
        utmSource: action.data.utmSource,
        utmSourceWarning: "",
      }

    case actionTypes.setLayoutId:
      return {
        ...state,
        layoutId: action.data.layoutId,
      }

    case actionTypes.setUtmSourceWarning:
      return {
        ...state,
        utmSourceWarning: action.data.utmSourceWarning,
      }

    case actionTypes.setNameWarning:
      return {
        ...state,
        nameWarning: action.data.nameWarning,
      }

    default:
      throw new Error(`"${action.type}" is not a valid action!`)
  }
}

export type FormState = ReturnType<typeof useFormState>["state"]
export type FormDispatch = ReturnType<typeof useFormState>["dispatch"]
