import styled from "styled-components"
import { theme } from "theme"

type SvgProps = {
  color?: string
}

export const Svg = styled("svg")`
  width: 100%;
  height: 100%;
  stroke: ${(props: SvgProps) => (props.color ? props.color : theme.color.textBlack)};
`
