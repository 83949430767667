import React from "react"
import styled from "styled-components"
import { theme } from "theme"
import { UnknownTableAvatar } from "../images"

type Props = {
  avatar: string
  children?: never
}

export const TableAvatar = (props: Props) => {
  const avatarSrc = props.avatar ? `${process.env.REACT_APP_MEDIA_URL_PREFIX}${props.avatar}` : undefined

  return avatarSrc ? <Avatar src={avatarSrc} alt={"user avatar"} /> : <UnknownTableAvatar />
}

const Avatar = styled("img")`
  display: block;
  position: relative;
  height: 50px;
  width: 50px;
  border-radius: ${theme.rounding2};
  object-fit: cover;
`
