import React from "react"
import { RouteComponentProps } from "react-router"
import { QuizForm } from "screens/quiz/general/quiz-form"
import { ContentWrapper, DataStatusWrapper, PageContainer } from "components/page"
import {
  QuizGeneralAutosave,
  QuizGeneralBreadcrumb,
  QuizPageHeader,
  QuizPublishButton,
  QuizUnpublishButton,
} from "screens/quiz/page-header"
import { useSelector } from "react-redux"
import { ReduxState } from "redux-store"
import { isEqual } from "lodash"
import { useQuery } from "@apollo/react-hooks"
import { QUERY_QUIZ } from "model/graphql/queries/quiz"
import { Quiz, QuizVariables } from "model/types"
import { Error, Loading } from "components/data-status"
import { QueryResult } from "@apollo/react-common"

type Props = {
  children?: never
} & RouteComponentProps<{ tab: string; quizId: string }>

export type QuizQueryResult = QueryResult<Quiz>

export const QuizGeneralScreen = (props: Props) => {
  const { quizId } = props.match.params
  const isPublished = useSelector((state: ReduxState) => state.quizGeneral.isPublished, isEqual)
  const { data, loading, error }: QuizQueryResult = useQuery(QUERY_QUIZ, { variables: { id: quizId } as QuizVariables })

  if (error) {
    return (
      <DataStatusWrapper>
        <Error>{error.message}</Error>
      </DataStatusWrapper>
    )
  }

  if (!data || !data.quiz || loading) {
    return (
      <DataStatusWrapper>
        <Loading>Loading...</Loading>
      </DataStatusWrapper>
    )
  }

  return (
    <PageContainer>
      <QuizPageHeader
        page={"general"}
        breadcrumb={<QuizGeneralBreadcrumb />}
        autosave={<QuizGeneralAutosave />}
        routeParams={props.match.params}
        action={!isPublished ? <QuizPublishButton /> : <QuizUnpublishButton />}
      />

      <ContentWrapper hasNavigation={true}>
        <QuizForm data={data} />
      </ContentWrapper>
    </PageContainer>
  )
}
