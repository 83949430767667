import { EMAIL_PATTERN } from "./variables"

/**
 * Get Sorting Indicator State
 */

type GetSortingIndicatorArgs = {
  sortBy: string
  isSortedBy: string
  isDescending: boolean
}

export const getSortingIndicator = (args: GetSortingIndicatorArgs) => {
  const { sortBy, isSortedBy, isDescending } = args
  return isSortedBy === sortBy ? (isDescending ? "up" : "down") : "none"
}

export const getRemainingTime = (expiration: number) => {
  const currentTimeInMilliseconds = new Date().getTime()
  const expirationTimeInMilliseconds = expiration * 1000

  return expirationTimeInMilliseconds - currentTimeInMilliseconds
}

export const isEmailValid = (email: string): boolean => {
  return EMAIL_PATTERN.test(email.toUpperCase())
}
