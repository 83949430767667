import gql from "graphql-tag"
import { fragments } from "../fragments"

export const MUTATION_UNIT_CREATE = gql`
  mutation UnitCreate(
    $name: String!
    $slotCompatibility: [String]
    $vendorId: ID!
    $initialScript: String
    $refreshScript: String
    $wipeScript: String
    $persistent: Boolean!
    $sortBy: String
    $desc: Boolean
  ) {
    unitCreate(
      input: {
        name: $name
        slotCompatibility: $slotCompatibility
        vendorId: $vendorId
        initialScript: $initialScript
        refreshScript: $refreshScript
        wipeScript: $wipeScript
        persistent: $persistent
        sortBy: $sortBy
        desc: $desc
      }
    ) {
      unit {
        ...UnitData
        vendor {
          ...VendorData
        }
        slotpairSet {
          edges {
            node {
              ...SlotData
            }
          }
        }
      }
      units {
        edges {
          node {
            ...UnitData
          }
        }
      }
      clientMutationId
    }
  }
  ${fragments.unit}
  ${fragments.vendor}
  ${fragments.slot}
`

export const MUTATION_UNIT_CHANGE = gql`
  mutation UnitChange(
    $id: ID!
    $name: String!
    $slotCompatibility: [String]
    $vendorId: ID!
    $initialScript: String
    $refreshScript: String
    $wipeScript: String
    $persistent: Boolean!
  ) {
    unitChange(
      input: {
        id: $id
        name: $name
        slotCompatibility: $slotCompatibility
        vendorId: $vendorId
        initialScript: $initialScript
        refreshScript: $refreshScript
        wipeScript: $wipeScript
        persistent: $persistent
      }
    ) {
      unit {
        ...UnitData
        vendor {
          ...VendorData
        }
        slotpairSet {
          edges {
            node {
              ...SlotData
            }
          }
        }
      }
      clientMutationId
    }
  }
  ${fragments.unit}
  ${fragments.vendor}
  ${fragments.slot}
`

export const MUTATION_UNIT_DELETE = gql`
  mutation UnitDelete($id: ID!) {
    unitDelete(input: { id: $id }) {
      deleted
      clientMutationId
    }
  }
`
