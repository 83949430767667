import { Action } from "utils"

/**
 * Initial State
 */

export const listsInitialState = {
  quizzes: { sortBy: "title", desc: false },
  users: { sortBy: "last_name", desc: false },
  vendors: { sortBy: "name", desc: false },
  units: { sortBy: "name", desc: false },
  layouts: { sortBy: "name", desc: false },
  layoutRules: { sortBy: "name", desc: false },
}

export type ListsState = typeof listsInitialState

/**
 * Action Types
 */

export const listsActionTypes = {
  // Sort By
  setQuizzesSortBy: "SET_QUIZZES_SORT_BY",
  setUsersSortBy: "SET_USERS_SORT_BY",
  setVendorsSortBy: "SET_VENDORS_SORT_BY",
  setUnitsSortBy: "SET_UNITS_SORT_BY",
  setLayoutsSortBy: "SET_LAYOUTS_SORT_BY",
  setLayoutRulesSortBy: "SET_LAYOUT_RULES_SORT_BY",
  // Desc
  setQuizzesDesc: "SET_QUIZZES_DESC",
  setUsersDesc: "SET_USERS_DESC",
  setVendorsDesc: "SET_VENDORS_DESC",
  setUnitsDesc: "SET_UNITS_DESC",
  setLayoutsDesc: "SET_LAYOUTS_DESC",
  setLayoutRulesDesc: "SET_LAYOUT_RULES_DESC",
}

/**
 * Reducer
 */

export type ListsActionData = {
  quizzes?: { sortBy?: string; desc?: boolean }
  users?: { sortBy?: string; desc?: boolean }
  vendors?: { sortBy?: string; desc?: boolean }
  units?: { sortBy?: string; desc?: boolean }
  layouts?: { sortBy?: string; desc?: boolean }
  layoutRules?: { sortBy?: string; desc?: boolean }
}

export const listsReducer = (state: ListsState = listsInitialState, action: Action<ListsActionData>): ListsState => {
  switch (action.type) {
    /**
     * Quizzes
     */
    case listsActionTypes.setQuizzesSortBy:
      if (action.data.quizzes.sortBy === state.quizzes.sortBy) {
        return state
      }
      return {
        ...state,
        quizzes: {
          ...state.quizzes,
          sortBy: action.data.quizzes.sortBy,
        },
      }
    case listsActionTypes.setQuizzesDesc:
      if (action.data.quizzes.desc === state.quizzes.desc) {
        return state
      }
      return {
        ...state,
        quizzes: {
          ...state.quizzes,
          desc: action.data.quizzes.desc,
        },
      }
    /**
     * Users
     */
    case listsActionTypes.setUsersSortBy:
      if (action.data.users.sortBy === state.users.sortBy) {
        return state
      }
      return {
        ...state,
        users: {
          ...state.users,
          sortBy: action.data.users.sortBy,
        },
      }
    case listsActionTypes.setUsersDesc:
      if (action.data.users.desc === state.users.desc) {
        return state
      }
      return {
        ...state,
        users: {
          ...state.users,
          desc: action.data.users.desc,
        },
      }
    /**
     * Vendors
     */
    case listsActionTypes.setVendorsSortBy:
      if (action.data.vendors.sortBy === state.vendors.sortBy) {
        return state
      }
      return {
        ...state,
        vendors: {
          ...state.vendors,
          sortBy: action.data.vendors.sortBy,
        },
      }
    case listsActionTypes.setVendorsDesc:
      if (action.data.vendors.desc === state.vendors.desc) {
        return state
      }
      return {
        ...state,
        vendors: {
          ...state.vendors,
          desc: action.data.vendors.desc,
        },
      }
    /**
     * Units
     */
    case listsActionTypes.setUnitsSortBy:
      if (action.data.units.sortBy === state.units.sortBy) {
        return state
      }
      return {
        ...state,
        units: {
          ...state.units,
          sortBy: action.data.units.sortBy,
        },
      }
    case listsActionTypes.setUnitsDesc:
      if (action.data.units.desc === state.units.desc) {
        return state
      }
      return {
        ...state,
        units: {
          ...state.units,
          desc: action.data.units.desc,
        },
      }
    /**
     * Layouts
     */
    case listsActionTypes.setLayoutsSortBy:
      if (action.data.layouts.sortBy === state.layouts.sortBy) {
        return state
      }
      return {
        ...state,
        layouts: {
          ...state.layouts,
          sortBy: action.data.layouts.sortBy,
        },
      }
    case listsActionTypes.setLayoutsDesc:
      if (action.data.layouts.desc === state.layouts.desc) {
        return state
      }
      return {
        ...state,
        layouts: {
          ...state.layouts,
          desc: action.data.layouts.desc,
        },
      }
    /**
     * Layout Rules
     */
    case listsActionTypes.setLayoutRulesSortBy:
      if (action.data.layoutRules.sortBy === state.layoutRules.sortBy) {
        return state
      }
      return {
        ...state,
        layoutRules: {
          ...state.layoutRules,
          sortBy: action.data.layoutRules.sortBy,
        },
      }
    case listsActionTypes.setLayoutRulesDesc:
      if (action.data.layoutRules.desc === state.layoutRules.desc) {
        return state
      }
      return {
        ...state,
        layoutRules: {
          ...state.layoutRules,
          desc: action.data.layoutRules.desc,
        },
      }
    /**
     * Default
     */
    default:
      return state
  }
}
