import React from "react"
import styled, { css } from "styled-components"
import { theme } from "theme"
import { ActionWrapper, BreadcrumbWrapper, HeadingWrapper, NavigationWrapper } from "components/page/wrappers"
import { Heading1 } from "components/typography"
import { Button } from "components/button"
import { IconPlus } from "components/icons"
import { Tab } from "components/tab"
import { BreadcrumbLink, BreadcrumbNavigation } from "components/breadcrumb"
import { truncate } from "lodash"

type Props = {
  breadcrumbLinks?: {
    name: string
    path: string
  }[]
  heading: string
  navigationTabs?: {
    name: string
    to: string
    isExact?: boolean
    isDisabled?: boolean
  }[]
  button?: {
    title: string
    action: () => void
    isDisabled?: boolean
    hasIcon?: boolean
  }
  children?: never
}

export const SubPageHeader = (props: Props) => {
  const { breadcrumbLinks, heading, navigationTabs, button } = props

  return (
    <Container hasNavigation={Boolean(navigationTabs)}>
      {/* Breadcrumb */}
      {breadcrumbLinks && (
        <BreadcrumbWrapper>
          <BreadcrumbNavigation>
            {breadcrumbLinks.map((breadcrumbLink, index) => (
              <BreadcrumbLink key={breadcrumbLink.name + index} path={breadcrumbLink.path}>
                {truncate(breadcrumbLink.name)}
              </BreadcrumbLink>
            ))}
          </BreadcrumbNavigation>
        </BreadcrumbWrapper>
      )}

      <GridContainer hasNavigation={Boolean(navigationTabs)}>
        {/* Heading */}
        <HeadingWrapper>
          <Heading1>{truncate(heading)}</Heading1>
        </HeadingWrapper>

        {/* Navigation */}
        {navigationTabs && (
          <NavigationWrapper>
            {navigationTabs.map((tab, index) => (
              <Tab key={tab.name + index} to={tab.to} isExact={tab.isExact} isDisabled={tab.isDisabled}>
                {tab.name}
              </Tab>
            ))}
          </NavigationWrapper>
        )}

        {/* Action */}
        {button && (
          <ActionWrapper>
            <Button
              icon={button.hasIcon ? <IconPlus /> : null}
              color={"green"}
              size={"regular"}
              variant={"filled"}
              type={"button"}
              onClick={button.action}
              isDisabled={button.isDisabled}
            >
              {button.title}
            </Button>
          </ActionWrapper>
        )}
      </GridContainer>
    </Container>
  )
}

/**
 * Styles
 */

type ContainerProps = {
  hasNavigation: boolean
}

const Container = styled("div")`
  display: grid;
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  height: 130px;
  grid-template-columns: 1fr;
  grid-template-rows: 20px max-content;
  grid-template-areas:
    "breadcrumb"
    "main";
  padding-top: ${theme.offset1};
  padding-bottom: ${theme.offset3};
  padding-left: ${theme.offset4};
  padding-right: ${theme.offset4};
  z-index: 990;

  background-image: linear-gradient(0deg, rgba(255, 255, 255, 0) 0, ${theme.color.backgroundGray} 25%);
  background-origin: padding-box;
  background-clip: border-box;
  background-size: auto auto;
  background-position: 50% 50%;

  ${(props: ContainerProps) =>
    props.hasNavigation
      ? css`
          @media all and (max-width: 1440px) {
            height: 202px;
          }
        `
      : ""};
`

type GridContainerProps = {
  hasNavigation: boolean
}

const GridContainer = styled("div")`
  display: grid;
  position: relative;
  width: 100%;
  grid-template-columns: 1fr max-content 1fr;
  grid-template-rows: 50px;
  grid-template-areas: "heading navigation action";
  grid-column-gap: ${theme.offset3};
  grid-area: main;

  ${(props: GridContainerProps) =>
    props.hasNavigation
      ? css`
          @media all and (max-width: 1440px) {
            grid-template-rows: 50px 32px;
            grid-row-gap: ${theme.offset3};
            grid-template-areas:
              "heading . action"
              "navigation navigation navigation";
          }
        `
      : ""};
`
