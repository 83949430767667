import React from "react"
import { Heading1 } from "components/typography"
import { HeadingWrapper } from "components/page"
import { truncate, isEqual } from "lodash"
import { useSelector } from "react-redux"
import { ReduxState } from "redux-store"

export const QuizGeneralHeading = () => {
  const quizTitle = useSelector((state: ReduxState) => state.quizGeneral.title, isEqual)

  return (
    <HeadingWrapper>
      <Heading1>{truncate(quizTitle)}</Heading1>
    </HeadingWrapper>
  )
}
