import React from "react"
import styled from "styled-components"
import { theme } from "theme"

type Props = {
  children: React.ReactNodeArray
}

export const BreadcrumbNavigation = (props: Props) => {
  return (
    <Container>
      {props.children.map((child, index) => (
        <React.Fragment key={`breadcrumb-nav-link-${index}`}>
          {child}
          {index < props.children.length - 1 && <Separator>{"/"}</Separator>}
        </React.Fragment>
      ))}
    </Container>
  )
}

const Container = styled("nav")`
  display: flex;
  position: relative;
  flex-direction: row;
`

const Separator = styled("span")`
  font-weight: ${theme.font.weight.semiBold};
  font-size: 14px;
  color: ${theme.color.textBlack};
  padding: 0 10px;
`
