import gql from "graphql-tag"
import { fragments } from "../fragments"

export const QUERY_VENDORS = gql`
  query Vendors($sortBy: String, $desc: Boolean) {
    vendors(sortBy: $sortBy, desc: $desc) {
      edges {
        node {
          ...VendorData
          unitSet {
            edges {
              node {
                ...UnitData
              }
            }
          }
        }
      }
    }
  }
  ${fragments.vendor}
  ${fragments.unit}
`
