import gql from "graphql-tag"
import { fragments } from "../fragments"

export const MUTATION_VENDOR_CREATE = gql`
  mutation VendorCreate($name: String, $initialScript: String, $adsTxtEntry: String, $sortBy: String, $desc: Boolean) {
    vendorCreate(
      input: { name: $name, initialScript: $initialScript, adsTxtEntry: $adsTxtEntry, sortBy: $sortBy, desc: $desc }
    ) {
      vendor {
        ...VendorData
        unitSet {
          edges {
            node {
              ...UnitData
            }
          }
        }
      }
      vendors {
        edges {
          node {
            ...VendorData
            unitSet {
              edges {
                node {
                  ...UnitData
                }
              }
            }
          }
        }
      }
      clientMutationId
    }
  }
  ${fragments.vendor}
  ${fragments.unit}
`

export const MUTATION_VENDOR_CHANGE = gql`
  mutation VendorChange($name: String, $initialScript: String, $adsTxtEntry: String, $id: ID!) {
    vendorChange(input: { id: $id, name: $name, initialScript: $initialScript, adsTxtEntry: $adsTxtEntry }) {
      vendor {
        ...VendorData
        unitSet {
          edges {
            node {
              ...UnitData
            }
          }
        }
      }
      clientMutationId
    }
  }
  ${fragments.vendor}
  ${fragments.unit}
`

export const MUTATION_VENDOR_DELETE = gql`
  mutation DeleteVendor($id: ID!) {
    vendorDelete(input: { id: $id }) {
      deleted
      clientMutationId
    }
  }
`
