import gql from "graphql-tag"
import { fragments } from "model/graphql/fragments"

export const QUERY_LAYOUTS = gql`
  query Layouts($desc: Boolean, $sortBy: String) {
    layouts(desc: $desc, sortBy: $sortBy) {
      edges {
        node {
          ...LayoutData
          variantSet {
            edges {
              node {
                ...VariantData
              }
            }
          }
        }
      }
    }
  }
  ${fragments.layout}
  ${fragments.variant}
`
