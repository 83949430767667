import gql from "graphql-tag"
import { fragments } from "../fragments"
import { QuestionCreate, QuestionDelete } from "model/types"
import { MutationUpdaterFn } from "apollo-client"

/**
 * Create Question
 */

export const MUTATION_QUESTION_CREATE = gql`
  mutation QuestionCreate(
    $quizId: ID!
    $order: Int
    $question: String
    $image: Upload
    $deleteImage: Boolean
    $correctAnswerText: String
    $correctAnswerImage: Upload
    $deleteCorrectAnswerImage: Boolean
    $wrongAnswerText: String
    $wrongAnswerImage: Upload
    $deleteWrongAnswerImage: Boolean
    $answerExplain: String
    $explainImage: Upload
    $deleteExplainImage: Boolean
    $ten: Boolean
  ) {
    questionCreate(
      input: {
        quizId: $quizId
        order: $order
        question: $question
        image: $image
        deleteImage: $deleteImage
        correctAnswerText: $correctAnswerText
        correctAnswerImage: $correctAnswerImage
        deleteCorrectAnswerImage: $deleteCorrectAnswerImage
        wrongAnswerText: $wrongAnswerText
        wrongAnswerImage: $wrongAnswerImage
        deleteWrongAnswerImage: $deleteWrongAnswerImage
        answerExplain: $answerExplain
        explainImage: $explainImage
        deleteExplainImage: $deleteExplainImage
        ten: $ten
      }
    ) {
      quiz {
        questionSet {
          edges {
            node {
              ...QuestionData
              answerSet {
                edges {
                  node {
                    ...AnswerData
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${fragments.question}
  ${fragments.answer}
`

export type QuestionCreateMutationUpdater = MutationUpdaterFn<QuestionCreate>

/**
 * Change Question
 */

export const MUTATION_QUESTION_CHANGE = gql`
  mutation QuestionChange(
    $id: ID!
    $question: String
    $order: Int
    $image: Upload
    $type: String
    $deleteImage: Boolean
    $correctAnswerText: String
    $correctAnswerImage: Upload
    $deleteCorrectAnswerImage: Boolean
    $wrongAnswerText: String
    $wrongAnswerImage: Upload
    $deleteWrongAnswerImage: Boolean
    $answerExplain: String
    $explainImage: Upload
    $deleteExplainImage: Boolean
  ) {
    questionChange(
      input: {
        id: $id
        question: $question
        order: $order
        image: $image
        type: $type
        deleteImage: $deleteImage
        correctAnswerText: $correctAnswerText
        correctAnswerImage: $correctAnswerImage
        deleteCorrectAnswerImage: $deleteCorrectAnswerImage
        wrongAnswerText: $wrongAnswerText
        wrongAnswerImage: $wrongAnswerImage
        deleteWrongAnswerImage: $deleteWrongAnswerImage
        answerExplain: $answerExplain
        explainImage: $explainImage
        deleteExplainImage: $deleteExplainImage
      }
    ) {
      clientMutationId
      question {
        ...QuestionData
        answerSet {
          edges {
            node {
              ...AnswerData
            }
          }
        }
      }
    }
  }
  ${fragments.question}
  ${fragments.answer}
`

/**
 * Delete Question
 */

export const MUTATION_QUESTION_DELETE = gql`
  mutation QuestionDelete($id: ID!) {
    questionDelete(input: { id: $id }) {
      quiz {
        questionSet {
          edges {
            node {
              ...QuestionData
              answerSet {
                edges {
                  node {
                    ...AnswerData
                  }
                }
              }
            }
          }
        }
      }
      deleted
      clientMutationId
    }
  }
  ${fragments.question}
  ${fragments.answer}
`
export type QuestionDeleteMutationUpdater = MutationUpdaterFn<QuestionDelete>

/**
 * Swap Question
 */

export const MUTATION_QUESTION_SWAP = gql`
  mutation QuestionSwap($id: ID!, $order: Int!) {
    questionSwap(input: { id: $id, order: $order }) {
      questions {
        edges {
          node {
            ...QuestionData
            answerSet {
              edges {
                node {
                  ...AnswerData
                }
              }
            }
          }
        }
      }

      clientMutationId
    }
  }
  ${fragments.question}
  ${fragments.answer}
`
