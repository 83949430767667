import { Action, AutosaveStatus } from "utils"

/**
 * Initial State
 */

export const quizGeneralInitialState = {
  id: "",
  title: "...",
  slug: "",
  slugWarning: "",
  isSlugCustom: false,
  description: "",
  tagIds: [] as string[], // Array of Ids
  authorId: "",
  featuredImagePreview: "",
  featuredImageFile: null as File,
  includeAtHomepage: false,
  isPublished: false,
  publishAt: null as String,
  selectedAuthor: { id: "", name: "" },
  autosaveStatus: "initial" as AutosaveStatus,
  isSlugUnique: true,
}

export type QuizGeneralState = typeof quizGeneralInitialState

/**
 * Action Types
 */

export const quizGeneralActionTypes = {
  setQuizGeneralData: "SET_QUIZ_GENERAL_DATA",
  setQuizGeneralTitle: "SET_QUIZ_GENERAL_TITLE",
  setQuizGeneralSlug: "SET_QUIZ_GENERAL_SLUG",
  setQuizGeneralSlugWarning: "SET_QUIZ_GENERAL_SLUG_WARNING",
  setQuizGeneralIsSlugCustom: "SET_QUIZ_GENERAL_IS_SLUG_CUSTOM",
  setQuizGeneralDescription: "SET_QUIZ_GENERAL_DESCRIPTION",
  setQuizGeneralTagIds: "SET_QUIZ_GENERAL_TAG_IDS",
  setQuizGeneralAuthorId: "SET_QUIZ_GENERAL_AUTHOR_ID",
  setQuizGeneralFeaturedImagePreview: "SET_QUIZ_GENERAL_FEATURED_IMAGE_PREVIEW",
  setQuizGeneralFeaturedImageFile: "SET_QUIZ_GENERAL_FEATURED_IMAGE_FILE",
  setQuizGeneralIncludeAtHomepage: "SET_QUIZ_GENERAL_INCLUDE_AT_HOMEPAGE",
  setQuizGeneralSelectedAuthor: "SET_QUIZ_GENERAL_SELECTED_AUTHOR",
  setQuizGeneralIsPublished: "SET_QUIZ_GENERAL_IS_PUBLISHED",
  setQuizGeneralPublishAt: "SET_QUIZ_GENERAL_PUBLISH_AT",
  setQuizGeneralAutosaveStatus: "SET_QUIZ_GENERAL_AUTOSAVE_STATUS",
}

/**
 * Reducer
 */

export type QuizGeneralActionData = {
  id?: string
  title?: string
  slug?: string
  slugWarning?: string
  isSlugCustom?: boolean
  description?: string
  tagIds?: string[]
  authorId?: string
  featuredImagePreview?: string
  featuredImageFile?: File
  includeAtHomepage?: boolean
  isPublished?: boolean
  publishAt?: String
  selectedAuthor?: { id: string; name: string }
  autosaveStatus?: AutosaveStatus
  isSlugUnique?: boolean
}

export const quizGeneralReducer = (
  state: QuizGeneralState = quizGeneralInitialState,
  action: Action<QuizGeneralActionData>
): QuizGeneralState => {
  switch (action.type) {
    // Set Quiz Data
    case quizGeneralActionTypes.setQuizGeneralData:
      return {
        ...quizGeneralInitialState,
        ...action.data,
      }

    // Set Quiz Title
    case quizGeneralActionTypes.setQuizGeneralTitle:
      if (action.data.title === state.title) {
        return state
      }
      return {
        ...state,
        title: action.data.title,
      }

    // Set Quiz Slug
    case quizGeneralActionTypes.setQuizGeneralSlug:
      if (action.data.slug === state.slug) {
        return state
      }
      return {
        ...state,
        slug: action.data.slug,
        slugWarning: "",
      }

    // Set Quiz Slug Warning
    case quizGeneralActionTypes.setQuizGeneralSlugWarning:
      if (action.data.slugWarning === state.slugWarning) {
        return state
      }
      return {
        ...state,
        slugWarning: action.data.slugWarning,
      }

    // Set Quiz Is Slug Custom
    case quizGeneralActionTypes.setQuizGeneralIsSlugCustom:
      if (action.data.isSlugCustom === state.isSlugCustom) {
        return state
      }
      return {
        ...state,
        isSlugCustom: action.data.isSlugCustom,
      }

    // Set Quiz Description
    case quizGeneralActionTypes.setQuizGeneralDescription:
      if (action.data.description === state.description) {
        return state
      }
      return {
        ...state,
        description: action.data.description,
      }

    // Set Quiz Tags
    case quizGeneralActionTypes.setQuizGeneralTagIds:
      if (action.data.tagIds === state.tagIds) {
        return state
      }
      return {
        ...state,
        tagIds: action.data.tagIds,
      }

    // Set Quiz Author Id
    case quizGeneralActionTypes.setQuizGeneralAuthorId:
      if (action.data.authorId === state.authorId) {
        return state
      }
      return {
        ...state,
        authorId: action.data.authorId,
      }

    // Set Quiz Featured Image Preview
    case quizGeneralActionTypes.setQuizGeneralFeaturedImagePreview:
      if (action.data.featuredImagePreview === state.featuredImagePreview) {
        return state
      }
      return {
        ...state,
        featuredImagePreview: action.data.featuredImagePreview,
      }

    // Set Quiz Featured Image File
    case quizGeneralActionTypes.setQuizGeneralFeaturedImageFile:
      if (action.data.featuredImageFile === state.featuredImageFile) {
        return state
      }
      return {
        ...state,
        featuredImageFile: action.data.featuredImageFile,
      }

    // Set Quiz Include At Homepage
    case quizGeneralActionTypes.setQuizGeneralIncludeAtHomepage:
      if (action.data.includeAtHomepage === state.includeAtHomepage) {
        return state
      }
      return {
        ...state,
        includeAtHomepage: action.data.includeAtHomepage,
      }

    // Set Quiz Selected Author
    case quizGeneralActionTypes.setQuizGeneralSelectedAuthor:
      if (action.data.selectedAuthor === state.selectedAuthor) {
        return state
      }
      return {
        ...state,
        selectedAuthor: action.data.selectedAuthor,
      }

    // Set Quiz Is Published
    case quizGeneralActionTypes.setQuizGeneralIsPublished:
      if (action.data.isPublished === state.isPublished) {
        return state
      }
      return {
        ...state,
        isPublished: action.data.isPublished,
      }

    // Set Quiz Publish At
    case quizGeneralActionTypes.setQuizGeneralPublishAt:
      if (action.data.publishAt === state.publishAt) {
        return state
      }
      return {
        ...state,
        publishAt: action.data.publishAt,
      }

    // Set Quiz Autosave Status
    case quizGeneralActionTypes.setQuizGeneralAutosaveStatus:
      if (action.data.autosaveStatus === state.autosaveStatus) {
        return state
      }
      return {
        ...state,
        autosaveStatus: action.data.autosaveStatus,
      }

    // Default
    default:
      return state
  }
}
