import React, { useEffect, useState } from "react"
import { actionTypes, FormDispatch, FormState } from "./form-state"
import { Box } from "components/box"
import { Input } from "components/input"
import { IconArrowBack } from "components/icons"
import { Select } from "components/select"
import styled from "styled-components"
import { Label } from "components/label"
import { Warning } from "components/warning"
import { Rule } from "model/types"

type Props = {
  state: FormState
  dispatch: FormDispatch
  layouts: Rule["layouts"]
  children?: never
}

type Options = React.ComponentProps<typeof Select>["options"]
type Option = Options[0]
type SelectedOption = { id: string; name: string }

export const Form = (props: Props) => {
  const { state, dispatch } = props
  const { name, utmSource, layoutId, utmSourceWarning, nameWarning } = state

  const [searchValue, setSearchValue] = useState("")
  const [selectedOption, setSelectedOption] = useState<SelectedOption>(undefined)
  const [selectOptions, setSelectOptions] = useState<Options>([])
  const [filteredSelectOptions, setFilteredSelectOptions] = useState<Options>([])

  useEffect(() => {
    if (selectOptions.length === 0) {
      const options = props.layouts.edges.map(
        ({ node: layout }): Option => ({
          id: layout.id,
          value: layout.name,
        })
      )
      setSelectOptions(options)
      setFilteredSelectOptions(options)
    }
  }, [props.layouts.edges, selectOptions.length])

  /**
   * Set Selected Layout Option
   */
  useEffect(() => {
    if (!layoutId) {
      const { node: defaultLayout } = props.layouts.edges.find(({ node: layout }) => layout.default)
      setSelectedOption({ id: defaultLayout.id, name: defaultLayout.name })
      dispatch({ type: actionTypes.setLayoutId, data: { layoutId: defaultLayout.id } })
    } else {
      const layout = selectOptions.find(option => option.id === layoutId)
      setSelectedOption({ id: layout.id, name: layout.value })
    }
  }, [dispatch, layoutId, props.layouts.edges, selectOptions])

  /**
   * Filter Layouts Options
   */

  useEffect(() => {
    if (selectOptions.length !== 0) {
      const newSelectOptions = searchValue
        ? selectOptions.filter(option => new RegExp(searchValue, "gi").test(option.value))
        : selectOptions
      setFilteredSelectOptions(newSelectOptions)
    }
  }, [searchValue, selectOptions])

  /**
   * Set Name
   */

  const setName = (value: string) => dispatch({ type: actionTypes.setName, data: { name: value } })

  /**
   * Set Utm Source
   */

  const setUtmSource = (value: string) => dispatch({ type: actionTypes.setUtmSource, data: { utmSource: value } })

  /**
   * Set Layout
   */

  const setLayout = ({ id: layoutId, value: layoutName }: Option) => {
    dispatch({ type: actionTypes.setLayoutId, data: { layoutId } })
    setSelectedOption({ id: layoutId, name: layoutName })
  }

  /**
   * JSX
   */

  return (
    <Container>
      <Box>
        <StyledForm>
          <FormRow gridArea={"name"}>
            <Label>{`Name`}</Label>
            <Input value={name} onChange={setName} hasWarning={Boolean(nameWarning)} />
            <Warning message={nameWarning} />
          </FormRow>

          <FormRow gridArea={"utm-source"}>
            <Label>{`If utm_source =`}</Label>
            <Input value={utmSource} onChange={setUtmSource} hasWarning={Boolean(utmSourceWarning)} />
            <Warning message={utmSourceWarning} />
          </FormRow>

          <IconContainer>
            <IconWrapper>
              <IconArrowBack />
            </IconWrapper>
          </IconContainer>

          <FormRow gridArea={"layout"}>
            <Label>{`Use Layout`}</Label>
            <Select
              selectedOption={selectedOption}
              options={filteredSelectOptions}
              optionsCount={selectOptions.length}
              onSelect={setLayout}
              search={{ placeholder: "start typing", value: searchValue, onChange: setSearchValue }}
            />
          </FormRow>
        </StyledForm>
      </Box>
    </Container>
  )
}

const Container = styled("div")`
  display: block;
  position: relative;
  width: 100%;
  max-width: 760px;
`

const StyledForm = styled("form")`
  display: grid;
  position: relative;
  padding: 40px;
  width: 760px;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 80px max-content 40px max-content;
  grid-template-areas:
    "name"
    "."
    "utm-source"
    "icon"
    "layout";
`

type FormRowProps = {
  gridArea: string
}

const FormRow = styled("div")`
  display: grid;
  position: relative;
  grid-template-columns: 120px 1fr;
  grid-template-rows: max-content max-content;
  grid-template-areas:
    "label ."
    "warning warning";
  grid-column-gap: 10px;
  align-items: start;
  grid-area: ${(props: FormRowProps) => props.gridArea};
`

const IconContainer = styled("div")`
  display: flex;
  position: relative;
  padding-left: 130px;
  justify-content: center;
  width: 100%;
  grid-area: icon;
  align-self: center;
`

const IconWrapper = styled("div")`
  display: flex;
  position: relative;
  width: 21px;
  height: 21px;
  transform: rotate3d(0, 0, 1, 270deg);
  transform-origin: center center;
`
