import styled, { css } from "styled-components"
import { theme } from "theme"

/**
 * Heading Wrapper
 */

export const HeadingWrapper = styled("div")`
  display: block;
  position: relative;
  grid-area: heading;
  justify-self: start;
`

/**
 * Action Wrapper
 */

export const ActionWrapper = styled("div")`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  grid-area: action;
`

/**
 * Search Wrapper
 */

export const SearchWrapper = styled("div")`
  display: block;
  position: relative;
  grid-area: search;
  justify-self: start;
  width: 100%;
`

/**
 * Navigation Wrapper
 */

export const NavigationWrapper = styled("div")`
  display: grid;
  position: relative;
  height: 32px;
  grid-auto-columns: max-content;
  grid-template-rows: 1fr;
  grid-auto-flow: column;
  grid-column-gap: ${theme.offset3};
  justify-self: start;
  align-self: end;
  grid-area: navigation;

  @media all and (max-width: 1440px) {
    justify-self: center;
  }
`

/**
 * Breadcrumb Wrapper
 */

export const BreadcrumbWrapper = styled("div")`
  display: block;
  position: relative;
  grid-area: breadcrumb;
  justify-self: start;
`

/**
 * Page Content Wrapper
 */

type ContentWrapperProps = {
  hasNavigation?: boolean
}

export const ContentWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  width: 100%;
  margin-top: 130px;
  padding-left: ${theme.offset4};
  padding-right: ${theme.offset4};
  padding-bottom: ${theme.offset3};

  ${(props: ContentWrapperProps) =>
    props.hasNavigation
      ? css`
          @media all and (max-width: 1440px) {
            margin-top: 202px;
          }
        `
      : ""};
`

/**
 * Data Status Wrapper
 */

export const DataStatusWrapper = styled("div")`
  display: flex;
  position: relative;
  width: 100%;
  margin-top: ${theme.offset3};
  justify-content: center;
  align-items: center;
  height: 50px;
`
