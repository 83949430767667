import React, { useContext } from "react"
import { Heading2, Paragraph } from "components/typography"
import { Input } from "components/input"
import { Button } from "components/button"
import styled from "styled-components"
import { Modal, ModalSection } from "components/modal"
import { Portal } from "components/portal"
import { Mutation } from "react-apollo"
import { Label } from "components/label"
import { Warning } from "components/warning"
import { getFormattedPageNumberToSend, getPageOverrideErrorMessage, stringContainOnlyDigitsOrEmpty } from "../helpers"
import { LayoutStoreContext } from "../layout-context"
import {
  CreateVariantMutation,
  CreateVariantMutationResult,
  MUTATION_CREATE_VARIANT,
  useCreateVariant,
  UseCreateVariantArgs,
} from "model/graphql/mutations"
import { layoutModalActionTypes } from "../layout-context/modal-reducer"
import { routes } from "router"
import { RouteComponentProps } from "react-router"

type Props = {
  layoutId: string
  history: RouteComponentProps["history"]
  children?: never
}

export const CreateVariantModal = (props: Props) => {
  const { layoutId, history } = props
  const { layoutModalDispatch, layoutModalState } = useContext(LayoutStoreContext)
  const { pageTo = "", pageFrom = "" } = layoutModalState

  /**
   * Change Page From
   *
   * @param value
   */

  const changePageFrom = (value: string) => {
    layoutModalDispatch({ type: layoutModalActionTypes.resetPageOverrideErrorMessage })
    layoutModalDispatch({ type: layoutModalActionTypes.setPageFrom, value: value })
  }

  /**
   * Change Page To
   *
   * @param value
   */

  const changePageTo = (value: string) => {
    layoutModalDispatch({ type: layoutModalActionTypes.resetPageOverrideErrorMessage })
    layoutModalDispatch({ type: layoutModalActionTypes.setPageTo, value: value })
  }

  /**
   * Get Save Button Disable State
   *
   * @param loading
   */

  const isSaveButtonDisabled = (loading: boolean) => {
    if (
      !stringContainOnlyDigitsOrEmpty(pageFrom) ||
      !stringContainOnlyDigitsOrEmpty(pageTo) ||
      (pageFrom === "" && pageTo === "")
    ) {
      return true
    }
    return loading
  }

  /**
   * Close Modal
   */

  const closeModal = () => {
    // Reset values
    layoutModalDispatch({ type: layoutModalActionTypes.resetPages })
    layoutModalDispatch({ type: layoutModalActionTypes.resetPageOverrideErrorMessage })

    // Close modal
    layoutModalDispatch({ type: layoutModalActionTypes.closeCreateVariantModal })
  }

  /**
   * Create Variant
   */

  const handleSuccess: UseCreateVariantArgs["onSuccessAction"] = variantId => {
    history.push(routes.layoutVariant.replace(":layoutId", layoutId).replace(":variantId", variantId))
    closeModal()
  }

  const handleError: UseCreateVariantArgs["onErrorAction"] = errorMessage => {
    layoutModalDispatch({
      type: layoutModalActionTypes.setPageOverrideErrorMessage,
      value: getPageOverrideErrorMessage(errorMessage),
    })
  }

  const { createVariant } = useCreateVariant({
    onSuccessAction: handleSuccess,
    onErrorAction: handleError,
  })

  /**
   * JSX
   */

  return (
    <Mutation mutation={MUTATION_CREATE_VARIANT}>
      {(createVariantMutation: CreateVariantMutation, { loading }: CreateVariantMutationResult) => (
        <Portal>
          <Modal focusIsTrapped={true} isVisible={layoutModalState.isCreateVariantModalOpen} closeAction={closeModal}>
            <ModalSection>
              <Heading2>{`Page Override`}</Heading2>
            </ModalSection>

            <ModalSection topOffset={"20px"}>
              <Paragraph>
                {`You can override ad settings for individual quiz pages. Specify the range of pages to which the new
                settings should apply.`}
              </Paragraph>
            </ModalSection>

            <ModalSection topOffset={"20px"}>
              <Paragraph>
                {`If you want the new settings to be applied to a single page only, put the page number to both fields.`}
              </Paragraph>
            </ModalSection>

            <ModalSection topOffset={"20px"}>
              <Paragraph>
                {`You can also leave one end of the range open by not putting any number to the corresponding field.`}
              </Paragraph>
            </ModalSection>

            <ModalSection topOffset={"40px"}>
              <Form>
                <FormField>
                  <Label>{`From page`}</Label>
                  <Input
                    value={pageFrom}
                    onChange={changePageFrom}
                    hasWarning={Boolean(layoutModalState.pageOverrideErrorMessage)}
                  />
                </FormField>

                <FormField>
                  <Label>{`to`}</Label>
                  <Input
                    value={pageTo}
                    onChange={changePageTo}
                    hasWarning={Boolean(layoutModalState.pageOverrideErrorMessage)}
                  />
                </FormField>

                <Warning message={layoutModalState.pageOverrideErrorMessage} />
              </Form>
            </ModalSection>

            <ModalSection topOffset={"40px"}>
              <Button
                color={"green"}
                size={"regular"}
                variant={"filled"}
                type={"button"}
                isDisabled={isSaveButtonDisabled(loading)}
                onClick={createVariant(createVariantMutation, {
                  layoutId: props.layoutId,
                  pageFrom: getFormattedPageNumberToSend(pageFrom),
                  pageTo: getFormattedPageNumberToSend(pageTo),
                })}
              >
                {`Save`}
              </Button>
            </ModalSection>
          </Modal>
        </Portal>
      )}
    </Mutation>
  )
}

const Form = styled("form")`
  display: grid;
  position: relative;
  grid-template-columns: repeat(2, max-content);
  grid-template-rows: max-content max-content;
  grid-template-areas:
    ". ."
    "warning warning";
  grid-column-gap: 80px;
  justify-content: center;
  width: 480px;
`

const FormField = styled("div")`
  display: grid;
  position: relative;
  grid-template-columns: max-content 80px;
  grid-template-rows: max-content;
  grid-template-areas: "label .";
  grid-column-gap: 20px;
  grid-row-gap: 6px;
  align-items: center;
  justify-items: end;
  width: min-content;
`
