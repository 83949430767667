import React from "react"
import styled from "styled-components"
import { theme } from "theme"

type Props = {
  children?: never
}

export const UnknownTableAvatar = (props: Props) => {
  return <UnknownAvatar />
}

const UnknownAvatar = styled("div")`
  display: block;
  position: relative;
  height: 50px;
  width: 50px;
  border-radius: ${theme.rounding2};
  background-color: ${theme.color.borderGray};
`
