import React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { routes } from "router"
import { ImageLogo } from "../images"
import { theme } from "theme"

type Props = {
  children?: React.ReactNode
}

export const Header = (props: Props) => {
  return (
    <Container>
      <Link to={routes.root} className={"logo-container"}>
        <ImageLogo />
      </Link>
      {props.children}
    </Container>
  )
}

export const height = "70px"

const Container = styled("header")`
  display: grid;
  position: fixed;
  top: 0;
  left: 0;
  grid-auto-flow: column;
  grid-template-columns: max-content auto max-content max-content;
  grid-template-rows: 1fr;
  grid-template-areas: "header-logo header-navigation separator header-dropdown";
  align-items: center;
  justify-items: end;
  height: ${height};
  width: 100%;
  background-color: ${theme.color.white};
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  z-index: 999;

  .logo-container {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 26px;
    margin: 0 ${theme.offset3};
  }
`
