import React from "react"
import { BreadcrumbLink } from "components/breadcrumb"
import { routes } from "router"
import { useSelector } from "react-redux"
import { ReduxState } from "redux-store"
import { truncate, isEqual } from "lodash"

/**
 * Quizzes Breadcrumb Link
 */

export const QuizzesBreadcrumbLink = () => <BreadcrumbLink path={routes.quizzes}>{`Quizzes`}</BreadcrumbLink>

/**
 * Quiz Breadcrumb Link
 */

export const QuizGeneralBreadcrumbLink = () => {
  const quizTitle = useSelector((state: ReduxState) => state.quizGeneral.title, isEqual)

  return <BreadcrumbLink path={routes.quiz}>{truncate(quizTitle)}</BreadcrumbLink>
}

export const QuizQuestionsBreadcrumbLink = () => {
  const quizTitle = useSelector((state: ReduxState) => state.quizQuestions.title, isEqual)

  return <BreadcrumbLink path={routes.quiz}>{truncate(quizTitle)}</BreadcrumbLink>
}

/**
 * Quiz General Tab Breadcrumb Link
 */

export const QuizGeneralTabBreadcrumbLink = () => <BreadcrumbLink path={routes.quizGeneral}>{`General`}</BreadcrumbLink>

/**
 * Quiz Questions Tab Breadcrumb Link
 */

export const QuizQuestionsTabBreadcrumbLink = () => (
  <BreadcrumbLink path={routes.quizQuestions}>{`Questions`}</BreadcrumbLink>
)
