import React from "react"
import { IconProps } from "./types"
import { Svg } from "./styles"

type Props = IconProps

export const IconUpload = (props: Props) => {
  return (
    <Svg color={props.color} xmlns="http://www.w3.org/2000/svg" width="33" height="21" viewBox="0 0 33 21">
      <g id="Upload_Icon" transform="translate(-1075.499 -451.5)">
        <path
          d="M715.1,123.688h-2.8a6,6,0,1,1,0-12,5.807,5.807,0,0,1,2.13.4,10,10,0,0,1,19.72-.111,6,6,0,0,1-1.85,11.711h-1.642"
          transform="translate(369.699 348.312)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <g transform="translate(1088.091 458.088)">
          <path
            d="M724.029,119.383l4.419-4.419,4.419,4.419"
            transform="translate(-724.029 -114.963)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            y1="10.312"
            transform="translate(4.419)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <line
          x2="8.878"
          transform="translate(1088.072 472)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </Svg>
  )
}
