import React from "react"
import { Redirect, RouteComponentProps } from "react-router"
import { routes } from "router"
import { Box } from "components/box"
import { Heading1 } from "components/typography"
import { NavigationLink } from "components/links"
import { Button } from "components/button"
import { FormRow } from "components/form-row"
import { Input } from "components/input"
import { useMutation } from "react-apollo"
import { AuthDispatch, useChangePageTitle } from "utils"
import { FormActionsWrapper, Grid } from "../screen"
import { Warning } from "components/warning"
import { Form } from "components/form"
import { MUTATION_LOGIN, useLogIn, UseLogInArgs } from "model/graphql/mutations"
import { Label } from "components/label"
import { useDispatch, useSelector } from "react-redux"
import { ReduxState } from "redux-store"
import { isEqual } from "lodash"
import { authActionTypes } from "redux-reducers"

/**
 * Props
 */

type Props = {
  children?: never
} & RouteComponentProps

/**
 * Login Screen
 */

export const LoginScreen = (props: Props) => {
  const [username, setUsername] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [usernameWarning, setUsernameWarning] = React.useState("")
  const [passwordWarning, setPasswordWarning] = React.useState("")

  const auth = useSelector((state: ReduxState) => state.auth, isEqual)
  const dispatch = useDispatch()

  const [logInMutation, { loading }] = useMutation(MUTATION_LOGIN)

  /**
   * Change Page Title
   */

  useChangePageTitle(props.match.path)

  /**
   * Change Username
   */

  const handleUsernameChange = (value: string) => {
    setUsername(value)

    if (usernameWarning !== "") {
      setUsernameWarning("")
    }
  }

  /**
   * Change Password
   */

  const handlePasswordChange = (value: string) => {
    setPassword(value)

    if (passwordWarning !== "") {
      setPasswordWarning("")
    }
  }

  /**
   * Log In
   */

  const handleSuccess: UseLogInArgs["onSuccessAction"] = () => {
    dispatch<AuthDispatch>({ type: authActionTypes.setIsAuthenticated, data: { isAuthenticated: true } })
  }

  const handleError: UseLogInArgs["onErrorAction"] = errorMessage => {
    const formattedMessage = errorMessage.replace("GraphQL error: ", "")

    setUsernameWarning(formattedMessage)
    setPasswordWarning(formattedMessage)
  }

  const { logIn } = useLogIn({
    onSuccessAction: handleSuccess,
    onErrorAction: handleError,
  })

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    await logIn(logInMutation, { username, password })
  }

  /**
   * Redirect if user is authenticated
   */

  const { from } = props.location.state || { from: { pathname: routes.root } }
  if (auth.isAuthenticated) return <Redirect to={from} />

  return (
    <Grid type={1}>
      <Heading1>Login</Heading1>
      <Box>
        <Form onSubmit={handleSubmit} method={"post"} type={1}>
          <FormRow>
            <Label>{"Your email"}</Label>
            <Input value={username} onChange={handleUsernameChange} isDisabled={loading} />
            <Warning message={usernameWarning} />
          </FormRow>

          <FormRow>
            <Label>{"Password"}</Label>
            <Input type={"password"} value={password} onChange={handlePasswordChange} isDisabled={loading} />
            <Warning message={passwordWarning} />
          </FormRow>

          <FormActionsWrapper>
            <Button
              type={"submit"}
              color={"green"}
              variant={"filled"}
              size={"regular"}
              isDisabled={!(username && password) || loading}
            >
              Login
            </Button>

            <NavigationLink color={"green"} weight={"regular"} size={"16px"} to={routes.passwordRecovery}>
              I forgot my password
            </NavigationLink>
          </FormActionsWrapper>
        </Form>
      </Box>
    </Grid>
  )
}
