import React from "react"
import styled from "styled-components"
import { theme } from "theme"

type Props = {
  isPublished: boolean
  children?: never
}

export const QuizStatus = (props: Props) => {
  const { isPublished } = props
  return (
    <Container>
      <CircleWrapper publishedStatus={isPublished}>{isPublished ? `◉` : `●`}</CircleWrapper>
      {isPublished ? " Published" : " Draft"}
    </Container>
  )
}

type StyledProps = {
  publishedStatus: boolean
}

const CircleWrapper = styled("span")`
  color: ${(props: StyledProps) => (props.publishedStatus ? theme.color.primaryGreen : theme.color.draftOrange)};
`
const Container = styled("p")`
  font-family: ${theme.font.family.default};
  font-weight: ${theme.font.weight.regular};
  color: ${theme.color.textBlack};
  font-size: 16px;
  white-space: nowrap;
`
