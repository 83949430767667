import React, { useContext, useEffect } from "react"
import { RouteComponentProps } from "react-router"
import { Mutation, Query, QueryResult } from "react-apollo"
import { Rule, RuleVariables } from "model/types"
import { routes } from "router"
import { actionTypes as formActionTypes, useFormState } from "./form-state"
import { Form } from "./form"
import { actionTypes as modalActionTypes, ModalContext } from "../../providers/modal"
import { Error, Loading } from "components/data-status"
import { MUTATION_RULE_CHANGE } from "model/graphql/mutations"
import { QUERY_RULE } from "model/graphql/queries"
import { useActivateSuccessModal, useChangePageTitle, usePreventNavigatingAway } from "utils"
import {
  getFormattedNameWarning,
  getFormattedUtmSourceWarning,
  isSaveButtonDisabled,
  RuleChangeMutation,
  RuleChangeMutationResult,
  useChangeRule,
} from "./helpers"
import { ContentWrapper, DataStatusWrapper, PageContainer, SubPageHeader } from "components/page"

type Props = {
  children?: never
} & RouteComponentProps<{ ruleId: string }>

export const EditRuleScreen = (props: Props) => {
  const { ruleId } = props.match.params

  return (
    <PageContainer>
      <Query query={QUERY_RULE} variables={{ id: ruleId } as RuleVariables}>
        {({ data, loading, error }: QueryResult<Rule>) => {
          if (error) {
            return (
              <DataStatusWrapper>
                <Error>{error.message}</Error>
              </DataStatusWrapper>
            )
          }
          if (!data || !data.rule || !data.layouts || loading) {
            return (
              <DataStatusWrapper>
                <Loading>Loading...</Loading>
              </DataStatusWrapper>
            )
          }
          return <ScreenContent {...props} data={data} />
        }}
      </Query>
    </PageContainer>
  )
}

type ContentProps = {
  data: QueryResult<Rule>["data"]
} & Props

const ScreenContent = (props: ContentProps) => {
  const { state: formState, dispatch: formDispatch } = useFormState()
  const { data } = props

  /**
   * Change Page Title
   */

  useChangePageTitle(props.match.path)

  useEffect(() => {
    formDispatch({ type: formActionTypes.setName, data: { name: data.rule.name } })
    formDispatch({ type: formActionTypes.setUtmSource, data: { utmSource: data.rule.utmSource } })
    formDispatch({ type: formActionTypes.setLayoutId, data: { layoutId: data.rule.layout.id } })
  }, [formDispatch, data])

  const { dispatch: modalDispatch } = useContext(ModalContext)

  useEffect(() => {
    if (
      data.rule.name !== formState.name ||
      data.rule.utmSource !== formState.utmSource ||
      data.rule.layout.id !== formState.layoutId
    ) {
      modalDispatch({ type: modalActionTypes.setCanLeavePage, value: false })
    } else {
      modalDispatch({ type: modalActionTypes.setCanLeavePage, value: true })
    }
  }, [
    modalDispatch,
    data.rule.name,
    data.rule.utmSource,
    data.rule.layout.id,
    formState.name,
    formState.utmSource,
    formState.layoutId,
  ])

  const { history } = props

  usePreventNavigatingAway({ history })

  /**
   * Change Rule
   */

  const { activateSuccessModal } = useActivateSuccessModal()

  const handleChangeRuleSuccess = () => {
    activateSuccessModal()
  }

  const handleChangeRuleError = (errorMessage: string) => {
    formDispatch({ type: formActionTypes.setNameWarning, data: { nameWarning: getFormattedNameWarning(errorMessage) } })
    formDispatch({
      type: formActionTypes.setUtmSourceWarning,
      data: { utmSourceWarning: getFormattedUtmSourceWarning(errorMessage) },
    })
  }

  const { changeRule } = useChangeRule({
    onSuccessAction: handleChangeRuleSuccess,
    onErrorAction: handleChangeRuleError,
  })

  const saveRule = (ruleChangeMutation: RuleChangeMutation) => async () => {
    await changeRule(ruleChangeMutation, {
      id: data.rule.id,
      name: formState.name,
      utmSource: formState.utmSource,
      layoutId: formState.layoutId,
    })
  }

  /**
   * JSX
   */

  return (
    <React.Fragment>
      <Mutation mutation={MUTATION_RULE_CHANGE}>
        {(ruleChangeMutation: RuleChangeMutation, { loading }: RuleChangeMutationResult) => {
          return (
            <React.Fragment>
              <SubPageHeader
                breadcrumbLinks={[
                  { name: `Layout Rules`, path: routes.layoutRules },
                  { name: data.rule.name, path: routes.layoutRuleEdit },
                ]}
                heading={data.rule.name}
                button={{
                  title: `Save`,
                  action: saveRule(ruleChangeMutation),
                  isDisabled: isSaveButtonDisabled(formState, loading),
                }}
              />

              <ContentWrapper>
                <Form state={formState} dispatch={formDispatch} layouts={data.layouts} />
              </ContentWrapper>
            </React.Fragment>
          )
        }}
      </Mutation>
    </React.Fragment>
  )
}
