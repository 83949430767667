import React from "react"
import { Tab } from "components/tab"
import { NavigationWrapper } from "components/page"
import { routes } from "router"

type Props = {
  tab: string
  quizId: string
  children?: never
}

export const QuizNavigationTabs = (props: Props) => {
  const { tab, quizId } = props

  return (
    <NavigationWrapper>
      <Tab to={routes.quizGeneral.replace(":tab", tab).replace(":quizId", quizId)}>{`General`}</Tab>
      <Tab to={routes.quizQuestions.replace(":tab", tab).replace(":quizId", quizId)}>{`Questions`}</Tab>
    </NavigationWrapper>
  )
}
