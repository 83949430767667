import React, { useEffect, useState } from "react"
import { Button } from "components/button"
import { IconUpload } from "components/icons"
import { theme } from "theme"
import styled from "styled-components"
import { Spinner } from "components/spinner"
import { useMutation } from "@apollo/react-hooks"
import { MUTATION_CHECK_REBUILD_STATUS, MUTATION_START_REBUILD } from "model/graphql/mutations/rebuild"
import { MutationResult } from "react-apollo"
import { CheckRebuildStatus, StartRebuild } from "model/types"

type Props = {
  children?: never
}

export const RebuildButton: React.FC<Props> = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [isBuilding, setIsBuilding] = useState(false)
  const [startRebuildMutation, { loading: startRebuildLoading }] = useMutation(MUTATION_START_REBUILD)
  const [checkRebuildStatus] = useMutation(MUTATION_CHECK_REBUILD_STATUS)

  /**
   * Initiate Rebuild
   */
  const handleClick = async () => {
    setIsButtonDisabled(true)

    try {
      const result = (await startRebuildMutation()) as MutationResult<StartRebuild>
      const { success } = result.data.rebuildStart
      if (success) {
        setIsBuilding(true)
      }
      if (!success) {
        setIsButtonDisabled(false)
      }
    } catch (error) {
      setIsButtonDisabled(false)
      console.error(error)
    }
  }

  /**
   * Check Rebuild Status and change Rebuild Website button status if needed
   */

  const rebuildStatusChecker = async () => {
    try {
      const result = (await checkRebuildStatus()) as MutationResult<CheckRebuildStatus>
      const { report } = result.data.rebuildStatus

      if (isBuilding === report.canBuild) {
        setIsBuilding(!report.canBuild)
        setIsButtonDisabled(!report.canBuild)
      }
    } catch (error) {
      console.error(error)
    }
  }

  /**
   * Sets interval for checking Rebuild Status
   */

  useEffect(() => {
    rebuildStatusChecker()

    const interval = window.setInterval(async () => {
      rebuildStatusChecker()
    }, 1000 * 5)

    return () => clearInterval(interval)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * JSX
   */
  return (
    <ButtonWrapper>
      <Button
        icon={isBuilding ? <Spinner color={theme.color.white} /> : <IconUpload color={theme.color.white} />}
        width={"233px"}
        onClick={handleClick}
        isDisabled={isButtonDisabled || isBuilding || startRebuildLoading}
      >
        {isBuilding ? "Rebuilding..." : "Rebuild Content"}
      </Button>
    </ButtonWrapper>
  )
}

const ButtonWrapper = styled("div")`
  display: flex;
  position: relative;
  align-items: center;
  height: 100%;
  padding: 0 10px;
`
