import React from "react"
import styled from "styled-components"
import { theme } from "../../theme"

type Props = {
  children?: never
  checked: boolean
  onChange: (isChecked: boolean) => void
}

export const Switch = (props: Props) => {
  const { checked, onChange } = props

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked)
  }

  return (
    <Container>
      <Track isChecked={checked} />
      <ThumbUnderlay isChecked={checked}>
        <Thumb>
          <Input type="checkbox" role="switch" isChecked={checked} onChange={handleChange} checked={checked} />
        </Thumb>
      </ThumbUnderlay>
    </Container>
  )
}

const Container = styled("div")`
  display: block;
  position: relative;
  width: 40px;
  height: 24px;
`

type TrackProps = {
  isChecked: Props["checked"]
}

const Track = styled("div")`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${(props: TrackProps) => (props.isChecked ? theme.color.primaryGreen : theme.color.textNonactive)};
  border-radius: 9999px;
`

type ThumbUnderlayProps = {
  isChecked: Props["checked"]
}

const ThumbUnderlay = styled("div")`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  top: -8px;
  left: -8px;
  transform-origin: center center;
  transform: ${(props: ThumbUnderlayProps) => (props.isChecked ? "translate3d(16px, 0, 0)" : "translate3d(0, 0, 0)")};
`

const Thumb = styled("div")`
  display: block;
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: ${theme.color.white};
`

type InputProps = {
  isChecked: Props["checked"]
}

const Input = styled("input")`
  display: block;
  position: absolute;
  top: -8px;
  left: -8px;
  right: auto;
  width: 60px;
  height: 40px;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  pointer-events: auto;
  transform-origin: center center;
  transform: ${(props: InputProps) => (props.isChecked ? "translate3d(-16px, 0, 0)" : "translate3d(0, 0, 0)")};
`
