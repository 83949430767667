import React, { useContext, useEffect, useState } from "react"
import { Heading2 } from "components/typography"
import { Button } from "components/button"
import { IconDeleteSmall } from "components/icons"
import styled from "styled-components"
import { theme } from "theme"
import { Query, QueryResult } from "react-apollo"
import { QUERY_VENDORS } from "model/graphql/queries"
import { Vendors } from "model/types"
import { Select } from "components/select"
import { LayoutStoreContext } from "screens/layout/layout-context"
import { Option, SlotObject } from "screens/layout/layout-context/variant-reducer"

type Props = {
  slotName: string
  slotActionType: string
  selectedOptions: {
    selectedVendorOption: Option
    selectedUnitOption: Option
  }
  selectedOptionsActionType: string
}

export const SelectBox = (props: Props) => {
  const { slotName, slotActionType, selectedOptionsActionType, selectedOptions } = props
  const { variantDispatch } = useContext(LayoutStoreContext)

  /**
   * Clean Slot
   */

  const cleanSlot = () => {
    variantDispatch({ type: slotActionType, data: { unitId: "" } })
    variantDispatch({
      type: selectedOptionsActionType,
      data: {
        options: {
          selectedVendorOption: { id: undefined, value: undefined },
          selectedUnitOption: { id: undefined, value: undefined },
        },
      },
    })
  }

  /**
   * Variables
   */

  const isClearButtonVisible =
    Boolean(selectedOptions.selectedVendorOption.id) || Boolean(selectedOptions.selectedUnitOption.id)
  const isFilled = Boolean(selectedOptions.selectedUnitOption.id)

  /**
   * JSX
   */

  return (
    <SelectBoxContainer isFilled={isFilled} gridArea={slotName}>
      <HeaderContainer>
        <TitleContainer>
          <Heading2>{slotName}</Heading2>
        </TitleContainer>

        <ActionContainer>
          {isClearButtonVisible && (
            <Button
              icon={<IconDeleteSmall />}
              color={"red"}
              size={"small"}
              variant={"outlined"}
              type={"button"}
              onClick={cleanSlot}
            >
              {`Clean`}
            </Button>
          )}
        </ActionContainer>
      </HeaderContainer>

      <Query query={QUERY_VENDORS}>
        {({ data }: QueryResult<Vendors>) => {
          return (
            <BodyContainer>
              <Selects
                data={data}
                slotName={slotName}
                slotActionType={slotActionType}
                selectedOptions={selectedOptions}
                selectedOptionsActionType={selectedOptionsActionType}
              />
            </BodyContainer>
          )
        }}
      </Query>
    </SelectBoxContainer>
  )
}

type SelectsProps = {
  data: QueryResult<Vendors>["data"]
  slotActionType: string
  selectedOptions: {
    selectedVendorOption: Option
    selectedUnitOption: Option
  }
  selectedOptionsActionType: string
  slotName: string
}

const Selects = (props: SelectsProps) => {
  const { data, slotName, selectedOptions, selectedOptionsActionType, slotActionType } = props
  const { variantState, variantDispatch } = useContext(LayoutStoreContext)

  const [vendorOptions, setVendorOptions] = useState([] as Option[])
  const [filteredVendorOptions, setFilteredVendorOptions] = useState(undefined)

  const [unitOptions, setUnitOptions] = useState([] as Option[])
  const [filteredUnitOptions, setFilteredUnitOptions] = useState(undefined)

  const [vendorSearchValue, setVendorSearchValue] = useState("")
  const [unitSearchValue, setUnitSearchValue] = useState("")

  /**
   * Set Vendor Options
   */

  useEffect(() => {
    if (data && data.vendors) {
      const options = data.vendors.edges
        // Filter out vendors with used units and units not for this slot
        .filter(({ node: vendor }) => {
          return vendor.unitSet.edges.some(({ node: unit }) => {
            const slots: { [key: string]: SlotObject } = variantState.slots
            const unitIds = Object.keys(slots).map(key => slots[key].unitId)
            return !unitIds.some(unitId => unitId === unit.id) && unit.slotCompatibility.includes(slotName)
          })
        })
        // Transform into option
        .map(({ node: vendor }): Option => ({ id: vendor.id, value: vendor.name }))
      setVendorOptions(options)
      setFilteredVendorOptions(options)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, variantState.slots])

  /**
   * Set Unit Options
   */

  useEffect(() => {
    if (data && data.vendors && selectedOptions.selectedVendorOption.id) {
      const [vendor] = data.vendors.edges
        // Get only selected vendor units
        .filter(({ node: vendor }) => vendor.id === selectedOptions.selectedVendorOption.id)

      const options = vendor.node.unitSet.edges
        // Filter slot compatible units
        .filter(({ node: unit }) => {
          return unit.slotCompatibility.some(slot => {
            return new RegExp(slotName).test(slot)
          })
        })

      // Filter used units
      if (options.length > 1) {
        options.filter(({ node: unit }) => {
          const slots: { [key: string]: SlotObject } = variantState.slots
          const unitIds = Object.keys(slots).map(key => slots[key].unitId)
          return !unitIds.some(unitId => unitId === unit.id)
        })
      }

      const availableUnits = options.map(
        ({ node: unit }): Option => ({
          id: unit.id,
          value: unit.name,
        })
      )

      setUnitOptions(availableUnits)
      setFilteredUnitOptions(availableUnits)
    }
  }, [data, slotName, variantState.slots, selectedOptions.selectedVendorOption.id])

  /**
   * Filter vendors
   */

  useEffect(() => {
    if (vendorOptions.length !== 0) {
      const newVendorOptions = vendorOptions.filter((option: Option) =>
        new RegExp(vendorSearchValue, "gi").test(option.value)
      )
      setFilteredVendorOptions(newVendorOptions)
    }
  }, [vendorSearchValue, vendorOptions])

  /**
   * Filter units
   */

  useEffect(() => {
    if (unitOptions.length !== 0) {
      const newUnitOptions = unitOptions.filter((option: Option) =>
        new RegExp(unitSearchValue, "gi").test(option.value)
      )
      setFilteredUnitOptions(newUnitOptions)
    }
  }, [unitSearchValue, unitOptions])

  /**
   * Change Vendor Options
   */

  const changeVendorOption = ({ id: vendorId, value: vendorName }: Option) => {
    variantDispatch({
      type: selectedOptionsActionType,
      data: {
        options: {
          selectedVendorOption: { id: vendorId, value: vendorName },
          selectedUnitOption: { id: undefined, value: undefined },
        },
      },
    })
    variantDispatch({ type: slotActionType, data: { unitId: undefined } })
  }

  /**
   * Change Unit Options
   */

  const changeUnitOption = ({ id: unitId, value: unitName }: Option) => {
    variantDispatch({
      type: selectedOptionsActionType,
      data: {
        options: {
          selectedVendorOption: selectedOptions.selectedVendorOption,
          selectedUnitOption: { id: unitId, value: unitName },
        },
      },
    })
    variantDispatch({ type: slotActionType, data: { unitId: unitId } })
  }

  /**
   * Variables
   */

  const isVendorSelectDisabled = vendorOptions.length === 0
  const isUnitSelectDisabled = !selectedOptions.selectedVendorOption.id || unitOptions.length === 0

  /**
   * JSX
   */

  return (
    <React.Fragment>
      <SelectContainer>
        <Select
          placeholder={"Choose Vendor"}
          onSelect={changeVendorOption}
          options={filteredVendorOptions || []}
          optionsCount={vendorOptions.length}
          selectedOption={{
            id: selectedOptions.selectedVendorOption.id,
            name: selectedOptions.selectedVendorOption.value,
          }}
          search={{
            onChange: setVendorSearchValue,
            placeholder: "start typing",
            value: vendorSearchValue,
          }}
          isDisabled={isVendorSelectDisabled}
        />
      </SelectContainer>
      <SelectContainer>
        <Select
          placeholder={"Choose Unit"}
          onSelect={changeUnitOption}
          optionsCount={unitOptions.length}
          options={filteredUnitOptions || []}
          selectedOption={{
            id: selectedOptions.selectedUnitOption.id,
            name: selectedOptions.selectedUnitOption.value,
          }}
          search={{
            onChange: setUnitSearchValue,
            placeholder: "start typing",
            value: unitSearchValue,
          }}
          isDisabled={isUnitSelectDisabled}
        />
      </SelectContainer>
    </React.Fragment>
  )
}

/**
 * Styles
 */

type SelectBoxContainerProps = {
  isFilled: boolean
  gridArea: string
}

const SelectBoxContainer = styled("div")`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-width: 340px;
  border-radius: ${theme.rounding2};
  border-width: 4px;
  border-style: solid;
  border-color: ${(props: SelectBoxContainerProps) =>
    props.isFilled ? theme.color.primaryGreen : theme.color.draftOrange};
  background-color: ${theme.color.white};
  grid-area: ${(props: SelectBoxContainerProps) => props.gridArea};
`

const HeaderContainer = styled("div")`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: ${theme.offset1} ${theme.offset1} 0 ${theme.offset1};
`

const TitleContainer = styled("div")`
  display: flex;
  position: relative;
  justify-content: flex-start;
  width: 50%;
`

const ActionContainer = styled("div")`
  display: flex;
  position: relative;
  justify-content: flex-end;
  width: 50%;
`

const BodyContainer = styled("div")`
  display: grid;
  position: relative;
  grid-template-columns: repeat(auto-fit, minmax(315px, 1fr));
  width: 100%;
  padding: 10px;
`

const SelectContainer = styled("div")`
  display: block;
  position: relative;
  width: 100%;
  padding: 10px;
`
