import React from "react"
import { IconDelete, IconDuplicate, IconEye } from "../icons"
import { Tooltip } from "../tooltip"
import { animated, useSpring } from "react-spring"
import styled from "styled-components"
import { theme } from "theme"

type Props = {
  actionType: "delete" | "duplicate" | "view"
  isDisabled?: boolean
  isDefaultVisible?: boolean
  onClick: () => void
  children?: never
}

export const ActionButton = (props: Props) => {
  const { actionType, onClick, isDefaultVisible = false } = props

  const [isTooltipOpened, setIsTooltipOpened] = React.useState(false)

  const tooltipVisibility = useSpring({ opacity: isTooltipOpened ? 1 : 0 })

  const getTooltipTitle = (actionType: "delete" | "duplicate" | "view") => {
    return actionType.charAt(0).toUpperCase() + actionType.slice(1)
  }

  return (
    <React.Fragment>
      <TooltipContainer style={tooltipVisibility}>
        {!props.isDisabled && <Tooltip value={getTooltipTitle(actionType)} />}
      </TooltipContainer>
      <Button
        actionType={actionType}
        onMouseOver={() => setIsTooltipOpened(true)}
        onMouseLeave={() => setIsTooltipOpened(false)}
        onClick={onClick}
        isDefaultVisible={isDefaultVisible}
        isDisabled={props.isDisabled}
        disabled={props.isDisabled}
      >
        {actionType === "delete" && (
          <IconDelete color={props.isDisabled ? theme.color.warningRedLight : theme.color.warningRed} />
        )}
        {actionType === "duplicate" && <IconDuplicate color={theme.color.primaryGreen} />}
        {actionType === "view" && <IconEye color={theme.color.primaryGreen} />}
      </Button>
    </React.Fragment>
  )
}

const TooltipContainer = styled(animated.div)`
  position: relative;
  top: -50px;
  left: 30px;
  z-index: 1;
`

type ButtonProps = {
  actionType: Props["actionType"]
  isDisabled: boolean
  isDefaultVisible?: boolean
}

const Button = styled("button")`
  padding: 0;
  margin: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: ${(props: ButtonProps) => (props.isDisabled ? "not-allowed" : "pointer")};
  outline: none;
  visibility: ${(props: ButtonProps) => (props.isDefaultVisible ? "visible" : "hidden")};

  svg:hover {
    stroke: ${(props: ButtonProps) => {
      switch (props.actionType) {
        case "delete":
          if (props.isDisabled) {
            return theme.color.warningRedLight
          }
          return theme.color.warningRedDark
        case "duplicate":
        case "view":
          return theme.color.primaryGreenDark
        default:
          return theme.color.textBlack
      }
    }};
  }
`
