import { MutationResult, MutationFunction } from "react-apollo"
import { UserChange, UserChangeVariables } from "model/types"

export type UserChangeMutation = MutationFunction<UserChange, UserChangeVariables>
export type UserChangeMutationResult = MutationResult<UserChange>

type UseChangeUserArgs = {
  onSuccessAction: () => void
}

export const useChangeUser = (args: UseChangeUserArgs) => {
  const { onSuccessAction } = args

  const changeUser = async (userChangeMutation: UserChangeMutation, variables: UserChangeVariables) => {
    try {
      await userChangeMutation({
        variables,
      })
      onSuccessAction()
    } catch (error) {
      console.error(error)
    }
  }

  return { changeUser }
}
