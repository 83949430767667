import styled, { css } from "styled-components"
import { theme } from "../../theme"

export const BreadcrumbWrapper = styled("div")`
  display: block;
  position: relative;
  grid-area: breadcrumb;
  justify-self: start;
`

export const HeadingWrapper = styled("div")`
  display: block;
  position: relative;
  grid-area: heading;
  justify-self: start;
`

export const NavigationWrapper = styled("div")`
  display: grid;
  position: relative;
  height: 32px;
  grid-auto-columns: max-content;
  grid-template-rows: 1fr;
  grid-auto-flow: column;
  grid-column-gap: ${theme.offset3};
  justify-self: center;
  align-self: center;
  grid-area: navigation;

  @media all and (max-width: 1720px) {
    margin-top: 60px;
  }
`

export const ActionWrapper = styled("div")`
  display: grid;
  position: relative;
  grid-area: action;
  justify-self: end;
  align-self: start;
  grid-template-rows: max-content;
  grid-template-columns: max-content max-content;
  grid-column-gap: 20px;
  align-items: center;
`

const contentWrapperDefaultStyle = css`
  position: relative;
  grid-area: content;
  align-self: start;
`

export const ContentWrapper1 = styled("div")`
  ${contentWrapperDefaultStyle};
  display: block;
  width: 100%;
  margin-top: 80px;

  @media all and (max-width: 1720px) {
    margin-top: 40px;
  }
`

export const ContentWrapper2 = styled("div")`
  ${contentWrapperDefaultStyle};
  display: flex;
  justify-content: center;
  margin-top: 56px;
  width: 100%;
`

export const ContentWrapper3 = styled("div")`
  ${contentWrapperDefaultStyle};
  display: flex;
  justify-content: center;
  margin-top: 26px;
  width: 100%;
`

type DataStatusWrapperProps = { gridArea: "loading" | "error" }

export const DataStatusWrapper = styled("div")`
  display: block;
  position: relative;
  grid-area: ${(props: DataStatusWrapperProps) => props.gridArea};
  align-self: start;
  justify-self: center;
`
export const NoContentWrapper = styled("div")`
  display: block;
  position: relative;
  margin-top: 120px;
`

export const SearchWrapper = styled("div")`
  position: relative;
  grid-area: search;
  padding: 0 ${theme.offset3} 0 ${theme.offset3};
`

export const FormActionsWrapper = styled("div")`
  display: grid;
  position: relative;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  grid-row-gap: ${theme.offset1};
  justify-items: center;
`

export const ParagraphWrapper = styled("div")`
  display: block;
  position: relative;

  p {
    text-align: center;
    margin: 0;
  }
`
