import React, { useState } from "react"
import { Textarea } from "components/textarea"
import styled from "styled-components"
import { TextField } from "components/text-field"
import { AutoResizableCodeTextarea, AutoResizableTextarea } from "components/textarea/auto-resizable-textarea"
import { theme } from "theme"

type Props = {
  prefix: string
} & React.ComponentProps<typeof Textarea>

export const PermalinkTextarea = (props: Props) => {
  const { isDisabled, hasWarning, prefix, onChange, fontType, placeholder, value, hasStaticHeight } = props
  const [isFocused, setIsFocused] = useState(false)

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value)
  }

  const handleFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    setIsFocused(true)
    if (event.target.value) event.target.select()
  }

  const handleBlur = () => setIsFocused(false)

  return (
    <Container>
      <PrefixContainer isFocused={isFocused} isDisabled={isDisabled}>
        <Prefix>{prefix}</Prefix>
      </PrefixContainer>

      <TextField isFocused={isFocused} hasWarning={hasWarning} isDisabled={isDisabled} hasNoLeftBorder={true}>
        {fontType === "CODE" ? (
          <AutoResizableCodeTextarea
            placeholder={placeholder}
            value={value !== null ? value : ""}
            onChange={handleChange}
            minRows={hasStaticHeight ? 6 : 1}
            maxRows={6}
            disabled={isDisabled}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        ) : (
          <AutoResizableTextarea
            placeholder={placeholder}
            value={value !== null ? value : ""}
            onChange={handleChange}
            minRows={hasStaticHeight ? 6 : 1}
            maxRows={6}
            disabled={isDisabled}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        )}
      </TextField>
    </Container>
  )
}

const Container = styled("div")`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
`

type PermalinkContainerProps = {
  isDisabled: boolean
  isFocused: boolean
}

const PrefixContainer = styled("div")`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: flex-start;
  padding: 13px 14px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props: PermalinkContainerProps) => {
    if (props.isFocused) {
      return theme.color.textNonactive
    }
    if (props.isDisabled) {
      return theme.color.backgroundGray
    }
    return theme.color.borderGray
  }};
  border-top-left-radius: ${theme.rounding1};
  border-bottom-left-radius: ${theme.rounding1};
  background-color: ${theme.color.backgroundGray};
`

const Prefix = styled("span")`
  font-size: 16px;
  font-weight: ${theme.font.weight.regular};
  color: ${theme.color.textNonactive};
`
