/**
 * Initial state
 */

export const initialLayoutModalState = {
  isCreateVariantModalOpen: false,
  isEditVariantModalOpen: false,
  isDeleteVariantModalOpen: false,

  pageFrom: undefined as string,
  pageTo: undefined as string,

  pageOverrideErrorMessage: undefined as string,
}

/**
 * Action types
 */

export const layoutModalActionTypes = {
  openCreateVariantModal: "OPEN_CREATE_VARIANT_MODAL",
  closeCreateVariantModal: "CLOSE_CREATE_VARIANT_MODAL",

  openEditVariantModal: "OPEN_EDIT_VARIANT_MODAL",
  closeEditVariantModal: "CLOSE_EDIT_VARIANT_MODAL",

  openDeleteVariantModal: "OPEN_DELETE_VARIANT_MODAL",
  closeDeleteVariantModal: "CLOSE_DELETE_VARIANT_MODAL",

  setPageFrom: "SET_PAGE_FROM",
  setPageTo: "SET_PAGE_TO",
  resetPages: "RESET_PAGES",

  setPageOverrideErrorMessage: "SET_PAGE_OVERRIDE_ERROR_MESSAGE",
  resetPageOverrideErrorMessage: "RESET_PAGE_OVERRIDE_ERROR_MESSAGE",
}

/**
 * Reducer
 */

export type LayoutModalState = typeof initialLayoutModalState
export type LayoutModalAction = { type: string; value?: any }

export const layoutModalReducer = (
  state: LayoutModalState = initialLayoutModalState,
  action: LayoutModalAction
): LayoutModalState => {
  switch (action.type) {
    // Open create variant modal
    case layoutModalActionTypes.openCreateVariantModal:
      return {
        ...state,
        isCreateVariantModalOpen: true,
      }

    // Close create variant modal
    case layoutModalActionTypes.closeCreateVariantModal:
      return {
        ...state,
        isCreateVariantModalOpen: false,
      }

    // Open edit variant modal
    case layoutModalActionTypes.openEditVariantModal:
      return {
        ...state,
        isEditVariantModalOpen: true,
      }

    // Close edit variant modal
    case layoutModalActionTypes.closeEditVariantModal:
      return {
        ...state,
        isEditVariantModalOpen: false,
      }

    // Open delete variant modal
    case layoutModalActionTypes.openDeleteVariantModal:
      return {
        ...state,
        isDeleteVariantModalOpen: true,
      }

    // Close delete variant modal
    case layoutModalActionTypes.closeDeleteVariantModal:
      return {
        ...state,
        isDeleteVariantModalOpen: false,
      }

    // Set page from
    case layoutModalActionTypes.setPageFrom:
      return {
        ...state,
        pageFrom: action.value,
      }

    // Set page to
    case layoutModalActionTypes.setPageTo:
      return {
        ...state,
        pageTo: action.value,
      }

    // Reset pages
    case layoutModalActionTypes.resetPages:
      return {
        ...state,
        pageFrom: initialLayoutModalState.pageFrom,
        pageTo: initialLayoutModalState.pageTo,
      }

    // Set page override error message
    case layoutModalActionTypes.setPageOverrideErrorMessage:
      return {
        ...state,
        pageOverrideErrorMessage: action.value,
      }

    // Reset page override error message
    case layoutModalActionTypes.resetPageOverrideErrorMessage:
      return {
        ...state,
        pageOverrideErrorMessage: initialLayoutModalState.pageOverrideErrorMessage,
      }

    default:
      throw new Error(`"${action.type}" is not a valid action!`)
  }
}
