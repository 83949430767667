import React from "react"
import styled from "styled-components"
import { theme } from "theme"
import { SortingIndicator } from "components/table/sorting-indicator"

type Props = {
  children?: string
  arrowState?: "none" | "up" | "down"
  onClick?: () => void
}
export const ColumnHeading = (props: Props) => {
  const { children, arrowState, onClick } = props
  const handleClick = () => {
    onClick()
  }

  return onClick === undefined ? (
    <Title>{children}</Title>
  ) : (
    <ActionTitle onClick={handleClick}>
      <SortingIndicator state={arrowState} />
      <ActionTitleText>{children}</ActionTitleText>
    </ActionTitle>
  )
}

const ActionTitle = styled("button")`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }
`

const ActionTitleText = styled("span")`
  font-size: 14px;
  font-weight: ${theme.font.weight.semiBold};
  color: ${theme.color.primaryGreen};
  text-transform: uppercase;
  margin-left: 5px;
`

const Title = styled("span")`
  font-size: 14px;
  font-weight: ${theme.font.weight.regular};
  color: ${theme.color.textNonactive};
  text-transform: uppercase;
`
