import React, { useCallback } from "react"
import { RouteComponentProps } from "react-router"
import { routes } from "router"
import { Query, QueryResult } from "react-apollo"
import { Layouts, LayoutsVariables } from "model/types"
import { ActionButton, ActionContainer, ColumnHeading, Table } from "components/table"
import { AnchorLink } from "components/links"
import { DeleteLayoutModal } from "./delete-layout-modal"
import { Error, Loading } from "components/data-status"
import { QUERY_LAYOUTS } from "model/graphql/queries"
import { AddLayoutModal } from "screens/layouts/add-layout-modal"
import { capitalize, find, isEqual } from "lodash"
import { getSortingIndicator, ListsDispatch, useChangePageTitle } from "utils"
import { listsActionTypes } from "redux-reducers"
import { useDispatch, useSelector } from "react-redux"
import { ReduxState } from "redux-store"
import { ContentWrapper, DataStatusWrapper, PageContainer, PageHeader } from "components/page"

type Props = {
  children?: never
} & RouteComponentProps

export const LayoutsScreen = (props: Props) => {
  const isSortedBy = useSelector((state: ReduxState) => state.lists.layouts.sortBy, isEqual)
  const isDescending = useSelector((state: ReduxState) => state.lists.layouts.desc, isEqual)

  /**
   * JSX
   */

  return (
    <PageContainer>
      <Query query={QUERY_LAYOUTS} variables={{ sortBy: isSortedBy, desc: isDescending } as LayoutsVariables}>
        {({ loading, error, data }: QueryResult<Layouts>) => {
          if (error) {
            return (
              <DataStatusWrapper>
                <Error>{error.message}</Error>
              </DataStatusWrapper>
            )
          }
          if (!data || !data.layouts || loading) {
            return (
              <DataStatusWrapper>
                <Loading>Loading...</Loading>
              </DataStatusWrapper>
            )
          }

          return <Content {...props} data={data} isSortedBy={isSortedBy} isDescending={isDescending} />
        }}
      </Query>
    </PageContainer>
  )
}

type ContentProps = {
  data: QueryResult<Layouts>["data"]
  isSortedBy: string
  isDescending: boolean
} & Props

type LayoutToDelete = React.ComponentProps<typeof DeleteLayoutModal>["layoutToDelete"]
const Content = (props: ContentProps) => {
  const { data, isSortedBy, isDescending } = props
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false)
  const [isAddLayoutModalOpen, setIsAddLayoutModalOpen] = React.useState(false)

  const [layoutToDelete, setLayoutToDelete] = React.useState<LayoutToDelete>({ id: "", name: "" })

  /**
   * Change Page Title
   */

  useChangePageTitle(props.match.path)

  /**
   * Navigate To Layout
   *
   * @param layoutId
   * @param defaultVariantId
   */

  const navigateToLayout = ({ layoutId, defaultVariantId }: { layoutId: string; defaultVariantId: string }) => {
    props.history.push(routes.layoutVariant.replace(":layoutId", layoutId).replace(":variantId", defaultVariantId))
  }

  /**
   * Open Delete Modal
   *
   * @param layout
   */

  const openDeleteModal = (layout: LayoutToDelete) => () => {
    setIsDeleteModalOpen(true)
    setLayoutToDelete({ id: layout.id, name: layout.name })
  }

  /**
   * Open Add Layout Modal
   */

  const openAddLayout = () => setIsAddLayoutModalOpen(true)

  /**
   * Set List Sorting
   */

  const dispatch = useDispatch()

  const setSortBy = useCallback(
    (sortBy: string) =>
      dispatch<ListsDispatch>({ type: listsActionTypes.setLayoutsSortBy, data: { layouts: { sortBy } } }),
    [dispatch]
  )
  const setDesc = useCallback(
    (desc: boolean) => dispatch<ListsDispatch>({ type: listsActionTypes.setLayoutsDesc, data: { layouts: { desc } } }),
    [dispatch]
  )

  const sortList = (sortBy: string) => () => {
    if (sortBy !== isSortedBy) {
      setSortBy(sortBy)
      setDesc(false)
      return
    }
    if (sortBy === isSortedBy && !isDescending) {
      setDesc(true)
      return
    }
    if (sortBy === isSortedBy && isDescending) {
      setDesc(false)
      return
    }
  }

  /**
   * JSX
   */

  return (
    <React.Fragment>
      <PageHeader heading={`Layouts`} button={{ title: `Add Layout`, action: openAddLayout }} />

      <ContentWrapper>
        <Table
          noContentNotification={"There are no Layouts yet"}
          content={{
            head: [
              <ColumnHeading
                onClick={sortList("name")}
                arrowState={getSortingIndicator({ sortBy: "name", isSortedBy, isDescending })}
              >
                {`Title`}
              </ColumnHeading>,
              <ColumnHeading>{`Action`}</ColumnHeading>,
            ],

            body: data.layouts.edges.map(({ node: layout }) => {
              const defaultVariant = find(layout.variantSet.edges, ({ node: variant }) => variant.default === true)

              return [
                <AnchorLink
                  color={"green"}
                  weight={"semiBold"}
                  size={"14px"}
                  onClick={() =>
                    navigateToLayout({
                      layoutId: layout.id,
                      defaultVariantId: defaultVariant.node.id,
                    })
                  }
                >
                  {layout.default ? capitalize(layout.name) : layout.name}
                </AnchorLink>,
                <ActionContainer>
                  <ActionButton actionType={"delete"} onClick={openDeleteModal(layout)} isDisabled={layout.default} />
                </ActionContainer>,
              ]
            }),
          }}
        />
      </ContentWrapper>

      <DeleteLayoutModal
        isModalOpen={isDeleteModalOpen}
        layoutToDelete={layoutToDelete}
        closeModal={() => {
          setIsDeleteModalOpen(false)
        }}
        queryVariables={{ sortBy: isSortedBy, desc: isDescending }}
      />

      <AddLayoutModal
        isModalOpen={isAddLayoutModalOpen}
        closeModal={() => setIsAddLayoutModalOpen(false)}
        queryVariables={{ sortBy: isSortedBy, desc: isDescending }}
      />
    </React.Fragment>
  )
}
