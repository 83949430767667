import React from "react"
import styled, { css } from "styled-components"
import { theme } from "theme"

type ButtonObjectType = Pick<Props, "variant" | "color" | "size" | "width">
type IconContainerType = Pick<Props, "variant" | "color" | "size">

type Props = {
  color?: "green" | "red" | "lightGreen"
  size?: "regular" | "small"
  variant?: "filled" | "outlined"
  isDisabled?: boolean
  icon?: React.ReactNode
  onClick?: () => void
  children: string
  type?: "button" | "submit"
  width?: string
}

export const Button = React.forwardRef((props: Props, ref: React.Ref<HTMLButtonElement>) => {
  const {
    icon,
    size = "regular",
    variant = "filled",
    color = "green",
    isDisabled,
    children,
    onClick,
    type = "button",
    width,
  } = props

  return (
    <ButtonObject
      size={size}
      color={color}
      ref={ref}
      variant={variant}
      onClick={type === "button" ? onClick : null}
      disabled={isDisabled}
      type={type}
      width={width}
    >
      {icon && (
        <IconContainer size={size} color={color} variant={variant}>
          {icon}
        </IconContainer>
      )}
      {children}
    </ButtonObject>
  )
})

const ButtonObject = styled("button")`
  border: none;
  color: ${(props: ButtonObjectType) => {
    if (props.variant === "filled") {
      return theme.color.white
    }
  }};

  background: transparent;

  padding: ${(props: ButtonObjectType) => {
    if (props.size === "regular") {
      return `13px ${theme.offset3}`
    } else if (props.size === "small") {
      return `3px ${theme.offset1}`
    }
  }};

  ${(props: ButtonObjectType) => {
    if (props.variant === "filled" && props.color === "green") {
      return primaryGreen
    }
    if (props.variant === "filled" && props.color === "red") {
      return primaryRed
    }
    if (props.variant === "outlined" && props.color === "green") {
      return outlinedGreen
    }
    if (props.variant === "outlined" && props.color === "red") {
      return outlinedRed
    }
    if (props.variant === "filled" && props.color === "lightGreen") {
      return lightGreen
    }
    return primaryGreen
  }};

  border-radius: 100px;
  font-family: ${theme.font.family.default};
  font-weight: ${theme.font.weight.semiBold};
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: none;
  width: ${(props: ButtonObjectType) => (props.width ? props.width : "auto")};
  & > * {
    margin-right: 8px;
  }
`

const primaryGreen = css`
  background: ${theme.color.primaryGreen};

  &:hover {
    background: ${theme.color.primaryGreenDark};
  }

  &:disabled {
    background: ${theme.color.primaryGreenLight};
    cursor: not-allowed;
  }
`

const primaryRed = css`
  background: ${theme.color.warningRed};

  &:hover {
    background: ${theme.color.warningRedDark};
  }

  &:disabled {
    background: ${theme.color.warningRedLight};
    cursor: not-allowed;
  }
`
const lightGreen = css`
  border: none;
  background: ${theme.color.primaryGreenLight};

  &:hover {
    background: ${theme.color.primaryGreen};
  }
  &:disabled {
    background: ${theme.color.borderGray};
  }
`

const outlinedGreen = css`
  border: 1px solid ${theme.color.primaryGreen};
  color: ${theme.color.primaryGreen};

  &:hover {
    border: 1px solid ${theme.color.primaryGreenDark};
    color: ${theme.color.primaryGreenDark};
    svg {
      stroke: ${theme.color.primaryGreenDark};
    }
  }

  &:disabled {
    border: 1px solid ${theme.color.primaryGreenLight};
    color: ${theme.color.primaryGreenLight};
    cursor: not-allowed;
    svg {
      stroke: ${theme.color.primaryGreenLight};
    }
  }
`

const outlinedRed = css`
  border: 1px solid ${theme.color.warningRed};
  color: ${theme.color.warningRed};

  &:hover {
    border: 1px solid ${theme.color.warningRedDark};
    color: ${theme.color.warningRedDark};
    svg {
      stroke: ${theme.color.warningRedDark};
    }
  }

  &:disabled {
    border: 1px solid ${theme.color.warningRedLight};
    color: ${theme.color.warningRedLight};
    cursor: not-allowed;
    svg {
      stroke: ${theme.color.warningRedLight};
    }
  }
`
const IconContainer = styled("div")`
  ${(props: IconContainerType) => {
    if (props.size === "small") {
      return iconSmall
    } else if (props.size === "regular") {
      return iconRegular
    }
  }};
  svg {
    stroke: ${(props: IconContainerType) => {
      if (
        (props.color === "green" || props.color === "red" || props.color === "lightGreen") &&
        props.variant === "filled"
      ) {
        return theme.color.white
      }
      if (props.color === "green" && props.variant === "outlined") {
        return theme.color.primaryGreen
      }
      if (props.color === "red" && props.variant === "outlined") {
        return theme.color.warningRed
      }
    }};
  }
`

const iconSmall = css`
  height: 10px;
  width: 10px;
`

const iconRegular = css`
  height: 20px;
  width: 20px;
`
