import React from "react"
import { IconLoading } from "components/icons"
import styled, { keyframes } from "styled-components"

type Props = {
  color: string
  size?: string
  children?: never
}

export const Spinner: React.FC<Props> = ({ color, size = "100%" }) => {
  return (
    <AnimatedContainer size={size}>
      <IconLoading color={color} />
    </AnimatedContainer>
  )
}

const spin = keyframes`
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 360deg);
  }
`

const AnimatedContainer = styled("div")`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: ${(props: Pick<Props, "size">) => props.size};
  height: ${(props: Pick<Props, "size">) => props.size};
  animation: ${spin} 0.6s linear infinite;
`
