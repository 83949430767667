import { useReducer } from "react"
import { Action } from "utils"

/**
 * Initial State
 */

export const initialInviteUserFormState = {
  firstName: "",
  lastName: "",
  email: "",
  role: "",

  firstNameWarning: "",
  lastNameWarning: "",
  emailWarning: "",
}

/**
 * Dispatch Action Types
 */

export const inviteUserFormActionTypes = {
  setFirstName: "SET_FIRST_NAME",
  setLastName: "SET_LAST_NAME",
  setEmail: "SET_EMAIL",
  setRole: "SET_ROLE",

  setFirstNameWarning: "SET_FIRST_NAME_WARNING",
  setLastNameWarning: "SET_LAST_NAME_WARNING",
  setEmailWarning: "SET_EMAIL_WARNING",

  resetForm: "RESET_FORM",
}

/**
 * Reducer
 */

export type InitialInviteUserFormState = typeof initialInviteUserFormState

type ActionData = {
  firstName?: string
  lastName?: string
  email?: string
  role?: string

  firstNameWarning?: string
  lastNameWarning?: string
  emailWarning?: string
}

const reducer = (
  state: InitialInviteUserFormState = initialInviteUserFormState,
  action: Action<ActionData>
): InitialInviteUserFormState => {
  switch (action.type) {
    // Set First Name
    case inviteUserFormActionTypes.setFirstName:
      if (action.data.firstName === state.firstName) {
        return state
      }
      return {
        ...state,
        firstName: action.data.firstName,
        firstNameWarning: "",
      }

    // Set Last Name
    case inviteUserFormActionTypes.setLastName:
      if (action.data.lastName === state.lastName) {
        return state
      }

      return {
        ...state,
        lastName: action.data.lastName,
        lastNameWarning: "",
      }

    // Set Email
    case inviteUserFormActionTypes.setEmail:
      if (action.data.email === state.email) {
        return state
      }
      return {
        ...state,
        email: action.data.email,
        emailWarning: "",
      }

    // Set Role
    case inviteUserFormActionTypes.setRole:
      if (action.data.role === state.role) {
        return state
      }
      return {
        ...state,
        role: action.data.role,
      }

    // Set Email Warning
    case inviteUserFormActionTypes.setEmailWarning:
      if (action.data.emailWarning === state.emailWarning) {
        return state
      }
      return {
        ...state,
        emailWarning: action.data.emailWarning,
      }

    // Set First Name Warning
    case inviteUserFormActionTypes.setFirstNameWarning:
      if (action.data.firstNameWarning === state.firstNameWarning) {
        return state
      }
      return {
        ...state,
        firstNameWarning: action.data.firstNameWarning,
      }

    // Set Last Name Warning
    case inviteUserFormActionTypes.setLastNameWarning:
      if (action.data.lastNameWarning === state.lastNameWarning) {
        return state
      }
      return {
        ...state,
        lastNameWarning: action.data.lastNameWarning,
      }

    // Reset Form
    case inviteUserFormActionTypes.resetForm:
      return initialInviteUserFormState

    // Default
    default:
      throw new Error(`"${action.type}" is not a valid action!`)
  }
}

/**
 * Form State Hook
 */

export const useFormState = () => {
  const [state, dispatch] = useReducer(reducer, initialInviteUserFormState)

  return { state, dispatch }
}

export type InviteFormState = ReturnType<typeof useFormState>["state"]
export type FormDispatch = ReturnType<typeof useFormState>["dispatch"]
