import { Action, hasToken } from "utils"

/**
 * Initial State
 */

export const authInitialState = {
  isAuthenticated: hasToken(),
}

export type AuthState = typeof authInitialState

/**
 * Action Types
 */

export const authActionTypes = {
  setIsAuthenticated: "SET_IS_AUTHENTICATED",
}

/**
 * Reducer
 */

export type AuthActionData = {
  isAuthenticated?: boolean
}

export const authReducer = (state: AuthState = authInitialState, action: Action<AuthActionData>): AuthState => {
  if (action.type === authActionTypes.setIsAuthenticated) {
    return {
      ...state,
      isAuthenticated: action.data.isAuthenticated,
    }
  } else {
    return state
  }
}
