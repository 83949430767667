import React, { useReducer } from "react"
import { SuccessModal } from "./modals/success-modal"
import { LeaveModal } from "./modals/leave-modal"
import { Action, actionTypes, initialState, reducer } from "./state"
import { history } from "router"
import { History, Location } from "history"
import { ModalContext } from "./modal-context"
import { LoadingModal } from "providers/modal/modals/loading-modal"

type Props = {
  children: React.ReactNode
}

export const ModalProvider = (props: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const activateSuccessModal = () => {
    dispatch({ type: actionTypes.setIsSuccessModalOpen, value: true })
    window.setTimeout(() => dispatch({ type: actionTypes.setIsSuccessModalOpen, value: false }), 2000)
  }

  return (
    <ModalContext.Provider
      value={{
        state,
        dispatch,
        activateSuccessModal, // deprecated
      }}
    >
      {props.children}

      <SuccessModal isModalOpen={state.isSuccessModalOpen} message={state.successModalMessage} />

      <LeaveModal
        isModalOpen={state.isLeaveModalOpen}
        confirmModal={confirmLeaveModal({ dispatch: dispatch, history: history, nextLocation: state.nextLocation })}
        closeModal={closeLeaveModal({ dispatch: dispatch })}
      />

      <LoadingModal isModalOpen={state.isLoadingModalOpen} message={state.loadingModalMessage} />
    </ModalContext.Provider>
  )
}

// Helper functions

type Dispatch = (value: Action) => void

type ConfirmLeaveModalArgs = {
  dispatch: Dispatch
  history: History<any>
  nextLocation: Location<any>
}

const confirmLeaveModal = (args: ConfirmLeaveModalArgs) => () => {
  const { history, dispatch, nextLocation } = args

  dispatch({ type: actionTypes.closeLeaveModal })
  history.push(nextLocation.pathname)
}

type CloseLeaveModalArgs = {
  dispatch: Dispatch
}

const closeLeaveModal = (args: CloseLeaveModalArgs) => () => {
  const { dispatch } = args

  dispatch({ type: actionTypes.setCanLeavePage, value: false })
  dispatch({ type: actionTypes.closeLeaveModal })
}
