import React, { useState } from "react"
import styled from "styled-components"
import { Textarea } from "components/textarea"
import { theme } from "theme"
import { IconClose, IconDone } from "components/icons"
import { TextField } from "components/text-field"
import { AutoResizableCodeTextarea, AutoResizableTextarea } from "components/textarea/auto-resizable-textarea"

type Props = {
  answerType: "YES" | "NO"
} & React.ComponentProps<typeof Textarea>

export const AnswerTextarea = (props: Props) => {
  const { isDisabled, hasWarning, answerType, onChange, fontType, placeholder, value, hasStaticHeight } = props
  const [isFocused, setIsFocused] = useState(false)

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value)
  }

  const handleFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    setIsFocused(true)
    if (event.target.value) event.target.select()
  }

  const handleBlur = () => setIsFocused(false)

  return (
    <Container>
      <IconContainer isDisabled={isDisabled} isFocused={isFocused}>
        <IconWrapper>
          {answerType === "YES" && (
            <IconDone color={isDisabled ? theme.color.primaryGreenLight : theme.color.primaryGreen} />
          )}
          {answerType === "NO" && (
            <IconClose color={isDisabled ? theme.color.warningRedLight : theme.color.warningRed} />
          )}
        </IconWrapper>
      </IconContainer>

      <TextField isFocused={isFocused} hasWarning={hasWarning} isDisabled={isDisabled} hasNoLeftBorder={true}>
        {fontType === "CODE" ? (
          <AutoResizableCodeTextarea
            placeholder={placeholder}
            value={value !== null ? value : ""}
            onChange={handleChange}
            minRows={hasStaticHeight ? 6 : 1}
            maxRows={6}
            disabled={isDisabled}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        ) : (
          <AutoResizableTextarea
            placeholder={placeholder}
            value={value !== null ? value : ""}
            onChange={handleChange}
            minRows={hasStaticHeight ? 6 : 1}
            maxRows={6}
            disabled={isDisabled}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        )}
      </TextField>
    </Container>
  )
}

const Container = styled("div")`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
`

type IconContainerProps = {
  isDisabled: boolean
  isFocused: boolean
}

const IconContainer = styled("div")`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 50px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props: IconContainerProps) => {
    if (props.isFocused) {
      return theme.color.textNonactive
    }
    if (props.isDisabled) {
      return theme.color.backgroundGray
    }
    return theme.color.borderGray
  }};
  border-top-left-radius: ${theme.rounding1};
  border-bottom-left-radius: ${theme.rounding1};
  background-color: ${theme.color.backgroundGray};
`

const IconWrapper = styled("div")`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 10px;
  width: 100%;
  max-width: 14px;
`
