import React, { useRef, useState } from "react"
import { IconDown, IconLogOut } from "../icons"
import { AuthDispatch, useHandleClickOutside } from "utils"
import styled from "styled-components"
import { theme } from "theme"
import { history, routes } from "router"
import { useMutation } from "react-apollo"
import { MUTATION_REVOKE_TOKEN, useRevokeToken } from "model/graphql/mutations"
import { useDispatch } from "react-redux"
import { authActionTypes } from "redux-reducers"

type Props = {
  avatar: string
  userName: string
  children?: never
}

type MenuState = "open" | "closed"

export const Dropdown = (props: Props) => {
  const dropdownRef = useRef(null)
  const [menuState, setMenuState] = useState<MenuState>("closed")
  const avatarImageSrc = props.avatar ? `${process.env.REACT_APP_MEDIA_URL_PREFIX}${props.avatar}` : undefined

  const [revokeTokenMutation] = useMutation(MUTATION_REVOKE_TOKEN)

  /**
   * Handle Click Outside
   */

  useHandleClickOutside({ ref: dropdownRef, handler: () => setMenuState("closed") })

  /**
   * Toggle Menu
   */

  const toggleMenu = () => setMenuState(menuState === "closed" ? "open" : "closed")

  /**
   * Navigate to My Profile
   */

  const navigateToMyProfile = () => {
    setMenuState("closed")
    history.push(routes.myProfile)
  }

  /**
   * Log Out
   */

  const dispatch = useDispatch()

  const handleSuccess = () => {
    setMenuState("closed")
    dispatch<AuthDispatch>({ type: authActionTypes.setIsAuthenticated, data: { isAuthenticated: false } })
  }

  const { revokeToken } = useRevokeToken({
    onSuccessAction: handleSuccess,
  })

  /**
   * JSX
   */

  return (
    <Container ref={dropdownRef}>
      <Toggle onClick={toggleMenu}>
        <AvatarWrapper>{avatarImageSrc && <Avatar src={avatarImageSrc} alt="avatar" />}</AvatarWrapper>
        <UserName>{props.userName}</UserName>

        <IconWrapper menuState={menuState}>
          <IconDown color={theme.color.primaryGreen} />
        </IconWrapper>
      </Toggle>

      {menuState === "open" && (
        <MenuContainer>
          {/* My Profile */}
          <MenuItemContainer onClick={navigateToMyProfile}>
            <ItemLink>{"My Profile"}</ItemLink>
          </MenuItemContainer>

          {/* Logout */}
          <MenuItemContainer onClick={revokeToken(revokeTokenMutation)}>
            <ItemLink>
              {"Logout"}
              <ItemIconWrapper>
                <IconLogOut />
              </ItemIconWrapper>
            </ItemLink>
          </MenuItemContainer>
        </MenuContainer>
      )}
    </Container>
  )
}

const Container = styled("div")`
  display: flex;
  width: fit-content;
  position: relative;
  height: 70px;
  background: ${theme.color.white};
`

const Toggle = styled("div")`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  padding-left: ${theme.offset1};
  padding-right: ${theme.offset3};
  cursor: pointer;
`

const AvatarWrapper = styled("div")`
  display: flex;
  position: relative;
  width: 30px;
  height: 30px;
  margin-right: ${theme.offset1};
  overflow: hidden;
  border-radius: 15px;
  background-color: ${theme.color.borderGray};
`

const Avatar = styled("img")`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const UserName = styled("span")`
  font-weight: ${theme.font.weight.semiBold};
  flex-grow: 1;
  color: ${theme.color.primaryGreen};
  font-size: 16px;
  white-space: nowrap;
  line-height: 22px;
`

type IconWrapperProps = {
  menuState: MenuState
}

const IconWrapper = styled("div")`
  display: flex;
  position: relative;
  width: 20px;
  height: 10px;
  justify-content: center;
  margin-left: ${theme.offset1};
  transform: ${(props: IconWrapperProps) => (props.menuState === "open" ? "rotate(180deg)" : "rotate(0deg)")};
`

const MenuContainer = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 70px;
  left: 0;
  padding-top: ${theme.offset1};
  padding-bottom: ${theme.offset1};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: ${theme.color.white};
`

const MenuItemContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  position: relative;
  line-height: 22px;
  cursor: pointer;
  margin-bottom: 15px;
  padding-left: ${theme.offset1};
  padding-right: ${theme.offset3};
  color: ${theme.color.primaryGreen};
  font-weight: ${theme.font.weight.regular};
  font-size: 16px;

  svg {
    stroke: ${theme.color.warningRed};
  }

  &:hover {
    text-decoration: underline;
    svg {
      stroke: ${theme.color.warningRedDark};
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`

const ItemLink = styled("div")`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

const ItemIconWrapper = styled("div")`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
`
