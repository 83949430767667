import React from "react"
import styled from "styled-components"
import { theme } from "theme"

type Props = {
  isDisabled?: boolean
  children: React.ReactNode
  rotate?: "90deg" | "180deg" | "270deg"
  onClick: () => void
}

export const RoundButton = (props: Props) => {
  const { isDisabled, children, rotate, onClick } = props

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    if (isDisabled) {
      return
    }

    onClick()
  }

  return (
    <Button onClick={handleClick} disabled={isDisabled}>
      <IconWrapper isDisabled={isDisabled} rotate={rotate}>
        {children}
      </IconWrapper>
    </Button>
  )
}

const Button = styled("button")`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  border: none;
  cursor: pointer;
  outline: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: ${theme.color.white};

  &:hover {
    background-color: ${theme.color.primaryGreen};
  }

  &:disabled {
    cursor: not-allowed;

    &:hover {
      background-color: ${theme.color.white};
    }
  }
`

type IconWrapperProps = {
  isDisabled: boolean
  rotate: Props["rotate"]
}

const IconWrapper = styled("div")`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  transform-origin: center center;
  transform: rotate(${(props: IconWrapperProps) => (props.rotate ? props.rotate : "0deg")});

  svg {
    stroke: ${(props: IconWrapperProps) =>
      props.isDisabled ? theme.color.primaryGreenLight : theme.color.primaryGreen};
  }

  ${Button}:hover & svg {
    stroke: ${(props: IconWrapperProps) => (props.isDisabled ? theme.color.primaryGreenLight : theme.color.white)};
  }
`
