import React, { useCallback } from "react"
import { ActionButton, ActionContainer, ColumnHeading, Table } from "components/table"
import { NavigationLink } from "components/links"
import { routes } from "router"
import { Quizzes_quizzes_edges_node } from "model/types"
import styled from "styled-components"
import { theme } from "theme"
import { QuizToDelete } from "./quizzes-screen"
import { getFormattedDateTime, openQuizInNewTab } from "./helpers"
import { QuizStatus } from "components/quiz-status"
import { useDispatch } from "react-redux"
import { getSortingIndicator, ListsDispatch } from "utils"
import { listsActionTypes } from "redux-reducers"

type Props = {
  tab: string
  quizzes: Quizzes_quizzes_edges_node[]
  openDeleteModal: (quiz: QuizToDelete) => () => void
  children?: never
  isSortedBy: string
  isDescending: boolean
}

export const QuizzesTable = (props: Props) => {
  const { quizzes = [], openDeleteModal, isSortedBy, isDescending } = props

  /**
   * Set List Sorting
   */
  const dispatch = useDispatch()

  const setSortBy = useCallback(
    (sortBy: string) =>
      dispatch<ListsDispatch>({ type: listsActionTypes.setQuizzesSortBy, data: { quizzes: { sortBy } } }),
    [dispatch]
  )
  const setDesc = useCallback(
    (desc: boolean) => dispatch<ListsDispatch>({ type: listsActionTypes.setQuizzesDesc, data: { quizzes: { desc } } }),
    [dispatch]
  )

  const sortList = (sortBy: string) => () => {
    if (sortBy !== isSortedBy) {
      setSortBy(sortBy)
      setDesc(false)
      return
    }
    if (sortBy === isSortedBy && !isDescending) {
      setDesc(true)
      return
    }
    if (sortBy === isSortedBy && isDescending) {
      setDesc(false)
      return
    }
  }

  /**
   * JSX
   */

  return (
    <React.Fragment>
      <Table
        pageHeaderHasNavigation={true}
        contentType={"quizzes"}
        noContentNotification={"Ups! There is no such Quiz…"}
        content={{
          head: [
            <ColumnHeading
              onClick={sortList("title")}
              arrowState={getSortingIndicator({ sortBy: "title", isSortedBy, isDescending })}
            >{`Title`}</ColumnHeading>,
            <ColumnHeading
              onClick={sortList("slug")}
              arrowState={getSortingIndicator({ sortBy: "slug", isSortedBy, isDescending })}
            >{`Url Slug`}</ColumnHeading>,
            <ColumnHeading
              onClick={sortList("author__last_name")}
              arrowState={getSortingIndicator({ sortBy: "author__last_name", isSortedBy, isDescending })}
            >{`Author`}</ColumnHeading>,
            <ColumnHeading
              onClick={sortList("created_at")}
              arrowState={getSortingIndicator({ sortBy: "created_at", isSortedBy, isDescending })}
            >{`Created`}</ColumnHeading>,
            <ColumnHeading
              onClick={sortList("published_at")}
              arrowState={getSortingIndicator({ sortBy: "published_at", isSortedBy, isDescending })}
            >{`Status`}</ColumnHeading>,
            <ColumnHeading>{`Action`}</ColumnHeading>,
          ],

          body: quizzes.map(quiz => [
            <NavigationLink
              color={"green"}
              weight={"semiBold"}
              size={"16px"}
              to={routes.quiz
                .replace(":tab", props.tab)
                .replace(":quizId", quiz.id)
                .replace(":quizTab", "general")}
            >
              {quiz.title}
            </NavigationLink>,
            <PlainTableTextWrapper>{quiz.slug}</PlainTableTextWrapper>,
            <PlainTableTextWrapper>{`${quiz.author.firstName} ${quiz.author.lastName}`}</PlainTableTextWrapper>,
            <PlainTableTextWrapper>{getFormattedDateTime(quiz.createdAt)}</PlainTableTextWrapper>,
            <PlainTableTextWrapper>
              <QuizStatus isPublished={Boolean(quiz.publishedAt)} />
            </PlainTableTextWrapper>,
            <ActionContainer>
              <ActionButton
                actionType={"view"}
                onClick={openQuizInNewTab(
                  routes.quiz
                    .replace(":tab", props.tab)
                    .replace(":quizId", quiz.id)
                    .replace(":quizTab", "general")
                )}
              />
              <ActionButton actionType={"delete"} onClick={openDeleteModal({ id: quiz.id, title: quiz.title })} />
            </ActionContainer>,
          ]),
        }}
      />
    </React.Fragment>
  )
}

const PlainTableTextWrapper = styled("div")`
  font-family: ${theme.font.family.default};
  font-weight: ${theme.font.weight.regular};
  color: ${theme.color.textBlack};
  font-size: 16px;
`
