import gql from "graphql-tag"
import { getRefreshToken, removeRefreshToken, removeToken, setRefreshToken, setToken } from "utils"
import { MutationResult, MutationFunction } from "react-apollo"
import { LogIn, LogInVariables, RevokeToken, RevokeTokenVariables } from "model/types"
import { ApolloError } from "apollo-client"
import { client } from "model/client"

/**
 * Revoke Token
 */

// Mutation

export const MUTATION_REVOKE_TOKEN = gql`
  mutation RevokeToken($refreshToken: String!) {
    revokeToken(refreshToken: $refreshToken) {
      revoked
    }
  }
`

// Hook

type UseRevokeTokenArgs = {
  onSuccessAction: () => void
}

export type RevokeTokenMutationFunction = MutationFunction<RevokeToken, RevokeTokenVariables>

export const useRevokeToken = (args: UseRevokeTokenArgs) => {
  const { onSuccessAction } = args

  const revokeToken = (revokeTokenMutation: RevokeTokenMutationFunction) => async () => {
    const refreshToken = getRefreshToken()
    if (refreshToken) {
      try {
        await revokeTokenMutation({
          variables: {
            refreshToken,
          },
        })
        removeToken()
        removeRefreshToken()
        await client.clearStore()
        onSuccessAction()
      } catch (error) {
        console.error(error)
      }
    }
  }

  return { revokeToken }
}

/**
 * Log In
 */

// Mutation

export const MUTATION_LOGIN = gql`
  mutation LogIn($username: String!, $password: String!) {
    tokenAuth(username: $username, password: $password) {
      token
      refreshToken
    }
  }
`

// Hook

export type UseLogInArgs = {
  onSuccessAction: () => void
  onErrorAction: (errorMessage: ApolloError["message"]) => void
}

export const useLogIn = (args: UseLogInArgs) => {
  const { onSuccessAction, onErrorAction } = args

  const logIn = async (logInMutation: LogInMutation, variables: LogInVariables) => {
    try {
      const result = (await logInMutation({ variables })) as LogInMutationResult

      const { token, refreshToken } = result.data.tokenAuth

      setToken(token)
      setRefreshToken(refreshToken)

      onSuccessAction()
    } catch (error) {
      console.error(error)

      const { message }: ApolloError = error
      onErrorAction(message)
    }
  }

  return { logIn }
}

export type LogInMutation = MutationFunction<LogIn, LogInVariables>
export type LogInMutationResult = MutationResult<LogIn>
